import { put, call, takeLatest, select } from "redux-saga/effects";
import {
  getPlans,
  checkout,
  getSubscription,
  upgradeSubscription,
  renewSubscription,
  cancelSubscription,
} from "../../api/subscription/subscription-api";
import { subscriptionActions } from "../../store";
///
///

function* getPlansRequest() {
  const { requestError, getPlansSuccess } = subscriptionActions;
  const result = yield call(getPlans);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getPlansSuccess(result.data));
  }
}

function* checkoutRequest({ payload }) {
  const { requestError, checkoutSuccess } = subscriptionActions;
  const result = yield call(checkout, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(checkoutSuccess(result.data));
  }
}

function* getSubscriptionRequest({ payload }) {
  const { requestError, getSubscriptionSuccess } = subscriptionActions;
  const result = yield call(getSubscription, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getSubscriptionSuccess(result.data));
  }
}

function* renewSubscriptionRequest({ payload }) {
  const { requestError, renewSubscriptionSuccess, getSubscription } =
    subscriptionActions;
  const result = yield call(renewSubscription, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    const { token } = yield select((state) => state.auth);
    if (payload.cb) {
      yield call(payload.cb);
    }
    yield put(renewSubscriptionSuccess(result.data));
    yield put(getSubscription(token));
  }
}

function* cancelSubscriptionRequest({ payload }) {
  const { requestError, cancelSubscriptionSuccess, getSubscription } =
    subscriptionActions;
  const result = yield call(cancelSubscription, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    const { token } = yield select((state) => state.auth);
    if (payload.cb) {
      yield call(payload.cb);
    }
    yield put(cancelSubscriptionSuccess(result.data));
    yield put(getSubscription(token));
  }
}

function* upgradeSubscriptionRequest({ payload }) {
  const {
    requestError,
    upgradeSubscriptionSuccess,
    getPlans,
    getSubscription,
  } = subscriptionActions;
  const result = yield call(upgradeSubscription, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    const { token } = yield select((state) => state.auth);
    if (payload.cb) {
      yield call(payload.cb);
    }
    yield put(upgradeSubscriptionSuccess(result.data));
    yield put(getPlans());
    yield put(getSubscription(token));
  }
}

// Export the saga (todo-saga)
export default function* subscriptionSaga() {
  yield takeLatest(`subscription/getPlans`, getPlansRequest);
  yield takeLatest(`subscription/checkout`, checkoutRequest);
  yield takeLatest(
    `subscription/cancelSubscription`,
    cancelSubscriptionRequest
  );
  yield takeLatest(`subscription/renewSubscription`, renewSubscriptionRequest);
  yield takeLatest(`subscription/getSubscription`, getSubscriptionRequest);
  yield takeLatest(
    `subscription/upgradeSubscription`,
    upgradeSubscriptionRequest
  );
}
