import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { Line } from "@ant-design/plots";
import { getYearlyTransactionsCount } from "../../../store/slices/dashboard/users-count-slice";
import { LoadingOutlined } from "@ant-design/icons";
import { Empty, Spin } from "antd";

const Donut = () => {
  const dispatch = useDispatch();
  const { yearlyTransactions, yearlyTransactionsLoading } = useSelector(
    (state) => state.users
  );
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getYearlyTransactionsCount(token));
  }, []);

  const config = {
    data: yearlyTransactions,
    xField: "month",
    yField: "count",
    label: {},
    point: {
      padding: 3,
      size: 3,
      shape: "diamond",
      style: {
        fill: "white",
        stroke: "#5B8FF9",
        lineWidth: 2,
      },
    },
    autoFit: true,
    tooltip: {
      showMarkers: false,
    },
    limitInPlot: false,
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: "#000",
          fill: "red",
        },
      },
    },
    // label: {
    //   formatter: (e) => e.month,
    // },
    interactions: [
      {
        type: "marker-active",
      },
    ],
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 90,
      }}
      spin
    />
  );

  return (
    <div className="w-full">
      {!yearlyTransactionsLoading ? (
        yearlyTransactions ? (
          <Line {...config} />
        ) : (
          <Empty />
        )
      ) : (
        <div className="h-[370px] flex items-center justify-center w-full">
          <Spin indicator={antIcon} />
        </div>
      )}
    </div>
  );
};

export default Donut;
