import React, { useEffect } from "react";
import { Tabs } from "antd";
import "../inquiries/inquiries.css";
import PendingInquiry from "./PendingInquiry";
import ProcessedInquiry from "./ProcessedInquiry";
import ResolvedInquiry from "./ResolvedInquiry";
import RemovedInquiry from "./RemovedInquiry";
import { useDispatch, useSelector } from "react-redux";
import { setActiveKey } from "../../../../store/slices/inquiries/inquiries-slice";

const items = [
  { label: "Pending", key: "item-1", children: <PendingInquiry /> }, // remember to pass the key prop
  { label: "Processing", key: "item-2", children: <ProcessedInquiry /> },
  { label: "Resolved", key: "item-3", children: <ResolvedInquiry /> },
  { label: "Removed", key: "item-4", children: <RemovedInquiry /> },
];

const InquiriesReports = () => {
  const dispatch = useDispatch();
  const { activeKey } = useSelector((state) => state.inquiry);

  return (
    <Tabs
      defaultActiveKey={activeKey}
      type="card"
      className="tabs-inquiries"
      activeKey={activeKey}
      onChange={(e) => dispatch(setActiveKey(e))}
      animated={true}
      items={items}
    ></Tabs>
  );
};
export default InquiriesReports;
