import { DownOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Dropdown,
  Empty,
  Form,
  Image,
  Popconfirm,
  Space,
  Table,
  Tag,
  theme,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUtilityReports,
  processUtilityReport,
  removeUtilityReport,
} from "../../../../store/slices/inquiries/inquiries-slice";
import {
  addPropertyPhase,
  getUpdatedPhase,
} from "../../../../store/slices/property/property-phase-slice";
import { LoadingTime } from "../../../../UI/LoadingScreen";
import "../../properties/property.css";
import InquiriesModal from "../inquiries/InquiriesModal";
import { pendingActions } from "./ReportsActionData";
import PopconfirmRemove from "../../../../UI/PopconfirmRemove";

const { useToken } = theme;

const PendingReports = () => {
  const { token } = useToken();
  const [form] = Form.useForm();
  const { phases, isLoading, phaseId } = useSelector(
    (state) => state.propertyPhases
  );
  const { utilityReports, utilityReportsLoading } = useSelector(
    (state) => state.inquiry
  );
  const { token: userToken } = useSelector((state) => state.auth);
  const [modal2Open, setModal2Open] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedPhase, setSelectedPhase] = useState();
  const dispatch = useDispatch();

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const text = "Are you sure to remove this report type?";
  const description = "Remove the report type";

  const text2 = "Are you sure to process this report type?";
  const description2 = "Process the report type";

  // processReports(record.reportId)
  //                       : () => deleteReport(record.reportId)
  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "150px",
      render: (record) => (
        <Dropdown
          trigger={["click"]}
          dropdownRender={() => (
            <div className=" flex flex-col items-start" style={contentStyle}>
              <Popconfirm
                placement="right"
                title={text2}
                description={description2}
                onConfirm={() => processReports(record.reportId)}
                okText="Yes"
                cancelText="Cancel"
              >
                <Button
                  style={{ color: "black" }}
                  type="link"
                  icon={<EditOutlined />}
                >
                  Process
                </Button>
              </Popconfirm>
              <PopconfirmRemove
                text={text}
                desc={description}
                onConfirm={() => deleteReport(record.reportId)}
              />
              {/* <Popconfirm
                placement="right"
                title={text}
                description={description}
                onConfirm={() => deleteReport(record.reportId)}
                okText="Yes"
                cancelText="Cancel"
              >
                <Button
                  style={{ color: "black" }}
                  type="link"
                  icon={<DeleteOutlined />}
                >
                  Remove
                </Button>
              </Popconfirm> */}
            </div>
          )}
        >
          <Button
            type="primary"
            style={{
              background: "#4d8e03",
            }}
          >
            <Space>
              Action
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      render: (md) => (md ? md : "-"),
      width: "200px",
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    {
      title: "Middle Name",
      dataIndex: "middlename",
      width: "200px",
      render: (md) => (md ? md : "-"),
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      render: (md) => (md ? md : "-"),
      width: "200px",
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    {
      title: "Subject",
      dataIndex: "type",
      width: "200px",
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      width: "200px",
    },
    {
      title: "Message",
      dataIndex: "message",
      width: "200px",
    },
    {
      title: "Contact No.",
      dataIndex: "contactNumber",
      width: "200px",
    },
    {
      title: "Address",
      dataIndex: "address",
      width: "200px",
    },
    {
      title: "Image",
      dataIndex: "imageUrl",
      width: "200px",
      render: (record) => {
        return record ? (
          <Image
            width={100}
            src={process.env.REACT_APP_BASE_URL + "/" + record}
          />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "200px",
      editable: true,
      render: (status) =>
        status === 0 ? (
          <Tag color="volcano">Inactive</Tag>
        ) : (
          <Tag color="blue">Active</Tag>
        ),
    },
  ];

  // src={
  //   process.env.REACT_APP_BASE_URL + "/" + record.image
  // }

  useEffect(() => {
    dispatch(getUtilityReports(userToken));
  }, []);

  const onFinish = (values) => {
    dispatch(
      addPropertyPhase({
        name: values.phase,
      })
    );
    setModal2Open(false);
    form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {};

  const onFinishEdit = (values) => {
    dispatch(
      getUpdatedPhase({
        params: selectedPhase,
        body: {
          name: values.phaseName,
          status: values.phaseStatus,
        },
      })
    );
    setModal2Open(false);
  };

  const showDrawer = (record) => {
    setVisible(true);
    setSelectedPhase(record.phaseId);
  };

  const onClose = () => {
    setVisible(false);
  };

  const test = () => {};

  const deleteReport = (value) => {
    dispatch(removeUtilityReport(value));
  };

  const processReports = (value) => {
    dispatch(processUtilityReport(value));
  };

  return (
    <div>
      <LoadingTime loading={utilityReportsLoading} />
      <Card>
        <Table
          loading={utilityReportsLoading}
          rowKey={"reportId"}
          columns={columns}
          dataSource={utilityReports?.filter((obj, i) => obj.status === 0)}
          scroll={{
            y: 500,
            x: "100%",
          }}
        />
      </Card>
      {/* <Drawer
        title={
          <div className="flex  md:flex-row md:items-center items-start justify-start">
            <div
              onClick={() => setVisible(false)}
              className="w-[50px] h-[50px] items-center text-center flex justify-center text-[20px] rounded-full hover:bg-slate-200 transition-all ease-in duration-150 cursor-pointer hover:scale-110"
            >
              <ArrowLeftOutlined className="text-[#1b1b1b]" />
            </div>

            <h1 className="text-[24px] md:text-[30px] font-[600] text-[#1b1b1b] mb-0 p-3">
              Payment details
            </h1>
          </div>
        }
        placement="right"
        closable={false}
        onClose={onClose}
        open={visible}
        id="property-drawer"
        className="demo"
        width={window.innerWidth > 1200 ? "550px" : "auto"}
      >
        <div className="h-full w-full flex flex-col p-0 md:p-3">
          <Form
            name="edit"
            onFinish={onFinishEdit}
            autoComplete="off"
            layout="vertical"
            style={{ padding: "20px" }}
            className="flex flex-col"
            form={form}
            initialValues={{
              remember: true,
            }}
          >
            <div
              className="flex-col md:flex-row md:items-end md:justify-end "
              style={{
                display: "flex",
                gap: editToggle ? "10px" : "0px",
                transition: "all ease 0.3s",
              }}
            >
              <Form.Item>
                <Button
                  onClick={() => setEditToggle(true)}
                  type="primary"
                  size="default"
                  icon={<EditOutlined />}
                  style={{
                    transition: "all ease 0.3s",
                    background: "#1b1b1b",
                    marginRight: editToggle ? "0px" : "6px",
                  }}
                >
                  Edit
                </Button>
              </Form.Item>
              <Form.Item
                style={{
                  display: !editToggle ? "none" : "block",
                }}
              >
                <Button
                  onClick={() => setEditToggle(false)}
                  style={{
                    display: !editToggle ? "none" : "block",
                    background: "#1b1b1b",
                  }}
                  htmlType="submit"
                  type="primary"
                  size="default"
                  icon={<SaveOutlined />}
                >
                  Save
                </Button>
              </Form.Item>
              <Form.Item
                style={{
                  display: !editToggle ? "none" : "block",
                }}
              >
                <Button
                  onClick={() => setEditToggle(false)}
                  style={{
                    display: !editToggle ? "none" : "block",
                  }}
                  type="outlined"
                  size="default"
                >
                  Cancel
                </Button>
              </Form.Item>
            </div>
            <div className="flex flex-col justify-start w-full mx-auto gap-2">
              <Form.Item
                name="phaseName"
                label={
                  <h1 className="font-[500] text-[14px]  mb-0 md:text-[20px] text-[#1b1b1b]">
                    Amount received
                  </h1>
                }
              >
                <Input
                  disabled={!editToggle}
                  className="p-0 md:p-[12px] text-[12px] md:text-[25px]"
                  style={{
                    borderRadius: "3px",
                    borderColor: "transparent",
                  }}
                  size="large"
                />
              </Form.Item>
              <Form.Item
                name="phaseStatus"
                label={
                  <h1 className="font-[500] text-[14px]  mb-0 md:text-[20px] text-[#1b1b1b]">
                    Status
                  </h1>
                }
              >
                <Select
                  style={{
                    borderRadius: "3px",
                    borderColor: "transparent",
                  }}
                  disabled={!editToggle}
                  optionFilterProp="children"
                  showSearch
                  options={[
                    {
                      value: 1,
                      label: "Decline",
                    },
                    {
                      value: 0,
                      label: "Paid",
                    },
                  ]}
                ></Select>
              </Form.Item>
              <Form.Item
                name="penalty"
                label={
                  <h1 className="font-[500] text-[14px]  mb-0 md:text-[20px] text-[#1b1b1b]">
                    Penalty
                  </h1>
                }
              >
                <Select
                  style={{
                    borderRadius: "3px",
                    borderColor: "transparent",
                  }}
                  disabled={!editToggle}
                  optionFilterProp="children"
                  showSearch
                  options={[
                    {
                      value: 1,
                      label: "Decline",
                    },
                    {
                      value: 0,
                      label: "Paid",
                    },
                  ]}
                ></Select>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Drawer> */}
      <InquiriesModal viewBilling={visible} closeBilling={onClose} />
    </div>
  );
};
export default PendingReports;
