import { SearchOutlined } from "@ant-design/icons";
import { Button, Card, DatePicker, Form, Input, Tabs } from "antd";
import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBillingStatementsPage } from "../../../store/slices/billing/billing-slice";
import "../billing/billing.css";
import Billing from "./Billing";
import Paid from "./Paid";
import Removed from "./Removed";

const BillingMain = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [homeownerId, setHomeownerId] = useState("");
  const { billings, isLoading } = useSelector((state) => state.billing);
  const [filteredArr, setFilteredArr] = useState(billings);
  const [activeTab, setActiveTab] = useState(1);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dueDate, setDueDate] = useState("");

  const items = [
    {
      label: "Pending statements",
      key: "1",
      children: (
        <Billing
          arr={filteredArr}
          stat={activeTab}
          fName={firstName}
          lName={lastName}
          dDate={dueDate}
        />
      ),
    },
    {
      label: "Paid statements",
      key: "2",
      children: (
        <Paid
          arr={filteredArr}
          stat={activeTab}
          fName={firstName}
          lName={lastName}
          dDate={dueDate}
        />
      ),
    },
    {
      label: "Removed statements",
      key: "3",
      children: (
        <Removed
          arr={filteredArr}
          stat={activeTab}
          fName={firstName}
          lName={lastName}
          dDate={dueDate}
        />
      ),
    },
  ];

  const onSearchFailed = (errorInfo) => {};

  const filterHandler = (type, val, arr, key) => {
    switch (type) {
      case "firstName":
        return val
          ? arr.filter((obj) =>
              obj[key].toLowerCase().includes(val.toLowerCase())
            )
          : [];
      case "lastName":
        return val
          ? arr.filter((obj) =>
              obj[key].toLowerCase().includes(val.toLowerCase())
            )
          : [];
    }
  };

  const onFinished = (values) => {
    setFirstName(values.firstName);
    setLastName(values.lastName);
    setDueDate(values.dueDate?.format("YYYY-MM-DD"));

    dispatch(
      getBillingStatementsPage({
        page: 1,
        page_size: 10,
        status: 0,
        firstName: firstName,
        lastName: lastName,
        dueDate: dueDate,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getBillingStatementsPage({
        page: 1,
        page_size: 10,
        status: 0,
        firstName: "",
        lastName: "",
        dueDate: "",
      })
    );
  }, []);

  const resetFields = () => {
    form.resetFields();
    dispatch(
      getBillingStatementsPage({
        page: 1,
        page_size: 10,
        status: 0,
        firstName: "",
        lastName: "",
        dueDate: "",
      })
    );
    setFirstName("");
    setLastName("");
    setDueDate("");
  };

  return (
    <div className="flex flex-col gap-7 tabsBill">
      <Card className="shadow-md card-search">
        <div className="flex gap-5 w-full">
          <Form
            form={form}
            layout="vertical"
            // disabled={isLoading}
            className="flex flex-col w-full"
            style={{
              gap: "20px",
            }}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinished}
            onFinishFailed={onSearchFailed}
            autoComplete="off"
          >
            <div className="grid grid-cols-1 md:grid-cols-3 w-full">
              <div className="w-full">
                <Form.Item
                  initialValue=""
                  label={<h1 className="text-[18px] font-[500]">First name</h1>}
                  name="firstName"
                >
                  <Input
                    className="input-gray input-text-gray"
                    placeholder="Enter first name"
                    allowClear
                    style={{
                      width: "90%",
                      padding: "10px",
                      fontSize: "15px",
                    }}
                  />
                </Form.Item>
              </div>
              <div className="w-full">
                <Form.Item
                  initialValue=""
                  name="lastName"
                  label={<h1 className="text-[18px] font-[500]">Last name</h1>}
                >
                  <Input
                    className="input-gray input-text-gray"
                    placeholder="Enter last name"
                    allowClear
                    style={{
                      width: "90%",
                      padding: "10px",
                      fontSize: "15px",
                    }}
                  />
                </Form.Item>
              </div>
              <div className="w-full">
                <Form.Item
                  name="dueDate"
                  label={
                    <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#003664]">
                      Due date
                    </h1>
                  }
                >
                  <DatePicker
                    className="input-gray input-text-gray"
                    style={{
                      width: "90%",
                      padding: "10px",
                      fontSize: "15px",
                    }}
                    value={null}
                    allowEmpty
                  />
                </Form.Item>
              </div>
            </div>
            <div className="flex gap-5">
              <Form.Item className="w-full md:w-[140px]">
                <Button
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                    maxWidth: "140px",
                    height: "40px",
                    background: "#4d8e03",
                    border: "1px solid transparent",
                    fontSize: "15px",
                    fontWeight: "500",
                    marginBottom: 0,
                  }}
                  // loading={isLoading}
                  type="primary"
                  htmlType="submit"
                  icon={<SearchOutlined />}
                >
                  Search
                </Button>
              </Form.Item>
              <Form.Item className="w-full md:w-[140px]">
                <Button
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                    maxWidth: "140px",
                    height: "40px",
                    border: "2px solid #4d8e03",
                    color: "#1b1b1b",
                    fontSize: "15px",
                    fontWeight: "500",
                    marginBottom: 0,
                  }}
                  // loading={isLoading}
                  type="outlined"
                  onClick={() => {
                    resetFields();
                  }}
                >
                  Clear
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Card>
      <Tabs
        style={{
          overflowAnchor: "auto",
        }}
        defaultActiveKey="1"
        type="card"
        className="tabs-billing"
        tabBarGutter={0}
        items={items}
        onTabClick={(e) => setActiveTab(e)}
      ></Tabs>
    </div>
  );
};
export default BillingMain;
