import { motion } from "framer-motion";
import React from "react";
// import { img5 } from "../assets/images";
import { img5 } from "../../assets/landing/images";
import ContactForm from "./Contact/ContactForm";
import ContactMaps from "./Contact/ContactMaps";

const Contact = () => {
	const textAnimate = {
		offscreen: { y: 0, opacity: 0 },
		onscreen: {
			y: 0,
			opacity: 1,
			transition: { type: "spring", bounce: 0.4, duration: 0.5 },
		},
	};
	const inputAnimate = {
		offscreen: { y: 100, opacity: 0 },
		onscreen: {
			y: 0,
			opacity: 1,
			transition: { type: "spring", bounce: 0.3, duration: 0.5 },
		},
	};

	return (
		<div className=" w-full bg-[#F5F5EE]" id="contact">
			<motion.div
				className=" xl:mx-20 lg:mx-10 sm:mx-5 mx-2 bg-white rounded-lg"
				initial={"offscreen"}
				whileInView={"onscreen"}
				viewport={{ once: true, amount: 0.3 }}
				transition={{ staggerChildren: 0.2 }}
			>
				<motion.div
					className=" xl:px-32 lg:px-20 px-5 md:pt-32 sm:pt-20 pt-12 flex flex-col md:gap-10 sm:gap-5 gap-2"
					// variants={textAnimate}
				>
					{/* <div className=" mx-32 pt-32 pb-20 flex flex-col gap-y-10 col-span-3"> */}
					<motion.h1
						className=" lg::text-5xl md:text-4xl xs:text-3xl text-2xl font-bold"
						variants={textAnimate}
					>
						We would love to hear from you!
					</motion.h1>
					<motion.p className=" md:text-lg xs:text-[16px] text-sm" variants={textAnimate}>
						Fill out the form below and we will get back to you shortly.
					</motion.p>
					{/* </div> */}
				</motion.div>
				<div className=" xl:px-32 lg:px-20 md:px-5 px-2 grid grid-cols-4 lg:gap-5 md:gap-10 gap-2">
					<motion.div
						className=" md:col-span-2 col-span-4 flex justify-center items-center w-full h-full "
						variants={inputAnimate}
					>
						<ContactForm />
					</motion.div>
					<motion.div
						className=" col-span-2 row-span-1 md:flex hidden justify-center items-center"
						variants={inputAnimate}
					>
						<img className=" rounded-xl lg:w-[80%] lg:h-[78%] w-full h-full" src={img5} alt="/" />
					</motion.div>
				</div>
			</motion.div>

			<motion.div
				className=" md:px-20 sm:px-10 px-2 md:py-32 sm:py-20 xs:py-10 py-8 sm:mt-20 xs:mt-10 mt-0 flex flex-col sm:gap-y-10 gap-y-4"
				initial={"offscreen"}
				whileInView={"onscreen"}
				viewport={{ once: true, amount: 0.3 }}
				transition={{ staggerChildren: 0.1 }}
			>
				<div className="">
					<motion.h1
						className=" max-w-[800px] md:text-5xl sm:text-4xl xs:text-2xl text-[22px] font-bold m-0 sm:px-0 px-4"
						variants={textAnimate}
					>
						Need more information about Properity?
					</motion.h1>
				</div>
				<div className=" text-lg flex flex-col gap-y-1">
					<motion.p
						className=" max-w-[550px] m-0 sm:px-0 px-4 sm:text-lg xs:text-[16px] text-sm"
						variants={textAnimate}
					>
						We will be happy to arrange an appointment with you to review your project.
					</motion.p>
				</div>
				<div className=" grid grid-cols-4 lg:grid-rows-1 sm:grid-rows-2 grid-rows-3">
					<motion.div
						className=" lg:col-span-2 col-span-4 lg:row-span-2 sm:row-span-1 row-span-1 flex justify-start items-center p-4"
						variants={inputAnimate}
					>
						<ContactMaps />
					</motion.div>
					<div className=" lg:col-span-2 col-span-4 lg:row-span-2 sm:row-span-1 row-span-2 2xl:pr-40 xl:pr-16 sm:pr-6 pr-0 sm:px-0 xs:px-4 px-2 flex flex-col xl:gap-y-5 xs:gap-y-2 gap-y-1">
						<motion.h1
							className=" md:text-4xl sm:text-3xl xs:text-2xl text-[22px] font-bold xs:my-5 my-3"
							variants={textAnimate}
						>
							Contact us
						</motion.h1>
						<div>
							<motion.h2
								className=" md:text-2xl sm:text-xl xs:text-lg text-[17px] font-bold"
								variants={textAnimate}
							>
								Main Office
							</motion.h2>
							<motion.p className=" sm:text-lg xs:text-[16px] text-sm" variants={textAnimate}>
								Unit 1206 12th The Trade & Financial Tower, 7th & 32 Ave. Bonifacio Global City,
								Taguig City, Philippines 1634
							</motion.p>
						</div>
						<div>
							<motion.h2
								className=" md:text-2xl sm:text-xl xs:text-lg text-[17px] font-bold"
								variants={textAnimate}
							>
								Satellite Office
							</motion.h2>
							<motion.p className=" sm:text-lg xs:text-[16px] text-sm" variants={textAnimate}>
								Blk 1 Lot 5 Ridgemont Avenue, Suburban Drive, Brgy. San Isidro, Taytay, Rizal
							</motion.p>
						</div>
						<div>
							<motion.h2
								className=" md:text-2xl sm:text-xl xs:text-lg text-[17px] font-bold"
								variants={textAnimate}
							>
								Email
							</motion.h2>
							<motion.p className=" sm:text-lg xs:text-[16px] text-sm my-1" variants={textAnimate}>
								info@dynamicglobalsoft.com
							</motion.p>
							<motion.p className=" sm:text-lg xs:text-[16px] text-sm" variants={textAnimate}>
								sales@dynamicglobalsoft.com
							</motion.p>
						</div>
						<div>
							<motion.h2
								className=" md:text-2xl sm:text-xl xs:text-lg text-[17px] font-bold"
								variants={textAnimate}
							>
								Phone
							</motion.h2>
							<motion.div
								className=" flex sm:flex-row flex-col xl:text-lg sm:text-[17px] xs:text-[16px] text-sm 2xl:gap-x-10 xl:gap-x-5 gap-x-2 sm:gap-y-0 gap-y-1"
								variants={textAnimate}
							>
								<p className=" m-0">0917-987-1468</p>
								<p className=" sm:block hidden">|</p>
								<p className=" m-0">02-82538279</p>
								<p className=" sm:block hidden">|</p>
								<p className=" m-0">0928-524-8720</p>
							</motion.div>
						</div>
					</div>
				</div>
			</motion.div>
		</div>
	);
};

export default Contact;
