import { ArrowLeftOutlined } from "@ant-design/icons";
import { Drawer, Table } from "antd";
import moment from "moment";
import React from "react";
import { format_PHCurrency } from "../../../helpers/formatCurrency";

const Overdues = ({ open, close, overdue, loading }) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "200px",
    },
    {
      title: "Due date",
      dataIndex: "dueDate",
      width: "200px",
      render: (dueDate) => moment(dueDate).format("LL"),
    },
    {
      title: "Total amount",
      dataIndex: "totalAmount",
      render: (amount) => format_PHCurrency(+amount),
      width: "200px",
    },
  ];

  const closeDrawer = () => {
    close(true);
  };
  return (
    <Drawer
      title={
        <div className="flex flex-col md:flex-row md:items-center items-start justify-start">
          <div
            onClick={() => closeDrawer()}
            className="w-[50px] h-[50px] items-center text-center flex justify-center text-[20px] rounded-full hover:bg-slate-200 transition-all ease-in duration-150 cursor-pointer hover:scale-110"
          >
            <ArrowLeftOutlined className="text-[#1b1b1b]" />
          </div>

          <h1 className="text-[24px] md:text-[25px] font-[600] text-[#1b1b1b] mb-0 p-3">
            Overdues breakdown
          </h1>
        </div>
      }
      placement="bottom"
      closable={false}
      onClose={close}
      open={open}
      height={"70%"}
    >
      <div className="p-10">
        <Table
          dataSource={overdue}
          columns={columns}
          loading={loading}
          rowKey="index"
          scroll={{
            y: 350,
          }}
        ></Table>
      </div>
    </Drawer>
  );
};

export default Overdues;
