import { ArrowLeftOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, DatePicker, Drawer, Form, Input, Modal, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dummyRequest } from "../../../constants/staticConst";
import { convertToBase64 } from "../../../helpers/tobase64";
import { addAnnouncement } from "../../../store/slices/announcements&events/announcements/announcements-slice";
import { LoadingTime } from "../../../UI/LoadingScreen";
const { RangePicker } = DatePicker;
const { Dragger } = Upload;

const AddAnnouncement = ({ openAddAnnouncement, closeAddAnnouncement }) => {
  const [form] = Form.useForm();
  const [imagePrev, setImagePrev] = useState([]);
  const [imageArr, setImageArr] = useState([]);
  const dispatch = useDispatch();
  const { addAnnouncementsLoading } = useSelector(
    (state) => state.announcements
  );
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    let formData = new FormData();

    formData.append("title", values?.title);
    formData.append("description", values?.description);
    formData.append("dateFrom", values.date[0].format("YYYY-MM-DD"));
    formData.append("dateTo", values.date[1].format("YYYY-MM-DD"));

    for (let index = 0; index < values?.imgUrl?.length; index++) {
      formData.append(
        "image",
        values?.imgUrl === "" ? null : values?.imgUrl[index]
      );
    }

    dispatch(
      addAnnouncement({
        formData,
        cb: () => {
          closeAdd();
        },
      })
    );
  };

  const closeAdd = () => {
    form.resetFields();
    closeAddAnnouncement(true);
    setImagePrev("");
    setImageArr([]);
    setPreviewImage("");
    setPreviewTitle("");
    setFileList([]);
  };

  const [fileList, setFileList] = useState([]);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await convertToBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);

    form.setFieldsValue({
      imgUrl: fileList?.map((file) => file?.originFileObj) || null,
    });
  };
  const uploadButton = (
    <div className="w-full min-w-[100px]">
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  return (
    <div className="flex justify-center items-center">
      <Drawer
        title={
          <div className="flex md:flex-row md:items-center items-start justify-start">
            <div
              onClick={closeAdd}
              className="w-[50px] h-[50px] items-center text-center flex justify-center text-[20px] rounded-full hover:bg-slate-200 transition-all ease-in duration-150 cursor-pointer hover:scale-110"
            >
              <ArrowLeftOutlined className="text-[#1b1b1b]" />
            </div>
            <h1 className="text-[24px] md:text-[30px] font-[600] text-[#1b1b1b] mb-0 p-3">
              Add Announcement
            </h1>
          </div>
        }
        centered
        onClose={closeAdd}
        open={openAddAnnouncement}
        footer={
          <div className="flex justify-end">
            <Button
              loading={addAnnouncementsLoading}
              disabled={addAnnouncementsLoading}
              className="shadow-md"
              type="primary"
              onClick={form.submit}
              htmlType="submit"
              style={{
                minWidth: "200px",
                height: "52px",
                fontSize: "17px",
                fontWeight: "400",
                borderRadius: "5px",
                background: "#4d8e03",
                marginBlock: "13px",
              }}
            >
              Confirm
            </Button>
          </div>
        }
        width={window.innerWidth > 1200 ? "30%" : "100%"}
        closable={false}
        placement={"right"}
      >
        <LoadingTime loading={addAnnouncementsLoading} />

        <Form
          form={form}
          name="add"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <div className="flex md:flex-col flex-col gap-10 items-center md:items-start p-6">
            <div className="flex flex-col gap-4 w-full   md:flex-1 ">
              <div className="text-label">
                <Form.Item
                  name="title"
                  label={<h1 className="form-title">Title</h1>}
                  rules={[
                    {
                      required: true,
                      message: "Please input title",
                    },
                  ]}
                >
                  <Input
                    style={{
                      padding: "10px",
                      fontSize: "18px",
                      borderRadius: "3px",
                    }}
                    placeholder="Enter title "
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="description"
                  label={<h1 className="form-title">Description</h1>}
                  rules={[
                    {
                      required: true,
                      message: "Please input description",
                    },
                  ]}
                >
                  <Input
                    style={{
                      padding: "10px",
                      fontSize: "18px",
                      borderRadius: "3px",
                    }}
                    placeholder="Enter description"
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="date"
                  label={<h1 className="form-title">Date</h1>}
                  rules={[
                    {
                      required: true,
                      message: "Please input date",
                    },
                  ]}
                >
                  <RangePicker />
                </Form.Item>
              </div>
            </div>
            <div className="">
              <Form.Item valuePropName="fileList" name="imgUrl">
                <>
                  <Upload
                    name="file"
                    customRequest={dummyRequest}
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                  >
                    {uploadButton}
                  </Upload>
                </>
              </Form.Item>
            </div>
          </div>
          <Modal
            open={previewOpen}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img
              alt="example"
              style={{
                width: "100%",
              }}
              src={previewImage}
            />
          </Modal>
        </Form>
      </Drawer>
    </div>
  );
};

export default AddAnnouncement;
