import React from "react";

const Home = () => {
	// const homeAnimate = {
	// 	offscreen: { y: 100, opacity: 0 },
	// 	onscreen: {
	// 		y: 0,
	// 		opacity: 1,
	// 		transition: { duration: 0.4 },
	// 	},
	// };
	return (
		<div
		// initial={"offscreen"}
		// whileInView={"onscreen"}
		// viewport={{ once: true, amount: 0.1 }}
		// transition={{ staggerChildren: 0.1 }}
		>
			<div
				className=" bg-pro-bg sm:h-[93vh] h-[91vh]  bg-cover bg-center flex justify-center items-center"
				id="home"
				// variants={homeAnimate}
			>
				<div className=" text-white drop-shadow-2xl xs:pt-0 pt-20">
					<div className=" flex flex-col items-center gap-y-4 flex-wrap text-center">
						<h1 className=" md:text-[67px] sm:text-[47px] text-4xl sm:mx-0 mx-2 drop-shadow-2xl font-bold text-center">
							Welcome to Properity
						</h1>
						<p className=" md:text-3xl sm:text-2xl text-xl sm:mx-0 mx-2 font-medium drop-shadow-2xl">
							Managing properties properly.
						</p>
					</div>
					<div className="text-center md:text-2xl sm:text-xl text-lg sm:mx-0 mx-2 sm:mt-14 mt-7 drop-shadow-2xl">
						<p>Are you looking for a reliable property management software solution?</p>
						<p>Look no further - PROPERITY is here to help!</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
