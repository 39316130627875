import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

const initialState = {
  isLoading: false,
  errorMessage: null,
  isAuthenticated: false,
  currentUser: null,
  designation: null,
  accountId: null,
  designation: null,
  token: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state) {
      state.isLoading = true;
    },
    logout(state) {
      state.currentUser = null;
      state.accountId = null;
      state.isLoading = false;
      state.isAuthenticated = false;
      state.token = null;
      state.errorMessage = null;
      state.associationId = null;
    },
    authenticate(state, action) {
      console.log(action.payload);
      state.currentUser = action.payload.data;
      state.associationId = action.payload.data.assocId;
      state.designation = action.payload.data.designation;
      state.accountId = action.payload.accountId;
      state.token = action.payload.token;
      state.isAuthenticated = true;
      state.isLoading = false;
    },
    loginError(state, action) {
      console.log(action.payload);
      state.isAuthenticated = false;
      message.warning(action.payload.data.message);
      state.isLoading = false;
    },
  },
});

export default authSlice;
