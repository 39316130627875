import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusSquareOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Dropdown,
  Popconfirm,
  Space,
  Table,
  Tag,
  theme,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format_addCommas } from "../../../helpers/formatCurrency";
import {
  getAssociationCharges,
  removeAssociationCharge,
} from "../../../store/slices/billing/billing-slice";
import { LoadingTime } from "../../../UI/LoadingScreen";

import "../properties/property.css";
import AssocChargeForm from "./Assoccharges/AssocChargeForm";
import PopconfirmRemove from "../../../UI/PopconfirmRemove";

const { useToken } = theme;

const BillCharge = () => {
  const { token } = useToken();
  const { assocCharges, assocChargesLoading } = useSelector(
    (state) => state.billing
  );
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [visible, setVisible] = useState(false);
  const [chargeID, setChargeID] = useState();
  const dispatch = useDispatch();

  const text = "Are you sure to remove this charge?";
  const description = "Remove the charge";

  const items = [
    {
      label: (
        <div className="flex gap-2 items-center">
          <EditOutlined />
          <p>Edit</p>
        </div>
      ),
      key: "1",
    },
  ];

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle = {
    boxShadow: "none",
  };

  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "150px",
      render: (record) => (
        <Dropdown
          trigger={["click"]}
          menu={{
            items,
            onClick: () => editHandler(record.chargeId),
          }}
          dropdownRender={(menu) => (
            <div className="flex flex-col items-start" style={contentStyle}>
              {React.cloneElement(menu, {
                style: menuStyle,
              })}
              {/* <Button
                onClick={() => editHandler(record.chargeId)}
                style={{ color: "black" }}
                type="link"
                icon={<EditOutlined />}
              >
                Edit charge
              </Button> */}
              <PopconfirmRemove
                text={text}
                desc={description}
                onConfirm={() => deleteHandler(record.chargeId)}
              />
              {/* <Popconfirm
                icon={
                  <QuestionCircleOutlined
                    style={{
                      color: "red",
                    }}
                  />
                }
                placement="right"
                title={text}
                description={description}
                onConfirm={() => deleteHandler(record.chargeId)}
                okText="Yes"
                cancelText="Cancel"
              >
                <Button
                  style={{ color: "black" }}
                  type="link"
                  icon={<DeleteOutlined />}
                >
                  Remove
                </Button>
              </Popconfirm> */}
            </div>
          )}
        >
          <Button
            type="primary"
            style={{
              background: "#4d8e03",
            }}
          >
            <Space>
              Action
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      width: "200px",
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      width: "200px",
      editable: true,
      render: (status) =>
        status === 0 ? (
          <Tag color="blue">Active</Tag>
        ) : (
          <Tag color="volcano">Inactive</Tag>
        ),
    },
    {
      title: "Date Created",
      dataIndex: "dateCreated",
      width: "200px",
      editable: true,
      defaultSortOrder: "descend",
      sorter: (a, b) => moment(a.dateCreated).diff(moment(b.dateCreated)),
      render: (dateCreated) => moment(dateCreated).format("LL"),
    },
  ];

  useEffect(() => {
    dispatch(getAssociationCharges());
  }, []);

  const onClose = () => {
    setVisible(false);
  };

  const onAdd = () => {
    setVisible(true);
    setIsEditing(false);
    setIsAdding(true);
  };

  const editHandler = (value) => {
    setIsEditing(true);
    setIsAdding(false);
    setVisible(true);
    setChargeID(value);
  };

  const deleteHandler = (value) => {
    dispatch(removeAssociationCharge({ params: value }));
  };

  return (
    <div>
      <LoadingTime loading={assocChargesLoading} />
      <Card
        title={
          <div className="flex justify-between w-full items-center p-5">
            <h1 className="font-[600] text-[25px] mb-0">List of charges</h1>
            <button
              onClick={() => onAdd()}
              type="button"
              className="hidden md:block rounded-[10px] text-[18px] max-w-[200px] md:max-w-[173px] w-full h-[52px] border-transparent px-6 py-2.5 bg-[#4d8e03] text-white font-medium  leading-tight uppercase shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
            >
              ADD
            </button>
            <div className="flex items-center md:hidden text-center justify-center">
              <Button
                style={{
                  border: "1px solid transparent",
                  alignItems: "center",
                  display: "flex",
                }}
                type="outlined"
                onClick={() => onAdd()}
                icon={
                  <PlusSquareOutlined className="text-2xl h-full items-center flex justify-center" />
                }
              >
                Add
              </Button>
            </div>
          </div>
        }
      >
        <Table
          loading={assocChargesLoading}
          rowKey={"chargeId"}
          columns={columns}
          dataSource={
            assocCharges ? assocCharges?.filter((obj) => obj.status === 0) : []
          }
          scroll={{
            y: 500,
            x: "100%",
          }}
        />
      </Card>
      <AssocChargeForm
        openAddForm={visible}
        closeAddForm={onClose}
        isEditing={isEditing}
        isAdding={isAdding}
        isLoading={assocChargesLoading}
        chargeId={chargeID}
      />
    </div>
  );
};
export default BillCharge;
