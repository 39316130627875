import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

const initialState = {
  isLoading: false,
  verifyLoading: true,
  checking: false,
  assoc: [],
  settingUp: false,

  status: null,
};

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    resetCheck(state, action) {
      state.checking = action.payload;
    },
    cancelSetup(state, action) {
      state.settingUp = action.payload;
    },
    onboarding(state) {
      state.isLoading = true;
      state.settingUp = true;
    },
    onboardingSuccess(state, action) {
      state.isLoading = false;
      state.assoc = action.payload;
      state.checking = true;
      message.success(action.payload.message);
      state.settingUp = false;
    },
    verifyEmail(state) {
      state.verifyLoading = true;
      state.status = null;
    },
    verifyEmailSuccess(state, action) {
      state.verifyLoading = false;
      message.success(action.payload.message);
      state.status = true;
    },
    requestError(state, action) {
      state.verifyLoading = false;
      state.status = false;
      state.isLoading = false;
      message.error(action.payload.data.message);
    },
  },
});

export const {
  cancelSetup,
  onboarding,
  onboardingSuccess,
  verifyEmail,
  verifyEmailSuccess,
  requestError,
  resetCheck,
} = onboardingSlice.actions;
export default onboardingSlice;
