import {
  CloseOutlined,
  MessageOutlined,
  FileImageOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import {
  Button,
  Popover,
  Tag,
  Divider,
  Form,
  Input,
  Upload,
  Radio,
  Select,
} from "antd";
import { useState, useEffect } from "react";
import ImgCrop from "antd-img-crop";
import { dummyRequest } from "../../constants/staticConst";
import { convertToBase64 } from "../../helpers/tobase64";
import { useDispatch, useSelector } from "react-redux";
import {
  pushAllNotification,
  pushNotification,
} from "../../store/slices/notifications/notification-slice";
import { LoadingTime } from "../../UI/LoadingScreen";
import { useLocation } from "react-router-dom";

const { Dragger } = Upload;

const { TextArea } = Input;
const recipients = [];

const MessagePop = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.notification);
  const { associationId } = useSelector((state) => state.auth);
  const [hover, setHover] = useState(false);
  const [messageRecipients, setMessageRecipients] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [notif, setNotif] = useState();
  const [openNotif, setOpenNotif] = useState();

  const openNotifHandler = (state) => {
    setHover(state);
  };

  const add = [
    { name: "Edgardo I. Dela Rosa", type: "Homeowner" },
    { name: "Street 1", type: "Street" },
    { name: "Phase 1", type: "Phase" },
  ];

  const checkType = (type) => {
    if (type === "Homeowner") {
      return "gold";
    } else if (type === "Street") {
      return "blue";
    } else if (type === "Phase") {
      return "volcano";
    }
  };

  useEffect(() => {}, [messageRecipients]);

  const getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
  };

  const addRecipients = () => {
    setMessageRecipients((prevArr) => [...prevArr, add[getRandomInt(3)]]);
  };

  useEffect(() => {
    form.resetFields();
    setNotif(0);
  }, [hover]);

  const onFinish = (values) => {
    if (notif === 1) {
      dispatch(
        pushAllNotification({
          title: values.title ? values.title : "n/a",
          body: values.body ? values.body : "n/a",
          route: values.route ? values.route : "n/a",
        })
      );
    } else {
      dispatch(
        pushNotification({
          accountId: values.accountId ? values.accountId : "n/a",
          title: values.title ? values.title : "n/a",
          body: values.body ? values.body : "n/a",
          route: values.route ? values.route : "n/a",
        })
      );
    }

    setHover(false);
    form.resetFields();
  };

  const handleClose = (removedTag) => {
    const newTags = messageRecipients.filter((tag) => tag !== removedTag);
    setMessageRecipients(newTags);
  };

  const [fileList, setFileList] = useState([]);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await convertToBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);

    form.setFieldsValue({
      imgUrl: fileList?.map((file) => file?.originFileObj) || null,
    });
  };

  const uploadButton = (
    <div className="w-full min-w-[50px] ">
      <FileImageOutlined />
    </div>
  );

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setNotif(e.target.value);
  };

  return (
    <div
      className={
        location.pathname === "/" ||
        location.pathname === "/404" ||
        location.pathname.includes("/privacy-policy") ||
        location.pathname === "/Success" ||
        location.pathname === `/Subscription/${associationId}`
          ? "hidden"
          : "text-4xl fixed z-[1000] right-[120px] bottom-[50px] pop-message"
      }
    >
      <LoadingTime loading={isLoading} />
      <Popover
        open={hover}
        onOpenChange={openNotifHandler} //Popover
        id="asd"
        className="shadow-lg "
        placement="topRight"
        title={
          <div className="flex justify-between items-center bg-[#F5F5F5] p-3">
            <h1 className="text-[16px] text-[#1b1b1b]">Create Notification</h1>
          </div>
        }
        content={
          <div className="flex max-w-[600px] flex-col px-3 items-start h-full transition-all ease-in duration-200 min-w-[380px]">
            <Form
              form={form}
              className="w-full"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className="flex gap-3 justify-start py-3">
                {/* <p className="pr-2 flex items-center">To:</p>
                <div className="flex items-center flex-wrap gap-1 h-full my-auto">
                  {messageRecipients?.map((r, i) => (
                    <Tag
                      closable
                      color={checkType(r.type)}
                      key={i}
                      onClose={() => handleClose(i)}
                    >
                      {r.name}
                    </Tag>
                  ))}
                  <Button
                    onClick={() => addRecipients()}
                    type="link"
                    icon={
                      <PlusSquareOutlined className="text-[17px] text-[#1b1b1b]" />
                    }
                  ></Button>
                </div> */}
                <Form.Item className="mb-0" name="radio">
                  <Radio.Group onChange={onChange}>
                    <Radio value={1}>Send to All</Radio>
                    <Radio value={2}>Send to Homeowner</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
              <div className={notif === 2 ? "block" : "hidden"}>
                <Divider style={{ margin: "10px 0px" }} />
                <Form.Item className="mb-0" label="Account Id" name="accountId">
                  <Input className="border-none font-[600] " />
                </Form.Item>
              </div>
              <Divider style={{ margin: "10px 0px" }} />
              <Form.Item className="mb-0" label="Route" name="route">
                <Select
                  placeholder={"Select route"}
                  style={{
                    width: "100%",
                  }}
                  onChange={handleChange}
                  options={[
                    {
                      value: "Event Calendar",
                      label: "Event Calendar",
                    },
                    {
                      value: "View Event",
                      label: "View Event",
                    },
                    {
                      value: "Announcement",
                      label: "Announcement",
                    },
                    {
                      value: "View Announcement",
                      label: "View Announcement",
                    },
                    {
                      value: "Transaction",
                      label: "Transaction",
                    },
                    {
                      value: "Transaction Details",
                      label: "Transaction Details",
                    },
                    {
                      value: "Generated Billing Statement",
                      label: "Generated Billing Statement",
                    },
                    {
                      value: "Past Due",
                      label: "Past Due",
                    },
                    {
                      value: "View Billing Statement",
                      label: "View Billing Statement",
                    },
                    {
                      value: "Home",
                      label: "Home",
                    },
                    {
                      value: "Association Services",
                      label: "Association Services",
                    },
                  ]}
                />
              </Form.Item>
              <Divider style={{ margin: "10px 0px" }} />
              <Form.Item className="mb-0" label="Title" name="title">
                <Input
                  className="border-none font-[600] "
                  placeholder="Title here..."
                />
              </Form.Item>
              <Divider style={{ margin: "10px 0px" }} />
              <Form.Item className="" name="body">
                <TextArea
                  className="border-none"
                  placeholder="Message here..."
                  rows={4}
                />
              </Form.Item>
              <Divider style={{ margin: "10px 0px" }} />
              <div className="flex justify-between">
                {/* <div className="flex">
                  <Form.Item valuePropName="fileList" name="imgUrl">
                    <ImgCrop>
                      <Upload
                        name="file"
                        customRequest={dummyRequest}
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={handlePreview}
                        onChange={handleChange}
                      >
                        {uploadButton}
                      </Upload>
                    </ImgCrop>
                  </Form.Item>
                </div> */}
                <Button
                  htmlType="submit"
                  className="rounded-[5px] bg-properity w-[100px] h-[40px]"
                  type="primary"
                >
                  Send
                </Button>
              </div>
            </Form>
          </div>
        }
        trigger="click"
      >
        <Button
          onClick={() => setHover(!hover)}
          style={{ width: "55px", height: "55px" }}
          className={`rounded-full flex items-center justify-center font-[500] ${
            hover && "message-hover "
          }`}
          icon={
            <div className="flex items-center justify-center">
              {hover ? (
                <CloseOutlined className="text-xl flex items-center p-1" />
              ) : (
                <MessageOutlined className="text-xl flex items-center p-1" />
              )}
            </div>
          }
        >
          {hover ? "Cancel" : ""}
        </Button>
      </Popover>
    </div>
  );
};

export default MessagePop;
