import React from "react";
import { Tabs } from "antd";
import Transactions from "./Transactions";
import "./transaction.css";
import Paid from "./Paid";

const items = [
  { label: "Pending", key: "1", children: <Transactions /> }, // remember to pass the key prop
  { label: "Paid", key: "2", children: <Paid /> },
];

const TransactionsMain = () => (
  <Tabs
    defaultActiveKey="1"
    type="card"
    className="tabs-transac"
    animated={true}
    items={items}
  ></Tabs>
);
export default TransactionsMain;
