import { DownOutlined } from "@ant-design/icons";
import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GetStarted } from "../../../assets/resources/icons/icons";
import { format_PHCurrency } from "../../../helpers/formatCurrency";
import {
  checkout,
  getPlans,
} from "../../../store/slices/subscription/subscription-slice";
import { LoadingTime } from "../../../UI/LoadingScreen";
import "./subs.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";

const Subscription = () => {
  const dispatch = useDispatch();
  const { plans, plansLoading, redirectUrl, checkoutLoading } = useSelector(
    (state) => state.subscription
  );
  const { associationId } = useSelector((state) => state.auth);
  const [active, setActive] = useState();
  const [expand, setExpand] = useState();

  useEffect(() => {
    dispatch(getPlans());
  }, []);

  useEffect(() => {
    localStorage.setItem("login", "subs");
  }, []);

  const packages = [
    {
      type: "Free",
      price: 0,
      description: "This plan is up to 5 accounts",
      additional: "",
      features: [
        "Users (unique account)",
        "Association",
        "Generate Billing",
        "Online Payment",
        "Transaction History",
        "Announcements",
        "Event Calendar",
      ],
    },
    {
      type: "Starter",
      price: 5000,
      description: "This plan is up to 50 accounts",
      additional: (
        <p className="font-semibold text-[14px] italic">
          PHP 1000 for every additional 25
        </p>
      ),
      features: [
        "Users (unique account)",
        "Association",
        "Generate Billing",
        "Online Payment",
        "Transaction History",
        "Announcements",
        "Event Calendar",
        "Inquiries and Utility Reporting",
        "Notification",
        "Setup and support",
        "Analytics and Reporting",
      ],
    },
    {
      type: "Plus",
      price: 10000,
      description: "This plan is up to 200 accounts",
      additional: (
        <p className="font-semibold text-[14px] italic">
          PHP 3000 for every additional 50
        </p>
      ),
      features: [
        "Users (unique account)",
        "Association",
        "Generate Billing",
        "Online Payment",
        "Transaction History",
        "Announcements",
        "Event Calendar",
        "Inquiries and Utility Reporting",
        "Notification",
        "Setup and support",
        "Analytics and Reporting",
      ],
    },
    {
      type: "Plus",
      price: 25000,
      description: "This plan is up to 500 accounts",
      additional: (
        <p className="font-semibold text-[14px] italic">
          PHP 7500 for every additional
        </p>
      ),
      features: [
        "Users (unique account)",
        "Association",
        "Generate Billing",
        "Online Payment",
        "Transaction History",
        "Announcements",
        "Event Calendar",
        "Inquiries and Utility Reporting",
        "Notification",
        "Setup and support",
        "Analytics and Reporting",
      ],
    },
  ];

  return (
    <div className="flex w-full items-center justify-center h-screen relative bg-pro-bg">
      <LoadingTime loading={checkoutLoading} />
      <div className="bg-pro-bg h-full w-full fixed bg-cover bg-no-repeat flex items-center justify-center brightness-50 z-[998] "></div>
      <div
        className="flex flex-col text-center items-center justify-center text-white z-[999] gap-10"
        data-aos="fade-down"
      >
        <div className="flex flex-col text-center text-white">
          <h1 className="font-semibold">Select a subscription</h1>
          <p>
            Unlock the full potential of our product with a subscription that
            gives you exclusive
            <br /> access to premium features and benefits. Enjoy unlimited
            usage, advanced analytics, <br /> priority support, and more.
          </p>
        </div>
        <div className="grid grid-cols-4 gap-5 h-full min-h-[728px] card-container">
          {plans?.map((obj, key) => (
            <div
              className={`col-span-1 w-[300px] relative overflow-hidden card ${
                active === key
                  ? active === 0
                    ? "bg-subcarddark h-[530px]"
                    : "bg-subcarddark h-[710px]"
                  : key === 0
                  ? "bg-subcard h-[470px]"
                  : "bg-subcard h-[650px]"
              }`}
              key={key}
              onClick={(e) => setActive(key)}
            >
              <Skeleton
                className="p-10"
                paragraph={{ rows: 12 }}
                active={plansLoading}
                loading={plansLoading}
              >
                <div className="p-6 items-start text-left flex flex-col gap-5">
                  <span>{obj.name}</span>
                  <p>
                    <span className="text-3xl font-bold">
                      {format_PHCurrency(obj.amount)}{" "}
                    </span>
                    /Months
                  </p>
                  <p className="font-[400] text-[12px] opacity-90">
                    PHP 10,000 monthly subscription, up to {obj.size} accounts
                  </p>
                  <span className="font-semibold text-[14px] italic">
                    {packages[key].additional}
                  </span>
                  {packages[key]?.features.map((obj, key) => (
                    <span className="text-[13px]" key={key}>
                      &#x2022; {obj}
                    </span>
                  ))}
                  <button
                    onClick={() =>
                      dispatch(
                        checkout({
                          assocId: associationId,
                          planId: obj.planId,
                        })
                      )
                    }
                    disabled={active !== key}
                    type="button"
                    className={
                      active === key
                        ? "w-full h-[39px] rounded-md bg-subcard text-center text-white items-center flex justify-center gap-3 text-[12px] border-none hover:bg-green-500 transition-all duration-150 cursor-pointer"
                        : "w-full h-[39px] opacity-0"
                    }
                  >
                    Checkout
                    <GetStarted />
                  </button>
                </div>
              </Skeleton>
            </div>
          ))}
        </div>
      </div>
      {/* <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination]}
        className="mySwiper z-[999]"
      >
        <SwiperSlide>Slide 1</SwiperSlide>
        <SwiperSlide>Slide 2</SwiperSlide>
        <SwiperSlide>Slide 3</SwiperSlide>
        <SwiperSlide>Slide 4</SwiperSlide>
        <SwiperSlide>Slide 5</SwiperSlide>
        <SwiperSlide>Slide 6</SwiperSlide>
        <SwiperSlide>Slide 7</SwiperSlide>
        <SwiperSlide>Slide 8</SwiperSlide>
        <SwiperSlide>Slide 9</SwiperSlide>
      </Swiper> */}
    </div>
  );
};

export default Subscription;
