import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Dropdown,
  Popconfirm,
  Space,
  Table,
  Tag,
  Typography,
  theme,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getForms, removeForms } from "../../../store/slices/forms/forms-slice";
import CardTitle from "../../../UI/CardTitle";
import { LoadingTime } from "../../../UI/LoadingScreen";
import "../announcements/announcements.css";
import "../properties/property.css";
import AddForm from "./AddForm";
import PopconfirmRemove from "../../../UI/PopconfirmRemove";

const { Link } = Typography;
const { useToken } = theme;

const Forms = () => {
  const { token } = useToken();
  const { forms, isLoading } = useSelector((state) => state.forms);
  const [visible, setVisible] = useState(false);
  const [editAnnouncement, setEditAnnouncement] = useState(false);
  const [formId, setFormId] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const dispatch = useDispatch();

  const text = "Are you sure to remove this form?";
  const description = "Remove the form";

  const items = [
    {
      label: (
        <div className="flex gap-2 items-center">
          <EditOutlined />
          <p>Edit</p>
        </div>
      ),
      key: "1",
    },
  ];

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle = {
    boxShadow: "none",
  };

  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "150px",
      render: (record) => (
        <Dropdown
          trigger={["click"]}
          menu={{
            items,
            onClick: () => editHandler(record.formId),
          }}
          dropdownRender={(menu) => (
            <div className="flex flex-col items-start" style={contentStyle}>
              {React.cloneElement(menu, {
                style: menuStyle,
              })}
              {/* <Button
                onClick={() => editHandler(record.formId)}
                style={{ color: "black" }}
                type="link"
                icon={<EditOutlined />}
              >
                Edit
              </Button> */}
              <PopconfirmRemove
                text={text}
                desc={description}
                onConfirm={() => deleteForm(record.formId)}
              />
              {/* <Popconfirm
                placement="right"
                title={text}
                description={description}
                onConfirm={() => deleteForm(record.formId)}
                okText="Yes"
                cancelText="Cancel"
              >
                <Button
                  style={{ color: "black" }}
                  type="link"
                  icon={<DeleteOutlined />}
                >
                  Remove
                </Button>
              </Popconfirm> */}
            </div>
          )}
        >
          <Button
            type="primary"
            style={{
              background: "#4d8e03",
            }}
          >
            <Space>
              Action
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
    {
      title: "Title",
      width: "200px",
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
      render: (record) => (
        <Link
          href={process.env.REACT_APP_BASE_URL + "/" + record.url}
          target="_blank"
        >
          {record?.title}
        </Link>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "200px",
    },
    {
      title: "Date Created",
      dataIndex: "dateCreated",
      width: "200px",
      editable: true,
      defaultSortOrder: "descend",
      sorter: (a, b) => moment(a.dateCreated).diff(moment(b.dateCreated)),
      render: (dateCreated) => moment(dateCreated).format("LL"),
    },
    {
      title: "Date Updated",
      dataIndex: "dateUpdated",
      width: "200px",
      editable: true,
      render: (dateUpdated) => moment(dateUpdated).format("LL"),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "200px",
      editable: true,
      render: (status) =>
        status === 0 ? (
          <Tag color="blue">Active</Tag>
        ) : (
          <Tag color="blue">Inactive</Tag>
        ),
    },
  ];

  useEffect(() => {}, [isEditing, isAdding]);

  useEffect(() => {
    dispatch(getForms());
  }, []);

  const onClose = () => {
    setVisible(false);
  };

  const onAdd = () => {
    setVisible(true);
    setIsEditing(false);
    setIsAdding(true);
  };

  const deleteForm = (value) => {
    dispatch(removeForms(value));
  };

  const editHandler = (value) => {
    setEditAnnouncement(true);
    setIsEditing(true);
    setIsAdding(false);
    setVisible(true);
    setFormId(value);
  };

  return (
    <div className="flex flex-col gap-7">
      <LoadingTime loading={isLoading} />
      <Card
        title={<CardTitle title={"Form"} add={"Add"} onAdd={onAdd}></CardTitle>}
      >
        <Table
          loading={isLoading}
          rowKey={"formId"}
          columns={columns}
          dataSource={forms?.filter((obj) => obj.status === 0)}
          scroll={{
            y: 500,
            x: "100%",
          }}
        />
      </Card>
      <AddForm
        openAddForm={visible}
        closeAddForm={onClose}
        isEditing={isEditing}
        isAdding={isAdding}
        formId={formId}
        isLoading={isLoading}
      />
    </div>
  );
};
export default Forms;
