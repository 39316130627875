import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, DatePicker, Drawer, Form, Input, Modal, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { useForm } from "antd/es/form/Form";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from "react-redux";
import { dummyRequest } from "../../../constants/staticConst";
import { convertToBase64 } from "../../../helpers/tobase64";
import {
  addPaymentChannel,
  editPaymentChannel,
} from "../../../store/slices/paymentchannel/payment-channel-slice";

const { RangePicker } = DatePicker;
const { Dragger } = Upload;

const PaymentChannelForm = ({
  openAddForm,
  closeAddForm,
  isEditing,
  isAdding,
  formId,
}) => {
  const [form] = useForm();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [imagePrev, setImagePrev] = useState([]);
  const [imageArr, setImageArr] = useState([]);
  const dispatch = useDispatch();
  const { paymentChannelLoading, paymentChannels } = useSelector(
    (state) => state.paymentChannel
  );
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [description, setDescription] = useState("");

  const found = paymentChannels?.find((obj) => obj.paymentChannelId === formId);

  const htmlToDraftBlocks = (html) => {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  const onFinish = (values) => {
    let formData = new FormData();

    formData.append("name", values?.name);
    formData.append(
      "instructions",
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );

    for (let index = 0; index < values?.image?.length; index++) {
      formData.append(
        "image",
        values?.image === "" ? null : values?.image[index]
      );
    }

    if (isAdding) {
      dispatch(addPaymentChannel({ formData }));
    } else {
      dispatch(editPaymentChannel({ params: formId, body: { formData } }));
    }

    closeAddForm(true);
    setImagePrev("");
    setPreviewTitle("");
    setFileList([]);
    setEditorState(
      EditorState.push(editorState, ContentState.createFromText(""))
    );
  };

  const onFinishFailed = (values) => {};

  useEffect(() => {
    if (isEditing) {
      form.setFieldsValue({
        ...found,
        name: found?.name,
      });

      setEditorState(
        typeof found?.instructions !== "undefined"
          ? htmlToDraftBlocks(found?.instructions)
          : ""
      );
    } else {
      form.resetFields();
      setEditorState(
        EditorState.push(editorState, ContentState.createFromText(""))
      );
    }
  }, [isEditing, formId]);

  const closeAdd = () => {
    closeAddForm(true);
    setImagePrev("");
    setImageArr([]);
    setPreviewImage("");
    setPreviewTitle("");
    setFileList([]);
  };

  const [fileList, setFileList] = useState([]);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await convertToBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);

    form.setFieldsValue({
      image: fileList?.map((file) => file?.originFileObj) || null,
    });
  };
  const uploadButton = (
    <div className="w-full min-w-[100px]">
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  return (
    <div className="flex justify-center items-center z-[999]">
      <Drawer
        title={
          <div className="flex md:flex-row md:items-center items-start justify-between">
            <h1 className="text-[24px] md:text-[30px] font-[600] text-[#1b1b1b] mb-0 p-3">
              {isEditing ? " Edit Payment Channel" : " Add Payment Channel"}
            </h1>
            <div
              onClick={closeAdd}
              className="w-[50px] h-[50px] items-center text-center flex justify-center text-[20px] rounded-full hover:bg-slate-200 transition-all ease-in duration-150 cursor-pointer hover:scale-110"
            >
              <CloseOutlined className="text-[#1b1b1b]" />
            </div>
          </div>
        }
        centered
        onClose={closeAdd}
        onCancel={closeAdd}
        open={openAddForm}
        footer={
          <div className="flex justify-end">
            <Button
              loading={paymentChannelLoading}
              disabled={paymentChannelLoading}
              className="shadow-md"
              type="primary"
              onClick={form.submit}
              htmlType="submit"
              style={{
                minWidth: "200px",
                height: "52px",
                fontSize: "17px",
                fontWeight: "400",
                borderRadius: "5px",
                background: "#4d8e03",
                marginBlock: "13px",
              }}
            >
              {isEditing ? " Update" : "Confirm"}
            </Button>
          </div>
        }
        width={"30%"}
        closable={false}
        placement={"right"}
      >
        <Form
          form={form}
          name="add"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <div className="flex md:flex-col flex-col gap-10 items-center md:items-start p-5">
            <div className="flex flex-col gap-4 w-full md:w-[100%]  md:flex-1 ">
              <div className="text-label">
                <Form.Item
                  name="name"
                  label={
                    <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
                      Payment channel name
                    </h1>
                  }
                  //   rules={[
                  //     {
                  //       required: true,
                  //       message: "Please input title!",
                  //     },
                  //   ]}
                >
                  <Input
                    style={{
                      padding: "10px",
                      fontSize: "18px",
                      borderRadius: "3px",
                    }}
                    placeholder="Enter payment channel name "
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="instructions"
                  label={
                    <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
                      Instructions
                    </h1>
                  }
                  //   rules={[
                  //     {
                  //       required: true,
                  //       message: "Please input description!",
                  //     },
                  //   ]}
                >
                  <div
                    style={{
                      border: "1px solid #d9d9d9",
                      padding: "2px",
                      minHeight: "300px",
                    }}
                  >
                    <Editor
                      editorState={editorState}
                      onEditorStateChange={setEditorState}
                      placeholder="Write your instructions here"
                    />
                  </div>
                </Form.Item>
              </div>
            </div>
            <div className="flex gap-3 items-center">
              <img
                className={
                  isEditing
                    ? "w-[100px] h-[100px] object-cover relative"
                    : "hidden"
                }
                alt="example"
                src={process.env.REACT_APP_BASE_URL + "/" + found?.imageUrl}
              />
              <div className="">
                <Form.Item valuePropName="fileList" name="image">
                  <ImgCrop>
                    <Dragger
                      maxCount={1}
                      name="file"
                      customRequest={dummyRequest}
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                    >
                      {uploadButton}
                    </Dragger>
                  </ImgCrop>
                </Form.Item>
              </div>
            </div>
          </div>

          <Modal
            open={previewOpen}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img
              alt="example"
              style={{
                width: "100%",
              }}
              src={previewImage}
            />
          </Modal>
        </Form>
      </Drawer>
    </div>
  );
};

export default PaymentChannelForm;
