import { Button, Card, Form, Typography, Tag, Progress } from "antd";
import { merge } from "antd/es/theme/util/statistic";
import Papa from "papaparse";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getForms } from "../../../store/slices/forms/forms-slice";
import { SearchTableInput, Table } from "ant-table-extensions";

import {
  addToMasterlist,
  getHomeowners,
  setApplicantsCount,
} from "../../../store/slices/homeowner/homeowner-slice";
import "../homeowners/TableSample.css";
import { decode } from "html-entities";

const { Link } = Typography;

const originData = [];

for (let i = 0; i < 100; i++) {
  originData.push({
    key: i.toString(),
    name: `Edrward ${i}`,
    age: 32,
    address: `London Park no. ${i}`,
    status: false,
  });
}

const Applicants = () => {
  const { forms } = useSelector((state) => state.forms);
  const { checkEntries, time } = useSelector((state) => state.homeowners);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const [fileName, setFileName] = useState("");
  const [load, setLoad] = useState(false);
  const [current, setCurrent] = useState(0);
  const [total, setTotal] = useState(0);
  const [mergeArray, setMergeArray] = useState();

  useEffect(() => {
    dispatch(setApplicantsCount(data));
  }, [data]);

  const uploadMasterList = () => {
    setLoad(true);
    setTotal(checkEntries.length + data.length);

    dispatch(addToMasterlist());
  };

  useEffect(() => {
    setCurrent(checkEntries.length);

    setMergeArray(checkEntries.map((d, i) => ({ ...d, id: i })));

    if (checkEntries.length === total) {
      setLoad(false);
    }
  }, [checkEntries]);

  useEffect(() => {
    dispatch(getHomeowners());
    dispatch(getForms());
  }, []);

  const columns = data.map((d) =>
    Object.keys(d)
      ?.map((d) => ({
        title: d,
        dataIndex: d,
        width: "200px",
        hidden: d === "id" ? true : false,
        render: (record) => decode(record),
      }))
      .filter((d) => !d.hidden)
  )[0];

  const resultCol = [
    {
      title: "Status",
      dataIndex: "status",
      width: "200px",
      editable: true,
      sorter: (a, b) => a.status - b.status,
      render: (status) =>
        status === 0 ? (
          <Tag color="volcano">Failed</Tag>
        ) : (
          <Tag color="green">Success</Tag>
        ),
    },
    { title: "Message", dataIndex: "message", width: "200px" },

    {
      title: "First name",
      dataIndex: "firstName",
      width: "200px",
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    {
      title: "Middle name",
      dataIndex: "middleName",
      width: "200px",
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    {
      title: "Last name",
      dataIndex: "lastName",
      width: "200px",
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    { title: "Address", dataIndex: "address", width: "200px" },
    { title: "Email", dataIndex: "email", width: "200px" },
    { title: "Mobile number", dataIndex: "mobileNumber", width: "200px" },
  ];

  const handleFile = (e) => {
    setFileName(e.target.files[0].name);
    Papa.parse(e.target.files[0], {
      header: true,
      encoding: "ISO-8859-1",
      error: (e) => console.log(e),
      skipEmptyLines: true,
      complete: (res) => {
        setData(res.data.map((obj, i) => ({ ...obj, id: i })));
      },
    });
  };

  const fileLink = forms.find((obj) => obj.title === "Template");

  const tableLoading = {
    spinning: load,
    indicator: null,
  };

  return (
    <div className="flex flex-col gap-7">
      <Card
        className="shadow-md card-search"
        title={
          <div className="flex justify-between items-center gap-3">
            {/* <h1 className="font-[600] text-[25px] ">Upload file</h1> */}
            <div className="py-5 flex gap-4 items-center">
              <label className="custom-file-upload">
                <input
                  className="w-full "
                  type="file"
                  name="file"
                  accept=".csv"
                  onChange={handleFile}
                ></input>
                {fileName ? fileName : " Upload file"}
              </label>
              {/* <Form form={form}>
                <Form.Item
                  valuePropName="file"
                  name="fileUrl"
                  className="mb-0 p-10"
                >
                  <Dragger
                    className="mb-0"
                    name="file"
                    customRequest={dummyRequest}
                    fileList={fileList}
                    onChange={handleChange}
                  >
                    {uploadButton}
                  </Dragger>
                </Form.Item>
              </Form> */}
              <Button
                onClick={() => uploadMasterList()}
                style={{
                  borderRadius: "5px",
                  width: "100%",
                  maxWidth: "140px",
                  height: "40px",
                  background: "#4d8e03",
                  color: "#ffff",
                  fontSize: "15px",
                  fontWeight: "400",
                  marginBottom: 0,
                }}
                className={fileName ? "block" : "hidden"}
              >
                Submit
              </Button>
            </div>
            <div>
              <Link
                href={
                  process.env.REACT_APP_BASE_URL +
                  "/" +
                  "uploads/templates/masterlist.csv"
                }
                target="_blank"
              >
                Download template
              </Link>
            </div>
          </div>
        }
      >
        <Table
          className={fileName ? "block" : "hidden"}
          bordered
          columns={columns}
          rowKey="id"
          dataSource={data}
          scroll={{
            y: 400,
            x: "100vw",
          }}
        />
      </Card>
      <Card title={"Results"} className="relative">
        <Table
          searchable
          loading={tableLoading}
          bordered
          columns={resultCol}
          rowKey="id"
          dataSource={Boolean(mergeArray) ? mergeArray : []}
          scroll={{
            y: 400,
            x: "100vw",
          }}
        />
        <div
          className={
            load
              ? " absolute top-0 right-0 bottom-0 left-0 flex items-center text-center justify-center m-auto h-full max-h-[50px] "
              : "hidden"
          }
        >
          <p className="bg-white p-2 text-sm shadow-md min-w-[200px] text-blue-700">
            Checking&nbsp;<span className="font-[600]">{current}&nbsp;</span>
            of&nbsp;
            <span className="font-[600]">{total}</span>
            <Progress percent={(current * (100 / total)).toFixed(0)} />
          </p>
        </div>
      </Card>
    </div>
  );
};

export default Applicants;
