import Icon from "@ant-design/icons";

export const DashboardIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22 10.9V4.1C22 2.6 21.36 2 19.77 2H15.73C14.14 2 13.5 2.6 13.5 4.1V10.9C13.5 12.4 14.14 13 15.73 13H19.77C21.36 13 22 12.4 22 10.9V10.9ZM22 19.9V18.1C22 16.6 21.36 16 19.77 16H15.73C14.14 16 13.5 16.6 13.5 18.1V19.9C13.5 21.4 14.14 22 15.73 22H19.77C21.36 22 22 21.4 22 19.9ZM10.5 13.1V19.9C10.5 21.4 9.86 22 8.27 22H4.23C2.64 22 2 21.4 2 19.9V13.1C2 11.6 2.64 11 4.23 11H8.27C9.86 11 10.5 11.6 10.5 13.1V13.1ZM10.5 4.1V5.9C10.5 7.4 9.86 8 8.27 8H4.23C2.64 8 2 7.4 2 5.9V4.1C2 2.6 2.64 2 4.23 2H8.27C9.86 2 10.5 2.6 10.5 4.1Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )}
    {...props}
  />
);

export const InquiriesIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 21H21M11 1V2M3 3L4 4M19 3L18 4M11 5C8.61305 5 6.32387 5.94821 4.63604 7.63604C2.94821 9.32387 2 11.6131 2 14V21H20V14C20 11.6131 19.0518 9.32387 17.364 7.63604C15.6761 5.94821 13.3869 5 11 5V5Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )}
    {...props}
  />
);

export const DirectoriesIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.3333 4.57508V17.0751M6.79167 7.07508H4.91667M7.41667 9.57508H4.91667M18.6667 13.9501V3.89174C18.6667 2.89174 17.85 2.15008 16.8583 2.23341H16.8083C15.0583 2.38341 12.4 3.27508 10.9167 4.20841L10.775 4.30008C10.5333 4.45008 10.1333 4.45008 9.89167 4.30008L9.68333 4.17508C8.2 3.25008 5.55 2.36674 3.8 2.22508C2.80833 2.14174 2 2.89174 2 3.88341V13.9501C2 14.7501 2.65 15.5001 3.45 15.6001L3.69167 15.6334C5.5 15.8751 8.29167 16.7917 9.89167 17.6667L9.925 17.6834C10.15 17.8084 10.5083 17.8084 10.725 17.6834C12.325 16.8001 15.125 15.8751 16.9417 15.6334L17.2167 15.6001C18.0167 15.5001 18.6667 14.7501 18.6667 13.9501Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )}
    {...props}
  />
);

export const FormsIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2865_25703)">
          <path
            d="M1.66666 18.3332H18.3333M5.83332 13.7498H8.33332M11.6667 13.7498H14.1667M5.83332 9.99984H8.33332M11.6667 9.99984H14.1667M5.83332 6.24984H8.33332M11.6667 6.24984H14.1667M14.1667 1.6665H5.83332C3.33332 1.6665 2.49999 3.15817 2.49999 4.99984V18.3332H17.5V4.99984C17.5 3.15817 16.6667 1.6665 14.1667 1.6665Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokewiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_2865_25703">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )}
    {...props}
  />
);

export const HelpcenterIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 18.4302H13L8.55 21.3902C8.39982 21.4903 8.22528 21.5478 8.045 21.5564C7.86472 21.5651 7.68546 21.5247 7.52635 21.4395C7.36724 21.3543 7.23424 21.2275 7.14154 21.0727C7.04884 20.9178 6.99992 20.7407 7 20.5602V18.4302C4 18.4302 2 16.4302 2 13.4302V7.43018C2 4.43018 4 2.43018 7 2.43018H17C20 2.43018 22 4.43018 22 7.43018V13.4302C22 16.4302 20 18.4302 17 18.4302Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokewiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 11.3599V11.1499C12 10.4699 12.42 10.1099 12.84 9.81986C13.25 9.53986 13.66 9.17986 13.66 8.51986C13.66 7.59986 12.92 6.85986 12 6.85986C11.08 6.85986 10.34 7.59986 10.34 8.51986M11.995 13.7499H12.005"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )}
    {...props}
  />
);

export const HomeownersIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.9695 13.44C17.3395 13.67 18.8495 13.43 19.9095 12.72C21.3195 11.78 21.3195 10.24 19.9095 9.3C18.8395 8.59 17.3095 8.35 15.9395 8.59M5.99945 13.44C4.62945 13.67 3.11945 13.43 2.05945 12.72C0.649453 11.78 0.649453 10.24 2.05945 9.3C3.12945 8.59 4.65945 8.35 6.02945 8.59M16.9995 6.16C16.9365 6.14999 16.8724 6.14999 16.8095 6.16C16.1421 6.13587 15.5102 5.85321 15.0475 5.37176C14.5847 4.89032 14.3272 4.2478 14.3295 3.58C14.3295 2.15 15.4795 1 16.9095 1C17.5937 1 18.2499 1.27182 18.7338 1.75566C19.2176 2.23951 19.4895 2.89574 19.4895 3.58C19.4876 4.24827 19.2274 4.88996 18.7634 5.37081C18.2993 5.85167 17.6672 6.13445 16.9995 6.16V6.16ZM4.96945 6.16C5.02945 6.15 5.09945 6.15 5.15945 6.16C5.82682 6.13587 6.45866 5.85321 6.92145 5.37176C7.38423 4.89032 7.64171 4.2478 7.63945 3.58C7.63945 2.15 6.48945 1 5.05945 1C4.37519 1 3.71896 1.27182 3.23512 1.75566C2.75127 2.23951 2.47945 2.89574 2.47945 3.58C2.48945 4.98 3.58945 6.11 4.96945 6.16ZM10.9995 13.63C10.9365 13.62 10.8724 13.62 10.8095 13.63C10.1421 13.6059 9.51025 13.3232 9.04746 12.8418C8.58467 12.3603 8.3272 11.7178 8.32945 11.05C8.32945 9.62 9.47945 8.47 10.9095 8.47C11.5937 8.47 12.2499 8.74182 12.7338 9.22566C13.2176 9.70951 13.4895 10.3657 13.4895 11.05C13.4795 12.45 12.3795 13.59 10.9995 13.63ZM8.08945 16.78C6.67945 17.72 6.67945 19.26 8.08945 20.2C9.68945 21.27 12.3095 21.27 13.9095 20.2C15.3195 19.26 15.3195 17.72 13.9095 16.78C12.3195 15.72 9.68945 15.72 8.08945 16.78Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )}
    {...props}
  />
);

export const TransactionIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.5 11.9902L13.49 17.0102M1.5 11.9902H18.5M1.5 6.01023L6.51 0.990234M18.5 6.01023H1.5"
          stroke="currentColor"
          strokeWidth="1.5"
          strokewiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )}
    {...props}
  />
);

export const PaymentChannelIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 8.50488H22M6 16.5049H8M10.5 16.5049H14.5"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.44 3.50488H17.55C21.11 3.50488 22 4.38488 22 7.89488V16.1049C22 19.6149 21.11 20.4949 17.56 20.4949H6.44C2.89 20.5049 2 19.6249 2 16.1149V7.89488C2 4.38488 2.89 3.50488 6.44 3.50488Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )}
    {...props}
  />
);

export const BillingIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.73 19.7C7.55 18.82 8.8 18.89 9.52 19.85L10.53 21.2C11.34 22.27 12.65 22.27 13.46 21.2L14.47 19.85C15.19 18.89 16.44 18.82 17.26 19.7C19.04 21.6 20.49 20.97 20.49 18.31V7.04C20.5 3.01 19.56 2 15.78 2H8.22C4.44 2 3.5 3.01 3.5 7.04V18.3C3.5 20.97 4.96 21.59 6.73 19.7Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.0957 11H8.1057"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.8984 11H16.3984"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.0957 7H8.1057"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.8984 7H16.3984"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )}
    {...props}
  />
);

export const PropertyIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 21.9998H22M19.98 22.0098V17.9998M3 13.9998H15M9 21.9998V18.2498M15.01 21.9898L3.01 22.0098L3 7.06984C3 6.39984 3.34 5.77984 3.89 5.40984L7.89 2.73984C8.56 2.28984 9.44 2.28984 10.11 2.73984L14.11 5.40984C14.67 5.77984 15 6.39984 15 7.06984L15.01 21.9898V21.9898ZM20 11.9998C18.9 11.9998 18 12.8998 18 13.9998V15.9998C18 17.0998 18.9 17.9998 20 17.9998C21.1 17.9998 22 17.0998 22 15.9998V13.9998C22 12.8998 21.1 11.9998 20 11.9998Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokewiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 10.5C9.39782 10.5 9.77936 10.342 10.0607 10.0607C10.342 9.77936 10.5 9.39782 10.5 9C10.5 8.60218 10.342 8.22064 10.0607 7.93934C9.77936 7.65804 9.39782 7.5 9 7.5C8.60218 7.5 8.22064 7.65804 7.93934 7.93934C7.65804 8.22064 7.5 8.60218 7.5 9C7.5 9.39782 7.65804 9.77936 7.93934 10.0607C8.22064 10.342 8.60218 10.5 9 10.5Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokewiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )}
    {...props}
  />
);

export const AnnouncementIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.33008 9.99979V13.9998C3.33008 15.9998 4.33008 16.9998 6.33008 16.9998H7.76008C8.13008 16.9998 8.50008 17.1098 8.82008 17.2998L11.7401 19.1298C14.2601 20.7098 16.3301 19.5598 16.3301 16.5898V7.40979C16.3301 4.42979 14.2601 3.28979 11.7401 4.86979L8.82008 6.69979C8.50008 6.88979 8.13008 6.99979 7.76008 6.99979H6.33008C4.33008 6.99979 3.33008 7.99979 3.33008 9.99979Z"
          stroke="currentColor"
          strokeWidth="1.5"
        />
        <path
          d="M19.3301 8C20.1966 9.15354 20.6651 10.5573 20.6651 12C20.6651 13.4427 20.1966 14.8465 19.3301 16"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )}
    {...props}
  />
);

export const RadioDotIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="12.5"
          cy="12.5"
          r="11.5"
          fill="white"
          stroke="#4D8E03"
          strokeWidth="2"
        />
        <circle cx="12.5" cy="12.5" r="8.5" fill="#4D8E03" />
      </svg>
    )}
    {...props}
  />
);

export const GetStarted = (props) => (
  <Icon
    component={() => (
      <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.230469"
          y="0.5"
          width="16"
          height="16"
          rx="8"
          fill="white"
          fillOpacity="0.6"
        />
        <path
          d="M7.24023 6.52148L9.21923 8.50048L7.24023 10.4795"
          stroke="#6DC15F"
          strokeWidth="0.989498"
          strokeLinecap="square"
        />
      </svg>
    )}
    {...props}
  />
);
