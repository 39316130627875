import {
  ArrowLeftOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  EyeOutlined,
  PlusSquareOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Divider,
  Drawer,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  Popconfirm,
} from "antd";
import { decode } from "html-entities";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Help from "../../../assets/resources/icons/Help.png";
import {
  addPropertyType,
  getPropertyTypes,
  getUpdatedPropertyType,
  removePropertyType,
} from "../../../store/slices/property/property-phase-slice";
import { LoadingTime } from "../../../UI/LoadingScreen";
import ModalButton from "../../../UI/ModalButton";
import PopconfirmRemove from "../../../UI/PopconfirmRemove";

const PropertyType = () => {
  const [form] = Form.useForm();
  const [formAdd] = Form.useForm();
  const { phases, isLoading, phaseId, propertyTypeLoading, propertyTypes } =
    useSelector((state) => state.propertyPhases);
  const [modal2Open, setModal2Open] = useState(false);
  const [editToggle, setEditToggle] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedType, setSelectedtype] = useState();
  const dispatch = useDispatch();

  const text = "Are you sure to remove this type?";
  const description = "Remove the type";

  const items = [
    {
      label: (
        <div className="flex gap-2 items-center">
          <EyeOutlined />
          <p>View type</p>
        </div>
      ),
      key: "1",
    },
  ];

  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "150px",
      render: (record) => (
        <Dropdown
          trigger={["click"]}
          menu={{
            items,
            onClick: () => showDrawer(record),
          }}
        >
          <Button
            type="primary"
            style={{
              background: "#4d8e03",
            }}
          >
            <Space>
              Action
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      width: "200px",
      render: (record) => <span>{decode(record, { level: "html5" })}</span>,
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "200px",
      editable: true,
      render: (status) =>
        status === 0 ? (
          <Tag color="blue">Active</Tag>
        ) : (
          <Tag color="blue">Inactive</Tag>
        ),
    },
  ];

  useEffect(() => {
    const found = propertyTypes.find(
      (obj) => obj.propertyTypeId === selectedType
    );
    form.setFieldsValue({
      ...found,
      propertyType: found?.name,
      status: found?.status,
    });
  }, [propertyTypes, selectedType]);

  useEffect(() => {
    dispatch(getPropertyTypes());
  }, []);

  const onFinish = (values) => {
    dispatch(
      addPropertyType({
        name: values.type,
      })
    );
    setModal2Open(false);
    formAdd.resetFields();
  };

  const onFinishFailed = (errorInfo) => {};

  const onFinishEdit = (values) => {
    dispatch(
      getUpdatedPropertyType({
        params: selectedType,
        body: {
          name: values.propertyType,
          status: values.status,
        },
      })
    );
    setModal2Open(false);
  };

  const showDrawer = (record) => {
    setVisible(true);
    setSelectedtype(record.propertyTypeId);
  };

  const removeTypeHandler = () => {
    setVisible(false);
    dispatch(removePropertyType(selectedType));
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div>
      <LoadingTime loading={propertyTypeLoading} />
      <Card
        title={
          <div className="flex justify-between w-full items-center p-5">
            <h1 className="font-[600] text-[25px] mb-0">Property Type</h1>
            <button
              onClick={() => setModal2Open(true)}
              type="button"
              className="hidden md:block rounded-[10px] text-[18px] max-w-[200px] md:max-w-[173px] w-full h-[52px] border-transparent px-6 py-2.5 bg-properity text-white font-medium  leading-tight uppercase shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
            >
              ADD
            </button>
            <div className="flex items-center md:hidden text-center justify-center">
              <Button
                style={{
                  border: "1px solid transparent",
                  alignItems: "center",
                  display: "flex",
                }}
                type="outlined"
                onClick={() => setModal2Open(true)}
                icon={
                  <PlusSquareOutlined className="text-2xl h-full items-center flex justify-center" />
                }
              >
                Add
              </Button>
            </div>
          </div>
        }
      >
        <Table
          loading={propertyTypeLoading}
          rowKey={"propertyTypeId"}
          columns={columns}
          dataSource={
            propertyTypes ? propertyTypes.filter((obj) => obj.status === 0) : []
          }
          scroll={{
            y: 500,
            x: "100%",
          }}
        />
      </Card>
      <Modal
        title={
          <h1 className="text-[24px] md:text-[30px] font-[600] text-[#1b1b1b] mb-0 ">
            Add property type
          </h1>
        }
        centered
        open={modal2Open}
        onOk={() => setModal2Open(false)}
        onCancel={() => setModal2Open(false)}
        footer={false}
      >
        <Form
          layout="vertical"
          form={formAdd}
          disabled={isLoading}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Divider />
          <Form.Item
            label={<h1 className="form-title">Property type</h1>}
            name="type"
            rules={[
              {
                required: true,
                message: "Please input property type",
              },
            ]}
          >
            <Input
              style={{
                padding: "10px",
                fontSize: "18px",
                borderRadius: "3px",
              }}
              placeholder="Enter property type"
              size="large"
            />
          </Form.Item>
          <ModalButton text={"Add"} />
        </Form>
      </Modal>
      <Drawer
        title={
          <div className="flex md:flex-row md:items-center items-start justify-start">
            <div
              onClick={() => setVisible(false)}
              className="w-[50px] h-[50px] items-center text-center flex justify-center text-[20px] rounded-full hover:bg-slate-200 transition-all ease-in duration-150 cursor-pointer hover:scale-110"
            >
              <ArrowLeftOutlined className="text-[#1b1b1b]" />
            </div>

            <h1 className="text-[24px] md:text-[30px] font-[600] text-[#1b1b1b] mb-0 p-3">
              Property type
            </h1>
          </div>
        }
        placement="right"
        closable={false}
        onClose={onClose}
        open={visible}
        id="property-drawer"
        className="demo "
        width={window.innerWidth > 1200 ? "550px" : "100%"}
      >
        <div className="h-full w-full flex flex-col p-0 ">
          <Form
            name="basic"
            onFinish={onFinishEdit}
            autoComplete="off"
            layout="vertical"
            style={{ padding: "20px" }}
            className="flex flex-col"
            form={form}
            initialValues={{
              remember: true,
            }}
          >
            <div
              className="flex-col md:flex-row md:items-end md:justify-end "
              style={{
                display: "flex",
                gap: editToggle ? "10px" : "0px",
                transition: "all ease 0.3s",
              }}
            >
              <Form.Item>
                <Button
                  onClick={() => setEditToggle(true)}
                  type="primary"
                  size="default"
                  icon={<EditOutlined />}
                  style={{
                    transition: "all ease 0.3s",
                    background: "#4d8e03",
                    marginRight: editToggle ? "0px" : "6px",
                  }}
                >
                  Edit
                </Button>
              </Form.Item>
              <Form.Item
                style={{
                  display: !editToggle ? "none" : "block",
                }}
              >
                <Button
                  onClick={() => setEditToggle(false)}
                  style={{
                    display: !editToggle ? "none" : "block",
                    background: "#4d8e03",
                  }}
                  htmlType="submit"
                  type="primary"
                  size="default"
                  icon={<SaveOutlined />}
                >
                  Save
                </Button>
              </Form.Item>
              <Form.Item
                style={{
                  display: !editToggle ? "none" : "block",
                }}
              >
                <Button
                  onClick={() => setEditToggle(false)}
                  style={{
                    display: !editToggle ? "none" : "block",
                  }}
                  type="primary"
                  danger
                  size="default"
                >
                  Cancel
                </Button>
              </Form.Item>
              <Form.Item>
                <PopconfirmRemove
                  text={text}
                  desc={description}
                  onConfirm={() => removeTypeHandler()}
                />
                {/* <Popconfirm
                  placement="right"
                  title={text}
                  description={description}
                  onConfirm={() => removeTypeHandler()}
                  okText="Yes"
                  cancelText="Cancel"
                >
                  <Button
                    style={{ color: "black" }}
                    type="link"
                    icon={<DeleteOutlined />}
                  >
                    Remove
                  </Button>
                </Popconfirm> */}
              </Form.Item>
            </div>
            <div className="flex flex-col justify-start w-full mx-auto gap-2">
              <Form.Item
                name="propertyType"
                label={<h1 className="form-title">Property type</h1>}
              >
                <Input
                  disabled={!editToggle}
                  style={{
                    padding: "10px",
                    fontSize: "18px",
                    borderRadius: "3px",
                  }}
                  size="large"
                />
              </Form.Item>
              <Form.Item
                name="status"
                label={<h1 className="form-title">Status</h1>}
              >
                <Select
                  style={{
                    borderRadius: "3px",
                    borderColor: "transparent",
                  }}
                  size="large"
                  disabled={!editToggle}
                  optionFilterProp="children"
                  showSearch
                  options={[
                    {
                      value: 1,
                      label: "Inactive",
                    },
                    {
                      value: 0,
                      label: "Active",
                    },
                  ]}
                ></Select>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Drawer>
    </div>
  );
};
export default PropertyType;
