import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Dropdown,
  Popconfirm,
  Space,
  Table,
  Tag,
  theme,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeAnnouncements } from "../../../../store/slices/announcements&events/announcements/announcements-slice";

import "../../announcements/announcements.css";
import "../../properties/property.css";

import { getDirectories } from "../../../../store/slices/directories-and-hotlines/directories/directories-slice";
import DirectoryForm from "./DirectoryForm";
import CardTitle from "../../../../UI/CardTitle";
import { LoadingTime } from "../../../../UI/LoadingScreen";

const { useToken } = theme;

const DirectoriesTab = () => {
  const { token } = useToken();
  const { directories, isLoading, directoryTypes } = useSelector(
    (state) => state.directories
  );
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [visible, setVisible] = useState(false);
  const [id, setId] = useState();
  const dispatch = useDispatch();

  const items = [
    {
      label: (
        <div className="flex gap-2 items-center">
          <EditOutlined />
          <p>Edit</p>
        </div>
      ),
      key: "1",
    },
  ];

  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "150px",
      render: (record) => (
        <Dropdown
          trigger={["click"]}
          menu={{
            items,
            onClick: () => editHandler(record.id),
          }}
        >
          <Button
            type="primary"
            style={{
              background: "#4d8e03",
            }}
          >
            <Space>
              Action
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      width: "200px",
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    {
      title: "Status",
      align: "center",
      dataIndex: "status",
      width: "200px",
      editable: true,
      render: (status) =>
        status === 0 ? (
          <Tag color="blue">Active</Tag>
        ) : (
          <Tag color="blue">Inactive</Tag>
        ),
    },
    {
      title: "Address",
      dataIndex: "address",
      width: "200px",
    },
    {
      title: "Contact Number",
      dataIndex: "contactNumber",
      width: "200px",
    },
    {
      title: "Contact Person",
      dataIndex: "contactPerson",
      width: "200px",
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    {
      title: "Date Created",
      dataIndex: "dateCreated",
      width: "200px",
      editable: true,
      defaultSortOrder: "descend",
      sorter: (a, b) => moment(a.dateCreated).diff(moment(b.dateCreated)),
      render: (dateCreated) => moment(dateCreated).format("LL"),
    },
    {
      title: "Date Updated",
      dataIndex: "dateUpdated",
      width: "200px",
      editable: true,
      render: (dateUpdated) => moment(dateUpdated).format("LL"),
    },
  ];

  useEffect(() => {
    dispatch(getDirectories());
  }, []);

  const onClose = () => {
    setVisible(false);
  };

  const editHandler = (value) => {
    setIsAdding(false);
    setVisible(true);
    setIsEditing(true);
    setId(value);
  };

  const addHandler = () => {
    setIsEditing(false);
    setVisible(true);
    setIsAdding(true);
  };

  return (
    <div className="flex flex-col gap-7">
      <LoadingTime loading={isLoading} />
      <Card
        title={
          <CardTitle
            title={"Directories"}
            add={"Add"}
            onAdd={addHandler}
          ></CardTitle>
        }
      >
        <Table
          loading={isLoading}
          rowKey={"id"}
          columns={columns}
          dataSource={directories?.filter((obj) => obj.status === 0)}
          scroll={{
            y: 500,
            x: "100%",
          }}
        />
      </Card>
      <DirectoryForm
        openDirectoryForm={visible}
        closeDirectoryForm={onClose}
        isEditing={isEditing}
        isAdding={isAdding}
        directoryTypes={directoryTypes}
        directories={directories}
        id={id}
      />
    </div>
  );
};
export default DirectoriesTab;
