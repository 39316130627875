import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

const initialState = {
  helpCenterLoading: false,
  helpCenterTypesLoading: false,
  helpCenters: [],
  helpCenterTypes: [],
};

const helpCenterSlice = createSlice({
  name: "helpCenter",
  initialState,
  reducers: {
    getHelpCenter(state) {
      state.helpCenters = [];
      state.helpCenterLoading = true;
    },
    getHelpCenterSuccess(state, action) {
      state.helpCenters = action.payload;
      state.helpCenterLoading = false;
    },
    addHelpCenter(state) {
      state.helpCenterLoading = true;
    },
    addHelpCenterSuccess(state, action) {
      message.success(action.payload.message);
      state.helpCenters = action.payload;
      state.helpCenterLoading = false;
    },
    updateHelpCenter(state) {
      state.helpCenterLoading = true;
    },
    updateHelpCenterSuccess(state, action) {
      message.success(action.payload.message);
      state.helpCenterLoading = false;
    },
    addHelpCenterType(state) {
      state.helpCenterTypesLoading = true;
    },
    addHelpCenterTypeSuccess(state, action) {
      message.success(action.payload.message);
      state.helpCenterTypes = action.payload;
      state.helpCenterTypesLoading = false;
    },
    updateHelpCenterType(state) {
      state.helpCenterTypesLoading = true;
    },
    updateHelpCenterTypeSuccess(state, action) {
      message.success(action.payload.message);
      state.helpCenterTypesLoading = false;
    },
    removeHelpCenterType(state) {
      state.helpCenterTypesLoading = true;
    },
    removeHelpCenterTypeSuccess(state, action) {
      message.success(action.payload.message);
      state.helpCenterTypesLoading = false;
    },
    removeHelpCenter(state) {
      state.helpCenterLoading = true;
    },
    removeHelpCenterSuccess(state, action) {
      message.success(action.payload.message);
      state.helpCenterLoading = false;
    },
    getHelpCenterTypes(state) {
      state.helpCenterTypes = [];
      state.helpCenterTypesLoading = true;
    },
    getHelpCenterTypesSuccess(state, action) {
      state.helpCenterTypes = action.payload;
      state.helpCenterTypesLoading = false;
    },
    requestError(state, action) {
      state.helpCenterLoading = false;
      message.error(action.payload.message);
    },
  },
});

export const {
  requestError,
  getHelpCenter,
  getHelpCenterSuccess,
  addHelpCenter,
  addHelpCenterSuccess,
  addHelpCenterType,
  updateHelpCenterType,
  updateHelpCenterTypeSuccess,
  updateHelpCenter,
  updateHelpCenterSuccess,
  addHelpCenterTypeSuccess,
  removeHelpCenter,
  removeHelpCenterSuccess,
  removeHelpCenterType,
  removeHelpCenterTypeSuccess,
  getHelpCenterTypes,
  getHelpCenterTypesSuccess,
} = helpCenterSlice.actions;
export default helpCenterSlice;
