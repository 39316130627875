import React from "react";

const Purpose = () => {
  return (
    <div className="flex flex-col gap-5">
      <h1 className="md:text-[28px] text-[16px] font-semibold">
        Purposes for collection, use and disclosure of the information provided
      </h1>
      <h3 className="font-semibold"> Personal Information</h3>
      <p className="text-gray-600 text-justify text-[14px] md:text-[18px]">
        Personal Information and data collected from cookies and other logging
        data may be used: (i) to provide and improve our Services and customer
        service, (ii) to administer your use of the Services, (iii) to better
        understand your needs and interests, (iv) to personalize and improve
        your experience, and (v) to provide or offer software updates and
        product announcements. If you no longer wish to receive communications
        from us, please follow the “unsubscribe” instructions provided in any of
        those communications, or update your account settings, as applicable. We
        do not sell your Personal Information, Files or any other information
        about you to third parties.
      </p>
      <h3 className="font-semibold"> Files</h3>
      <p className="text-gray-600 text-justify text-[14px] md:text-[18px]">
        Files are encrypted and stored with Amazon’s (AWS) storage service and
        may be accessed and downloaded by you. Properity does not access or use
        your Files other than (i) in an encrypted form or (ii) in aggregated
        reports that do not contain, nor that can be used to extract, personally
        identifiable information.
      </p>
      <h3 className="font-semibold"> Analytics</h3>
      <p className="text-gray-600 text-justify text-[14px] md:text-[18px]">
        We may also collect some Information (ourselves or by using third party
        services) using logging and cookies, such as IP addresses, which can
        sometimes be correlated with Personal Information. We use this
        Information for the above purposes and to monitor and analyze use of the
        Services, for the Services’ technical administration, to increase our
        Services’ functionality and user-friendliness, and to verify users have
        the authorization needed for the Services to process their requests.
      </p>
    </div>
  );
};

export default Purpose;
