import aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Forgotpassword from "../src/components/login/Forgotpassword";
import Login from "../src/components/login/Login";
import Resetpassword from "../src/components/login/Resetpassword";
import "./App.css";
import MessagePop from "./components/Message/MessagePop";
import Layout from "./components/layout";
import OTP from "./components/login/OTP";
import Announcements from "./components/pages/announcements/Announcements";
import Events from "./components/pages/announcements/Events";
import BillCharge from "./components/pages/billing/BillCharges";
import BillingMain from "./components/pages/billing/BillingMain";
import SearchHomeowner from "./components/pages/billing/SearchHomeowner";
import Dashboard from "./components/pages/dashboard/Dashboard";
import Directories from "./components/pages/directories-and-hotlines/directories/Directories";
import Hotlines from "./components/pages/directories-and-hotlines/hotlines/Hotlines";
import Forms from "./components/pages/forms/Forms";
import HelpCenter from "./components/pages/help-center/HelpCenter";
import Applicants from "./components/pages/homeowners/Applicants";
import MasterList from "./components/pages/homeowners/MasterList";
import ResidentProfile from "./components/pages/homeowners/ResidentProfile";
import TableSample from "./components/pages/homeowners/TableSample";
import InquiriesReports from "./components/pages/inquiries-and-reports/inquiries/InquiriesReports";
import UtilityReportTypes from "./components/pages/inquiries-and-reports/reports/UtilityReportTypes";
import UtilityReports from "./components/pages/inquiries-and-reports/reports/UtilityReports";
import ProperityLayout from "./components/pages/landing/ProperityLayout";
import Subscription from "./components/pages/managesubscription/Subscription";
import Success from "./components/pages/managesubscription/Success";
import NotFound from "./components/pages/notfound/NotFound";
import PaymentChannel from "./components/pages/paymentchannel/PaymentChannel";
import AccountDeletion from "./components/pages/privacypolicy/AccountDeletion";
import ChangesToPrivacy from "./components/pages/privacypolicy/ChangesToPrivacy";
import Consent from "./components/pages/privacypolicy/Consent";
import Contact from "./components/pages/privacypolicy/Contact";
import HowWeCollect from "./components/pages/privacypolicy/HowWeCollect";
import InformationWeCollect from "./components/pages/privacypolicy/InformationWeCollect";
import Privacy from "./components/pages/privacypolicy/Privacy";
import PrivacyPolicy from "./components/pages/privacypolicy/PrivacyPolicy";
import Purpose from "./components/pages/privacypolicy/Purpose";
import ManageProfile from "./components/pages/profile/ManageProfile";
import UpdatePassword from "./components/pages/profile/UpdatePassword";
import UpdateProfile from "./components/pages/profile/UpdateProfile";
import Phase from "./components/pages/properties/Phase";
import Properties from "./components/pages/properties/Properties";
import PropertyName from "./components/pages/properties/PropertyName";
import PropertySize from "./components/pages/properties/PropertySize";
import PropertyType from "./components/pages/properties/PropertyType";
import Street from "./components/pages/properties/Street";
import SuccessVerification from "./components/pages/successverification/SuccessVerification";
import TransactionsMain from "./components/pages/transactions/TransactionsMain";
import Registration from "./components/registration/Registration";
import SuccessSubscription from "./components/registration/SuccessSubscription";
import ManageSubscription from "./components/pages/managesubscription/ManageSubscription";
import UpgradeSubscription from "./components/pages/managesubscription/UpgradeSubscription";
import { Button, Result } from "antd";

const App = () => {
  const { isAuthenticated, token, currentUser, designation } = useSelector(
    (state) => state.auth
  );
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    aos.init({
      duration: 1000,
    });
  }, []);

  useEffect(() => {
    if (!isAuthenticated && localStorage.getItem("login") === "login") {
      navigate("/Login");
    } else {
      if (
        location.pathname === "/Login" ||
        location.pathname === "/Forgotpassword"
      ) {
        if (designation === "Admin") {
          navigate("/Dashboard");
        } else if (designation === "Billing Admin") {
          navigate("/Transactions");
        }
        localStorage.removeItem("login");
        localStorage.setItem("accId", currentUser.accountId);
      }
    }

    if (location.pathname === "/privacy-policy") {
      navigate("/privacy-policy/introduction");
    }
  }, [isAuthenticated]);

  const NotFoundx = () => {
    return <Navigate to="/404" state={{ from: location }} replace />;
  };

  const NotAuthenticated = () => {
    return (
      <Routes>
        <Route path={"/"} element={<ProperityLayout />} />
        <Route
          path={"/SuccessVerification/:token"}
          element={<SuccessVerification />}
        />
        <Route path={"/Login"} element={<Login />} />
        <Route path={"/Forgotpassword"} element={<Forgotpassword />} />
        <Route path={"/OTP"} element={<OTP />} />
        <Route path={"/Resetpassword"} element={<Resetpassword />} />
        <Route path={"/Registration"} element={<Registration />} />
        <Route
          path={"/SuccessSubscription"}
          element={<SuccessSubscription />}
        />
        <Route element={<PrivacyPolicy />}>
          <Route path={"/privacy-policy/introduction"} element={<Privacy />} />
          <Route
            path={"/privacy-policy/information"}
            element={<InformationWeCollect />}
          />
          <Route path={"/privacy-policy/collect"} element={<HowWeCollect />} />
          <Route path={"/privacy-policy/store"} element={<Purpose />} />
          <Route path={"/privacy-policy/consent"} element={<Consent />} />
          <Route
            path={"/privacy-policy/deletion"}
            element={<AccountDeletion />}
          />
          <Route
            path={"/privacy-policy/changes"}
            element={<ChangesToPrivacy />}
          />
          <Route path={"/privacy-policy/contact"} element={<Contact />} />
        </Route>
        <Route path={"/404"} element={<NotFound />} />
        <Route path="*" element={<NotFoundx />} />
      </Routes>
    );
  };

  const AuthenticatedAdmin = () => {
    return (
      <Routes>
        <Route
          path={"/SuccessVerification/:token"}
          element={<SuccessVerification />}
        />
        <Route path={"/Registered"} element={<TableSample />} />
        <Route path={"/Applications"} element={<Applicants />} />
        <Route path={"/MasterList"} element={<MasterList />} />
        <Route path={"/Phase"} element={<Phase />} />
        <Route path={"/Properties"} element={<Properties />} />
        <Route path={"/PropertyName"} element={<PropertyName />} />
        <Route path={"/PropertySize"} element={<PropertySize />} />
        <Route path={"/PropertyType"} element={<PropertyType />} />
        <Route path={"/Transactions"} element={<TransactionsMain />} />
        <Route path={"/ResidentProfile"} element={<ResidentProfile />} />
        <Route path={"/Street"} element={<Street />} />
        <Route path={"/Billing"} element={<BillingMain />} />
        <Route path={"/AssociationCharges"} element={<BillCharge />} />
        <Route path={"/AddBilling"} element={<SearchHomeowner />} />
        <Route path={"/Announcements"} element={<Announcements />} />
        <Route path={"/Events"} element={<Events />} />
        <Route path={"/Inquiries"} element={<InquiriesReports />} />
        <Route path={"/UtilityReports"} element={<UtilityReports />} />
        <Route path={"/UtilityReportTypes"} element={<UtilityReportTypes />} />
        <Route element={<ManageProfile />}>
          <Route path={"/EditProfile"} element={<UpdateProfile />} />
          <Route path={"/ChangePassword"} element={<UpdatePassword />} />
        </Route>
        <Route path={"/Directories"} element={<Directories />} />
        <Route path={"/Hotlines"} element={<Hotlines />} />
        <Route path={"/Forms"} element={<Forms />} />
        <Route path={"/HelpCenter"} element={<HelpCenter />} />
        <Route path={"/PaymentChannel"} element={<PaymentChannel />} />
        <Route path={"/Dashboard"} element={<Dashboard />} />
        <Route path={"/"} element={<ProperityLayout />} />
        <Route path={`/ManageSubscription`} element={<ManageSubscription />} />
        <Route
          path={`/UpgradeSubscription`}
          element={<UpgradeSubscription />}
        />
        <Route
          path={`/Subscription/:associationId`}
          element={<Subscription />}
        />
        <Route path={"/404"} element={<NotFound />} />
        <Route path={"/Success"} element={<Success />} />
        <Route element={<PrivacyPolicy />}>
          <Route path={"/privacy-policy/introduction"} element={<Privacy />} />
          <Route
            path={"/privacy-policy/information"}
            element={<InformationWeCollect />}
          />
          <Route path={"/privacy-policy/collect"} element={<HowWeCollect />} />
          <Route path={"/privacy-policy/store"} element={<Purpose />} />
          <Route path={"/privacy-policy/consent"} element={<Consent />} />
          <Route
            path={"/privacy-policy/deletion"}
            element={<AccountDeletion />}
          />
          <Route
            path={"/privacy-policy/changes"}
            element={<ChangesToPrivacy />}
          />
          <Route path={"/privacy-policy/contact"} element={<Contact />} />
        </Route>
        <Route path="*" element={<NotFoundx />} />
      </Routes>
    );
  };

  const AuthenticatedBillingAdmin = () => {
    return (
      <Routes>
        <Route
          path={"/SuccessVerification/:token"}
          element={<SuccessVerification />}
        />
        <Route path={"/Transactions"} element={<TransactionsMain />} />
        <Route path={"/Billing"} element={<BillingMain />} />
        <Route path={"/AssociationCharges"} element={<BillCharge />} />
        <Route path={"/AddBilling"} element={<SearchHomeowner />} />
        <Route element={<ManageProfile />}>
          <Route path={"/EditProfile"} element={<UpdateProfile />} />
          <Route path={"/ChangePassword"} element={<UpdatePassword />} />
        </Route>
        <Route path={"/"} element={<ProperityLayout />} />
        <Route path={`/ManageSubscription`} element={<ManageSubscription />} />
        <Route
          path={`/UpgradeSubscription`}
          element={<UpgradeSubscription />}
        />
        <Route
          path={`/Subscription/:associationId`}
          element={<Subscription />}
        />
        <Route path={"/404"} element={<NotFound />} />
        <Route path={"/Success"} element={<Success />} />
        <Route element={<PrivacyPolicy />}>
          <Route path={"/privacy-policy/introduction"} element={<Privacy />} />
          <Route
            path={"/privacy-policy/information"}
            element={<InformationWeCollect />}
          />
          <Route path={"/privacy-policy/collect"} element={<HowWeCollect />} />
          <Route path={"/privacy-policy/store"} element={<Purpose />} />
          <Route path={"/privacy-policy/consent"} element={<Consent />} />
          <Route
            path={"/privacy-policy/deletion"}
            element={<AccountDeletion />}
          />
          <Route
            path={"/privacy-policy/changes"}
            element={<ChangesToPrivacy />}
          />
          <Route path={"/privacy-policy/contact"} element={<Contact />} />
        </Route>
        <Route path="*" element={<NotFoundx />} />
      </Routes>
    );
  };

  return (
    <>
      {isAuthenticated ? (
        <>
          {designation === "Admin" && <MessagePop />}
          {location.pathname.split("/")[1] !== "SuccessVerification" && (
            <Layout>
              {designation === "Admin" ? (
                <AuthenticatedAdmin />
              ) : (
                <AuthenticatedBillingAdmin />
              )}
            </Layout>
          )}
          {location.pathname.split("/")[1] === "SuccessVerification" &&
            (designation === "Admin" ? (
              <AuthenticatedAdmin />
            ) : (
              <AuthenticatedBillingAdmin />
            ))}
        </>
      ) : (
        <NotAuthenticated />
      )}
    </>
  );
};

export default App;
