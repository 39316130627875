import { defaultAxios, axiosMultipart } from "../axios";

const baseApi = "/api/associations/helpcenter/";

defaultAxios.interceptors.request.use(async function (config) {
  config.headers["Authorization"] = `Bearer ${
    JSON.parse(await localStorage.getItem("persist:auth")).token.split(`"`)[1]
  }`;
  return config;
});

axiosMultipart.interceptors.request.use(async function (config) {
  config.headers["Authorization"] = `Bearer ${
    JSON.parse(await localStorage.getItem("persist:auth")).token.split(`"`)[1]
  }`;
  return config;
});

export const getHelpCenter = async () => {
  try {
    const result = await defaultAxios.get(baseApi + "getHelpCenter");
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getHelpCenterTypes = async () => {
  try {
    const result = await defaultAxios.get(baseApi + "getHelpCenterTypes");
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const addHelpCenter = async (body) => {
  try {
    const result = await axiosMultipart.post(
      baseApi + "addHelpCenter",
      body.formData
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const updateHelpCenter = async (data) => {
  try {
    const result = await axiosMultipart.post(
      baseApi + "updateHelpCenter/" + data.params,
      data.body.formData
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const addHelpCenterType = async (body) => {
  try {
    const result = await defaultAxios.post(baseApi + "addHelpCenterType", body);
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const removeHelpCenterType = async (params) => {
  try {
    const result = await defaultAxios.post(
      baseApi + "removeHelpCenterType/" + params
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const updateHelpCenterType = async (data) => {
  try {
    const result = await defaultAxios.post(
      baseApi + "updateHelpCenterType/" + data.params,
      data.body
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const removeHelpCenter = async (params) => {
  try {
    const result = await defaultAxios.post(
      baseApi + "removeHelpCenter/" + params
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};
