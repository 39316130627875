import React from "react";
import { Image } from "antd";
import properity from "../../../assets/resources/images/properityReg.png";
import success from "../../../assets/resources/images/success-like.png";
import successLike from "../../../assets/resources/images/success-icon.png";
import { useNavigate } from "react-router-dom";

const Success = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col h-screen w-full">
      <div className="w-full h-[80px] flex items-center justify-center">
        <Image
          preview={false}
          style={{ width: "176px", heigth: "33px" }}
          src={properity}
        />
      </div>
      <div className="w-full h-[348px] flex items-center justify-center bg-properity">
        <Image
          preview={false}
          style={{ width: "190px", heigth: "190px" }}
          src={success}
        />
      </div>
      <div className="w-full h-full flex flex-col gap-16 items-center justify-center">
        <h1 className="text-properity text-[55px] font-[600]">Thank you !</h1>
        <span className="items-center flex text-center text-[29px] font-[500] gap-4">
          <Image
            preview={false}
            style={{ width: "42px", heigth: "42px" }}
            src={successLike}
          />
          Payment Done Successfully
        </span>
        <p className="text-[20px] text-[#909090] text-center">
          Your payment has been received and processed. If you have any
          questions or concerns about your account,
          <br /> please do not hesitate to contact us. We are always here to
          help.
        </p>
        <button
          onClick={() => navigate("/Dashboard")}
          type="button"
          className="text-white text-[30px] font-[600] w-[515px] h-[82px] bg-properity border-none hover:bg-green-700 cursor-pointer transition-all ease-in duration-150"
        >
          Back to home page
        </button>
      </div>
    </div>
  );
};

export default Success;
