import { put, call, takeLatest } from "redux-saga/effects";
import {
  getAnnouncements,
  removeAnnouncement,
  addAnnouncement,
  updateAnnouncement,
  removeAnnouncementAttachment,
  addAnnouncementAttachment,
  updateAnnouncementAttachment,
} from "../../../api/announcements&events/announcements/announcements-api";
import { announcementActions } from "../../../store";
///
///

function* getAnnouncementsRequest() {
  const { requestError, getAnnouncementsSuccess } = announcementActions;
  const result = yield call(getAnnouncements);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getAnnouncementsSuccess(result.data));
  }
}

function* addAnnouncementRequest({ payload }) {
  const { requestError, addAnnouncementSuccess, getAnnouncements } =
    announcementActions;
  const result = yield call(addAnnouncement, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    if (payload.cb) {
      yield call(payload.cb);
    }
    yield put(addAnnouncementSuccess(result.data));
    yield put(getAnnouncements());
  }
}

function* updateAnnouncementRequest({ payload }) {
  const { requestError, updateAnnouncementSuccess, getAnnouncements } =
    announcementActions;
  const result = yield call(updateAnnouncement, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    if (payload.cb) {
      yield call(payload.cb);
    }
    yield put(updateAnnouncementSuccess(result.data));
    yield put(getAnnouncements());
  }
}

function* removeAnnouncementRequest({ payload }) {
  const { requestError, removeAnnouncementsSuccess, getAnnouncements } =
    announcementActions;
  const result = yield call(removeAnnouncement, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    if (payload.cb) {
      yield call(payload.cb);
    }
    yield put(removeAnnouncementsSuccess(result.data));
    yield put(getAnnouncements());
  }
}

function* removeAnnouncementAttachmentRequest({ payload }) {
  const { requestError, removeAnnouncementsSuccess, getAnnouncements } =
    announcementActions;
  const result = yield call(removeAnnouncementAttachment, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    if (payload.cb) {
      yield call(payload.cb);
    }
    yield put(removeAnnouncementsSuccess(result.data));
    yield put(getAnnouncements());
  }
}

function* addAnnouncementAttachmentRequest({ payload }) {
  const { requestError, addAnnouncementsAttachmentSuccess, getAnnouncements } =
    announcementActions;
  const result = yield call(addAnnouncementAttachment, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    if (payload.cb) {
      yield call(payload.cb);
    }
    yield put(addAnnouncementsAttachmentSuccess(result.data));
    yield put(getAnnouncements());
  }
}

function* updateAnnouncementAttachmentRequest({ payload }) {
  const {
    requestError,
    updateAnnouncementAttachmentSuccess,
    getAnnouncements,
  } = announcementActions;
  const result = yield call(updateAnnouncementAttachment, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    if (payload.cb) {
      yield call(payload.cb);
    }
    yield put(updateAnnouncementAttachmentSuccess(result.data));
    yield put(getAnnouncements());
  }
}

// Export the saga (todo-saga)
export default function* announcementSaga() {
  yield takeLatest(`announcements/getAnnouncements`, getAnnouncementsRequest);
  yield takeLatest(
    `announcements/updateAnnouncement`,
    updateAnnouncementRequest
  );
  yield takeLatest(
    `announcements/addAnnouncementsAttachment`,
    addAnnouncementAttachmentRequest
  );
  yield takeLatest(
    `announcements/updateAnnouncementAttachment`,
    updateAnnouncementAttachmentRequest
  );
  yield takeLatest(
    `announcements/removeAnnouncementsAttachment`,
    removeAnnouncementAttachmentRequest
  );
  yield takeLatest(`announcements/addAnnouncement`, addAnnouncementRequest);
  yield takeLatest(
    `announcements/removeAnnouncements`,
    removeAnnouncementRequest
  );
}
