import { Card, Table } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  format_PHCurrency,
  format_addCommas,
} from "../../../../helpers/formatCurrency";
import { getPreviousBillingStatement } from "../../../../store/slices/billing/billing-slice";
import PreviousCharges from "./PreviousCharges";

const PreviousBilling = ({
  previousBillingStatementLoading,
  previousBillingStatement,
  billingStatement,
  billId,
  previousBillingId,
}) => {
  const { currentUser } = useSelector((state) => state.auth);
  const columns = [
    {
      title: "Billing date",
      dataIndex: "billingDate",
      render: (billingDate) => moment(billingDate).format("LL"),
      width: "200px",
    },
    {
      title: "Due date",
      dataIndex: "dueDate",
      render: (billingDate) => moment(billingDate).format("LL"),
      width: "200px",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (amount) =>
        amount ? format_PHCurrency(amount) : format_PHCurrency(0),
      width: "200px",
    },
    {
      title: "Vat amount",
      dataIndex: "vatAmount",
      render: (amount) =>
        amount ? format_PHCurrency(amount) : format_PHCurrency(0),
      width: "200px",
    },
    {
      title: "Total amount",
      dataIndex: "totalAmount",
      render: (amount) =>
        amount ? format_PHCurrency(amount) : format_PHCurrency(0),
      width: "200px",
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    if (previousBillingId) {
      dispatch(
        getPreviousBillingStatement({
          params: previousBillingId,
          body: {
            billId: billId,
            propertyId: "27f94458-5e81-11ed-8dce-0ebf1fc6bf7e",
          },
        })
      );
    }
  }, [previousBillingId]);

  return (
    <Card
      title={
        <div className="flex justify-between  items-center p-5">
          <h1 className="font-[600] text-[25px] mb-0">Previous billing</h1>
        </div>
      }
    >
      <Table
        loading={previousBillingStatementLoading}
        className="table-charge"
        rowKey={"key"}
        columns={columns}
        scroll={{
          y: 500,
          x: "100%",
        }}
        dataSource={
          billingStatement?.previousBillingId
            ? [{ ...previousBillingStatement, key: 1 }]
            : []
        }
        expandable={{
          expandedRowRender: (record) => (
            <PreviousCharges
              previousBillingStatementsLoading={previousBillingStatementLoading}
              previousBillingStatements={previousBillingStatement}
            />
          ),
          rowExpandable: (record) => record.name !== "Not Expandable",
        }}
      />
    </Card>
  );
};

export default PreviousBilling;
