import { Button, Card, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getProfile,
  updateProfile,
  profileMode,
} from "../../../store/slices/profile/profile-slice";

const UpdateProfile = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const { profile, isLoading } = useSelector((state) => state.profile);
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    if (profile) {
      return;
    } else {
      dispatch(getProfile(token));
    }
  }, []);

  useEffect(() => {
    dispatch(profileMode("editProfile"));
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      name: profile ? profile[0]?.name : "-",
      email: profile ? profile[0]?.email : "-",
      mobileNumber: profile ? profile[0]?.mobileNumber.substring(2) : "-",
    });
  }, [profile]);

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    dispatch(
      updateProfile({
        name: values?.name,
        email: values?.email,
        mobileNumber: "63" + values?.mobileNumber,
      })
    );
    setIsEditing(!isEditing);
  };

  const editHandler = () => {
    setIsEditing(!isEditing);
    dispatch(getProfile(token));
  };

  const updatePassword = () => {
    dispatch(profileMode("editPass"));
    navigate("/ChangePassword");
    setIsEditing(false);
  };

  return (
    <div className="w-full">
      <Card
        title={
          <div className="flex flex-col md:flex-row justify-between w-full items-left md:items-center p-5">
            <h1 className="font-[600] text-[25px] mb-0">Manage Profile</h1>
            <div>
              <p
                className="text-sm cursor-pointer hover:text-blue-600 transition-all ease-in duration-150"
                onClick={() => updatePassword()}
              >
                Password and security
              </p>
            </div>
          </div>
        }
      >
        <div className="w-full h-full">
          <Form
            disabled={!isEditing}
            form={form}
            name="add"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
          >
            <div className="flex md:flex-col flex-col gap-10 items-center md:items-start p-6">
              <div className="flex flex-col gap-4 w-full md:w-[40%]  md:flex-1 ">
                <div className="text-label">
                  <Form.Item
                    name="name"
                    label={
                      <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
                        Name
                      </h1>
                    }
                  >
                    <Input
                      style={{
                        padding: "10px",
                        fontSize: "18px",
                        borderRadius: "3px",
                      }}
                      placeholder="Enter name"
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label={
                      <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
                        Email
                      </h1>
                    }
                  >
                    <Input
                      style={{
                        padding: "10px",
                        fontSize: "18px",
                        borderRadius: "3px",
                      }}
                      placeholder="Enter email"
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item
                    name="mobileNumber"
                    label={
                      <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
                        Contact number
                      </h1>
                    }
                    rules={[
                      {
                        pattern: /^[0-9]{10}$/,
                        message:
                          "Value must start with 9 and must be 10 digits",
                      },
                    ]}
                  >
                    <Input
                      style={{
                        padding: "10px",
                        fontSize: "18px",
                        borderRadius: "3px",
                      }}
                      size="large"
                      prefix="+63"
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>
          <div className="flex gap-3 px-6">
            <Button
              onClick={editHandler}
              type="primary"
              style={{
                background: isEditing ? "red" : "#4d8e03",
                transition: "all ease 0.3s",
              }}
            >
              {!isEditing ? "Edit" : "Cancel"}
            </Button>
            <Button
              loading={isLoading}
              style={{ background: "#4d8e03" }}
              className={!isEditing ? "hidden" : "block"}
              onClick={() => form.submit()}
              type="primary"
            >
              Update
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default UpdateProfile;
