import { put, call, takeLatest } from "redux-saga/effects";
import {
  getHelpCenter,
  getHelpCenterTypes,
  addHelpCenter,
  removeHelpCenter,
  addHelpCenterType,
  updateHelpCenterType,
  removeHelpCenterType,
  updateHelpCenter,
} from "../../api/help-center/help-center-api";
import { helpCenterActions } from "../../store";
///
///

function* getHelpCenterRequest() {
  const { requestError, getHelpCenterSuccess } = helpCenterActions;
  const result = yield call(getHelpCenter);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getHelpCenterSuccess(result.data));
  }
}

function* addHelpCenterRequest({ payload }) {
  const { requestError, addHelpCenterSuccess, getHelpCenter } =
    helpCenterActions;
  const result = yield call(addHelpCenter, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(addHelpCenterSuccess(result.data));
    yield put(getHelpCenter());
  }
}

function* updateHelpCenterRequest({ payload }) {
  const { requestError, updateHelpCenterSuccess, getHelpCenter } =
    helpCenterActions;
  const result = yield call(updateHelpCenter, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(updateHelpCenterSuccess(result.data));
    yield put(getHelpCenter());
  }
}

function* addHelpCenterTypeRequest({ payload }) {
  const { requestError, addHelpCenterTypeSuccess, getHelpCenterTypes } =
    helpCenterActions;
  const result = yield call(addHelpCenterType, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(addHelpCenterTypeSuccess(result.data));
    yield put(getHelpCenterTypes());
  }
}

function* updateHelpCenterTypeRequest({ payload }) {
  const { requestError, updateHelpCenterTypeSuccess, getHelpCenterTypes } =
    helpCenterActions;
  const result = yield call(updateHelpCenterType, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(updateHelpCenterTypeSuccess(result.data));
    yield put(getHelpCenterTypes());
  }
}

function* removeHelpCenterTypeRequest({ payload }) {
  const { requestError, removeHelpCenterTypeSuccess, getHelpCenterTypes } =
    helpCenterActions;
  const result = yield call(removeHelpCenterType, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(removeHelpCenterTypeSuccess(result.data));
    yield put(getHelpCenterTypes());
  }
}

function* removeHelpCenterRequest({ payload }) {
  const { requestError, removeHelpCenterSuccess, getHelpCenter } =
    helpCenterActions;
  const result = yield call(removeHelpCenter, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(removeHelpCenterSuccess(result.data));
    yield put(getHelpCenter());
  }
}

function* getHelpCenterTypesRequest() {
  const { requestError, getHelpCenterTypesSuccess } = helpCenterActions;
  const result = yield call(getHelpCenterTypes);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getHelpCenterTypesSuccess(result.data));
  }
}

// Export the saga (todo-saga)
export default function* helpCenterSaga() {
  yield takeLatest(`helpCenter/getHelpCenter`, getHelpCenterRequest);
  yield takeLatest(`helpCenter/getHelpCenterTypes`, getHelpCenterTypesRequest);
  yield takeLatest(`helpCenter/addHelpCenter`, addHelpCenterRequest);
  yield takeLatest(`helpCenter/removeHelpCenter`, removeHelpCenterRequest);
  yield takeLatest(
    `helpCenter/removeHelpCenterType`,
    removeHelpCenterTypeRequest
  );
  yield takeLatest(`helpCenter/addHelpCenterType`, addHelpCenterTypeRequest);
  yield takeLatest(
    `helpCenter/updateHelpCenterType`,
    updateHelpCenterTypeRequest
  );
  yield takeLatest(`helpCenter/updateHelpCenter`, updateHelpCenterRequest);
}
