import React from "react";

const InformationWeCollect = () => {
  return (
    <div className="flex flex-col gap-5">
      <h1 className="md:text-[28px] text-[16px] font-semibold">
        Information we collect
      </h1>
      <ul className="text-gray-600 pl-5">
        <li>Full name</li>
        <li>Contact information</li>
        <li> Email address</li>
        <li>Birthdate</li>
        <li>Property(ies) addresses</li>
      </ul>
      <p className="text-gray-600 text-justify text-[14px] md:text-[18px]">
        Any information that you provide by filling out Properity registration
        form, additional information required by the system in both WEB and
        mobile applications at the time of registration and agreeing to the
        policy and the terms & conditions we instill; Financial data such as
        e-wallets and bank account information, and any other modes of payment
        Details about your entered required information, inquiries and
        reporting, changes you made on your personal data after registration In
        any case that there is in need to make use of your personal data for
        security or fraud prevention purposes
      </p>
    </div>
  );
};

export default InformationWeCollect;
