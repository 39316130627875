import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-scroll";
// import { img4 } from "../../assets/images";
import { img4 } from "../../../assets/landing/images";
// import { Properity } from "../../assets/logos";
import { Properity } from "../../../assets/landing/logos";

const OurStory = () => {
	const textAnimate = {
		offscreen: { y: 100, opacity: 0 },
		onscreen: {
			y: 0,
			opacity: 1,
			transition: { type: "spring", bounce: 0.4, duration: 1 },
		},
	};
	const imageAnimate = {
		offscreen: { y: 100, opacity: 0 },
		onscreen: {
			y: 0,
			opacity: 1,
			transition: { type: "spring", bounce: 0.3, duration: 1 },
		},
	};
	return (
		<>
			<div className=" bg-[#F5F5EE]">
				<div className=" grid grid-cols-8 lg:px-10 px-2">
					<motion.div
						className=" 2xl:col-span-3 xl:col-span-4 col-span-8 mt-3 flex justify-center items-center"
						initial={"offscreen"}
						whileInView={"onscreen"}
						viewport={{ once: true, amount: 0.3 }}
						transition={{ staggerChildren: 0.1 }}
					>
						<motion.img
							className=" max-w-full xl:min-h-full sm:h-[500px] h-[300px]"
							src={img4}
							alt="/"
							variants={imageAnimate}
						/>
					</motion.div>
					<motion.div
						className=" 2xl:col-span-5 xl:col-span-4 col-span-8 lg:m-10 m-4"
						initial={"offscreen"}
						whileInView={"onscreen"}
						viewport={{ once: true, amount: 0.2 }}
						transition={{ staggerChildren: 0.2 }}
					>
						<motion.h1
							className=" sm:text-5xl xs:text-3xl text-[26px] font-bold text-[#57833C] sm:mb-5 sm:pb-5 mb-2 pb-2"
							variants={imageAnimate}
						>
							Our Story
						</motion.h1>
						<motion.div
							className=" flex flex-col sm:gap-y-5 gap-y-2 font-medium"
							// initial={"offscreen"}
							// whileInView={"onscreen"}
							// viewport={{ once: true, amount: 0.1 }}
							// transition={{ delayChildren: 0.3, staggerChildren: 0.4 }}
						>
							<motion.p
								className="  sm:text-lg text-sm sm:leading-[2.5rem] leading-10 text-[black] sm:mr-5 mr-0"
								variants={textAnimate}
							>
								Properity is a property management system planned and built by a group of IT experts
								from Dynamic Global Soft, Inc. This online platform is initially designed for
								residential properties wherein homeowners have the capabilities of managing owned
								properties, generation of billing statements, paying bills online easily and
								conveniently. It is open for all subdivisions, associations and other real estate
								organizations. The idea of creating this project started with the developers' own
								experiences such as the need to physically appear at the homeowner's association
								office in order to pay the dues, penalty of late payments, emergency, security and
								safety concerns and the like. Moreover, project scope ranges from residential to
								commercial and industrial types of properties and also serve other special purposes.
							</motion.p>
							<motion.p
								className="  sm:text-lg text-sm sm:leading-[2.5rem] leading-10 text-[black] sm:mr-5 mr-0"
								variants={textAnimate}
								// variants={imageAnimate}
							>
								Our software offers a comprehensive suite of features to manage your property in an
								efficient and cost-effective manner. You can easily manage and track homeowners,
								tenants, rent or dues payments, and other important property-related transactions.
								Our software also allows property managers to generate reports and keep track of
								transactional data, analyze trends, and forecast future needs. With our intuitive
								interface, you can quickly and easily manage your property management needs with a
								few clicks.
							</motion.p>
							<motion.p
								className="  sm:text-lg text-sm sm:leading-[2.5rem] leading-10 text-[black] sm:mr-5 mr-0"
								variants={textAnimate}
								// variants={textAnimate}
							>
								<Link
									className=" text-[#197419] cursor-pointer"
									to="contact"
									spy={true}
									smooth={true}
									offset={-80}
									duration={500}
								>
									Get in touch
								</Link>{" "}
								with the PROPERITY team today and see why it's the best solution for your property
								management needs!
							</motion.p>
						</motion.div>
					</motion.div>
				</div>
				<motion.div
					className=" grid grid-cols-6 2xl:px-24 xl:px-16 lg:px-12 px-2 mx-auto"
					initial={"offscreen"}
					whileInView={"onscreen"}
					viewport={{ once: true, amount: 0.3 }}
					transition={{ staggerChildren: 0.1 }}
				>
					<motion.div
						className=" lg:col-span-1 col-span-6 2xl:mt-16 xl:mt-12 sm:mt-5 mt-0 lg:w-auto sm:w-[300px] xs:w-[180px] w-[150px] lg:ml-0 ml-5 sm:mb-5 mb-0"
						variants={imageAnimate}
					>
						<img className=" w-full" src={Properity} alt="/" />
					</motion.div>
					<motion.div
						className=" lg:col-span-5 col-span-6 2xl:m-16 xl:m-12 lg:m-5 m-4 font-medium"
						variants={textAnimate}
					>
						<p className=" sm:text-lg text-sm sm:leading-[2.5rem] leading-10 text-[black] 2xl:mr-16 xl:mr-12 mr-0">
							Properity is a fully-integrated Property Management System for residential, business,
							commercial, and industrial properties. We enable the facilitation of transactions,
							service delivery, and communication between building administrators and property
							occupants. The system combines the convenience of mobile applications and the power of
							integrated management solutions accessible through the web.
						</p>
					</motion.div>
				</motion.div>
			</div>
		</>
	);
};

export default OurStory;
