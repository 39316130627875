import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { dummyRequest } from "../../constants/staticConst";
import { convertToBase64 } from "../../helpers/tobase64";
const { Dragger } = Upload;

const AssocFiles = ({ setFormValues, setCurrent, formData }) => {
  const [formLogo] = Form.useForm();
  const [imagePrev, setImagePrev] = useState([]);
  const [fileList, setFileList] = useState([]);
  const dispatch = useDispatch();
  const { eventsLoading } = (state) => state.events;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    setFormValues((prevState) => ({ ...prevState, ...values }));

    setCurrent((prev) => prev + 1);
    formData.resetFields();

    setImagePrev("");
    setPreviewTitle("");
    setFileList([]);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await convertToBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);

    formData.setFieldsValue({
      fileUrl: fileList?.map((file) => file?.originFileObj) || null,
    });
  };
  const uploadButton = (
    <div className="w-full min-w-[100px]">
      <div
        style={{
          marginTop: 8,
          padding: "8px",
        }}
      >
        Upload files (Max: 5)
      </div>
    </div>
  );
  return (
    <div
      className="poppins flex flex-col items-center text-center"
      data-aos="fade"
      data-aos-easing="ease-in"
      data-aos-duration="600"
    >
      <h1 className="text-[#568634] font-[700] text-[40px] pb-10">
        Now, let’s upload your documents
      </h1>
      <div className="flex flex-col pb-10">
        <p className="text-[24px] font-[600] text-[#568634]">
          Must have the following:
        </p>
        <p className="text-[20px] font-[500] text-[#568634]">
          1. Securities and Exchange Commission (SEC) certificate,
        </p>
        <p className="text-[20px] font-[500] text-[#568634]">
          2. Bureau of Internal Revenue (BIR) registration certificate,
        </p>
        <p className="text-[20px] font-[500] text-[#568634]">
          3. Secretary certificate (authorized personnel to manage the properity
          system)
        </p>
      </div>
      <Form
        form={formData}
        name="add"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      >
        <div className="">
          <Form.Item
            valuePropName="file"
            name="fileUrl"
            rules={[
              {
                required: true,
                message: "Please upload your documents",
              },
            ]}
          >
            <Dragger
              multiple={true}
              maxCount={5}
              name="file"
              customRequest={dummyRequest}
              file={fileList}
              onChange={handleChange}
            >
              {uploadButton}
            </Dragger>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default AssocFiles;
