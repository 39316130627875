import { DeleteOutlined, DownOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Divider,
  Dropdown,
  Form,
  Input,
  Modal,
  Popconfirm,
  Space,
  Table,
  Tag,
  theme,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addHelpCenterType,
  getHelpCenterTypes,
  removeHelpCenterType,
  updateHelpCenterType,
} from "../../../store/slices/help-center/help-center-slice";
import CardTitle from "../../../UI/CardTitle";
import { LoadingTime } from "../../../UI/LoadingScreen";
import ModalButton from "../../../UI/ModalButton";
import PopconfirmRemove from "../../../UI/PopconfirmRemove";

const { useToken } = theme;

const HelpCenterTypesTab = () => {
  const { token } = useToken();
  const [form] = Form.useForm();
  const { eventTypes, eventTypesLoading } = useSelector(
    (state) => state.events
  );
  const { helpCenterTypes, helpCenterTypesLoading } = useSelector(
    (state) => state.helpCenter
  );
  const [isAdding, setIsAdding] = useState(false);
  const [editToggle, setEditToggle] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [visible, setVisible] = useState(false);
  const [typeId, setTypeId] = useState("");
  const dispatch = useDispatch();

  const text = "Are you sure to remove this help center type?";
  const description = "Remove the help center type";

  const items = [
    {
      label: (
        <div className="flex gap-2 items-center">
          <EditOutlined />
          <p>Edit</p>
        </div>
      ),
      key: "1",
    },
  ];

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle = {
    boxShadow: "none",
  };

  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "150px",
      render: (record) => (
        <Dropdown
          trigger={["click"]}
          menu={{
            items,
            onClick: () => editHandler(record.typeId),
          }}
          dropdownRender={(menu) => (
            <div className=" flex flex-col items-start" style={contentStyle}>
              {React.cloneElement(menu, {
                style: menuStyle,
              })}
              {/* <Button
                onClick={() => editHandler(record.typeId)}
                style={{ color: "black" }}
                type="link"
                icon={<EditOutlined />}
              >
                Edit
              </Button> */}
              <PopconfirmRemove
                text={text}
                desc={description}
                onConfirm={() => deleteHelpCenterType(record.typeId)}
              />
              {/* <Popconfirm
                placement="right"
                title={text}
                description={description}
                onConfirm={() => deleteHelpCenterType(record.typeId)}
                okText="Yes"
                cancelText="Cancel"
              >
                <Button
                  style={{ color: "black" }}
                  type="link"
                  icon={<DeleteOutlined />}
                >
                  Remove
                </Button>
              </Popconfirm> */}
            </div>
          )}
        >
          <Button
            type="primary"
            style={{
              background: "#4d8e03",
            }}
          >
            <Space>
              Action
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      width: "200px",
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "200px",
      editable: true,
      render: (status) =>
        status === 0 ? (
          <Tag color="blue">Active</Tag>
        ) : (
          <Tag color="blue">Inactive</Tag>
        ),
    },
    {
      title: "Date Created",
      dataIndex: "dateCreated",
      width: "200px",
      editable: true,
      defaultSortOrder: "descend",
      sorter: (a, b) => moment(a.dateCreated).diff(moment(b.dateCreated)),
      render: (dateCreated) => moment(dateCreated).format("LL"),
    },
    {
      title: "Date Updated",
      dataIndex: "dateUpdated",
      width: "200px",
      editable: true,
      render: (dateUpdated) => moment(dateUpdated).format("LL"),
    },
  ];

  useEffect(() => {
    const found = helpCenterTypes.find((obj) => obj.typeId === typeId);

    if (isEditing) {
      form.setFieldsValue({
        ...found,
        type: found?.name,
      });
    } else {
      form.resetFields();
    }
  }, [helpCenterTypes, typeId, isEditing]);

  useEffect(() => {
    dispatch(getHelpCenterTypes());
  }, []);

  const onFinish = (values) => {
    if (isEditing) {
      dispatch(
        updateHelpCenterType({ params: typeId, body: { name: values?.type } })
      );
    } else {
      dispatch(
        addHelpCenterType({
          name: values?.type,
        })
      );
    }

    closeHandler();
    form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {};

  const deleteHelpCenterType = (value) => {
    dispatch(removeHelpCenterType(value));
  };

  const addHandler = () => {
    setIsAdding(true);
  };

  const editHandler = (value) => {
    setIsEditing(true);
    setTypeId(value);
  };

  const closeHandler = () => {
    setIsEditing(false);
    setIsAdding(false);
  };

  return (
    <div>
      <LoadingTime loading={helpCenterTypesLoading} />
      <Card
        title={
          <CardTitle
            title={"Help Center Types"}
            add={"Add"}
            onAdd={addHandler}
          ></CardTitle>
        }
      >
        <Table
          loading={helpCenterTypesLoading}
          rowKey={"typeId"}
          columns={columns}
          dataSource={helpCenterTypes?.filter((obj) => obj.status === 0)}
          scroll={{
            y: 500,
            x: "100%",
          }}
        />
      </Card>
      <Modal
        title={
          <h1 className="text-[24px] md:text-[30px] font-[600] text-[#1b1b1b] mb-0 ">
            {isEditing ? "Edit help center type" : "Add help center type"}
          </h1>
        }
        onClose={closeHandler}
        centered
        open={isAdding || isEditing}
        onOk={closeHandler}
        onCancel={closeHandler}
        footer={false}
      >
        <Form
          layout="vertical"
          form={form}
          disabled={eventTypesLoading}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Divider />
          <Form.Item
            label={<h1 className="form-title">Help center type</h1>}
            name="type"
            rules={[
              {
                required: true,
                message: "Please input help center type",
              },
            ]}
          >
            <Input
              style={{
                padding: "10px",
                fontSize: "18px",
                borderRadius: "3px",
              }}
              placeholder="Enter help center type"
              size="large"
            />
          </Form.Item>
          <ModalButton text={isEditing ? "Update" : "Add"} />
        </Form>
      </Modal>
    </div>
  );
};
export default HelpCenterTypesTab;
