import React from "react";
import { Tabs } from "antd";
import ActiveChannel from "./ActiveChannel";
import InactiveChannel from "./InactiveChannel";

import "./paymentchannel.css";
import PendingChannel from "./PendingChannel";

const items = [
  // { label: "Pending Channel", key: "item-1", children: <PendingChannel /> }, // remember to pass the key prop
  { label: "Active Channel", key: "item-1", children: <ActiveChannel /> }, // remember to pass the key prop
  {
    label: "Inactive Channel",
    key: "item-3",
    children: <InactiveChannel />,
  },
];

const PaymentChannel = () => (
  <Tabs
    defaultActiveKey="item-1"
    type="card"
    className="tabs-help"
    animated={true}
    items={items}
  ></Tabs>
);
export default PaymentChannel;
