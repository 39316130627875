import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { RxDotFilled } from "react-icons/rx";
import { priceFeatures2, pricing } from "../../../constants/static";
// import { priceFeatures2, pricing } from "../../constants/static";
import PriceMobileTemplate from "./PriceMobileTemplate";

const PriceMobileLayout = ({ cardAnimate, cardTextAnimate }) => {
  const features = priceFeatures2.map((obj, index) => {
    return { ...obj, id: index };
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [filteredFeatures, setFilteredFeatures] = useState([]);

  useEffect(() => {
    let selectedFeatures = features.map((feature) => ({
      ...feature,
      pricing: feature.pricing[currentIndex],
      value: feature.value[currentIndex],
    }));

    const result = features.map((obj) => {
      let found = selectedFeatures.find(({ id }) => id === obj.id);
      if (Boolean(found)) {
        return found;
      } else {
        return { ...obj, value: "" };
      }
    });

    setFilteredFeatures(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);
  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? pricing.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === pricing.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  // console.log(filteredFeatures);
  const selectedPricing = pricing[currentIndex];
  return (
    <motion.div
      initial={"offscreen"}
      whileInView={"onscreen"}
      viewport={{ once: true, amount: 0.1 }}
      transition={{ delayChildren: 0.1, staggerChildren: 0.2 }}
      // className=" max-w-full"
    >
      {/* <motion.div
        className="max-w-[500px] max-h-[780px] w-full m-auto py-0 relative group bg-[#F5F5EE]"
        variants={cardAnimate}
      >
        <div className=" grid-cols-2">
          <PriceMobileTemplate
            selectedPricing={selectedPricing}
            prevSlide={prevSlide}
            nextSlide={nextSlide}
            cardAnimate={cardAnimate}
            cardTextAnimate={cardTextAnimate}
          />
        </div>
        <div className="flex top-4 justify-center py-2">
          {pricing.map((slide, slideIndex) => (
            <div
              key={slideIndex}
              onClick={() => goToSlide(slideIndex)}
              className="text-2xl cursor-pointer"
            >
              <RxDotFilled
                className={slideIndex === currentIndex ? "scale-150" : ""}
              />
            </div>
          ))}
        </div>
      </motion.div> */}
      <motion.div
        className=" flex justify-center sm:px-0 xs:px-5 px-0"
        variants={cardAnimate}
      >
        <div className=" w-full max-w-[700px] bg-[#F6F6F6] flex flex-col justify-center items-center p-3 rounded-xl">
          <div className=" h-[50px] w-full flex items-center bg-white rounded-lg">
            <h1 className=" text-[#139018] text-2xl pl-2 m-0">Features: </h1>
          </div>
          {filteredFeatures.map((obj, index) => {
            const bgColor = index % 2 === 0 ? "#F6F6F6" : "#FFFFFF";
            return (
              <motion.div
                key={index}
                className=" w-full h-[65px] p-3 rounded-lg"
                style={{
                  backgroundColor: `${bgColor}`,
                }}
                // initial={"offscreen"}
                // whileInView={"onscreen"}
                // viewport={{ once: true, amount: 0.5 }}
                // transition={{ delayChildren: 0.1, staggerChildren: 0.1 }}
                // variants={cardTextAnimate}
              >
                <div className="  flex flex-col justify-center">
                  <div className=" flex justify-start items-center">
                    <motion.h1
                      className=" text-[15px] font-bold"
                      // variants={cardTextAnimate}
                    >
                      {obj.label}
                    </motion.h1>
                  </div>
                  <div className=" flex justify-start items-center">
                    <motion.h2
                      className=" text-sm ml-3 font-semibold"
                      // variants={cardTextAnimate}
                    >
                      {obj.value}
                    </motion.h2>
                  </div>
                </div>
              </motion.div>
            );
          })}
        </div>
      </motion.div>
    </motion.div>
  );
};

export default PriceMobileLayout;
