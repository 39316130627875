import React from "react";
import { Button, Form } from "antd";

const ModalButton = ({ text }) => {
  return (
    <Form.Item
      style={{
        margin: "auto",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Button
        type="primary"
        htmlType="submit"
        style={{
          minWidth: "200px",
          height: "62px",
          fontSize: "19px",
          fontWeight: "400",
          borderRadius: "5px",
          background: "#4d8e03",
        }}
      >
        {text}
      </Button>
    </Form.Item>
  );
};

export default ModalButton;
