// import {
// 	check,
// 	feature1,
// 	feature10,
// 	feature2,
// 	feature3,
// 	feature4,
// 	feature5,
// 	feature6,
// 	feature7,
// 	feature8,
// 	feature9,
// } from "../assets/icons";
import {
  check,
  feature1,
  feature10,
  feature2,
  feature3,
  feature4,
  feature5,
  feature6,
  feature7,
  feature8,
  feature9,
} from "../assets/landing/icons";
// import { buildingslogo, crown, grammerly, people } from "../assets/icons/iconsax";
// import { slide1, slide2, slide3 } from "../assets/images";
import {
  buildingslogo,
  crown,
  grammerly,
  people,
} from "../assets/landing/icons/iconsax";
import { slide1, slide2, slide3 } from "../assets/landing/images";

export const card = [
  {
    imgSrc: `${buildingslogo}`,
    midText: "2014",
    botText: "ESTABLISHED",
  },
  {
    imgSrc: `${people}`,
    midText: "50+",
    botText: "TEAM SIZE",
  },
  {
    imgSrc: `${crown}`,
    midText: "20+ Years",
    botText: "TEAM EXPERIENCE",
  },
  {
    imgSrc: `${grammerly}`,
    midText: "100+",
    botText: "CLIENT SERVED",
  },
];

export const feature = [
  {
    imgSrc: `${feature1}`,
    title: "Multi-property management",
    details: "Manage all your properties centrally with a single sign-on.",
  },
  {
    imgSrc: `${feature2}`,
    title: "Visitor Management",
    details:
      "Track and manage visitor movement digitally. No more manual logs.",
  },
  {
    imgSrc: `${feature3}`,
    title: "Tenant and homeowner management",
    details:
      "Obtain visibility on tenants and homeowners’ accounts, payments, and concerns.",
  },
  {
    imgSrc: `${feature4}`,
    title: "Billing statements",
    details:
      "Help tenants and homeowners stay on top of their payables through digital billing statements sent via e-mail and available through the Properity mobile application.",
  },
  {
    imgSrc: `${feature5}`,
    title: "Online payments",
    details: "Provide convenience for payments and track them accordingly.",
  },
  {
    imgSrc: `${feature6}`,
    title: "News & announcements",
    details:
      "Organize news, announcements, and notices all from the admin portal.",
  },
  {
    imgSrc: `${feature7}`,
    title: "Transaction history",
    details:
      "Keep track of payments and other transactions from a central dashboard",
  },
  {
    imgSrc: `${feature8}`,
    title: "Calendar",
    details:
      "Pencil dates in for events, bookings, maintenance schedules, and notices of repair",
  },
  {
    imgSrc: `${feature9}`,
    title: "Notifications",
    details:
      "Reach your audience efficiently through push notifications in-app and via e-mail.",
  },
  {
    imgSrc: `${feature10}`,
    title: "Hotline",
    details: "Manage important numbers for service requests and emergencies.",
  },
];
export const slides = [
  {
    imgSrc: `${slide1}`,
    details: {
      title: "Residential",
      content: "Villages / subdivisions, condominiums, townhouses, apartments",
    },
  },
  {
    imgSrc: `${slide2}`,
    details: {
      title: "Commercial",
      content:
        "Office buildings, retail stores, leisure and health care properties",
    },
  },
  {
    imgSrc: `${slide3}`,
    details: {
      title: "Industrial",
      content: "Warehouse & manufacturing plants, office buildings ",
    },
  },
];
export const slideDetails = [
  {
    title: "Residential",
    content: "Villages / subdivisions, condominiums, townhouses, apartments",
  },
  {
    title: "Commercial",
    content:
      "Office buildings, retail stores, leisure and health care properties",
  },
  {
    title: "Industrial",
    content: "Warehouse & manufacturing plants, office buildings ",
  },
];

export const residential = [
  {
    listName: "Multi-property management",
  },
  {
    listName: "Billing Statements",
  },
  {
    listName: "Transaction History",
  },
  {
    listName: "Notifications",
  },
  {
    listName: "Payments",
  },
  {
    listName: "Hotline",
  },
  {
    listName: "Announcements",
  },
  {
    listName: "Calendar",
  },
  {
    listName: "Forms Download",
  },
  {
    listName: "Visitor Management",
  },
  {
    listName: "Marketplace",
  },
  {
    listName: "Listing - Lease/Sale",
  },
  {
    listName: "Voting / Polls / Surveys",
  },
  {
    listName: "Messaging",
  },
];

export const comIndustrial = [
  {
    listName: "Multi-property management",
  },
  {
    listName: "Visitor management",
  },
  {
    listName: "Tenant management",
  },
  {
    listName: "Parking management",
  },
  {
    listName: "Listing - Lease / Sale",
  },
  {
    listName: "Jobs market",
  },
  {
    listName: "Access Pass",
  },
];

// const arr = [
// 	{
// 		title: "Starter",
// 		bgColor: "red",
// 		textColor: "blue",
// 		starts: {
// 			title: "Price starts at",
// 			values: [
// 				{
// 					value: "P5,000/mo",
// 					sub: "up to 50 accounts",
// 				},
// 				{
// 					value: "PROMO P3,000/mo",
// 					sub: "up to 100 accounts when you subscribe today",
// 				},
// 			],
// 		},
// 		addOn: {
// 			title: "Add on Price",
// 			values: {
// 				value: "P1,000",
// 				sub: "for every additional 25",
// 			},
// 		},
// 	},
// ];

export const pricing = [
  {
    planName: "Starter",
    titleTextColor: "#0B9301",
    bodyTextColor: "#0B9301",
    bgColor: "#FFFFFF",
    monthlyPrice: "5000",
    upTo: "50",
    everyAdd: "25",
    addPrice: "1000",
    pricePax: "40",
    hasRibbon: false,
  },
  {
    planName: "Plus",
    titleTextColor: "#0B9301",
    bodyTextColor: "#0B9301",
    bgColor: "#FFFFFF",
    monthlyPrice: "10000",
    upTo: "200",
    everyAdd: "50",
    addPrice: "3000",
    pricePax: "50",
    hasRibbon: false,
  },
  {
    planName: "Premium",
    titleTextColor: "#0B9301",
    bodyTextColor: "#0B9301",
    bgColor: "#FFFFFF",
    monthlyPrice: "25000",
    upTo: "500",
    everyAdd: "100",
    addPrice: "7500",
    pricePax: "50",
    hasRibbon: false,
  },
];

export const titles = [
  { id: 1, title: "1 resident / property" },
  { id: 2, title: "Multiple residents / property" },
  { id: 3, title: "Multiple residents / property" },
];

const checkImage = <img alt="" src={check} className=" w-[23px]" />;
export const priceFeatures2 = [
  {
    label: "Users (unique account)",
    pricing: ["starter", "plus", "premiumPlus"],
    value: [
      "1 resident / property",
      "Multiple residents / property",
      "Multiple residents / property",
    ],
  },
  {
    label: "Association",
    pricing: ["starter", "plus", "premiumPlus"],
    value: [checkImage, checkImage, checkImage],
  },
  {
    label: "Generate Billing",
    pricing: ["starter", "plus", "premiumPlus"],
    value: [checkImage, checkImage, checkImage],
  },
  {
    label: "Online Payment",
    pricing: ["starter", "plus", "premiumPlus"],
    value: [checkImage, checkImage, checkImage],
  },
  {
    label: "Transaction History",
    pricing: ["starter", "plus", "premiumPlus"],
    value: [checkImage, checkImage, checkImage],
  },
  {
    label: "Announcements",
    pricing: ["starter", "plus", "premiumPlus"],
    value: [checkImage, checkImage, checkImage],
  },
  {
    label: "Event Calendar",
    pricing: ["starter", "plus", "premiumPlus"],
    value: [checkImage, checkImage, checkImage],
  },
  {
    label: "Inquiries and Utility Reporting",
    pricing: ["starter", "plus", "premiumPlus"],
    value: [checkImage, checkImage, checkImage],
  },
  {
    label: "Notification",
    pricing: ["starter", "plus", "premiumPlus"],
    value: [checkImage, checkImage, checkImage],
  },
  {
    label: "Setup and support",
    pricing: ["starter", "plus", "premiumPlus"],
    value: [checkImage, checkImage, checkImage],
  },
  {
    label: "Analytics and Reporting",
    pricing: ["starter", "plus", "premiumPlus"],
    value: [checkImage, checkImage, checkImage],
  },
  {
    label: "Multi-property Administration",
    pricing: ["starter", "plus", "premiumPlus"],
    value: [
      "Less than 200 properties",
      "Greater than 200 properties",
      "Greater than 200 properties",
    ],
  },
  {
    label: "Customization / White labeling",
    pricing: ["starter", "plus", "premiumPlus"],
    value: ["", checkImage, checkImage],
  },
  {
    label: "Email/SMS Notification",
    pricing: ["starter", "plus", "premiumPlus"],
    value: ["", checkImage, checkImage],
  },
  {
    label: "Api Access",
    pricing: ["starter", "plus", "premiumPlus"],
    value: ["", checkImage, checkImage],
  },
  {
    label: "Data Migration",
    pricing: ["starter", "plus", "premiumPlus"],
    value: ["", "", checkImage],
  },
  {
    label: "Advanced Revenue Management",
    pricing: ["starter", "plus", "premiumPlus"],
    value: ["", "", checkImage],
  },
  {
    label: "E-Voting",
    pricing: ["starter", "plus", "premiumPlus"],
    value: ["", "", checkImage],
  },
  {
    label: "Visitors Management",
    pricing: ["starter", "plus", "premiumPlus"],
    value: ["", "", checkImage],
  },
  {
    label: "Marketplace",
    pricing: ["starter", "plus", "premiumPlus"],
    value: ["", "", checkImage],
  },
];

export const priceFeatures = [
  {
    featureName: "Users (unique account)",
    starter: "1 resident / property",
    starterCheck: "",
    plus: "Multiple residents / property",
    plusCheck: `${check}`,
    premiumPlus: "Multiple residents / property",
    premiumPlusCheck: "",
  },
  {
    featureName: "Association",
    starter: "",
    starterCheck: `${check}`,
    plus: "",
    plusCheck: `${check}`,
    premiumPlus: "",
    premiumPlusCheck: `${check}`,
  },
  {
    featureName: "Generate Billing",
    starter: "",
    starterCheck: `${check}`,
    plus: "",
    plusCheck: `${check}`,
    premiumPlus: "",
    premiumPlusCheck: `${check}`,
  },
  {
    featureName: "Online Payment",
    starter: "",
    starterCheck: `${check}`,
    plus: "",
    plusCheck: `${check}`,
    premiumPlus: "",
    premiumPlusCheck: `${check}`,
  },
  {
    featureName: "Transaction History",
    starter: "",
    starterCheck: `${check}`,
    plus: "",
    plusCheck: `${check}`,
    premiumPlus: "",
    premiumPlusCheck: `${check}`,
  },
  {
    featureName: "Announcements",
    starter: "",
    starterCheck: `${check}`,
    plus: "",
    plusCheck: `${check}`,
    premiumPlus: "",
    premiumPlusCheck: `${check}`,
  },
  {
    featureName: "Event Calendar",
    starter: "",
    starterCheck: `${check}`,
    plus: "",
    plusCheck: `${check}`,
    premiumPlus: "",
    premiumPlusCheck: `${check}`,
  },
  {
    featureName: "Inquiries and Utility Reporting",
    starter: "",
    starterCheck: `${check}`,
    plus: "",
    plusCheck: `${check}`,
    premiumPlus: "",
    premiumPlusCheck: `${check}`,
  },
  {
    featureName: "Notification",
    starter: "",
    starterCheck: `${check}`,
    plus: "",
    plusCheck: `${check}`,
    premiumPlus: "",
    premiumPlusCheck: `${check}`,
  },
  {
    featureName: "Setup and support",
    starter: "",
    starterCheck: `${check}`,
    plus: "",
    plusCheck: `${check}`,
    premiumPlus: "",
    premiumPlusCheck: `${check}`,
  },
  {
    featureName: "Analytics and Reporting",
    starter: "",
    starterCheck: `${check}`,
    plus: "",
    plusCheck: `${check}`,
    premiumPlus: "",
    premiumPlusCheck: `${check}`,
  },
  {
    featureName: "Multi-property Administration",
    starter: "Less than 200 properties",
    starterCheck: "",
    plus: "Greater than 200 properties",
    plusCheck: "",
    premiumPlus: "Greater than 200 properties",
    premiumPlusCheck: "",
  },
  {
    featureName: "Customization / White labeling",
    starter: "",
    starterCheck: "",
    plus: "",
    plusCheck: `${check}`,
    premiumPlus: "",
    premiumPlusCheck: `${check}`,
  },
  {
    featureName: "Email/SMS Notification",
    starter: "",
    starterCheck: "",
    plus: "",
    plusCheck: `${check}`,
    premiumPlus: "",
    premiumPlusCheck: `${check}`,
  },
  {
    featureName: "Api Access",
    starter: "",
    starterCheck: "",
    plus: "",
    plusCheck: `${check}`,
    premiumPlus: "",
    premiumPlusCheck: `${check}`,
  },
  {
    featureName: "Data Migration",
    starter: "",
    starterCheck: "",
    plus: "",
    plusCheck: "",
    premiumPlus: "",
    premiumPlusCheck: `${check}`,
  },
  {
    featureName: "Advanced Revenue Management",
    starter: "",
    starterCheck: "",
    plus: "",
    plusCheck: "",
    premiumPlus: "",
    premiumPlusCheck: `${check}`,
  },
  {
    featureName: "E-Voting",
    starter: "",
    starterCheck: "",
    plus: "",
    plusCheck: "",
    premiumPlus: "",
    premiumPlusCheck: `${check}`,
  },
  {
    featureName: "Visitors Management",
    starter: "",
    starterCheck: "",
    plus: "",
    plusCheck: "",
    premiumPlus: "",
    premiumPlusCheck: `${check}`,
  },
  {
    featureName: "Marketplace",
    starter: "",
    starterCheck: "",
    plus: "",
    plusCheck: "",
    premiumPlus: "",
    premiumPlusCheck: `${check}`,
  },
];

export const objBenefits = [
  {
    title: "Streamlined Processes",
    content:
      "By automating tedious tasks such as dues or rent collection and maintenance requests, property managers can save a lot of time and effort.",
  },
  {
    title: "Increased Efficiency",
    content:
      "Streamlining processes increases the efficiency of property management. This can help to reduce costs and improve customer satisfaction.",
  },
  {
    title: "Easy Accessibility",
    content:
      "Provide easy access to important information such as tenant records, maintenance logs, and financial documents. This makes it easy for property managers to keep track of all the important details.",
  },
  {
    title: "Enhanced Customer Service",
    content:
      "Properties are ultimately built and developed to serve its occupants. Keep service top of mind by providing systems such as payment platforms, visitor management, and service request portals to allow for a better experience. Our occupants are our customers after all.",
  },
];
