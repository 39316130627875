import React from "react";
import OurStory from "./About/OurStory";

const About = () => {
	return (
		<>
			<div id="about">
				{/* <AboutCardLayout /> */}
				<OurStory />
			</div>
		</>
	);
};

export default About;
