import { defaultAxios, axiosMultipart, token } from "../axios";

const baseApi = "/api/associations/profile/";

defaultAxios.interceptors.request.use(async function (config) {
  config.headers["Authorization"] = `Bearer ${
    JSON.parse(await localStorage.getItem("persist:auth")).token.split(`"`)[1]
  }`;
  return config;
});

axiosMultipart.interceptors.request.use(async function (config) {
  config.headers["Authorization"] = `Bearer ${
    JSON.parse(await localStorage.getItem("persist:auth")).token.split(`"`)[1]
  }`;
  return config;
});

export const getProfile = async (body) => {
  try {
    const result = await token.get(baseApi + "getProfile", {
      headers: {
        Authorization: "Bearer " + body,
      },
    });
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const updateProfile = async (body) => {
  try {
    const result = await defaultAxios.post(baseApi + "updateProfile", body);
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const updatePassword = async (body) => {
  try {
    const result = await defaultAxios.post(baseApi + "updatePassword", body);
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};
