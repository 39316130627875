import { createSlice } from "@reduxjs/toolkit";
import { message, Button } from "antd";

const initialState = {
  isLoading: false,
  assocChargesLoading: false,
  billingStatementLoading: false,
  previousBillingStatementsLoading: false,
  billingStatePageLoading: false,
  createBillLoading: false,

  billings: [],
  assocCharges: [],
  billingStatement: [],
  previousBillingStatements: [],

  billingCharges: [],
  billingChargesAttachments: [],
  descriptions: [],
  billingChargesAttachmentsTable: [],
  success: false,

  billingStatePage: [],
  billingDrawer: false,
};

const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    clearCharges(state) {
      state.billingCharges = [];
      state.billingChargesAttachmentsTable = [];
      state.descriptions = [];
      state.billingChargesAttachments = [];
    },
    setAddingState(state, action) {
      state.success = action.payload;
    },
    setBillingCharges(state, action) {
      const charge = action.payload;
      state.billingCharges = [...state.billingCharges, charge];
    },
    updateBillingCharges(state, action) {
      let charge = [...state.billingCharges];
      charge[action.payload.index] = {
        ...charge[action.payload.index],
        name: action.payload.name,
        chargeId: action.payload.chargeId,
        amount: action.payload.amount,
        additionalAmount: action.payload.additionalAmount || "n/a",
        remarks: action.payload.remarks || "n/a",
      };
      state.billingCharges = charge;
      console.log(charge, "CHARGE");
      console.log(action.payload, "ACTION");
    },
    deleteBillingCharges(state, action) {
      let charge = [...state.billingCharges];
      charge.splice(action.payload, 1);
      state.billingCharges = charge;
    },
    setbillingChargesAttachmentsTable(state, action) {
      const charge = action.payload;
      state.billingChargesAttachmentsTable = [
        ...state.billingChargesAttachmentsTable,
        charge,
      ];
    },
    getbillingChargesAttachmentsTable(state, action) {
      const chargeId = action.payload;
      state.billingChargesAttachmentsTable =
        state.billingChargesAttachmentsTable.filter(
          (obj) => obj.chargeId === chargeId
        );
    },
    setBillingDescriptions(state, action) {
      const desc = action.payload;
      state.descriptions = [...state.descriptions, desc];
    },
    setBillingChargesAttachements(state, action) {
      const chargeAttachment = action.payload;

      state.billingChargesAttachments = [
        ...state.billingChargesAttachments,
        chargeAttachment,
      ];
    },
    getBillings(state) {
      state.billings = [];
      state.isLoading = true;
    },
    getBillingsSuccess(state, action) {
      state.billings = action.payload;
      state.isLoading = false;
    },
    getBillingStatementsPage(state) {
      state.billingStatePage = [];
      state.billingStatePageLoading = true;
    },
    getBillingStatementsPageSuccess(state, action) {
      state.billingStatePage = action.payload;
      state.billingStatePageLoading = false;
    },
    getBillingStatement(state) {
      state.billingStatement = [];
      state.billingStatementLoading = true;
    },
    getBillingStatementSuccess(state, action) {
      state.billingStatement = action.payload;
      state.billingStatementLoading = false;
    },
    getPreviousBillingStatement(state) {
      state.previousBillingStatements = [];
      state.previousBillingStatementsLoading = true;
    },
    getPreviousBillingStatementSuccess(state, action) {
      state.previousBillingStatements = action.payload;
      state.previousBillingStatementsLoading = false;
    },
    billingDrawerHandler(state, action) {
      state.billingDrawer = action.payload;
    },
    createBillingStatement(state) {
      state.billingStatementLoading = true;
    },
    createBillingStatementSuccess(state, action) {
      message.success(
        <span>
          {action.payload.message}
          <a href="/Billing">&nbsp;View bills</a>
        </span>
      );
      state.billingStatementLoading = false;
      state.billingCharges = [];
      state.billingChargesAttachments = [];
    },
    removeBillingStatement(state) {
      state.billingStatementLoading = true;
    },
    removeBillingStatementSuccess(state, action) {
      state.billingStatementLoading = false;
      message.success(action.payload.message);
    },
    removeAssociationCharge(state) {
      state.assocChargesLoading = true;
    },
    removeAssociationChargeSuccess(state, action) {
      state.assocChargesLoading = false;
      message.success(action.payload.message);
    },
    removeBillingStatementCharge(state) {
      state.billingStatementLoading = true;
    },
    removeBillingStatementChargeSuccess(state, action) {
      state.billingStatementLoading = false;
      message.success(action.payload.message);
    },
    removeBillingStatementAttachment(state) {
      state.billingStatementLoading = true;
    },
    removeBillingStatementAttachmentSuccess(state, action) {
      state.billingStatementLoading = false;
      message.success(action.payload.message);
    },
    getAssociationCharges(state) {
      state.assocCharges = [];
      state.assocChargesLoading = true;
    },
    getAssociationChargesSuccess(state, action) {
      state.assocCharges = action.payload;
      state.assocChargesLoading = false;
    },
    addAssociationCharge(state) {
      state.assocChargesLoading = true;
    },
    addAssociationChargeSuccess(state, action) {
      message.success(action.payload.message);
      state.assocChargesLoading = false;
    },
    addBillingStatementCharges(state) {
      state.billingStatementLoading = true;
    },
    addBillingStatementChargesSuccess(state, action) {
      console.log(action.payload);
      state.amount = action.payload;
      message.success(action.payload.message);
      state.billingStatementLoading = false;
    },
    addBillingStatementAttachments(state) {
      state.billingStatementLoading = true;
    },
    addBillingStatementAttachmentsSuccess(state, action) {
      state.billingStatementLoading = false;
      state.billingStatement = action.payload;
      message.success(action.payload.message);
    },
    createBillingStatementWithCharges(state) {
      state.createBillLoading = true;
    },
    createBillingStatementWithChargesSuccess(state, action) {
      message.success(
        <span>
          {action.payload.message}
          <a href="/Billing">&nbsp;View bills</a>
        </span>
      );
      state.createBillLoading = false;
      state.billingCharges = [];
      state.billingChargesAttachments = [];
      state.billingChargesAttachmentsTable = [];
      state.descriptions = [];
      state.success = true;
    },
    updateAssociationCharge(state) {
      state.assocChargesLoading = true;
    },
    updateAssociationChargeSuccess(state, action) {
      message.success(action.payload.message);
      state.assocChargesLoading = false;
    },
    updateBillingStatementAttachment(state) {
      state.billingStatementLoading = true;
    },
    updateBillingStatementAttachmentSuccess(state, action) {
      message.success(action.payload.message);
      state.billingStatementLoading = false;
    },
    updateBillingStatementCharge(state) {
      state.billingStatementLoading = true;
    },
    updateBillingStatementChargeSuccess(state, action) {
      message.success(action.payload.message);
      state.billingStatementLoading = false;
    },
    updateBillingStatement(state) {
      state.billingStatementLoading = true;
    },
    updateBillingStatementSuccess(state, action) {
      message.success(action.payload.message);
      state.billingStatementLoading = false;
    },
    requestError(state, action) {
      state.isLoading = false;
      state.assocChargesLoading = false;
      state.billingStatementLoading = false;
      message.error(action.payload);

      state.previousBillingStatementsLoading = false;
      state.billingStatePageLoading = false;
      state.createBillLoading = false;
    },
  },
});

export const {
  requestError,
  setBillingCharges,
  setBillingChargesAttachements,
  setBillingDescriptions,
  setbillingChargesAttachmentsTable,
  getbillingChargesAttachmentsTable,
  clearCharges,
  setAddingState,

  createBillingStatementWithCharges,
  createBillingStatementWithChargesSuccess,

  getBillings,
  getBillingsSuccess,
  getBillingStatement,
  getBillingStatementSuccess,
  getPreviousBillingStatement,
  getPreviousBillingStatementSuccess,
  getAssociationCharges,
  getAssociationChargesSuccess,
  getBillingStatementsPage,
  getBillingStatementsPageSuccess,

  updateBillingStatementCharge,
  updateBillingStatementChargeSuccess,
  updateAssociationCharge,
  updateAssociationChargeSuccess,
  updateBillingStatementAttachment,
  updateBillingStatementAttachmentSuccess,
  updateBillingStatement,
  updateBillingStatementSuccess,

  removeAssociationCharge,
  removeAssociationChargeSuccess,
  removeBillingStatementCharge,
  removeBillingStatementChargeSuccess,
  removeBillingStatement,
  removeBillingStatementSuccess,
  removeBillingStatementAttachment,
  removeBillingStatementAttachmentSuccess,

  billingDrawerHandler,
  addAssociationCharge,
  addAssociationChargeSuccess,
  addBillingStatementCharges,
  addBillingStatementChargesSuccess,
  addBillingStatementAttachments,
  addBillingStatementAttachmentsSuccess,
  createBillingStatement,
  createBillingStatementSuccess,
  updateBillingCharges,
  deleteBillingCharges,
} = billingSlice.actions;
export default billingSlice;
