import defaultAxios from "axios";

const axios = defaultAxios.create({
  baseURL: process.env.REACT_APP_BASE_URL + `/api/associations/auth/`,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

export const login = async (body) => {
  try {
    const result = await axios.post("login", body);
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};
