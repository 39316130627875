import { put, call, takeLatest } from "redux-saga/effects";
import {
  pushNotification,
  pushAllNotification,
} from "../../api/notifications/notification-api";
import { notificationActions } from "../../store";
///
///

function* pushNotificationRequest({ payload }) {
  const { requestError, pushNotificationSuccess } = notificationActions;
  const result = yield call(pushNotification, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(pushNotificationSuccess(result.data));
  }
}

function* pushAllNotificationRequest({ payload }) {
  const { requestError, pushAllNotificationSuccess } = notificationActions;
  const result = yield call(pushAllNotification, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(pushAllNotificationSuccess(result.data));
  }
}

// Export the saga (todo-saga)
export default function* notificationSaga() {
  yield takeLatest(`notification/pushNotification`, pushNotificationRequest);
  yield takeLatest(
    `notification/pushAllNotification`,
    pushAllNotificationRequest
  );
}
