import { motion } from "framer-motion";
import React from "react";
import CarouselSwiper from "./Features/CarouselSwiper";
import FeatureCardLayout from "./Features/FeatureCardLayout";
import Objectives from "./Features/Objectives";

const Features = () => {
  const textAnimate = {
    offscreen: { y: 0, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.2, duration: 1 },
    },
  };
  const imageAnimate = {
    offscreen: { y: 100, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.2, duration: 1 },
    },
  };
  const cardImageAnimate = {
    offscreen: { y: 100, x: 0, opacity: 0 },
    onscreen: {
      y: 0,
      x: 0,
      rotate: [0, 360],
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.3,
        duration: 1,
      },
    },
  };
  return (
    <div id="features" className=" bg-[#F5F5EE]">
      <FeatureCardLayout
        textAnimate={textAnimate}
        imageAnimate={imageAnimate}
        cardImageAnimate={cardImageAnimate}
      />
      <motion.div
        className=" flex flex-col items-center py-10"
        initial={"offscreen"}
        whileInView={"onscreen"}
        viewport={{ once: true, amount: 0.5 }}
        transition={{ staggerChildren: 0.1 }}
      >
        <motion.hr
          className=" w-[32px] border-[#4D8E03] mb-1"
          variants={textAnimate}
        ></motion.hr>
        <motion.h1
          className=" text-sm text-[#4D8E03] border-t-2 border-[#4D8E03]"
          variants={textAnimate}
        ></motion.h1>
        <motion.h1
          className=" text-[#20683B] sm:text-3xl text-2xl font-bold"
          variants={textAnimate}
        >
          Property Types
        </motion.h1>
      </motion.div>
      {/* <FeaturesCarousel /> */}
      <CarouselSwiper />
      {/* <Carousel /> */}
      <Objectives textAnimate={textAnimate} imageAnimate={imageAnimate} />
    </div>
  );
};

export default Features;
