import { Image, Modal, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingTime } from "../../../UI/LoadingScreen";
import upgrade from "../../../assets/resources/images/upgrade.png";
import {
  getPlans,
  getSubscription,
  upgradeSubscription,
} from "../../../store/slices/subscription/subscription-slice";
import { packages } from "./SubscriptionPackages";

const UpgradeSubscription = () => {
  const [modal2Open, setModal2Open] = useState(false);
  const dispatch = useDispatch();
  const { subscription, plans, upgradeLoading, plansLoading } = useSelector(
    (state) => state.subscription
  );
  const { token } = useSelector((state) => state.auth);
  const [selected, setSelected] = useState(-1);

  useEffect(() => {
    dispatch(getSubscription(token));
    dispatch(getPlans());
  }, []);

  const chooseSub = (e) => {
    setSelected(e);
  };

  const upgradeSubscriptions = () => {
    dispatch(
      upgradeSubscription({
        subscriptionId: subscription.subscriptionId,
        validUntil: moment(subscription.validUntil).format("YYYY-MM-DD"),
        planId: plans[selected]?.planId,
        cb: () => {
          setModal2Open(false);
        },
      })
    );
  };

  return (
    <div className="flex flex-col justify-center h-full w-full items-center">
      <LoadingTime loading={upgradeLoading || plansLoading} />
      <div className="flex flex-col gap-5">
        <div className="flex flex-col items-center">
          <span className="text-[14px] font-regular uppercase text-gray-500">
            Current Plan
          </span>
          <h1 className="text-[32px] text-properity">
            {subscription?.name} Plan
          </h1>
        </div>
        <div className="grid grid-cols-2 gap-5 items-center">
          {plans.map((obj, key) => (
            <div
              onClick={() => chooseSub(key)}
              key={key}
              className="overflow-hidden relative flex flex-row w-[601px] h-full rounded-[5px] p-10 justify-between items-center cursor-pointer hover:scale-[1.03] transition-all ease-in duration-150"
              style={{
                border:
                  subscription?.planId === plans[key]?.planId
                    ? "2px solid #4D8E03"
                    : selected === key
                    ? "2px solid #4D8E03"
                    : "2px solid #DCDCDC",
              }}
            >
              <div className="flex items-center gap-5">
                <div className="flex flex-col">
                  <span className="text-[20px] font-semibold text-properity">
                    {obj?.name} Plan
                  </span>
                  <div className="flex flex-col">
                    <span className="text-[20px] font-semibold">
                      {obj?.name !== "Free" ? "Add on price" : ""}
                    </span>
                    {packages[key].additional}
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center">
                <span className="text-[20px] font-semibold">{obj?.terms}</span>
                {obj?.name !== "Free" ? `up to ${obj?.size} accounts` : ""}
              </div>

              <div
                className={
                  subscription?.planId === plans[key]?.planId
                    ? "absolute right-[-50px] top-[20px] h-16 w-40"
                    : "hidden"
                }
              >
                <div className="absolute bg-properity w-full font-medium text-white rotate-[45deg] text-center flex items-center justify-center">
                  Active
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex gap-4 items-center justify-center text-center text-white text-[16px]">
          <div
            onClick={() => {
              selected !== -1
                ? setModal2Open(true)
                : message.error("Please select a subscription first.");
            }}
            className="items-center grid col-span-4 w-full h-[66px] bg-properity hover:bg-green-600 rounded-[5px] duration-150 cursor-pointer max-w-[601px]"
          >
            Upgrade Subscription
          </div>
        </div>
        <Modal
          footer={false}
          centered
          closable={false}
          open={modal2Open}
          onOk={() => setModal2Open(false)}
          onCancel={() => setModal2Open(false)}
          width={"750px"}
        >
          <div className="flex flex-col gap-7 items-center text-center p-5">
            <Image preview={false} width={"107px"} src={upgrade} />
            <span className="font-semibold text-[20px]">
              Are you sure you want to Upgarde your subscription?
            </span>
            <p>
              Upgrade now and experience the best our service has to offer.
              Click the 'Upgrade Now' button below to get started!"
            </p>
            <div className="flex gap-4 w-full ">
              <div
                onClick={() => setModal2Open(false)}
                className="text-gray-700 items-center grid col-span-2 w-full h-[66px] bg-[#EBEBEB] hover:bg-gray-200 rounded-[5px] duration-150 cursor-pointer"
              >
                Keep Plan
              </div>
              <div
                onClick={() => upgradeSubscriptions()}
                className="text-white items-center grid col-span-2 w-full h-[66px] bg-properity hover:bg-red-700 rounded-[5px] duration-150 cursor-pointer"
              >
                Upgrade Now
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default UpgradeSubscription;
