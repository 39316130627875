import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

const initialState = {
  isLoading: false,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    pushNotification(state) {
      state.isLoading = true;
    },
    pushNotificationSuccess(state, action) {
      state.isLoading = false;
      message.success("Notification sent successfully!");
    },
    pushAllNotification(state) {
      state.isLoading = true;
    },
    pushAllNotificationSuccess(state, action) {
      state.isLoading = false;
      message.success("Notification sent successfully!");
    },
    requestError(state, action) {
      state.isLoading = false;
      message.error(action.payload.data.message);
    },
  },
});

export const {
  requestError,
  pushNotification,
  pushNotificationSuccess,
  pushAllNotification,
  pushAllNotificationSuccess,
} = notificationSlice.actions;
export default notificationSlice;
