import { DownOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Dropdown,
  Empty,
  Form,
  Image,
  Popconfirm,
  Space,
  Table,
  Tag,
  Typography,
  theme,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getInquiries,
  processInquiry,
  removeInquiry,
} from "../../../../store/slices/inquiries/inquiries-slice";
import { LoadingTime } from "../../../../UI/LoadingScreen";
import "../../properties/property.css";
import { pendingActions } from "./InquiriesActionData";
import InquiriesModal from "./InquiriesModal";

const { useToken } = theme;
const { Paragraph, Text } = Typography;

const PendingInquiry = () => {
  const { token } = useToken();
  const [ellipsis, setEllipsis] = useState(true);
  const { inquiries, inquiriesLoading } = useSelector((state) => state.inquiry);
  const { token: userToken } = useSelector((state) => state.auth);

  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "150px",
      render: (record) => (
        <Dropdown
          trigger={["click"]}
          dropdownRender={() => (
            <div className="flex flex-col items-start" style={contentStyle}>
              {pendingActions?.map((obj, i) => {
                return (
                  <Popconfirm
                    key={i}
                    placement="right"
                    title={obj.title}
                    description={obj.description}
                    onConfirm={
                      obj.text === "Process"
                        ? () => processInquiries(record.inquiryId)
                        : () => deleteInquiry(record.inquiryId)
                    }
                    okText="Yes"
                    cancelText="Cancel"
                  >
                    <Button
                      style={{ color: "black" }}
                      type="link"
                      icon={obj.icon}
                    >
                      {obj.text}
                    </Button>
                  </Popconfirm>
                );
              })}
            </div>
          )}
        >
          <Button
            type="primary"
            style={{
              background: "#4d8e03",
            }}
          >
            <Space>
              Action
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
    {
      title: "Subject",
      dataIndex: "subject",
      width: "200px",
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    {
      title: "Message",
      dataIndex: "message",
      width: "200px",
      render: (record) => (
        <Paragraph
          ellipsis={
            ellipsis
              ? {
                  rows: 3,
                  expandable: true,
                  symbol: "more",
                }
              : false
          }
        >
          {record}
        </Paragraph>
      ),
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      render: (md) => (md ? md : "-"),
      width: "200px",
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    {
      title: "Middle Name",
      dataIndex: "middlename",
      width: "200px",
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
      render: (md) => (md ? md : "-"),
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      render: (md) => (md ? md : "-"),
      width: "200px",
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    {
      title: "Contact No.",
      dataIndex: "contactNumber",
      width: "200px",
    },
    {
      title: "Address",
      dataIndex: "address",
      width: "200px",
    },
    {
      title: "Image",
      dataIndex: "imageUrl",
      align: "center",
      width: "200px",
      render: (record) => {
        return record ? (
          <Image
            width={100}
            src={process.env.REACT_APP_BASE_URL + "/" + record}
          />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "200px",
      editable: true,
      render: (status) => status === 0 && <Tag color="volcano">Pending</Tag>,
    },
  ];

  useEffect(() => {
    dispatch(getInquiries(userToken));
  }, []);

  const onClose = () => {
    setVisible(false);
  };

  const deleteInquiry = (value) => {
    dispatch(removeInquiry(value));
  };

  const processInquiries = (value) => {
    dispatch(processInquiry({ params: value, body: { remarks: "" } }));
  };

  return (
    <div>
      <LoadingTime loading={inquiriesLoading} />
      <Card>
        <Table
          loading={inquiriesLoading}
          rowKey={"inquiryId"}
          columns={columns}
          dataSource={inquiries?.filter((obj) => obj.status === 0)}
          scroll={{
            y: 500,
            x: "100%",
          }}
        />
      </Card>
      <InquiriesModal viewBilling={visible} closeBilling={onClose} />
    </div>
  );
};
export default PendingInquiry;
