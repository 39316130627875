import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Popconfirm, Button } from "antd";

import React from "react";

const PopconfirmRemove = ({ text, desc, onConfirm }) => {
  return (
    <Popconfirm
      icon={
        <QuestionCircleOutlined
          style={{
            color: "red",
          }}
        />
      }
      placement="right"
      title={text}
      description={desc}
      onConfirm={onConfirm}
      okText="Yes"
      cancelText="Cancel"
    >
      <Button style={{ color: "black" }} type="link" icon={<DeleteOutlined />}>
        Remove
      </Button>
    </Popconfirm>
  );
};

export default PopconfirmRemove;
