import { MenuOutlined } from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  Col,
  Divider,
  Drawer,
  Image,
  Layout,
  Menu,
  Popover,
  Row,
  Tag,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  AnnouncementIcon,
  BillingIcon,
  DashboardIcon,
  DirectoriesIcon,
  FormsIcon,
  HelpcenterIcon,
  HomeownersIcon,
  InquiriesIcon,
  PaymentChannelIcon,
  PropertyIcon,
  TransactionIcon,
} from "../../assets/resources/icons/icons";
import { spaceBetween } from "../../helpers/SpaceBetween";
import { getProfile } from "../../store/slices/profile/profile-slice";
import { authActions } from "../../store/store";
import "./index.css";
import { removeSlash } from "../../helpers/htmlToText";
import { getProperties } from "../../store/slices/property/property-phase-slice";
import {
  getInquiries,
  getUtilityReports,
} from "../../store/slices/inquiries/inquiries-slice";
import { getBillings } from "../../store/slices/billing/billing-slice";
import { getSubscription } from "../../store/slices/subscription/subscription-slice";
import { getTransactionList } from "../../store/slices/transactions/transactions-slice";
const { Text } = Typography;
const { Sider, Header, Content } = Layout;

const rootSubmenuKeys = [
  "Property",
  "Homeowners",
  "Billings",
  "AnnouncementsMain",
  "ReportsMain",
  "DirectoriesAndHotlines",
];
const LayoutWithRoute = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const { profile } = useSelector((state) => state.profile);
  const { designation } = useSelector((state) => state.auth);
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const { logout } = authActions;
  const navigate = useNavigate();
  const [openAccSettings, setOpenAccSettings] = useState(false);

  const { properties } = useSelector((state) => state.propertyPhases);
  const { inquiries, utilityReports } = useSelector((state) => state.inquiry);
  const { subscription } = useSelector((state) => state.subscription);
  const { billings } = useSelector((state) => state.billing);
  const { transactionList } = useSelector((state) => state.transaction);

  const { currentUser, token, associationId } = useSelector(
    (state) => state.auth
  );

  const getItem = (label, key, children) => {
    return {
      key,
      children,
      label,
    };
  };

  const [openKeys, setOpenKeys] = useState([]);
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  useEffect(() => {
    if (designation === "Admin") {
      dispatch(getProfile(token));
      dispatch(getProperties(token));
      dispatch(getInquiries(token));
      dispatch(getUtilityReports(token));
      dispatch(getBillings(token));
      dispatch(getSubscription(token));
      dispatch(getTransactionList(token));
    } else {
      dispatch(getProfile(token));
      dispatch(getBillings(token));
      dispatch(getTransactionList(token));
      dispatch(getSubscription(token));
    }
  }, []);

  const adminItems = [
    {
      key: "Dashboard",
      // icon: <DashboardIcon />,
      label: (
        // <h4 className="my-0 menu-item text-md md:text-[14px] text-[#9C9C9C]">
        //   Home
        // </h4>
        <Typography
          level={4}
          className="my-0 menu-item text-md md:text-[14px] text-[#9C9C9C] font-semibold flex items-center gap-3"
        >
          <DashboardIcon />
          Dashboard
        </Typography>
      ),
    },
    // {
    //   key: "Associations",
    //   icon: <img src={assoc} alt="" />,
    //   label: (
    //     <Typography
    //       level={4}
    //       className="my-0 menu-item text-md md:text-[14px] text-[#9C9C9C] font-semibold"
    //     >
    //       Associations
    //     </Typography>
    //   ),
    // },
    getItem(
      <div className="flex justify-between items-center">
        <div>
          <Typography
            level={4}
            className="my-0 menu-item text-md md:text-[14px] text-[#9C9C9C] font-semibold flex items-center gap-3"
          >
            <PropertyIcon />
            Properties
          </Typography>
        </div>
        <Badge
          className="mr-2"
          count={
            properties
              ? properties?.filter((obj) => obj.status === 0).length
              : 0
          }
          overflowCount={999}
        />
      </div>,
      "Property",
      // <img src={properties} alt="" />,
      [
        getItem(
          <div className="flex justify-between items-center">
            <Typography className="font-semibold my-0 menu-item text-[12px] md:text-[14px] text-properity ">
              Properties
            </Typography>
            <Badge
              className="mr-2"
              count={
                properties
                  ? properties?.filter((obj) => obj.status === 0).length
                  : 0
              }
              overflowCount={999}
            />
          </div>,
          "Properties"
        ),
        getItem(
          <Typography className="font-semibold my-0 menu-item text-[12px] md:text-[14px] text-properity">
            Phase
          </Typography>,
          "Phase"
        ),
        getItem(
          <Typography className="font-semibold my-0 menu-item text-[12px] md:text-[14px] text-properity">
            Street
          </Typography>,
          "Street"
        ),
        getItem(
          <Typography className="font-semibold my-0 menu-item text-[12px] md:text-[14px] text-properity">
            Property Name
          </Typography>,
          "PropertyName"
        ),
        getItem(
          <Typography className="font-semibold my-0 menu-item text-[12px] md:text-[14px] text-properity">
            Property Size
          </Typography>,
          "PropertySize"
        ),
        getItem(
          <Typography className="font-semibold my-0 menu-item text-[12px] md:text-[14px] text-properity">
            Property Type
          </Typography>,
          "PropertyType"
        ),
      ]
    ),
    getItem(
      <Typography
        level={4}
        className="my-0 menu-item text-md md:text-[14px] text-[#9C9C9C] font-semibold flex items-center gap-3"
      >
        <HomeownersIcon />
        Homeowners
      </Typography>,
      "Homeowners",

      [
        getItem(
          <Typography className="font-semibold my-0 menu-item text-[12px] md:text-[14px] text-properity">
            Registered
          </Typography>,
          "Registered"
        ),
        getItem(
          <Typography className="font-semibold my-0 menu-item text-[12px] md:text-[14px] text-properity">
            Applications
          </Typography>,
          "Applications"
        ),
        getItem(
          <Typography className="font-semibold my-0 menu-item text-[12px] md:text-[14px] text-properity">
            Master List
          </Typography>,
          "MasterList"
        ),
      ]
    ),
    getItem(
      <div className="flex justify-between items-center">
        <Typography
          level={4}
          className="my-0 menu-item text-md md:text-[14px] text-[#9C9C9C] font-semibold flex items-center gap-3"
        >
          <TransactionIcon />
          Transactions
        </Typography>
        <Badge
          className="mr-2"
          count={
            transactionList
              ? transactionList?.filter((obj) => obj.status === 0).length
              : 0
          }
          overflowCount={999}
        />
      </div>,
      "Transactions"
    ),
    {
      key: "PaymentChannel",
      label: (
        <Typography
          level={4}
          className="my-0 menu-item text-md md:text-[14px] text-[#9C9C9C] font-semibold flex items-center gap-3"
        >
          <PaymentChannelIcon />
          Payment Channel
        </Typography>
      ),
    },
    getItem(
      <div className="flex justify-between items-center">
        <div>
          <Typography
            level={4}
            className="my-0 menu-item text-md md:text-[14px] text-[#9C9C9C] font-semibold flex items-center gap-3"
          >
            <BillingIcon />
            Billings
          </Typography>
        </div>
        <Badge
          className="mr-2"
          count={
            billings ? billings?.filter((obj) => obj.status === 0).length : 0
          }
          overflowCount={999}
        />
      </div>,
      "Billings",

      [
        getItem(
          <div className="flex justify-between items-center">
            <Typography className="font-semibold my-0 menu-item text-[12px] md:text-[14px] text-properity ">
              Billing Statements
            </Typography>
            <Badge
              className="mr-2"
              count={
                billings
                  ? billings?.filter((obj) => obj.status === 0).length
                  : 0
              }
              overflowCount={999}
            />
          </div>,
          "Billing"
        ),
        getItem(
          <Typography className="font-semibold my-0 menu-item text-[12px] md:text-[14px] text-properity">
            Add Billing
          </Typography>,
          "AddBilling"
        ),
        getItem(
          <Typography className="font-semibold my-0 menu-item text-[12px] md:text-[14px] text-properity">
            Association Charges
          </Typography>,
          "AssociationCharges"
        ),
      ]
    ),
    getItem(
      <Typography
        level={4}
        className="my-0 menu-item text-md md:text-[14px] text-[#9C9C9C] font-semibold flex items-center gap-3"
      >
        <AnnouncementIcon />
        Announcements
      </Typography>,
      "AnnouncementsMain",

      [
        getItem(
          <Typography className="font-semibold my-0 menu-item text-[12px] md:text-[14px] text-properity">
            Announcements
          </Typography>,
          "Announcements"
        ),
        getItem(
          <Typography className="font-semibold my-0 menu-item text-[12px] md:text-[14px] text-properity">
            Events
          </Typography>,
          "Events"
        ),
      ]
    ),
    getItem(
      <div className="flex justify-between items-center">
        <div>
          <Typography
            level={4}
            className="my-0 menu-item text-md md:text-[14px] text-[#9C9C9C] font-semibold flex items-center gap-3"
          >
            <InquiriesIcon />
            Inquiries & Reports
          </Typography>
        </div>
        <Badge
          className="mr-2"
          count={
            inquiries || utilityReports
              ? inquiries?.filter((obj) => obj.status === 0).length +
                utilityReports?.filter((obj) => obj.status === 0).length
              : 0
          }
          overflowCount={999}
        />
      </div>,
      "ReportsMain",

      [
        getItem(
          <div className="flex justify-between items-center">
            <Typography className="font-semibold my-0 menu-item text-[12px] md:text-[14px] text-properity ">
              Inquiries
            </Typography>
            <Badge
              className="mr-2"
              count={
                inquiries
                  ? inquiries?.filter((obj) => obj.status === 0).length
                  : 0
              }
              overflowCount={999}
            />
          </div>,
          "Inquiries"
        ),
        getItem(
          <div className="flex justify-between items-center">
            <Typography className="font-semibold my-0 menu-item text-[12px] md:text-[14px] text-properity ">
              Utility Reports
            </Typography>
            <Badge
              className="mr-2"
              count={
                utilityReports
                  ? utilityReports?.filter((obj) => obj.status === 0).length
                  : 0
              }
              overflowCount={999}
            />
          </div>,
          "UtilityReports"
        ),
        getItem(
          <Typography className="font-semibold my-0 menu-item text-[12px] md:text-[14px] text-properity">
            Utility Report Types
          </Typography>,
          "UtilityReportTypes"
        ),
      ]
    ),
    getItem(
      <Typography
        level={4}
        className="my-0 menu-item text-md md:text-[14px] text-[#9C9C9C] font-semibold flex items-center gap-3"
      >
        <DirectoriesIcon />
        Directories & Hotlines
      </Typography>,
      "DirectoriesAndHotlines",

      [
        getItem(
          <Typography className="font-semibold my-0 menu-item text-[12px] md:text-[14px] text-properity">
            Directories
          </Typography>,
          "Directories"
        ),
        getItem(
          <Typography className="font-semibold my-0 menu-item text-[12px] md:text-[14px] text-properity">
            Hotlines
          </Typography>,
          "Hotlines"
        ),
      ]
    ),
    {
      key: "Forms",
      label: (
        <Typography
          level={4}
          className="my-0 menu-item text-md md:text-[14px] text-[#9C9C9C] font-semibold flex items-center gap-3"
        >
          <FormsIcon />
          Forms
        </Typography>
      ),
    },
    {
      key: "HelpCenter",
      label: (
        <Typography
          level={4}
          className="my-0 menu-item text-md md:text-[14px] text-[#9C9C9C] font-semibold flex items-center gap-3"
        >
          <HelpcenterIcon />
          Help Center
        </Typography>
      ),
    },
  ];

  const billingAdminItems = [
    getItem(
      <div className="flex justify-between items-center">
        <Typography
          level={4}
          className="my-0 menu-item text-md md:text-[14px] text-[#9C9C9C] font-semibold flex items-center gap-3"
        >
          <TransactionIcon />
          Transactions
        </Typography>
        <Badge
          className="mr-2"
          count={
            transactionList
              ? transactionList?.filter((obj) => obj.status === 0).length
              : 0
          }
          overflowCount={999}
        />
      </div>,
      "Transactions"
    ),
    getItem(
      <Typography
        level={4}
        className="my-0 menu-item text-md md:text-[14px] text-[#9C9C9C] font-semibold flex items-center gap-3"
      >
        <BillingIcon />
        Billings
      </Typography>,
      "Billings",

      [
        getItem(
          <div className="flex justify-between items-center">
            <Typography className="font-semibold my-0 menu-item text-[12px] md:text-[14px] text-properity ">
              Billing Statements
            </Typography>
            <Badge
              className="mr-2"
              count={
                billings
                  ? billings?.filter((obj) => obj.status === 0).length
                  : 0
              }
              overflowCount={999}
            />
          </div>,
          "Billing"
        ),
        getItem(
          <Typography className="font-semibold my-0 menu-item text-[12px] md:text-[14px] text-properity">
            Add Billing
          </Typography>,
          "AddBilling"
        ),
        getItem(
          <Typography className="font-semibold my-0 menu-item text-[12px] md:text-[14px] text-properity">
            Association Charges
          </Typography>,
          "AssociationCharges"
        ),
      ]
    ),
  ];

  useEffect(() => {}, []);

  const openAccSettingsHandler = (state) => {
    setOpenAccSettings(state);
  };

  const logoutHandler = () => {
    dispatch(logout());
  };

  if (
    location.pathname === "/" ||
    location.pathname === "/404" ||
    location.pathname === "/Success" ||
    location.pathname.includes("/privacy-policy") ||
    location.pathname === `/Subscription/${associationId}`
  )
    return <>{children}</>;

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {/* Sidebar */}
      <Sider
        className="shadow-lg sidebar mb-0 relative z-[999]"
        breakpoint={"md"}
        theme="light"
        collapsedWidth={0}
        width={"270px"}
        trigger={null}
      >
        <div className="sticky left-0 top-5 bottom-0">
          <div className="flex flex-col justify-between gap-[100px]">
            <div>
              <div className="logo-container flex flex-col items-center">
                <Image
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                  }}
                  preview={false}
                  src={
                    process.env.REACT_APP_BASE_URL +
                    "/" +
                    currentUser?.assocLogo
                  }
                />
                <div className="flex flex-col py-5 ">
                  <p className="current-user text-xl font-[500] text-center break-all">
                    {currentUser?.assocName}
                  </p>
                  <p className="current-user text-xl font-[700] text-center">
                    {`(${currentUser?.assocAcronym})`}
                  </p>
                </div>
              </div>
              <div className="flex">
                <Menu
                  openKeys={openKeys}
                  onOpenChange={onOpenChange}
                  className="menu-items"
                  theme="light"
                  mode="inline"
                  defaultSelectedKeys={[location.pathname]}
                  selectedKeys={[location.pathname.substring(1)]}
                  onClick={(param) => navigate(param.key)}
                  items={
                    currentUser.designation === "Admin"
                      ? adminItems
                      : billingAdminItems
                  }
                />
              </div>
            </div>
            <div className="flex">
              {/* <img
                className="logo w-[90%] mx-auto items-center flex justify-center "
                alt="logo"
                src={properity}
              ></img> */}
            </div>
          </div>
        </div>
      </Sider>

      {/* Sidebar */}

      <Layout className="site-layout">
        <Header
          className="main-header"
          style={{
            paddingRight: "35px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: "sticky",
            top: "0",
            backgroundColor: "#4d8e03",
            zIndex: "1000",
          }}
        >
          {/* Account */}
          <div className="flex items-center gap-10">
            <div className="md:hidden  text-white block">
              {React.createElement(collapsed ? MenuOutlined : MenuOutlined, {
                className: "menu trigger text-3xl ",
                onClick: () => setVisible(true),
              })}
            </div>
            <h1 className="mb-0 text-[25px] poppins text-center items-center m-0 poppins font-[600] hidden md:block text-white">
              {[spaceBetween(removeSlash(location.pathname))]}
            </h1>
          </div>
          <div className="button-right flex items-center gap-10">
            {/* Notifcations/Messages */}

            {/* <Badge count={2} overflowCount={999}>
              <Popover
                placement="bottomRight"
                title={
                  <h1 className="text-[1.3rem] p-3 m-0 text-gray-800 poppins font-bold">
                    Notifications
                  </h1>
                }
                // content={Messages}
                trigger="click"
              >
                <img
                  src={notif}
                  alt=""
                  className="w-[22px]  cursor-pointer"
                  style={{
                    color: "gray",
                    border: "1px solid transparent",
                  }}
                />
              </Popover>
            </Badge>
            <Badge count={12} overflowCount={999}>
              <Popover
                className="pop-notif"
                placement="bottomRight"
                title={
                  <h1 className="text-[1.3rem] p-3 m-0 text-gray-800 poppins font-bold">
                    Messages
                  </h1>
                }
                // content={Notifications}
                trigger="click"
                role="tooltip"
              >
                <img
                  src={inbox}
                  alt=""
                  className="w-[22px]  cursor-pointer"
                  style={{
                    color: "gray",
                    border: "1px solid transparent",
                  }}
                />
              </Popover>
            </Badge> */}

            {/* Notifcations/Messages */}

            <Popover
              open={openAccSettings}
              onOpenChange={openAccSettingsHandler} //Popover
              className="profile-popover "
              placement="bottomRight"
              arrowPointAtCenter
              role="tooltip"
              arrowContent={false}
              trigger="click"
              content={
                <div className="p-2 flex flex-col">
                  <div className="item-center text-center flex flex-col justify-center">
                    <div>
                      <Avatar
                        className="bg-white"
                        shape="square"
                        size={80}
                        icon={
                          <img
                            src={
                              process.env.REACT_APP_BASE_URL +
                              "/" +
                              currentUser?.assocLogo
                            }
                            alt={
                              profile ? profile[0]?.name?.substring(0, 1) : "-"
                            }
                          />
                        }
                      />
                    </div>
                    <h1 className="mb-0 text-[18px] font-[500]">
                      {profile ? profile[0]?.name : "-"}
                    </h1>
                    <p className="mb-0">{profile ? profile[0]?.email : "-"}</p>{" "}
                  </div>
                  <div className="flex items-center justify-center pt-2">
                    <Tag color="blue">
                      <p className="mb-0 text-gray-700 font-semibold ">
                        {profile ? profile[0]?.designation : "-"}
                      </p>
                    </Tag>
                  </div>
                  <Divider className="m-3" />
                  <div className="flex flex-col w-full gap-3">
                    <div
                      className="flex flex-col items-start justify-center  h-full gap-2 rounded-[5px] py-2 px-4"
                      style={{ border: "2px solid #4D8E03" }}
                    >
                      <div className="flex justify-between uppercase text-[12px] w-full text-gray-500">
                        <span>current plan</span>
                        <Link to={`/ManageSubscription`}>
                          <span
                            onClick={() => openAccSettingsHandler(false)} //Popover
                          >
                            View
                          </span>
                        </Link>
                      </div>
                      <div className="flex flex-col">
                        <span
                          className="uppercase text-[14px] font-medium text-properity
                        "
                        >
                          {subscription?.name} Plan
                        </span>
                        <span className="uppercase text-[16px] font-semibold">
                          {subscription?.terms}
                        </span>
                      </div>
                    </div>
                    <Link to={`/UpgradeSubscription`}>
                      <button
                        onClick={() => openAccSettingsHandler(false)} //Popover
                        type="button"
                        className="cursor-pointer  w-full border-transparent inline-block px-6 py-2.5 bg-properity text-white font-[500] text-[14px] leading-tight uppercase rounded shadow-md hover:bg-[#66bb04] hover:shadow-lg focus:shadow-lg focus:outline-none  active:shadow-lg transition duration-150 ease-in-out"
                      >
                        Upgrade now
                      </button>
                    </Link>
                    <Link to={"/EditProfile"}>
                      <button
                        onClick={() => openAccSettingsHandler(false)} //Popover
                        type="button"
                        className="cursor-pointer w-full border-transparent inline-block px-6 py-2.5 bg-transparent text-properity font-[500] text-[14px] leading-tight uppercase rounded hover:text-blue-700 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none focus:ring-0 active:bg-gray-200 transition duration-150 ease-in-out"
                      >
                        Manage your Account
                      </button>
                    </Link>
                  </div>
                  <Divider className="m-3" />
                  <div className="flex items-center justify-center">
                    <Link to={"/Login"}>
                      <Button
                        style={{
                          height: "42.75px",
                          textTransform: "uppercase",
                          fontWeight: 500,
                          fontSize: "14px",
                          maxWidth: "100px",
                        }}
                        type="outlined"
                        className="w-full"
                        onClick={() => logoutHandler()}
                      >
                        Log out
                      </Button>
                    </Link>
                  </div>
                  <Divider className="m-3" />
                  <div className="flex items-center justify-center text-center gap-3">
                    <p
                      onClick={() => navigate("/privacy-policy/introduction")}
                      className="mb-0 cursor-pointer hover:text-blue-600 duration-150"
                    >
                      Privacy Policy
                    </p>
                    <div className="w-[4px] h-[4px] rounded-full bg-black"></div>
                    <p className="mb-0">Terms of Service</p>
                  </div>
                </div>
              }
            >
              <div className="">
                <Avatar
                  className="font-[700]"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0px 0px 20px 1px #9e9e9e77",
                    objectFit: "cover",
                  }}
                  src={
                    process.env.REACT_APP_BASE_URL +
                    "/" +
                    currentUser?.assocLogo
                  }
                  size={42}
                ></Avatar>
              </div>
            </Popover>
          </div>

          {/* Account */}

          {/* Burger Menu */}

          <Drawer
            className="layout"
            closable={false}
            title={
              <Row>
                <Col>
                  <div className="logo-container">
                    <img
                      className="logo w-full"
                      alt="logo"
                      src={
                        process.env.REACT_APP_BASE_URL +
                        "/" +
                        currentUser?.assocLogo
                      }
                    ></img>
                  </div>
                </Col>
                <Col className="current-user-container">
                  <p className="current-user"> {currentUser?.assocName}</p>
                </Col>
              </Row>
            }
            width={250}
            placement="left"
            onClick={() => setVisible(false)}
            onClose={() => setVisible(false)}
            open={visible}
          >
            <Menu
              className="menu-items"
              theme="light"
              mode="inline"
              defaultSelectedKeys={[location.pathname]}
              items={
                currentUser.designation === "Admin"
                  ? adminItems
                  : billingAdminItems
              }
              selectedKeys={[location.pathname.substring(1)]}
              onClick={(param) => navigate(param.key)}
            ></Menu>
          </Drawer>

          {/* Burger Menu */}
        </Header>
        <Content
          className="site-layout-background"
          style={{ margin: "24px 14px", height: "100%" }}
        >
          {children}
          {/* <Outlet /> */}
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutWithRoute;
