import { put, call, takeLatest, select } from "redux-saga/effects";
import {
  getBillingStatements,
  getBillingStatement,
  getAssociationCharges,
  removeBillingStatement,
  addAssociationCharge,
  createBillingStatement,
  updateAssociationCharge,
  removeAssociationCharge,
  removeBillingStatementCharge,
  addBillingStatementCharges,
  updateBillingStatementCharge,
  addBillingStatementAttachments,
  removeBillingStatementAttachment,
  updateBillingStatementAttachment,
  updateBillingStatement,
  getPreviousBillingStatement,
  getBillingStatementsPage,
  createBillingStatementWithCharges,
} from "../../api/billing/billing-api";
import { billingActions } from "../../store";
///
///

function* getBillingStatementsRequest({ payload }) {
  const { requestError, getBillingsSuccess } = billingActions;
  const result = yield call(getBillingStatements, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getBillingsSuccess(result.data));
  }
}

function* getBillingStatementsPageRequest({ payload }) {
  const { requestError, getBillingStatementsPageSuccess } = billingActions;
  const result = yield call(getBillingStatementsPage, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getBillingStatementsPageSuccess(result.data));
  }
}

function* getBillingStatementRequest({ payload }) {
  const { requestError, getBillingStatementSuccess } = billingActions;
  const result = yield call(getBillingStatement, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getBillingStatementSuccess(result.data));
    console.log(result.data);
  }
}

function* getPreviousBillingStatementRequest({ payload }) {
  const { requestError, getPreviousBillingStatementSuccess } = billingActions;
  const result = yield call(getPreviousBillingStatement, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getPreviousBillingStatementSuccess(result.data));
    console.log(payload);
  }
}

function* createBillingStatementRequest({ payload }) {
  const { requestError, createBillingStatementSuccess, getBillings } =
    billingActions;
  const result = yield call(createBillingStatement, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(createBillingStatementSuccess(result.data));
    const { currentUser, token } = yield select((state) => state.auth);
    yield put(
      getBillings({
        params: currentUser.accountId,
      })
    );
  }
}

function* updateBillingStatementRequest({ payload }) {
  const { requestError, updateBillingStatementSuccess, getBillingStatement } =
    billingActions;
  const result = yield call(updateBillingStatement, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(updateBillingStatementSuccess(result.data));
    yield put(getBillingStatement({ params: payload.params }));
  }
}

function* removeBillingStatementRequest({ payload }) {
  const { requestError, removeBillingStatementSuccess, getBillings } =
    billingActions;
  const result = yield call(removeBillingStatement, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(removeBillingStatementSuccess(result.data));
    console.log(payload);
    yield put(getBillings({ params: payload.body.accountId }));
    window.location.reload();
  }
}

function* getAssociationChargesRequest({ payload }) {
  const { requestError, getAssociationChargesSuccess } = billingActions;
  const result = yield call(getAssociationCharges, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getAssociationChargesSuccess(result.data));
  }
}

function* addAssociationChargeRequest({ payload }) {
  const { requestError, addAssociationChargeSuccess, getAssociationCharges } =
    billingActions;
  const result = yield call(addAssociationCharge, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(addAssociationChargeSuccess(result.data));
    yield put(getAssociationCharges());
  }
}

function* removeAssociationChargeRequest({ payload }) {
  const {
    requestError,
    removeAssociationChargeSuccess,
    getAssociationCharges,
  } = billingActions;
  const result = yield call(removeAssociationCharge, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(removeAssociationChargeSuccess(result.data));
    console.log(payload);
    yield put(getAssociationCharges());
  }
}

function* removeBillingStatementChargeRequest({ payload }) {
  const {
    requestError,
    removeBillingStatementChargeSuccess,
    getBillingStatement,
  } = billingActions;
  const result = yield call(removeBillingStatementCharge, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response.message));
  } else {
    yield put(removeBillingStatementChargeSuccess(result.data));
    yield put(getBillingStatement({ params: payload.billId }));
  }
}

function* updateAssociationChargeRequest({ payload }) {
  const {
    requestError,
    updateAssociationChargeSuccess,
    getAssociationCharges,
  } = billingActions;
  const result = yield call(updateAssociationCharge, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(updateAssociationChargeSuccess(result.data));
    yield put(getAssociationCharges());
  }
}

function* addBillingStatementChargesRequest({ payload }) {
  const {
    requestError,
    addBillingStatementChargesSuccess,
    getBillingStatement,
  } = billingActions;
  const result = yield call(addBillingStatementCharges, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response.data.message));
    console.log(result.response.data.message);
  } else {
    yield put(addBillingStatementChargesSuccess(result.data));
    yield put(getBillingStatement({ params: payload.billId }));
  }
}

function* updateBillingStatementChargeRequest({ payload }) {
  const {
    requestError,
    updateBillingStatementChargeSuccess,
    getBillingStatement,
  } = billingActions;
  const result = yield call(updateBillingStatementCharge, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response.data.message));
    console.log(result.response.data.message);
  } else {
    yield put(updateBillingStatementChargeSuccess(result.data));
    yield put(getBillingStatement({ params: payload.billId }));
  }
}

function* addBillingStatementAttachmentsRequest({ payload }) {
  const {
    requestError,
    addBillingStatementAttachmentsSuccess,
    getBillingStatement,
  } = billingActions;
  const result = yield call(addBillingStatementAttachments, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response.data.message));
    console.log(result.response.data.message);
  } else {
    yield put(addBillingStatementAttachmentsSuccess(result.data));
    yield put(getBillingStatement({ params: payload.formData.get("billId") }));
  }
}

function* createBillingStatementWithChargesRequest({ payload }) {
  const {
    requestError,
    createBillingStatementWithChargesSuccess,
    getBillings,
  } = billingActions;
  const result = yield call(createBillingStatementWithCharges, payload);
  if (result.name === "AxiosError") {
    if (result.response.data.message === 500) {
      yield put(requestError("Fill all fields!"));
    }
    console.log(result.response.data.message);
  } else {
    yield put(createBillingStatementWithChargesSuccess(result.data));
    const { currentUser, token } = yield select((state) => state.auth);
    yield put(getBillings(token));
  }
}

function* removeBillingStatementAttachmentRequest({ payload }) {
  const {
    requestError,
    removeBillingStatementAttachmentSuccess,
    getBillingStatement,
  } = billingActions;
  const result = yield call(removeBillingStatementAttachment, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response.data.message));
    console.log(result.response.data.message);
  } else {
    yield put(removeBillingStatementAttachmentSuccess(result.data));
    yield put(getBillingStatement({ params: payload.body.billId }));
  }
}

function* updateBillingStatementAttachmentRequest({ payload }) {
  const {
    requestError,
    updateBillingStatementAttachmentSuccess,
    getBillingStatement,
  } = billingActions;
  const result = yield call(updateBillingStatementAttachment, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response.data.message));
    console.log(result.response.data.message);
  } else {
    yield put(updateBillingStatementAttachmentSuccess(result.data));
    yield put(getBillingStatement({ params: payload.body.billId }));
  }
}

// Export the saga (todo-saga)
export default function* billingSaga() {
  yield takeLatest(`billing/getBillings`, getBillingStatementsRequest);
  yield takeLatest(
    `billing/getBillingStatementsPage`,
    getBillingStatementsPageRequest
  );
  yield takeLatest(
    `billing/getPreviousBillingStatement`,
    getPreviousBillingStatementRequest
  );
  yield takeLatest(
    `billing/updateBillingStatement`,
    updateBillingStatementRequest
  );
  yield takeLatest(
    `billing/updateBillingStatementAttachment`,
    updateBillingStatementAttachmentRequest
  );
  yield takeLatest(
    `billing/removeBillingStatementAttachment`,
    removeBillingStatementAttachmentRequest
  );
  yield takeLatest(
    `billing/updateBillingStatementCharge`,
    updateBillingStatementChargeRequest
  );
  yield takeLatest(
    `billing/addBillingStatementCharges`,
    addBillingStatementChargesRequest
  );
  yield takeLatest(
    `billing/removeAssociationCharge`,
    removeAssociationChargeRequest
  );
  yield takeLatest(`billing/addAssociationCharge`, addAssociationChargeRequest);
  yield takeLatest(
    `billing/addBillingStatementAttachments`,
    addBillingStatementAttachmentsRequest
  );
  yield takeLatest(
    `billing/removeBillingStatement`,
    removeBillingStatementRequest
  );
  yield takeLatest(`billing/getBillingStatement`, getBillingStatementRequest);
  yield takeLatest(
    `billing/createBillingStatement`,
    createBillingStatementRequest
  );
  yield takeLatest(
    `billing/getAssociationCharges`,
    getAssociationChargesRequest
  );
  yield takeLatest(
    `billing/updateAssociationCharge`,
    updateAssociationChargeRequest
  );
  yield takeLatest(
    `billing/removeBillingStatementCharge`,
    removeBillingStatementChargeRequest
  );
  yield takeLatest(
    `billing/createBillingStatementWithCharges`,
    createBillingStatementWithChargesRequest
  );
}
