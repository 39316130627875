import React from "react";
import { Divider, Skeleton, Calendar, theme, Badge, Select, Image } from "antd";
import CountUp from "react-countup";
import { useNavigate } from "react-router-dom";

const Users = ({
  homeownersLoading,
  residentsLoading,
  homeowners,
  residents,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className="bg-white shadow-lg rounded-[10px] hover:scale-[1.03] hover:shadow-2xl duration-150 cursor-pointer row-span-1"
      onClick={() => navigate("/Registered")}
    >
      <div className="w-full h-full p-5 ">
        <div className="flex-col flex ">
          <span className="font-semibold text-lg">Users</span>
          <Divider />
          <div className="grid grid-rows-2 gap-5">
            <div className="flex flex-row items-center justify-between">
              <Skeleton
                paragraph={{ rows: 2 }}
                active={homeownersLoading}
                loading={homeownersLoading}
              >
                <div>
                  <span className="font-semibold ">Homeowners</span>
                  <p className="items-center ">total no. of homeowners</p>
                </div>
                <div className="bg-blue-400 w-[60px] h-[60px] rounded-lg flex gap-3 items-center justify-center pending-count">
                  <CountUp
                    end={homeowners.count ? homeowners.count : 0}
                    className="text-white text-2xl font-semibold text-center"
                  >
                    <p className="text-white text-2xl font-semibold text-center">
                      {homeowners.count ? homeowners.count : 0}
                    </p>
                  </CountUp>
                </div>
              </Skeleton>
            </div>
            <div className="flex flex-row items-center justify-between">
              <Skeleton
                paragraph={{ rows: 2 }}
                active={residentsLoading}
                loading={residentsLoading}
              >
                <div>
                  <span className="font-semibold">Residents</span>
                  <p className="items-center ">total no. of residents</p>
                </div>
                <div className="bg-blue-400 w-[60px] h-[60px] rounded-lg flex gap-3 items-center justify-center pending-count">
                  <CountUp
                    end={residents.count ? residents.count : 0}
                    className="text-white text-2xl font-semibold text-center"
                  >
                    <p>{residents.count ? residents.count : 0}</p>
                  </CountUp>
                </div>
              </Skeleton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
