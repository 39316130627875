import { put, call, takeLatest } from "redux-saga/effects";
import {
  getDirectories,
  getDirectoryTypes,
  addDirectory,
  updateDirectory,
  updateDirectoryType,
  addDirectoryType,
  removeDirectoryType,
} from "../../../api/directories-and-hotlines/directories/directories-api";
import { directoriesActions } from "../../../store";

function* getDirectoriesRequest() {
  const { requestError, getDirectoriesSuccess } = directoriesActions;
  const result = yield call(getDirectories);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getDirectoriesSuccess(result.data));
  }
}

function* addDirectoryRequest({ payload }) {
  const { requestError, addDirectoriesSuccess, getDirectories } =
    directoriesActions;
  const result = yield call(addDirectory, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(addDirectoriesSuccess(result.data));
    yield put(getDirectories());
  }
}

function* updateDirectoryRequest({ payload }) {
  const { requestError, updateDirectoriesSuccess, getDirectories } =
    directoriesActions;
  const result = yield call(updateDirectory, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(updateDirectoriesSuccess(result.data));
    yield put(getDirectories());
  }
}

function* getDirectoryTypesRequest() {
  const { requestError, getDirectoryTypesSuccess } = directoriesActions;
  const result = yield call(getDirectoryTypes);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getDirectoryTypesSuccess(result.data));
  }
}

function* addDirectoryTypeRequest({ payload }) {
  const { requestError, addDirectoryTypesSuccess, getDirectoryTypes } =
    directoriesActions;
  const result = yield call(addDirectoryType, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(addDirectoryTypesSuccess(result.data));
    yield put(getDirectoryTypes());
  }
}

function* updateDirectoryTypeRequest({ payload }) {
  const { requestError, updateDirectoryTypesSuccess, getDirectoryTypes } =
    directoriesActions;
  const result = yield call(updateDirectoryType, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(updateDirectoryTypesSuccess(result.data));
    yield put(getDirectoryTypes());
  }
}

function* removeDirectoryTypeRequest({ payload }) {
  const { requestError, removeDirectoryTypesSuccess, getDirectoryTypes } =
    directoriesActions;
  const result = yield call(removeDirectoryType, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(removeDirectoryTypesSuccess(result.data));
    yield put(getDirectoryTypes());
  }
}

// Export the saga (todo-saga)
export default function* directoriesSaga() {
  yield takeLatest(`directories/getDirectories`, getDirectoriesRequest);
  yield takeLatest(`directories/addDirectories`, addDirectoryRequest);
  yield takeLatest(`directories/updateDirectories`, updateDirectoryRequest);
  yield takeLatest(`directories/getDirectoryTypes`, getDirectoryTypesRequest);
  yield takeLatest(`directories/addDirectoryTypes`, addDirectoryTypeRequest);
  yield takeLatest(
    `directories/removeDirectoryTypes`,
    removeDirectoryTypeRequest
  );
  yield takeLatest(
    `directories/updateDirectoryTypes`,
    updateDirectoryTypeRequest
  );
}
