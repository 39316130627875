import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Input,
  Space,
  Table,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format_PHCurrency } from "../../../../helpers/formatCurrency";
import { deleteBillingCharges } from "../../../../store/slices/billing/billing-slice";
import AddChargeModal from "./AddChargeModal";
import ViewAddedCharge from "./ViewAddedCharge";

const AddChargesToBilling = ({
  adding,
  formData,
  propertyId,
  setRemarks,
  setDate,
}) => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [openCharge, setOpenCharge] = useState(false);
  const [charge, setCharge] = useState();
  const [form] = Form.useForm();
  const [arr, setArr] = useState();
  const [edit, setEdit] = useState(false);
  const [record, setRecord] = useState();
  const [key, setKey] = useState();
  const [chargeList, setChargeList] = useState([]);

  const { assocCharges, billingCharges, billingChargesAttachments } =
    useSelector((state) => state.billing);

  useEffect(() => {
    setChargeList(assocCharges.map((obj) => ({ ...obj, isSelected: 0 })));
  }, [assocCharges]);

  const items = [
    {
      label: (
        <div className="flex gap-2 items-center">
          <PlusSquareOutlined />
          <p> Add attachment</p>
        </div>
      ),
      key: "1",
    },
    {
      label: (
        <div className="flex gap-2 items-center">
          <EditOutlined />
          <p> Edit</p>
        </div>
      ),
      key: "2",
    },
    {
      label: (
        <div className="flex gap-2 items-center text-red-500">
          <DeleteOutlined />
          <p>Remove</p>
        </div>
      ),
      key: "3",
    },
  ];

  useEffect(() => {
    form.resetFields();
  }, [adding]);

  const updateChargeList = (e) => {
    setChargeList(e);
  };

  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "150px",
      render: (record, _, index) => (
        <Dropdown
          trigger={["click"]}
          menu={{
            items,
            onClick: ({ key }) => {
              if (key === "1") {
                openChargeAttachment(record);
              } else if (key === "2") {
                editCharge(record);
                setKey(index);
              } else if (key === "3") {
                dispatch(deleteBillingCharges(index));
                let updated = chargeList.map((obj) => {
                  if (obj.chargeId === record.chargeId) {
                    return { ...obj, isSelected: 0 };
                  }
                  return obj;
                });
                updateChargeList(updated);
              }
            },
          }}
        >
          <Button
            type="primary"
            style={{
              background: "#4d8e03",
            }}
          >
            <Space>
              Action
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      width: "200px",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (amount) =>
        amount ? format_PHCurrency(amount) : format_PHCurrency(0),
      width: "200px",
    },
    {
      title: "Additional amount",
      dataIndex: "additionalAmount",
      render: (amount) =>
        amount ? format_PHCurrency(amount) : format_PHCurrency(0),
      width: "200px",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      width: "200px",
    },
    {
      title: "Billing date",
      dataIndex: "billingDate",
      render: (billingDate) => moment(billingDate).format("LL"),
      width: "200px",
    },
  ];

  const openChargeAttachment = (e) => {
    setOpenCharge(true);
    setCharge(e.chargeId);

    setArr(localStorage.getItem("attachments"));
  };

  const addCharges = () => {
    setOpenModal(true);
  };

  const editCharge = (record) => {
    setOpenModal(true);
    setRecord(record);
    setEdit(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
    setEdit(false);
  };

  const closeCharge = () => {
    setOpenCharge(false);
  };

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    setRemarks(values.remarks ? values.remarks : "n/a");
    setDate(values.dueDate.format("YYYY-MM-DD"));
  };

  return (
    <div className={!adding ? "hidden" : " grid grid-cols-3 gap-10"}>
      <Card
        className="col-span-2"
        title={
          <div className="flex  items-center p-5">
            <h1 className="font-[600] text-[25px] mb-0">Billing charges</h1>
            <div className="flex items-center  text-center justify-center">
              <Button
                style={{
                  border: "1px solid transparent",
                  alignItems: "center",
                  display: "flex",
                }}
                type="outlined"
                onClick={() => addCharges()}
                icon={
                  <PlusSquareOutlined className="text-2xl h-full items-center flex justify-center" />
                }
              >
                Add
              </Button>
            </div>
          </div>
        }
      >
        <Table
          className="master-table"
          bordered
          columns={columns}
          rowKey="chargeId"
          dataSource={billingCharges ? billingCharges : []}
          // loading={isLoading}
          scroll={{
            y: 400,
            x: "100vw",
          }}
        />
      </Card>
      <div className="flex flex-col w-full py-5 gap-5">
        <div className="flex flex-col text-left text-[18px]">
          <span>
            Subtotal: &nbsp;
            <span className="font-semibold">
              {billingCharges
                ? format_PHCurrency(
                    +billingCharges
                      .map((obj) => obj.amount)
                      .reduce((a, b) => a + b, 0) +
                      +billingCharges
                        .map((obj) => obj.additionalAmount)
                        .reduce((a, b) => a + b, 0)
                  )
                : format_PHCurrency(0)}
            </span>
          </span>
          <span>
            VAT(12%): &nbsp;
            <span className="font-semibold">
              {billingCharges
                ? format_PHCurrency(
                    (+billingCharges
                      .map((obj) => obj.amount)
                      .reduce((a, b) => a + b, 0) +
                      +billingCharges
                        .map((obj) => obj.additionalAmount)
                        .reduce((a, b) => a + b, 0)) *
                      0.12
                  )
                : format_PHCurrency(0)}
            </span>
          </span>
          <Divider className="m-3" />
          <span className="font-semibold">
            Total:&nbsp;
            <span className="font-semibold">
              {billingCharges
                ? format_PHCurrency(
                    +billingCharges
                      .map((obj) => obj.amount)
                      .reduce((a, b) => a + b, 0) +
                      +billingCharges
                        .map((obj) => obj.additionalAmount)
                        .reduce((a, b) => a + b, 0) +
                      (+billingCharges
                        .map((obj) => obj.amount)
                        .reduce((a, b) => a + b, 0) +
                        +billingCharges
                          .map((obj) => obj.additionalAmount)
                          .reduce((a, b) => a + b, 0)) *
                        0.12
                  )
                : format_PHCurrency(0)}
            </span>
          </span>
        </div>
        <Form
          layout="vertical"
          form={form}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          onChange={(e) => setRemarks(e.target.value)}
        >
          <Form.Item
            value=""
            name="remarks"
            label={<h1 className="form-title">Remarks</h1>}
          >
            <Input
              style={{
                padding: "10px",
                fontSize: "18px",
                borderRadius: "3px",
              }}
              size="large"
              placeholder="Enter remarks "
            />
          </Form.Item>
          <Form.Item
            value=""
            name="dueDate"
            label={<h1 className="form-title">Due date</h1>}
            rules={[
              {
                required: true,
                message: "Please input date",
              },
            ]}
          >
            <DatePicker
              onChange={(e) => setDate(e.format("YYYY-MM-DD"))}
              value=""
              disabledDate={(d) => !d || d.isBefore(moment().calendar("LL"))}
            />
          </Form.Item>
        </Form>
      </div>
      <ViewAddedCharge
        openAttachment={openCharge}
        closeAttachment={closeCharge}
        charge={charge}
        arr={arr}
      />
      <AddChargeModal
        edit={edit}
        record={record}
        openModal={openModal}
        closeModal={onCloseModal}
        index={key}
        chargeList={chargeList}
        updateChargeList={updateChargeList}
      />
    </div>
  );
};

export default AddChargesToBilling;
