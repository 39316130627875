import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

const initialState = {
  isLoading: false,
  homeowner: [],
  registered: [],
  pending: [],
  masterList: [],
  masterListLoading: false,
  searchedHomeowner: null,
  homeownerLoading: false,
  acceptHomeownerLoading: false,

  checkEntries: [],
  successfulEntries: [],
  errorMessage: "",
  successMessage: "",
  applicantsCount: [],
  applicantsTest: [],
};

const homeownersSlice = createSlice({
  name: "homeowners",
  initialState,
  reducers: {
    setApplicantsCount(state, action) {
      state.applicantsCount = action.payload;
    },
    getHomeowners(state) {
      state.homeowner = [];
      state.isLoading = true;
    },
    getHomeownersSuccess(state, action) {
      state.isLoading = false;
      state.homeowner = action.payload;
      state.registered = state.homeowner.filter(
        (registered) => registered.isApproved === "APPROVED"
      );
      state.pending = state.homeowner.filter(
        (pending) => pending.isApproved === "PENDING"
      );
    },
    getFilteredHomeowner(state) {
      state.isLoading = true;
      state.homeowner = [];
    },
    getFilteredHomeownerSuccess(state, action) {
      console.log(action.payload);
      state.homeowner = action.payload;
      state.isLoading = false;
    },
    getHomeowner(state) {
      state.homeownerLoading = true;
      state.searchedHomeowner = null;
    },
    getHomeownerSuccess(state, action) {
      console.log(action.payload);
      state.searchedHomeowner = action.payload;
      state.homeownerLoading = false;
    },
    getMasterlist(state) {
      state.masterListLoading = true;
      state.masterList = [];
    },
    getMasterlistSuccess(state, action) {
      state.masterList = action.payload;
      state.searchedHomeowner = null;
      state.masterListLoading = false;
    },
    addToMasterlist(state) {
      state.masterListLoading = true;
    },
    addToMasterlistSuccess(state, action) {
      // message.success(action.payload.message);
      state.masterListLoading = false;
      state.successMessage = action.payload.message;
    },
    addToMasterlistError(state, action) {
      state.masterListLoading = false;
      state.errorMessage = action.payload.data.message;
    },
    updateStatus(state) {
      state.isLoading = true;
    },
    updateStatusSuccess(state, action) {
      console.log(action.payload);
      message.success(action.payload.message);
      state.isLoading = false;
    },
    acceptHomeowner(state) {
      state.acceptHomeownerLoading = true;
    },
    acceptHomeownerSuccess(state, action) {
      message.success(action.payload.message);
      state.acceptHomeownerLoading = false;
    },
    requestError(state, action) {
      state.isLoading = false;
      state.acceptHomeownerLoading = false;
      state.masterListLoading = false;
      state.homeownerLoading = false;
      message.error(action.payload.data.message);
    },
    errorEntries(state, action) {
      const data = action.payload;
      const dataVal = [data].map((d) => ({
        ...d,
        status: 0,
        message: state.errorMessage,
      }));
      state.checkEntries = [...state.checkEntries, ...dataVal];
    },
    successfulEntries(state, action) {
      const data = action.payload;
      const dataVal = [data].map((d) => ({
        ...d,
        status: 1,
        message: state.successMessage,
      }));
      state.checkEntries = [...state.checkEntries, ...dataVal];
    },
  },
});

export const {
  errorEntries,
  successfulEntries,
  setApplicantsCount,
  getHomeowners,
  getHomeownersSuccess,
  acceptHomeowner,
  addToMasterlist,
  addToMasterlistSuccess,
  getHomeowner,
  getHomeownerSuccess,
  getMasterlist,
  getMasterlistSuccess,
  getFilteredHomeowner,
  getFilteredHomeownerSuccess,
  requestError,
  updateStatus,
  updateStatusSuccess,
} = homeownersSlice.actions;
export default homeownersSlice;
