import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, DatePicker, Form, Input, Modal, Select, Tooltip } from "antd";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Help from "../../../assets/resources/icons/Help.png";
import dayjs from "dayjs";

import {
  getUpdatedProfileResident,
  removeResident,
} from "../../../store/slices/homeowner/homeowner-properties-slice";

const originData = [];

for (let i = 0; i < 100; i++) {
  originData.push({
    key: i.toString(),
    name: `Edrward ${i}`,
    age: 32,
    address: `London Park no. ${i}`,
  });
}
const ResidentProfile = ({
  viewResident,
  closeResident,
  residentId,
  editToggle,
  setEditToggle,
}) => {
  const [form] = Form.useForm();
  const { registered } = useSelector((state) => state.homeowners);
  const { residents, propertyId } = useSelector(
    (state) => state.homeownerProperties
  );
  const dispatch = useDispatch();

  const found = residents?.find((obj) => obj.accountId === residentId);

  useEffect(() => {
    form.setFieldsValue({
      ...found,
      firstName: found?.firstName,
      lastName: found?.lastName,
      middleName: found?.middleName ? found?.middleName : "n/a",
      mobileNumber: "" + found?.mobileNumber?.slice(2),
      email: found?.email,
      sex: found?.sex,
      birthdate: dayjs(found?.birthdate, "YYYY-MM-DD"),
    });
  }, [registered, residentId]);

  const onFinish = (values) => {
    dispatch(
      getUpdatedProfileResident({
        params: residentId,
        propertyId: propertyId,
        body: {
          birthdate: moment(values.birthdate).format("YYYY-MM-DD"),
          firstName: values?.firstName,
          sex: values?.sex,
          lastName: values?.lastName,
          mobileNumber: "63" + values?.mobileNumber,
          email: values?.email,
        },
      })
    );
  };

  const onFinishFailed = (errorInfo) => {};

  const deleteResident = () => {
    Modal.confirm({
      centered: true,
      title: "Are you sure to delete this resident?",
      onOk: () => {
        dispatch(
          removeResident({
            params: propertyId,
            body: { residentId: residentId },
          })
        );
      },
    });
  };

  return (
    <div>
      <Modal
        title={
          <h1 className="text-[24px] md:text-[30px] font-[600] text-[#1b1b1b] mb-0 ">
            Resident profile
          </h1>
        }
        centered
        closable={true}
        onCancel={closeResident}
        open={viewResident}
        className="demo"
        width={900}
        footer={false}
      >
        <div className="h-full w-full flex flex-col p-0 md:p-3">
          <Form
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
            style={{ padding: "20px" }}
            className="flex flex-col"
            form={form}
            initialValues={{
              remember: true,
            }}
          >
            <div
              className="flex-col md:flex-row md:items-end md:justify-end "
              style={{
                display: "flex",
                gap: editToggle ? "10px" : "0px",
                transition: "all ease 0.3s",
              }}
            >
              <Form.Item>
                <Button
                  onClick={() => setEditToggle(true)}
                  type="primary"
                  size="default"
                  icon={<EditOutlined />}
                  style={{
                    transition: "all ease 0.3s",
                    background: "#4d8e03",
                    marginRight: editToggle ? "0px" : "6px",
                  }}
                >
                  Edit
                </Button>
              </Form.Item>
              <Form.Item
                style={{
                  display: !editToggle ? "none" : "block",
                }}
              >
                <Button
                  onClick={() => setEditToggle(false)}
                  style={{
                    display: !editToggle ? "none" : "block",
                    background: "#4d8e03",
                  }}
                  htmlType="submit"
                  type="primary"
                  size="default"
                  icon={<SaveOutlined />}
                >
                  Save
                </Button>
              </Form.Item>
              <Form.Item
                style={{
                  display: !editToggle ? "none" : "block",
                }}
              >
                <Button
                  onClick={() => {
                    setEditToggle(false);
                    form.setFieldsValue({
                      ...found,
                      firstName: found?.firstName,
                      lastName: found?.lastName,
                      mobileNumber: "" + found?.mobileNumber?.slice(2),
                      email: found?.email,
                      sex: found?.sex,
                      birthdate: dayjs(found?.birthdate, "YYYY-MM-DD"),
                    });
                  }}
                  style={{
                    display: !editToggle ? "none" : "block",
                  }}
                  type="primary"
                  danger
                  size="default"
                >
                  Cancel
                </Button>
              </Form.Item>
              {/* <Form.Item>
                <Button
                  onClick={() => deleteResident()}
                  danger
                  type="text"
                  style={{ fontSize: "15px", padding: "0px" }}
                >
                  Delete resident &nbsp;
                  <Tooltip
                    title="Madedelete ang resident at hindi na pwedeng ibalik okay?"
                    placement="bottom"
                  >
                    <img src={Help} alt="" />
                  </Tooltip>
                </Button>
              </Form.Item> */}
            </div>
            <div className="flex flex-wrap justify-start w-full mx-auto gap-2 md:gap-10">
              <Form.Item
                name="firstName"
                label={<h1 className="form-title">First name</h1>}
              >
                <Input
                  disabled={!editToggle}
                  style={{
                    padding: "10px",
                    fontSize: "18px",
                    borderRadius: "3px",
                  }}
                  size="large"
                />
              </Form.Item>
              <Form.Item
                name="lastName"
                label={<h1 className="form-title">Last name</h1>}
              >
                <Input
                  disabled={!editToggle}
                  style={{
                    padding: "10px",
                    fontSize: "18px",
                    borderRadius: "3px",
                  }}
                  size="large"
                />
              </Form.Item>
              <Form.Item
                name="mobileNumber"
                label={<h1 className="form-title">Mobile number</h1>}
                rules={[
                  {
                    pattern: /^[0-9]{10}$/,
                    message: "Value must start with 9 and must be 10 digits",
                  },
                ]}
              >
                <Input
                  disabled={!editToggle}
                  style={{
                    padding: "10px",
                    fontSize: "18px",
                    borderRadius: "3px",
                  }}
                  size="large"
                  prefix="+63"
                />
              </Form.Item>
              <Form.Item
                name="email"
                label={<h1 className="form-title">Email address</h1>}
              >
                <Input
                  disabled={!editToggle}
                  style={{
                    padding: "10px",
                    fontSize: "18px",
                    borderRadius: "3px",
                  }}
                  size="large"
                />
              </Form.Item>
              <Form.Item name="sex" label={<h1 className="form-title">Sex</h1>}>
                <Select
                  className="home-owner"
                  style={{
                    borderRadius: "3px",
                    borderColor: "transparent",
                  }}
                  disabled={!editToggle}
                  optionFilterProp="children"
                  showSearch
                  options={[
                    {
                      value: "0",
                      label: "Male",
                    },
                    {
                      value: "1",
                      label: "Female",
                    },
                  ]}
                ></Select>
              </Form.Item>
              <Form.Item
                name="birthdate"
                label={<h1 className="form-title">Birthdate</h1>}
              >
                <DatePicker disabled={!editToggle} size="large" />
              </Form.Item>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default ResidentProfile;
