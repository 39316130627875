import {
  CloseOutlined,
  PaperClipOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Drawer,
  Form,
  Input,
  message,
  Modal,
  Typography,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dummyRequest } from "../../../constants/staticConst";
import {
  addForms,
  getForms,
  updateForms,
} from "../../../store/slices/forms/forms-slice";
import MyDocument from "./MyDocument";
const { Dragger } = Upload;
const { Link } = Typography;

const AddForm = ({
  openAddForm,
  closeAddForm,
  isAdding,
  isEditing,
  formId,
  isLoading,
}) => {
  const [form] = Form.useForm();
  const { forms } = useSelector((state) => state.forms);
  const dispatch = useDispatch();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    let formData = new FormData();

    formData.append("title", values?.title);
    formData.append("description", values?.description);

    for (let index = 0; index < values?.fileUrl?.length; index++) {
      formData.append(
        "file",
        values?.fileUrl === "" ? null : values?.fileUrl[index]
      );
    }

    if (isEditing) {
      dispatch(updateForms({ params: formId, body: { formData } }));
    } else {
      dispatch(addForms({ formData }));
    }

    closeAddForm(true);
    setFileList([]);
  };

  const closeAdd = () => {
    closeAddForm(true);
    setFileList([]);
  };

  const [fileList, setFileList] = useState([]);

  const handleCancel = () => setPreviewOpen(false);

  const handleChange = ({ fileList: newFileList }) => {
    if (newFileList[0]?.status === "done") {
      message.success("file uploaded successfully");
    }
    setFileList(newFileList);

    form.setFieldsValue({
      fileUrl: fileList?.map((file) => file?.originFileObj) || null,
    });
  };
  const uploadButton = (
    <div className="w-full min-w-[100px]">
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload file
      </div>
    </div>
  );
  const found = forms?.find((obj) => obj.formId === formId);

  useEffect(() => {
    if (isEditing) {
      form.setFieldsValue({
        ...found,
        title: found?.title,
        description: found?.description,
      });
    }
  }, [forms, isEditing, formId]);

  useEffect(() => {
    if (isEditing) {
      dispatch(getForms());
    } else {
      form.resetFields();
    }
  }, [isEditing]);

  return (
    <div className="flex justify-center items-center">
      <Drawer
        title={
          <div className="flex md:flex-row md:items-center items-start justify-between">
            <h1 className="text-[24px] md:text-[30px] font-[600] text-[#1b1b1b] mb-0 p-3">
              {isEditing ? "Edit form" : "Add form"}
            </h1>
            <div
              onClick={closeAdd}
              className="w-[50px] h-[50px] items-center text-center flex justify-center text-[20px] rounded-full hover:bg-slate-200 transition-all ease-in duration-150 cursor-pointer hover:scale-110"
            >
              <CloseOutlined className="text-[#1b1b1b]" />
            </div>
          </div>
        }
        onClose={closeAdd}
        onCancel={closeAdd}
        open={openAddForm}
        footer={
          <div className="flex justify-end">
            <Button
              loading={isLoading}
              disabled={isLoading}
              className="shadow-md"
              type="primary"
              onClick={form.submit}
              htmlType="submit"
              style={{
                minWidth: "200px",
                height: "52px",
                fontSize: "17px",
                fontWeight: "400",
                borderRadius: "5px",
                background: "#4d8e03",
                marginBlock: "13px",
              }}
            >
              {isEditing ? "Update" : "Confirm"}
            </Button>
          </div>
        }
        height={"90%"}
        closable={false}
        placement={"bottom"}
      >
        <Form
          form={form}
          name="add"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <div className="flex md:flex-col flex-col gap-10 items-center md:items-start p-6">
            <div className="flex flex-col gap-4 w-full md:w-[20%]  md:flex-1 ">
              <div className="text-label">
                <Form.Item
                  name="title"
                  label={<h1 className="form-title">Title</h1>}
                  rules={[
                    {
                      required: true,
                      message: "Please input title",
                    },
                  ]}
                >
                  <Input
                    style={{
                      padding: "10px",
                      fontSize: "18px",
                      borderRadius: "3px",
                    }}
                    placeholder="Enter title "
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="description"
                  label={<h1 className="form-title">Description</h1>}
                  rules={[
                    {
                      required: true,
                      message: "Please input description",
                    },
                  ]}
                >
                  <Input
                    style={{
                      padding: "10px",
                      fontSize: "18px",
                      borderRadius: "3px",
                    }}
                    placeholder="Enter description"
                    size="large"
                  />
                </Form.Item>
                <Link
                  className={isEditing ? "flex items-center  gap-2" : "hidden"}
                  href={process.env.REACT_APP_BASE_URL + "/" + found?.url}
                  target="_blank"
                >
                  <PaperClipOutlined />
                  {found?.url}
                </Link>
              </div>
            </div>
            <div className="">
              <Form.Item valuePropName="file" name="fileUrl">
                <Dragger
                  name="file"
                  customRequest={dummyRequest}
                  fileList={fileList}
                  onChange={handleChange}
                >
                  {uploadButton}
                </Dragger>
              </Form.Item>
            </div>
          </div>
          <Modal
            open={previewOpen}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img
              alt="example"
              style={{
                width: "100%",
              }}
              src={previewImage}
            />
          </Modal>
          {/* <div className={isEditing ? "w-full h-full" : "hidden"}>
            <MyDocument
              file={process.env.REACT_APP_BASE_URL + "/" + found?.url}
            />
          </div> */}
        </Form>
      </Drawer>
    </div>
  );
};

export default AddForm;
