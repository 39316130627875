import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

const initialState = {
  isLoading: false,
  profile: [],
  mode: null,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    getProfile(state) {
      state.profile = [];
      state.isLoading = true;
    },
    getProfileSuccess(state, action) {
      state.profile = action.payload;
      state.isLoading = false;
    },
    updateProfile(state) {
      state.isLoading = true;
    },
    updateProfileSuccess(state, action) {
      message.success(action.payload.message);
      state.isLoading = false;
    },
    updatePassword(state) {
      state.isLoading = true;
    },
    updatePasswordSuccess(state, action) {
      message.success(action.payload.message);
      state.isLoading = false;
    },
    profileMode(state, action) {
      state.mode = action.payload;
      console.log(state, action);
    },
    requestError(state, action) {
      state.isLoading = false;
      message.error(action.payload.data.message);
    },
  },
});

export const {
  requestError,
  getProfile,
  getProfileSuccess,
  updateProfile,
  updateProfileSuccess,
  updatePassword,
  updatePasswordSuccess,
  profileMode,
} = profileSlice.actions;
export default profileSlice;
