import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

const initialState = {
  eventsLoading: false,
  eventTypesLoading: false,
  events: [],
  eventTypes: [],
};

const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    getEvents(state) {
      state.events = [];
      state.eventsLoading = true;
    },
    getEventsSuccess(state, action) {
      state.eventsLoading = false;
      state.events = action.payload;
      console.log(action.payload);
    },
    addEvents(state) {
      state.eventsLoading = true;
    },
    addEventsSuccess(state, action) {
      state.eventsLoading = false;
      state.events = action.payload;
      message.success(action.payload.message);
    },
    updateEvents(state) {
      state.eventsLoading = true;
    },
    updateEventsSuccess(state, action) {
      state.eventsLoading = false;
      state.events = action.payload;
      message.success(action.payload.message);
    },
    addEventAttachment(state) {
      state.eventsLoading = true;
    },
    addEventAttachmentSuccess(state, action) {
      state.eventsLoading = false;
      state.events = action.payload;
      message.success(action.payload.message);
    },
    updateEventAttachment(state) {
      state.eventTypesLoading = true;
    },
    updateEventAttachmentSuccess(state, action) {
      state.eventTypesLoading = false;
      state.events = action.payload;
      message.success(action.payload.message);
    },
    removeEventAttachment(state) {
      state.eventsLoading = true;
    },
    removeEventAttachmentSuccess(state, action) {
      state.eventsLoading = false;
      message.success(action.payload.message);
    },
    removeEvent(state) {
      state.eventsLoading = true;
    },
    removeEventSuccess(state, action) {
      state.eventsLoading = false;
      message.success(action.payload.message);
    },
    getEventTypes(state) {
      state.eventTypes = [];
      state.eventsLoading = true;
    },
    getEventTypesSuccess(state, action) {
      state.eventsLoading = false;
      state.eventTypes = action.payload;
    },
    addEventType(state) {
      state.eventTypesLoading = true;
    },
    addEventTypeSuccess(state, action) {
      state.eventTypesLoading = false;
      state.eventTypes = action.payload;
      message.success(action.payload.message);
    },
    removeEventType(state) {
      state.eventTypesLoading = true;
    },
    removeEventTypeSuccess(state, action) {
      state.eventTypesLoading = false;
      message.success(action.payload.message);
    },
    updateEventType(state) {
      state.eventTypesLoading = true;
    },
    updateEventTypeSuccess(state, action) {
      state.eventTypesLoading = false;
      state.eventTypes = action.payload;
      message.success(action.payload.message);
    },
    requestError(state, action) {
      state.eventsLoading = false;
      message.error(action.payload.data.message);
    },
  },
});

export const {
  getEvents,
  getEventsSuccess,
  addEventAttachment,
  addEventAttachmentSuccess,
  updateEvents,
  updateEventsSuccess,
  addEvents,
  addEventsSuccess,
  updateEventAttachment,
  updateEventAttachmentSuccess,
  removeEvent,
  removeEventSuccess,
  removeEventAttachment,
  removeEventAttachmentSuccess,
  getEventTypes,
  getEventTypesSuccess,
  addEventType,
  addEventTypeSuccess,
  removeEventType,
  removeEventTypeSuccess,
  updateEventType,
  updateEventTypeSuccess,
  requestError,
} = eventsSlice.actions;
export default eventsSlice;
