import { DeleteOutlined, DownOutlined, EyeOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Dropdown,
  Form,
  Pagination,
  Popconfirm,
  Space,
  Table,
  Tag,
  theme,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  format_addCommas,
  format_PHCurrency,
} from "../../../helpers/formatCurrency";
import {
  getBillings,
  getBillingStatementsPage,
  removeBillingStatement,
} from "../../../store/slices/billing/billing-slice";
import { LoadingTime } from "../../../UI/LoadingScreen";
import "../properties/property.css";
import ViewBill from "./ViewBill";

const { useToken } = theme;

const Billing = ({ arr, stat, fName, lName, dDate }) => {
  const { currentUser, token: userToken } = useSelector((state) => state.auth);
  const { billingStatePage, billingStatePageLoading, billings } = useSelector(
    (state) => state.billing
  );

  const [visible, setVisible] = useState(false);
  const [selectedBill, setSelectedBill] = useState();
  const { token } = useToken();
  const [totalPages, setTotalPages] = useState();
  const dispatch = useDispatch();

  const text = "Are you sure to remove this billing statement?";
  const description = "Remove the billing statement";

  const items = [
    {
      label: (
        <div className="flex gap-2 items-center">
          <EyeOutlined />
          <p>View bill</p>
        </div>
      ),
      key: "1",
    },
  ];

  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "150px",
      render: (record) => (
        <Dropdown
          trigger={["click"]}
          menu={{
            items,
            onClick: () => showDrawer(record.billId),
          }}
          // dropdownRender={(menu) => (
          //   <div className=" flex flex-col items-start" style={contentStyle}>
          //     {React.cloneElement(menu, {
          //       style: menuStyle,
          //     })}
          //     {/* <Button
          //       onClick={() => showDrawer(record.billId)}
          //       style={{ color: "black" }}
          //       type="link"
          //       icon={<EyeOutlined />}
          //     >
          //       View bill
          //     </Button> */}
          //     <Popconfirm
          //       placement="bottomLeft"
          //       title={text}
          //       description={description}
          //       onConfirm={() => deleteBillingStatement(record.billId)}
          //       okText="Yes"
          //       cancelText="Cancel"
          //     >
          //       <Button
          //         style={{ color: "black" }}
          //         type="link"
          //         icon={<DeleteOutlined />}
          //       >
          //         Remove
          //       </Button>
          //     </Popconfirm>
          //   </div>
          // )}
        >
          <Button
            type="primary"
            style={{
              background: "#4d8e03",
            }}
          >
            <Space>
              Action
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
    {
      title: "First name",
      dataIndex: "firstName",
      width: "200px",
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    {
      title: "Last name",
      dataIndex: "lastName",
      width: "200px",
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      width: "100px",
      render: (status) =>
        status === 0 ? (
          <Tag color="yellow">Pending</Tag>
        ) : (
          <Tag color="blue">Deleted</Tag>
        ),
    },
    {
      title: "Total amount",
      dataIndex: "totalAmount",
      width: "200px",
      render: (amount) =>
        amount ? format_PHCurrency(amount) : format_PHCurrency(0),
    },
    {
      title: "Due date",
      dataIndex: "dueDate",
      width: "200px",
      render: (dueDate) => moment(dueDate).format("LL"),
    },
    {
      title: "Date Created",
      dataIndex: "dateCreated",
      width: "200px",
      editable: true,
      defaultSortOrder: "descend",
      sorter: (a, b) => moment(a.dateCreated).diff(moment(b.dateCreated)),
      render: (dateCreated) => moment(dateCreated).format("LL"),
    },
    {
      title: "Property",
      dataIndex: "customName",
      width: "200px",
    },
    {
      title: "Address",
      dataIndex: "address",
      width: "200px",
    },
    {
      title: "Billing date",
      dataIndex: "billingDate",
      width: "200px",
      render: (billingDate) => moment(billingDate).format("LL"),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      width: "200px",
    },
  ];

  const showDrawer = (record) => {
    setVisible(true);
    setSelectedBill(record);
  };

  const onClose = () => {
    setVisible(false);
  };

  const fetchBillingPage = (page, pageSize, status) => {
    dispatch(
      getBillingStatementsPage({
        page: page,
        page_size: pageSize,
        status: status,
        firstName: fName,
        lastName: lName,
        dueDate: dDate,
      })
    );
    setTotalPages(billingStatePage.number_of_pages);
  };

  useEffect(() => {
    if (stat == 1) {
      fetchBillingPage(1, 10, 0);
    }
  }, [stat, fName, lName, dDate]);

  const deleteBillingStatement = (value) => {
    dispatch(
      removeBillingStatement({
        params: value,
        body: {
          accountId: currentUser.accountId,
        },
      })
    );
  };

  useEffect(() => {
    dispatch(getBillings(userToken));
  }, []);

  return (
    <div className="flex flex-col gap-7 ">
      <LoadingTime loading={billingStatePageLoading} />
      {/* <Card className="shadow-md card-search">
        <div className="flex gap-5 w-full">
          <Form
            form={form}
            layout="vertical"
            // disabled={isLoading}
            className="flex flex-col w-full"
            style={{
              gap: "20px",
            }}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinished}
            onFinishFailed={onSearchFailed}
            autoComplete="off"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 w-full">
              <div className="w-full">
                <Form.Item
                  initialValue={""}
                  label={
                    <h1 className="text-[18px] font-[500]">Homeowner ID</h1>
                  }
                  name="homeownerId"
                  rules={[
                    {
                      required: true,
                      message: "Please input homeowner id!",
                    },
                  ]}
                >
                  <Input
                    className="input-gray input-text-gray"
                    placeholder="Enter homeowner id"
                    allowClear
                    style={{
                      width: "90%",
                      padding: "10px",
                      fontSize: "15px",
                    }}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="flex gap-5">
              <Form.Item className="w-full md:w-[140px]">
                <Button
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                    maxWidth: "140px",
                    height: "40px",
                    background: "#4d8e03",
                    border: "1px solid transparent",
                    fontSize: "15px",
                    fontWeight: "500",
                    marginBottom: 0,
                  }}
                  // loading={isLoading}
                  type="primary"
                  htmlType="submit"
                  icon={<SearchOutlined />}
                >
                  Search
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Card> */}
      <Card
        title={
          <div className="flex justify-between w-full items-center p-5">
            <h1 className="font-[600] text-[25px] mb-0">Pending statements</h1>
            {/* <button
              onClick={() => addBill()}
              type="button"
              className="hidden md:block rounded-[10px] text-[18px] max-w-[200px] md:max-w-[173px] w-full h-[52px] border-transparent px-6 py-2.5 bg-[#4d8e03] text-white font-medium  leading-tight uppercase shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
            >
              ADD
            </button>
            <div className="flex items-center md:hidden text-center justify-center">
              <Button
                style={{
                  border: "1px solid transparent",
                  alignItems: "center",
                  display: "flex",
                }}
                type="outlined"
                onClick={() => addBill()}
                icon={
                  <PlusSquareOutlined className="text-2xl h-full items-center flex justify-center" />
                }
              >
                Add
              </Button>
            </div> */}
          </div>
        }
      >
        <Table
          loading={billingStatePageLoading}
          rowKey={"billId"}
          columns={columns}
          dataSource={billingStatePage.data ? billingStatePage.data : []}
          scroll={{
            y: 500,
            x: "100%",
          }}
          pagination={{
            total: billingStatePage.total_items,
            pageSize: billingStatePage.page_size,
            onChange: (page, pageSize) => {
              fetchBillingPage(page, pageSize, 0);
            },
          }}
        />
      </Card>
      <ViewBill open={visible} close={onClose} billId={selectedBill} />
      {/* <BillingForm
        open={openBilling}
        isEditing={isEditing}
        isAdding={isAdding}
        close={closeHandler}
        propertyId={propertyId}
      /> */}
      {/* <SearchHomeowner
        open={openBilling}
        isEditing={isEditing}
        isAdding={isAdding}
        close={closeHandler}
        homeownerId={homeownerId}
      /> */}
    </div>
  );
};
export default Billing;
