import { MenuOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "react-scroll";
import { Properity, properityWhite } from "../../assets/landing/logos";
// import { Properity, properityWhite } from "../assets/logos";
import "./Header.css";
import MenuItems from "./Header/MenuItems";

const Header = ({ menuActive, setMenuActive }) => {
  const { isAuthenticated, designation } = useSelector((state) => state.auth);
  const Nav = [
    {
      name: "Home",
      id: "home",
      offset: -80,
    },
    {
      name: "About",
      id: "about",
      offset: -80,
    },
    {
      name: "Features",
      id: "features",
      offset: -80,
    },
    {
      name: "Pricing",
      id: "pricing",
      offset: -80,
    },
    {
      name: "Contact",
      id: "contact",
      offset: -80,
    },
  ];
  // const [activeLink, setActiveLink] = useState("Home");

  // const handleLinkClick = (name) => {
  // 	setActiveLink(name);
  // };
  const [scrollPosition, setScrollPosition] = useState(0);
  const [activeItem, setActiveItem] = useState("home");
  useEffect(() => {
    const handleScroll = () => {
      const home = document.getElementById("home");
      const about = document.getElementById("about");
      const features = document.getElementById("features");
      const pricing = document.getElementById("pricing");
      const contact = document.getElementById("contact");
      if (
        home.getBoundingClientRect().top <= 90 &&
        about.getBoundingClientRect().top > 90
      ) {
        setActiveItem("home");
      } else if (
        about.getBoundingClientRect().top <= 100 &&
        features.getBoundingClientRect().top > 90
      ) {
        setActiveItem("about");
      } else if (
        features.getBoundingClientRect().top <= 90 &&
        pricing.getBoundingClientRect().top > 90
      ) {
        setActiveItem("features");
      } else if (
        pricing.getBoundingClientRect().top <= 90 &&
        contact.getBoundingClientRect().top > 90
      ) {
        setActiveItem("pricing");
      } else if (contact.getBoundingClientRect().top <= 90) {
        setActiveItem("contact");
      }

      setScrollPosition(window.pageYOffset);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const shouldBlur = scrollPosition > 0;

  const showMenu = () => {
    setMenuActive(!menuActive);
  };
  return (
    <>
      <div
        // className=" fixed-top backdrop-blur-md bg-white/70 flex items-center sm:justify-around justify-start h-[70px]"
        className={
          activeItem !== "home"
            ? `fixed top-0 left-0 right-0 w-full z-10 ${
                shouldBlur ? "backdrop-blur-md bg-white/70" : "bg-transparent"
              } flex items-center md:justify-around justify-start h-[70px]`
            : `fixed top-0 left-0 right-0 w-full z-10 ${
                shouldBlur ? "backdrop-blur-md bg-black/20" : "bg-transparent"
              } flex items-center md:justify-around justify-start h-[70px]`
        }
      >
        {/* <FloatButton.BackTop visibilityHeight={1000} /> */}
        <div>
          <img
            src={activeItem !== "home" ? Properity : properityWhite}
            alt="/"
            onClick={() => window.location.reload()}
            className=" h-[21px] md:ml-0 ml-5 cursor-pointer"
          />
        </div>
        <div></div>
        <div className=" justify-center items-center gap-x-10 md:flex hidden">
          {Nav.map((nav, i) => {
            return (
              <Link
                key={i}
                to={nav.id}
                spy={true}
                smooth={true}
                offset={nav.offset}
                duration={700}
                // activeClass="active-link"
                activeClass={
                  activeItem === "home" ? "active-link" : "not-home-active"
                }
                // className="text-black py-3 px-1 cursor-pointer"
                className={`${
                  activeItem === "home" ? "text-white" : "text-[#57833C]"
                } cursor-pointer py-3 px-1`}

                // onClick={() => handleLinkClick(nav.name)}
              >
                {nav.name}
              </Link>
            );
          })}
          <RouterLink
            to={
              isAuthenticated
                ? designation === "Admin"
                  ? "/Dashboard"
                  : "/Transactions"
                : "/Login"
            }
            className={`no-underline ${
              activeItem === "home"
                ? "text-[#57833C] bg-white"
                : "text-white bg-[#57833C]"
            } cursor-pointer py-1 px-8 rounded-lg`}
          >
            Login
          </RouterLink>
        </div>
        <MenuOutlined
          onClick={showMenu}
          className={
            activeItem !== "home"
              ? "fixed right-6 top-6 scale-150 cursor-pointer text-[#57833C] md:hidden block"
              : "fixed right-6 top-6 scale-150 cursor-pointer text-white md:hidden block"
          }
        />
      </div>
      <MenuItems
        showMenu={showMenu}
        menuActive={menuActive}
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        setMenuActive={setMenuActive}
        shouldBlur={shouldBlur}
        Nav={Nav}
      />
    </>
  );
};

export default Header;
