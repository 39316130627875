import { Button, Checkbox, Form, Input, Card } from "antd";
import pass from "../../assets/resources/images/password.png";
import { useDispatch, useSelector } from "react-redux";
import { forgotPasswordActions } from "../../store/store";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import { useState } from "react";

const Resetpassword = () => {
  const dispatch = useDispatch();
  const { isLoading, uId, token, password } = useSelector(
    (state) => state.generateOTP
  );
  const { resetPassword } = forgotPasswordActions;

  const [newPass, setNewPass] = useState();
  const [confirmNewPass, setConfirmNewPass] = useState();
  const navigate = useNavigate();

  const onFinish = (values) => {
    dispatch(
      resetPassword({
        token: token,
        uId: uId,
        password: values.password,
      })
    );
    navigate("/");
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <div
      className="poppins"
      style={{
        width: "100%",
        height: "100vh",
        padding: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px 0px",
        alignItems: "center",
        background: "#f5f5f5",
      }}
    >
      <Card
        data-aos="fade-left"
        data-aos-easing="ease-in"
        data-aos-duration="600"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          maxWidth: "490px",
          maxHeight: "450px",
          height: "100%",

          borderRadius: "12px",
          boxShadow:
            "1.1px 1.2px 4.5px rgba(0, 0, 0, 0.02), 2.7px 2.8px 10.8px rgba(0, 0, 0, 0.028), 5px 5.3px 20.3px rgba(0, 0, 0, 0.035), 8.9px 9.4px 36.2px rgba(0, 0, 0, 0.042), 16.7px 17.5px 67.7px rgba(0, 0, 0, 0.05), 40px 42px 162px rgba(0, 0, 0, 0.07)",
        }}
        bodyStyle={{
          width: "100%",
        }}
      >
        <h1
          style={{
            fontWeight: "600",
            fontSize: "26px",
            alignItems: "center",
            textAlign: "center",
            fontWeight: "700",
          }}
        >
          Reset Password
        </h1>
        <h4
          style={{
            fontWeight: "600",
            fontSize: "14px",
            alignItems: "center",
            textAlign: "center",
            paddingBottom: "40px",
            fontWeight: "400",
          }}
        >
          Enter your new password
        </h4>
        <Form
          disabled={isLoading}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="password"
            rules={[
              {
                min: 7,
                required: newPass < 7 && newPass > 0,
                message: "Password length too short",
              },
              {
                required: newPass === "",
                message: "Please input your new password",
              },
            ]}
          >
            <Input.Password
              onChange={(e) => setNewPass(e.target.value)}
              style={{
                padding: "12px",
                fontSize: "20px",
                borderRadius: "3px",
              }}
              prefix={
                <img
                  style={{
                    width: "25px",
                  }}
                  src={pass}
                  alt=""
                />
              }
              placeholder="New password"
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="verifyPassword"
            rules={[
              {
                onChange: newPass !== confirmNewPass,
                min: 7,
                required: true,
                message: "Password does not match",
              },
            ]}
          >
            <Input.Password
              onChange={(e) => setConfirmNewPass(e.target.value)}
              style={{
                padding: "12px",
                fontSize: "20px",
                borderRadius: "3px",
              }}
              prefix={
                <img
                  style={{
                    width: "25px",
                  }}
                  src={pass}
                  alt=""
                />
              }
              placeholder="Verify password"
              size="large"
            />
          </Form.Item>
          <Form.Item
            style={{
              margin: "auto",
              width: "100%",
            }}
          >
            <Button
              loading={isLoading}
              type="primary"
              htmlType="submit"
              style={{
                width: "100%",
                height: "62px",
                fontSize: "19px",
                fontWeight: "400",
                borderRadius: "5px",
                background: "#4d8e03",
              }}
            >
              Reset password
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Resetpassword;
