import { React, useEffect, useState } from "react";
import { Badge, Descriptions, Image } from "antd";
import location from "../../location/all.json";
import { convertToBase64 } from "../../helpers/tobase64";

const AssocCheckDetails = ({ information }) => {
  const [setImage, setSetImage] = useState();
  const [barangay, setBarangay] = useState();
  const [region, setRegion] = useState();
  const [province, setProvince] = useState();
  const [city, setCity] = useState();

  useEffect(() => {
    const kahitano = async () => {
      setSetImage(await convertToBase64(information?.logo?.[0]));
    };

    setBarangay(
      location.brgy.filter((obj) => obj.brgyCode === information.brgyId)
    );
    setRegion(
      location.region.filter((obj) => obj.regCode === information.regionId)
    );
    setProvince(
      location.province.filter((obj) => obj.provCode === information.provinceId)
    );
    setCity(location.city.filter((obj) => obj.cityCode === information.cityId));

    kahitano();
  }, [information?.logo?.[0]]);

  return (
    <div
      className="poppins flex flex-col items-center text-center"
      data-aos="fade-left"
      data-aos-easing="ease-in"
      data-aos-duration="600"
    >
      <h1 className="text-[#568634] font-[700] text-[40px]">
        Please review your information
      </h1>
      <p className="text-[25px] font-[500] text-[#568634]">
        Before we proceed, please review and confirm your information.
      </p>
      <div className="w-full py-5 text-left">
        <Descriptions title="" layout="vertical" bordered column={4}>
          <Descriptions.Item label="Logo" span={1}>
            <Image
              style={{
                width: "100px",
                height: "100px",
                objectFit: "cover",
              }}
              src={setImage}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Files" span={1}>
            {information?.fileUrl.map((obj, i) => (
              <p key={i}>{obj.name}</p>
            ))}
          </Descriptions.Item>
          <Descriptions.Item label="Association name" span={1}>
            {information?.assocName}
          </Descriptions.Item>
          <Descriptions.Item label="Acronym" span={1}>
            {information?.assocAcronym}
          </Descriptions.Item>
          <Descriptions.Item label="First name" span={1}>
            {information?.firstName}
          </Descriptions.Item>
          <Descriptions.Item label="Last name" span={1}>
            {information?.lastName}
          </Descriptions.Item>
          <Descriptions.Item label="Email address" span={1}>
            {information?.email}
          </Descriptions.Item>
          <Descriptions.Item label="Mobile number" span={1}>
            +63{information?.mobileNumber}
          </Descriptions.Item>
          <Descriptions.Item label="Region" span={1}>
            {region?.[0]?.regDesc}
          </Descriptions.Item>
          <Descriptions.Item label="Province" span={1}>
            {province?.[0]?.provDesc}
          </Descriptions.Item>
          <Descriptions.Item label="City" span={1}>
            {city?.[0]?.cityDesc}
          </Descriptions.Item>
          <Descriptions.Item label="Barangay" span={1}>
            {barangay?.[0]?.brgyDesc}
          </Descriptions.Item>
        </Descriptions>
      </div>
    </div>
  );
};

export default AssocCheckDetails;
