import { Button, ConfigProvider, Form, Input, InputNumber, Select } from "antd";
import React, { useRef } from "react";
import { regExp_MobileNumber } from "../../../helpers/regex"; //ok na

const ContactForm = () => {
  const getOption = (value, label) => {
    return {
      label,
      value,
    };
  };
  const options = [
    getOption("generalInquiry", "General Inquiry"),
    getOption("support", "Support"),
    getOption("customSolutions", "Inquiry for custom solutions"),
  ];

  const onFinish = (values) => {
    console.log(values);
  };

  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const mobileNumberRef = useRef(null);
  const emailAddressRef = useRef(null);
  const inquiryTypeRef = useRef(null);
  const messageRef = useRef(null);

  const fieldRefs = {
    firstName: firstNameRef,
    lastName: lastNameRef,
    emailAddress: emailAddressRef,
    mobileNumber: mobileNumberRef,
    inquiryType: inquiryTypeRef,
    message: messageRef,
  };
  const onFinishFailed = ({ errorFields }) => {
    errorFields.reverse().forEach(({ name }) => {
      const fieldName = name[0];
      const ref = fieldRefs[fieldName];
      if (ref) {
        ref.current.focus();
      }
    });
  };
  return (
    <div className=" w-full">
      <ConfigProvider
        theme={{
          token: {
            colorBgBase: "white",
            colorPrimary: " #4D8E03",
            colorPrimaryBg: "white",
            // colorPrimaryActive: "white",
            // controlItemBgActive: "white",
          },
        }}
      >
        <Form
          className=" max-w-[700px] col-span-2 row-span-1"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            className=""
            name="firstName"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please enter First name",
              },
            ]}
          >
            <Input
              size="large"
              className=" w-full py-3"
              placeholder="First name"
              ref={firstNameRef}
            />
          </Form.Item>
          <Form.Item
            className=""
            name="lastName"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please enter Last name",
              },
            ]}
          >
            <Input
              size="large"
              className=" w-full py-3"
              placeholder="Last name"
              ref={lastNameRef}
            />
          </Form.Item>
          <Form.Item
            className=""
            name="emailAddress"
            hasFeedback
            rules={[
              {
                required: true,

                message: "Please input E-mail address",
              },
              {
                type: "email",
                message: "This is not valid E-mail address",
              },
            ]}
          >
            <Input
              size="large"
              className=" w-full py-3"
              placeholder="Email"
              ref={emailAddressRef}
            />
          </Form.Item>
          <Form.Item
            className=""
            name="mobileNumber"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please input Mobile number",
              },
              {
                validator: (rule, value) => {
                  return new Promise((resolve, reject) => {
                    if (value && !regExp_MobileNumber.test(value)) {
                      reject("This is not valid Mobile number");
                    } else {
                      resolve();
                    }
                  });
                },
              },
            ]}
          >
            <InputNumber
              size="large"
              addonBefore="+63"
              parser={(value) => value.replace(/\D/g, "")}
              maxLength={10}
              minLength={10}
              min={0}
              controls={false}
              className=" w-full py-1"
              placeholder="Mobile Number ex: 9XX-XXX-XXXX"
              ref={mobileNumberRef}
            />
          </Form.Item>
          <Form.Item
            className=""
            name="inquiryType"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please select Inquiry type",
              },
            ]}
          >
            <Select
              ref={inquiryTypeRef}
              options={options}
              placeholder="Inquiry Type"
              className=" w-full"
              size="large"
            />
          </Form.Item>
          <Form.Item
            className=""
            name="message"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please type your message",
              },
            ]}
          >
            <Input.TextArea
              size="large"
              rows={6}
              className=" w-full "
              placeholder="Your message"
              ref={messageRef}
            />
          </Form.Item>
          <Form.Item>
            <Button
              className=" w-full h-12 rounded-lg"
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </ConfigProvider>
    </div>
  );
};

export default ContactForm;
