import { call, put, takeLatest, select } from "redux-saga/effects";
import { filterHomeowners } from "../../api/homeowner/filter-homeowner-api";
import {
  acceptHomeowner,
  addToMasterlist,
  getHomeowner,
  getHomeowners,
  getMasterlist,
} from "../../api/homeowner/homeowner-api";
import { updateHomeownerStatus } from "../../api/homeowner/homeowner-update-status-api";
import { homeownersActions } from "../../store";
import { decode } from "html-entities";
///
///

function* getHomeonwersRequest() {
  const { requestError, getHomeownersSuccess } = homeownersActions;
  const result = yield call(getHomeowners);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getHomeownersSuccess(result.data));
  }
}

function* acceptHomeownerRequest({ payload }) {
  const { requestError, acceptHomeownerSuccess, getMasterlist } =
    homeownersActions;
  const result = yield call(acceptHomeowner, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(acceptHomeownerSuccess(result.data));
    yield put(getMasterlist());
  }
}

function* getHomeownerRequest({ payload }) {
  const { requestError, getHomeownerSuccess } = homeownersActions;
  const result = yield call(getHomeowner, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getHomeownerSuccess(result.data));
  }
}

function* getMasterlistRequest() {
  const { requestError, getMasterlistSuccess } = homeownersActions;
  const result = yield call(getMasterlist);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getMasterlistSuccess(result.data));
  }
}

const data = (i, applicantsCount) => {
  const x = {
    firstName: applicantsCount[i]["First name"],
    middleName: applicantsCount[i]["Middle name"],
    lastName: applicantsCount[i]["Last name"],
    address: applicantsCount[i]["Address"],
    mobileNumber: applicantsCount[i]["Mobile number"],
    email: applicantsCount[i]["Email"],
  };

  return x;
};

function* addToMasterlistRequest({ payload }) {
  const {
    addToMasterlistSuccess,
    getMasterlist,
    errorEntries,
    successfulEntries,
    addToMasterlistError,
  } = homeownersActions;

  const { applicantsCount } = yield select((state) => state.homeowners);
  console.log(applicantsCount);

  for (let i = 0; i < applicantsCount?.length; i++) {
    const result = yield call(addToMasterlist, data(i, applicantsCount));

    if (result.name === "AxiosError") {
      yield put(addToMasterlistError(result.response));
      yield put(errorEntries(data(i, applicantsCount)));
    } else {
      yield put(addToMasterlistSuccess(result.data));
      yield put(successfulEntries(data(i, applicantsCount), result.response));
      yield put(getMasterlist());
    }
  }
}

function* filterHomeownerRequest({ payload }) {
  const { getFilteredHomeownerSuccess, requestError } = homeownersActions;

  const result = yield call(filterHomeowners, payload);
  console.log(payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    console.log(result.data);
    yield put(getFilteredHomeownerSuccess(result.data));
  }
}

function* updateStatusRequest({ payload }) {
  const { updateStatusSuccess, requestError, getHomeowners } =
    homeownersActions;

  const result = yield call(updateHomeownerStatus, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    console.log(result.data);
    yield put(updateStatusSuccess(result.data));
    yield put(getHomeowners());
  }
}

// Export the saga (todo-saga)
export default function* homeownersSaga() {
  yield takeLatest(`homeowners/acceptHomeowner`, acceptHomeownerRequest);
  yield takeLatest(`homeowners/addToMasterlist`, addToMasterlistRequest);
  yield takeLatest(`homeowners/getMasterlist`, getMasterlistRequest);
  yield takeLatest(`homeowners/getHomeowner`, getHomeownerRequest);
  yield takeLatest(`homeowners/getHomeowners`, getHomeonwersRequest);
  yield takeLatest(`homeowners/getFilteredHomeowner`, filterHomeownerRequest);
  yield takeLatest(`homeowners/updateStatus`, updateStatusRequest);
}
