import React, { useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.js`;

const MyDocument = ({ file }) => {
  const [pageCount, setPageCount] = React.useState(0);

  return (
    <div style={{ height: "50px", width: "50px" }}>
      <Document
        file={file}
        onLoadError={(err) => console.log("onload err", err)}
        options={
          {
            // workerSrc: " /pdf.worker.js"
            // standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`
          }
        }
        style={{ height: "50px", width: "50px" }}
        onLoadSuccess={({ numPages }) => {
          setPageCount(numPages);
          console.log("onLoadSuccess");
        }}
      >
        {Array.from(new Array(pageCount), (el, index) => (
          <Page
            key={index}
            pageNumber={index + 1}
            // height={90}
            // scale={0.8}
            renderAnnotationLayer={false}
            renderTextLayer={false}
          />
        ))}
        {/* <Page
          renderInteractiveForms={true}
          key={0}
          pageNumber={1}
          renderTextLayer={false}
        /> */}
      </Document>
    </div>
  );
};

export default MyDocument;
