import { defaultAxios, axiosMultipart, token } from "../axios";

const baseApi = "/api/associations/services/";

defaultAxios.interceptors.request.use(async function (config) {
  config.headers["Authorization"] = `Bearer ${
    JSON.parse(await localStorage.getItem("persist:auth")).token.split(`"`)[1]
  }`;
  return config;
});

axiosMultipart.interceptors.request.use(async function (config) {
  config.headers["Authorization"] = `Bearer ${
    JSON.parse(await localStorage.getItem("persist:auth")).token.split(`"`)[1]
  }`;
  return config;
});

// export const getBillingStatements = async () => {
//   try {
//     const result = await defaultAxios.get(baseApi + "getBillingStatements");
//     console.log(result);
//     return result;
//   } catch (err) {
//     console.log(err);
//     return err;
//   }
// };

export const getBillingStatements = async (body) => {
  try {
    const result = await token.get(baseApi + "getBillingStatements", {
      headers: {
        Authorization: "Bearer " + body,
      },
    });
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getBillingStatementsPage = async (body) => {
  try {
    const result = await defaultAxios.post(
      baseApi + "getBillingStatementsPage",
      body
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getBillingStatement = async ({ params }) => {
  try {
    const result = await defaultAxios.get(
      baseApi + "getBillingStatement/" + params
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getPreviousBillingStatement = async (data) => {
  try {
    const result = await defaultAxios.get(
      baseApi + "getPreviousBillingStatement/" + data.params,
      data.body
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const removeBillingStatement = async ({ params }) => {
  try {
    const result = await defaultAxios.post(
      baseApi + "removeBillingStatement/" + params
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const createBillingStatement = async (body) => {
  try {
    const result = await defaultAxios.post(
      baseApi + "createBillingStatement",
      body
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const updateBillingStatement = async (data) => {
  try {
    const result = await defaultAxios.post(
      baseApi + "updateBillingStatement/" + data.params,
      data.body
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const addBillingStatementCharges = async (body) => {
  try {
    const result = await defaultAxios.post(
      baseApi + "addBillingStatementCharges",
      body
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const updateBillingStatementCharge = async (body) => {
  try {
    const result = await defaultAxios.post(
      baseApi + "updateBillingStatementCharge",
      body
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const removeBillingStatementCharge = async (body) => {
  try {
    const result = await defaultAxios.post(
      baseApi + "removeBillingStatementCharge",
      body
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const addBillingStatementAttachments = async (body) => {
  try {
    const result = await axiosMultipart.post(
      baseApi + "addBillingStatementAttachments",
      body.formData
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const updateBillingStatementAttachment = async (data) => {
  try {
    const result = await axiosMultipart.post(
      baseApi + "updateBillingStatementAttachment/" + data.params,
      data.body.formData
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getAssociationCharges = async () => {
  try {
    const result = await defaultAxios.get(baseApi + "getAssociationCharges");
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const addAssociationCharge = async (body) => {
  try {
    const result = await defaultAxios.post(
      baseApi + "addAssociationCharge",
      body
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const removeAssociationCharge = async ({ params }) => {
  try {
    const result = await defaultAxios.post(
      baseApi + "removeAssociationCharge/" + params
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const removeBillingStatementAttachment = async ({ params }) => {
  try {
    const result = await defaultAxios.post(
      baseApi + "removeBillingStatementAttachment/" + params
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const updateAssociationCharge = async (data) => {
  try {
    const result = await defaultAxios.post(
      baseApi + "updateAssociationCharge/" + data.params,
      data.body
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const createBillingStatementWithCharges = async (body) => {
  try {
    const result = await axiosMultipart.post(
      baseApi + "createBillingStatementWithCharges",
      body.formData
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};
