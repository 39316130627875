import {
  Divider,
  Skeleton,
  Calendar,
  theme,
  Badge,
  Select,
  Image,
  Statistic,
} from "antd";
import { ExpandAltOutlined, ShrinkOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getActivities,
  getDailyTransactionsCount,
  getHomeownersCount,
  getInquiriesCount,
  getOverdueTransactions,
  getReportsCount,
  getResidentsCount,
  getRevenues,
  getYearlyTransactionsCount,
} from "../../../store/slices/dashboard/users-count-slice";
import Revenue from "../dashboard/Revenue";
import { format_PHCurrency } from "../../../helpers/formatCurrency";
import Overdues from "./Overdues";
import Donut from "./Donut";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import CurrentEvents from "./currentevents/CurrentEvents";
import UpcomingEvents from "./upcomingevents/UpcomingEvents";
import PastEvents from "./pastevents/PastEvents";
import { truncateString } from "../../../helpers/TruncateString";
import "./dashboard.css";
import {
  setActiveKey,
  setActiveKeyReport,
} from "../../../store/slices/inquiries/inquiries-slice";
import CountUp from "react-countup";
import graph from "../../../assets/resources/images/diagram.png";
import overdue from "../../../assets/resources/images/danger.png";
import daily from "../../../assets/resources/images/emptywallet.png";
import Users from "./users/Users";
import { home } from "../../../assets/resources/icons";
import Tasks from "./tasks/Tasks";
import { Events } from "react-scroll";

const Dashboard = () => {
  const dispatch = useDispatch();
  const {
    residents,
    homeowners,
    inquiries,
    dailyTransactions,
    overdueTransaction,
    yearlyTransactions,
    reports,
    revenues,
    activities,
    activitiesLoading,
    revenuesLoading,
    reportsLoading,
    yearlyTransactionsLoading,
    overdueTransactionLoading,
    dailyTransactionsLoading,
    inquiriesLoading,
    residentsLoading,
    homeownersLoading,
  } = useSelector((state) => state.users);
  const { token } = useSelector((state) => state.auth);

  const [open, setOpen] = useState(false);
  const [openCurrentEvents, setOpenCurrentEvents] = useState(false);
  const [openUpcomingEvents, setOpenUpcomingEvents] = useState(false);
  const [openPastEvents, setOpenPastEvents] = useState(false);
  const [expandTask, setExpandTask] = useState(false);
  const [filterTransacVal, setFilterTransacVal] = useState(1);
  const navigate = useNavigate();

  const formatter = (value) => (
    <CountUp end={value} separator="," decimals={2} prefix="₱ " />
  );

  useEffect(() => {
    dispatch(getResidentsCount(token));
    dispatch(getHomeownersCount(token));
    dispatch(getInquiriesCount(token));
    dispatch(getDailyTransactionsCount(token));
    dispatch(getOverdueTransactions(token));
    dispatch(getReportsCount(token));
    dispatch(getRevenues(token));
    dispatch(getActivities(token));
  }, []);

  useEffect(() => {
    filterTransacVal === 1
      ? dispatch(getYearlyTransactionsCount(token))
      : dispatch(getDailyTransactionsCount(token));
  }, [filterTransacVal]);

  const overduesBreakdownHandler = () => {
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  const closeCurrentEvents = () => {
    setOpenCurrentEvents(false);
  };

  const closeUpcomingEvents = () => {
    setOpenUpcomingEvents(false);
  };

  const closePastEvents = () => {
    setOpenPastEvents(false);
  };

  return (
    <div className="grid grid-cols-4 grid-rows-3 gap-3 h-full">
      <div className="col-span-2 bg-white shadow-lg rounded-[10px]">
        <div className="w-full h-full p-5">
          <div className="flex-col flex">
            <span className="font-semibold text-lg">Revenue</span>
            <Divider />
            <div className="grid grid-cols-3 gap-3 items-center">
              <div className="flex flex-col gap-10">
                <div className="flex flex-col gap-3">
                  <Skeleton
                    active={revenuesLoading}
                    loading={revenuesLoading}
                    paragraph={{ rows: 1 }}
                  >
                    <div className="flex flex-col">
                      <div className=""> Last month</div>
                      <Statistic
                        className="font-bold text-2xl"
                        value={revenues.pastMonth ? revenues.pastMonth : 0}
                        formatter={formatter}
                      />
                      {/* <span className="font-bold text-2xl">
                        {format_PHCurrency(+revenues.pastMonth)}
                      </span> */}
                    </div>
                  </Skeleton>
                  <Skeleton
                    active={revenuesLoading}
                    loading={revenuesLoading}
                    paragraph={{ rows: 1 }}
                  >
                    <div className="flex flex-col">
                      <div className=""> This month</div>
                      <Statistic
                        className="font-bold text-2xl"
                        value={
                          revenues.currentMonth ? revenues.currentMonth : 0
                        }
                        formatter={formatter}
                      />
                      {/* <span className="font-bold text-2xl">
                        {format_PHCurrency(+revenues.currentMonth)}
                      </span> */}
                    </div>
                  </Skeleton>
                </div>
              </div>
              <div className="col-span-2 h-full">
                <Revenue />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Users
        homeownersLoading={homeownersLoading}
        residentsLoading={residentsLoading}
        homeowners={homeowners}
        residents={residents}
      />
      {/* ACTIVITIES */}
      <div className="row-span-3 grid h-full gap-3">
        <div
          className={`bg-white shadow-lg rounded-[10px] ${
            !expandTask ? "row-span-2" : "hidden"
          } transition-all ease-out duration-150`}
        >
          <div className="w-full h-full p-5">
            <div className="flex-col flex">
              <div className="flex flex-col gap-3">
                <div className="flex flex-col gap-2">
                  <div className="flex flex-row justify-between items-center">
                    <span className="text-lg font-semibold">
                      Current Events
                    </span>
                    <span
                      className={
                        activities?.current?.length > 0
                          ? "text-blue-600 cursor-pointer hover:text-blue-400 duration-150 transition-all ease-out"
                          : "hidden"
                      }
                      onClick={() => setOpenCurrentEvents(true)}
                    >
                      View all
                    </span>
                  </div>
                  <div className="flex bg-[#FF7B50] w-full h-[85px] rounded-lg p-3 items-center hover:scale-[1.05] duration-150 hover:shadow-lg">
                    <Skeleton
                      paragraph={{ rows: 1 }}
                      active={activitiesLoading}
                      loading={activitiesLoading}
                    >
                      {activities?.current?.length > 0 ? (
                        <div className="flex flex-row items-center gap-3">
                          <div className="bg-[#FF9471] w-[60px] h-[60px] rounded-lg flex gap-3 items-center justify-center">
                            <div className="flex flex-col">
                              <p className="text-white text-sm">
                                {moment(
                                  activities?.current[0]?.dateFrom
                                ).format("MMM")}
                              </p>
                              <p className="text-white font-semibold text-2xl text-center">
                                {moment(
                                  activities?.current[0]?.dateFrom
                                ).format("Do")}
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col ">
                            <span className="text-white">
                              {activities?.current[0]?.title}
                            </span>
                            <span className="text-white text-xs font-light">
                              Description:&nbsp;
                              {truncateString(
                                activities?.current[0]?.description,
                                20
                              )}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div className="text-white flex items-center justify-center text-center">
                          <p className="text-center">No event listed</p>
                        </div>
                      )}
                    </Skeleton>
                  </div>
                  <CurrentEvents
                    open={openCurrentEvents}
                    close={closeCurrentEvents}
                    events={activities?.current ? activities?.current : []}
                    loading={activitiesLoading}
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex flex-row justify-between items-center">
                    <span className="text-lg font-semibold">
                      Upcoming Events
                    </span>
                    <span
                      className={
                        activities?.upcoming?.length > 0
                          ? "text-blue-600 cursor-pointer hover:text-blue-400 duration-150 transition-all ease-out"
                          : "hidden"
                      }
                      onClick={() => setOpenUpcomingEvents(true)}
                    >
                      View all
                    </span>
                  </div>
                  <div className="flex bg-[#6758F3] w-full h-[85px] rounded-lg p-3 items-center hover:scale-[1.05] duration-150 hover:shadow-lg">
                    <Skeleton
                      paragraph={{ rows: 1 }}
                      active={activitiesLoading}
                      loading={activitiesLoading}
                    >
                      {activities?.upcoming?.length > 0 ? (
                        <div className="flex flex-row items-center gap-3">
                          <div className="bg-[#6961FF] w-[60px] h-[60px] rounded-lg flex gap-3 items-center justify-center">
                            <div className="flex flex-col">
                              <p className="text-white text-sm">
                                {moment(
                                  activities?.upcoming[0]?.dateFrom
                                ).format("MMM")}
                              </p>
                              <p className="text-white font-semibold text-2xl text-center">
                                {moment(
                                  activities?.upcoming[0]?.dateFrom
                                ).format("Do")}
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col">
                            <span className="text-white">
                              {activities?.upcoming[0]?.title}
                            </span>
                            <span className="text-white text-xs font-light">
                              Description:&nbsp;
                              {truncateString(
                                activities?.upcoming[0]?.description,
                                20
                              )}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div className="text-white flex items-center justify-center text-center">
                          <p className="text-center">No event listed</p>
                        </div>
                      )}
                    </Skeleton>
                  </div>
                  <UpcomingEvents
                    open={openUpcomingEvents}
                    close={closeUpcomingEvents}
                    events={activities?.upcoming ? activities?.upcoming : []}
                    loading={activitiesLoading}
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex flex-row justify-between items-center">
                    <span className="text-lg font-semibold">Past Events</span>
                    <span
                      className={
                        activities?.past?.length > 0
                          ? "text-blue-600 cursor-pointer hover:text-blue-400 duration-150 transition-all ease-out"
                          : "hidden"
                      }
                      onClick={() => setOpenPastEvents(true)}
                    >
                      View all
                    </span>
                  </div>
                  <div className="flex bg-gray-500 w-full h-[85px] rounded-lg p-3 items-center hover:scale-[1.05] duration-150 hover:shadow-lg">
                    <Skeleton
                      paragraph={{ rows: 1 }}
                      active={activitiesLoading}
                      loading={activitiesLoading}
                    >
                      {activities?.past?.length > 0 ? (
                        <div className="flex flex-row items-center gap-3">
                          <div className="bg-gray-400 w-[60px] h-[60px] rounded-lg flex gap-3 items-center justify-center">
                            <div className="flex flex-col">
                              <p className="text-white text-sm">
                                {moment(activities?.past[0]?.dateFrom).format(
                                  "MMM"
                                )}
                              </p>
                              <p className="text-white font-semibold text-2xl text-center">
                                {moment(activities?.past[0]?.dateFrom).format(
                                  "Do"
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col  items-center">
                            <div className="flex flex-col">
                              <span className="text-white">
                                {activities?.past[0]?.title}
                              </span>
                              <span className="text-white text-xs font-light">
                                Description:&nbsp;
                                {truncateString(
                                  activities?.past[0]?.description,
                                  20
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="text-white flex items-center justify-center text-center">
                          <p className="text-center">No event listed</p>
                        </div>
                      )}
                    </Skeleton>
                  </div>
                  <PastEvents
                    open={openPastEvents}
                    close={closePastEvents}
                    events={activities?.past ? activities?.past : []}
                    loading={activitiesLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`bg-white shadow-lg rounded-[10px] ${
            expandTask ? " row-span-4" : "row-span-2"
          }`}
        >
          <Tasks
            reports={reports}
            inquiries={inquiries}
            expandTask={expandTask}
            setExpandTask={() => setExpandTask(!expandTask)}
          />
        </div>
      </div>
      {/* ACTIVITIES */}
      {/* OVERALL TRANSACTIONS */}
      <div className="col-span-2 row-span-2 bg-white shadow-lg rounded-[10px]">
        <div className="w-full h-full p-5">
          <div className="flex-col flex">
            <div className="flex justify-between items-center">
              <span className="font-semibold text-lg">
                Overall Transactions
              </span>
            </div>
            <Divider />
            <Donut />
          </div>
        </div>
      </div>
      {/* OVERALL TRANSACTIONS */}
      <div className="grid gap-3 row-span-2">
        {/* TRANSACTIONS */}
        <div
          className="bg-white shadow-lg rounded-[10px] hover:scale-[1.03] hover:shadow-2xl duration-150 cursor-pointer"
          onClick={() => navigate("/Transactions")}
        >
          <div className="w-full h-full p-5">
            <div className="flex-col flex">
              <div className="flex justify-between items-center">
                <span className="font-semibold text-lg">Transactions</span>
              </div>
              <Divider />
              <div className="flex flex-row items-center justify-between">
                <Skeleton
                  paragraph={{ rows: 1 }}
                  active={yearlyTransactionsLoading}
                  loading={yearlyTransactionsLoading}
                >
                  <div className="flex flex-col">
                    <div className="">Total no. of transactions this year</div>
                    <span className="font-bold text-2xl">
                      {yearlyTransactions
                        ? yearlyTransactions.reduce((a, b) => a + b.count, 0)
                        : 0}
                    </span>
                  </div>
                  <Image
                    preview={false}
                    style={{
                      width: "60px",
                      height: "60px",
                      objectFit: "cover",
                    }}
                    src={graph}
                  />
                </Skeleton>
              </div>
              <Divider />
              <div className="flex flex-row items-center justify-between">
                <Skeleton
                  paragraph={{ rows: 1 }}
                  active={dailyTransactionsLoading}
                  loading={dailyTransactionsLoading}
                >
                  <div className="flex flex-col">
                    <div className="">Total no. of transactions daily</div>
                    <span className="font-bold text-2xl">
                      {dailyTransactions ? dailyTransactions.count : 0}
                    </span>
                  </div>
                  <Image
                    preview={false}
                    style={{
                      width: "60px",
                      height: "60px",
                      objectFit: "cover",
                    }}
                    src={daily}
                  />
                </Skeleton>
              </div>
            </div>
          </div>
        </div>
        {/* TRANSACTIONS */}
        {/* TRANSACTION OVERDUES */}
        <div
          className="bg-white shadow-lg rounded-[10px] hover:scale-[1.03] hover:shadow-2xl duration-150 cursor-pointer"
          onClick={() => overduesBreakdownHandler()}
        >
          <div className="w-full h-full p-5">
            <div className="flex-col flex">
              <span className="font-semibold text-lg">
                Transactions Overdues
              </span>
              <Divider />
              <div className="flex flex-row items-center justify-between">
                <Skeleton
                  paragraph={{ rows: 1 }}
                  active={overdueTransactionLoading}
                  loading={overdueTransactionLoading}
                >
                  <div className="flex flex-col">
                    <div className="col-span-2">Total overdues</div>
                    <Statistic
                      className="font-bold text-2xl"
                      value={
                        overdueTransaction
                          ? overdueTransaction.reduce(
                              (a, b) => +a + +b.totalAmount,
                              0
                            )
                          : 0
                      }
                      formatter={formatter}
                    />
                    {/* <span
                      className="font-bold text-2xl"
                      formatter={formatter}
                    ></span> */}
                  </div>
                  <Image
                    preview={false}
                    style={{
                      width: "60px",
                      height: "60px",
                      objectFit: "cover",
                    }}
                    src={overdue}
                  />
                </Skeleton>
              </div>
            </div>
          </div>
        </div>
        <Overdues
          open={open}
          close={closeDrawer}
          overdue={overdueTransaction}
          loading={overdueTransactionLoading}
        />
        {/* TRANSACTION OVERDUES */}
      </div>
    </div>
  );
};

export default Dashboard;
