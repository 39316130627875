import { ArrowLeftOutlined, PlusOutlined } from "@ant-design/icons";
import { Form, Modal, Upload, Input } from "antd";
import ImgCrop from "antd-img-crop";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { dummyRequest } from "../../../../constants/staticConst";
import { convertToBase64 } from "../../../../helpers/tobase64";
import {
  addBillingStatementAttachments,
  updateBillingStatementAttachment,
} from "../../../../store/slices/billing/billing-slice";

const { Dragger } = Upload;
const AttachmentForm = ({
  isEditing,
  isAdding,
  found,
  open,
  close,
  billId,
  chargeId,
  attachmentId,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [imagePrev, setImagePrev] = useState("");
  const [fileList, setFileList] = useState([]);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await convertToBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChangeAttachment = ({ fileList: newFileList }) => {
    setFileList(newFileList);

    form.setFieldsValue({
      imgUrl: fileList?.map((file) => file?.originFileObj) || null,
    });
  };

  const uploadButton = (
    <div className="w-full min-w-[100px]">
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload new
      </div>
    </div>
  );

  const onFinishFailed = (values) => {};

  useEffect(() => {
    const desc = found?.attachments
      ?.filter((obj, i) => obj?.attachmentId === attachmentId)
      .map((obj, i) => obj?.description);

    if (isEditing) {
      form.setFieldsValue({
        ...found,
        description: desc,
      });
    } else {
      setFileList([]);
      form.resetFields();
    }
  }, [attachmentId, isEditing]);

  const onFinish = (values) => {
    let formData = new FormData();

    formData.append("billId", billId);
    formData.append("chargeId", chargeId);
    formData.append(
      "description",
      values?.description ? values?.description : "-"
    );

    for (let index = 0; index < values?.imgUrl?.length; index++) {
      formData.append(
        "image",
        values?.imgUrl === "" ? null : values?.imgUrl[index]
      );
    }

    if (!isEditing) {
      dispatch(addBillingStatementAttachments({ formData }));
    } else {
      dispatch(
        updateBillingStatementAttachment({
          params: attachmentId,
          body: {
            formData,
            billId: billId,
          },
        })
      );
    }
    // dispatch(
    //   updateAnnouncementAttachment({
    //     params: attachmentId,
    //     body: { formData },
    //   })
    // );

    setImagePrev("");
    setPreviewTitle("");
    close(true);
    setFileList([]);
  };

  const closeEditAttachmentImg = () => {
    close(true);
    setImagePrev("");
    setPreviewTitle("");
    setFileList([]);
  };

  return (
    <Modal
      title={
        <div className="flex md:flex-row md:items-center items-start justify-start">
          <div
            onClick={closeEditAttachmentImg}
            className="w-[50px] h-[50px] items-center text-center flex justify-center text-[20px] rounded-full hover:bg-slate-200 transition-all ease-in duration-150 cursor-pointer hover:scale-110"
          >
            <ArrowLeftOutlined className="text-[#1b1b1b]" />
          </div>
          <h1 className="text-[24px] md:text-[24px] font-[600] text-[#1b1b1b] mb-0 p-3">
            {isEditing ? "Edit attachment" : "Add attachment"}
          </h1>
        </div>
      }
      closable={false}
      onClose={closeEditAttachmentImg}
      open={open}
      onCancel={closeEditAttachmentImg}
      onOk={form.submit}
      className="demo w-full"
    >
      <div className="h-full w-full flex flex-col">
        <div className="flex flex-wrap gap-3 items-center p-3">
          {found?.attachments
            ?.filter((obj, i) => obj?.attachmentId === attachmentId)
            .map((obj, i) => (
              <div key={i}>
                <div className="w-[100px] relative">
                  <img
                    className={
                      isEditing
                        ? "w-[100px] h-[100px] object-cover relative"
                        : "hidden"
                    }
                    alt="example"
                    src={process.env.REACT_APP_BASE_URL + "/" + obj?.imageUrl}
                  />
                </div>
              </div>
            ))}
        </div>
        <Form
          form={form}
          name="add"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <div className="flex flex-col gap-10 items-center md:items-start p-3">
            <div className="flex-1 h-[278px] ">
              <Form.Item
                valuePropName="fileList"
                name="imgUrl"
                className="max-w-[250px]"
              >
                <>
                  <Upload
                    name="file"
                    customRequest={dummyRequest}
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChangeAttachment}
                  >
                    {uploadButton}
                  </Upload>
                </>
              </Form.Item>
              <Form.Item
                value=""
                name="description"
                label={
                  <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
                    Description
                  </h1>
                }
              >
                <Input
                  style={{
                    padding: "10px",
                    fontSize: "18px",
                    borderRadius: "3px",
                  }}
                  placeholder="Enter description"
                  size="large"
                />
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default AttachmentForm;
