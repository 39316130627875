import React from "react";
import { Tabs } from "antd";
import "../properties/property.css";

import PendingProperties from "./registered-properties/PendingProperties";
import ApprovedProperties from "./registered-properties/ApprovedProperties";
import RejectedProperties from "./registered-properties/RejectedProperties";

const items = [
  { label: "Pending", key: "item-1", children: <PendingProperties /> }, // remember to pass the key prop
  { label: "Approved", key: "item-2", children: <ApprovedProperties /> },
  { label: "Rejected", key: "item-3", children: <RejectedProperties /> },
];

const Properties = () => (
  <Tabs
    defaultActiveKey="item-1"
    type="card"
    className="tabs-inquiries"
    animated={true}
    items={items}
  ></Tabs>
);
export default Properties;
