import { defaultAxios, axiosMultipart, token } from "../axios";

const baseApi = "/api/associations/notifications/";

defaultAxios.interceptors.request.use(async function (config) {
  config.headers["Authorization"] = `Bearer ${
    JSON.parse(await localStorage.getItem("persist:auth")).token.split(`"`)[1]
  }`;
  return config;
});

export const pushNotification = async (body) => {
  try {
    const result = await defaultAxios.post(baseApi + "pushNotification", body);
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const pushAllNotification = async (body) => {
  try {
    const result = await defaultAxios.post(
      baseApi + "pushAllNotification",
      body
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};
