import defaultAxios from "axios";

const axios = defaultAxios.create({
  baseURL: process.env.REACT_APP_BASE_URL + `/api/associations/homeowner/`,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

// const axios2 = defaultAxios.create({
//   baseURL: process.env.REACT_APP_BASE_URL + `/api/teacher/profile/`,
//   headers: {
//     "Content-Type": "application/json",
//     "Access-Control-Allow-Origin": "*",
//   },
// });

// export const getProfile = async (token) => {
//   console.log(token);
//   try {
//     const result = await axios2.get("getProfile", {
//       headers: {
//         Authorization: "Bearer " + (token?.payload ? token.payload : token),
//       },
//     });
//     console.log(result);
//     return result;
//   } catch (err) {
//     console.log(err);
//     return err;
//   }
// };

axios.interceptors.request.use(async function (config) {
  config.headers["Authorization"] = `Bearer ${
    JSON.parse(await localStorage.getItem("persist:auth")).token.split(`"`)[1]
  }`;
  return config;
});

export const updateHomeownerProfile = async (data) => {
  try {
    const result = await axios.post(
      "updateHomeownerProfile?homeownerId=" + data.params,
      data.body
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};
