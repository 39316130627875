import React from "react";

const Privacy = () => {
  return (
    <div className="flex flex-col gap-5">
      <h1 className="md:text-[28px] text-[16px] font-semibold">Introduction</h1>
      <p className="text-gray-600 text-justify text-[14px] md:text-[18px]">
        We at Properity value your privacy as much as your trust and we are
        committed to protecting and safeguarding any ''personal'' information or
        data that you provide. In line with this, we have developed this
        “Privacy Policy” in accordance to the Data Privacy Act of 2012 or R.A.
        10173 in order for you to see and understand how we collect, transfer,
        communicate, store, disclose and make use of your Personal Data.
      </p>
      <p className="text-gray-600 text-justify text-[14px] md:text-[18px]">
        We assure you that all changes or revisions of our Privacy Policy will
        always be readily available for you via this page and your continued use
        of our products and services means that you acknowledge and accept such
        changes made. We may revise this policy and terms from time to time
        without any prior notice to the public.
      </p>
      <p className="text-gray-600 text-justify text-[14px] md:text-[18px]">
        We also ensure you that this privacy policy strong holds that the
        confidentiality of your provided data is protected and maintained.
      </p>
    </div>
  );
};

export default Privacy;
