import React from "react";
import { Tabs } from "antd";
import "../directories.css";
import DirectoriesTab from "./DirectoriesTab";
import DirectoriesTypesTab from "./DirectoriesTypesTab";

const items = [
  { label: "Directories", key: "item-1", children: <DirectoriesTab /> }, // remember to pass the key prop
  {
    label: "Directories Types",
    key: "item-2",
    children: <DirectoriesTypesTab />,
  },
];

const Directories = () => (
  <Tabs
    defaultActiveKey="item-1"
    type="card"
    className="tabs-directories"
    animated={true}
    items={items}
  ></Tabs>
);
export default Directories;
