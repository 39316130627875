import { Card, Table, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format_PHCurrency } from "../../../helpers/formatCurrency";
import { getBillingStatementsPage } from "../../../store/slices/billing/billing-slice";
import "../properties/property.css";

const Removed = ({ arr, stat, fName, lName, dDate }) => {
  const [totalPages, setTotalPages] = useState();
  const { billingStatePage, billingStatePageLoading } = useSelector(
    (state) => state.billing
  );
  const dispatch = useDispatch();

  const fetchBillingPage = (page, pageSize, status) => {
    dispatch(
      getBillingStatementsPage({
        page: page,
        page_size: pageSize,
        status: status,
        firstName: fName,
        lastName: lName,
        dueDate: dDate,
      })
    );
    setTotalPages(billingStatePage.number_of_pages);
  };

  useEffect(() => {
    if (stat == 3) {
      fetchBillingPage(1, 10, -1);
    }
  }, [stat, fName, lName, dDate]);

  const columns = [
    // {
    //   title: "Action",
    //   dataIndex: "",
    //   key: "x",
    //   width: "150px",
    //   render: (record) => (
    //     <Dropdown
    //       trigger={["click"]}
    //       dropdownRender={() => (
    //         <div className="dropdown-action flex flex-col items-start">
    //           <Button
    //             onClick={() => showDrawer(record.billId)}
    //             style={{ color: "black" }}
    //             type="link"
    //             icon={<EyeOutlined />}
    //           >
    //             View bill
    //           </Button>
    //           <Popconfirm
    //             placement="bottomLeft"
    //             title={text}
    //             description={description}
    //             onConfirm={() => deleteBillingStatement(record.billId)}
    //             okText="Yes"
    //             cancelText="Cancel"
    //           >
    //             <Button
    //               style={{ color: "black" }}
    //               type="link"
    //               icon={<DeleteOutlined />}
    //             >
    //               Remove
    //             </Button>
    //           </Popconfirm>
    //         </div>
    //       )}
    //     >
    //       <Button
    //         type="primary"
    //         style={{
    //           background: "#4d8e03",
    //         }}
    //       >
    //         <Space>
    //           Action
    //           <DownOutlined />
    //         </Space>
    //       </Button>
    //     </Dropdown>
    //   ),
    // },
    {
      title: "First name",
      dataIndex: "firstName",
      width: "200px",
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    {
      title: "Last name",
      dataIndex: "lastName",
      width: "200px",
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      width: "200px",
      render: (status) =>
        status === -1 ? (
          <Tag color="volcano">Removed</Tag>
        ) : (
          <Tag color="green">Paid</Tag>
        ),
    },
    {
      title: "Total amount",
      dataIndex: "totalAmount",
      width: "200px",
      render: (amount) => format_PHCurrency(+amount),
    },
    {
      title: "Property",
      dataIndex: "customName",
      width: "200px",
    },
    {
      title: "Address",
      dataIndex: "address",
      width: "200px",
    },
    {
      title: "Billing date",
      dataIndex: "billingDate",
      width: "200px",
      render: (billingDate) => moment(billingDate).format("LL"),
    },
    {
      title: "Due date",
      dataIndex: "dueDate",
      width: "200px",
      render: (dueDate) => moment(dueDate).format("LL"),
    },
    {
      title: "Date Created",
      dataIndex: "dateCreated",
      width: "200px",
      editable: true,
      defaultSortOrder: "descend",
      sorter: (a, b) => moment(a.dateCreated).diff(moment(b.dateCreated)),
      render: (dateCreated) => moment(dateCreated).format("LL"),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      width: "200px",
    },
  ];

  return (
    <div>
      <Card
        title={
          <div className="flex justify-between w-full items-center p-5">
            <h1 className="font-[600] text-[25px] mb-0">Removed statements</h1>
          </div>
        }
      >
        <Table
          loading={billingStatePageLoading}
          rowKey={"billId"}
          columns={columns}
          dataSource={billingStatePage.data ? billingStatePage.data : []}
          scroll={{
            y: 500,
            x: "100%",
          }}
          pagination={{
            total: billingStatePage.total_items,
            pageSize: billingStatePage.page_size,
            onChange: (page, pageSize) => {
              fetchBillingPage(page, pageSize, -1);
            },
          }}
        />
      </Card>
    </div>
  );
};
export default Removed;
