import defaultAxios from "axios";
import { token } from "../axios";

const axios = defaultAxios.create({
  baseURL: process.env.REACT_APP_BASE_URL + `/api/associations/properties/`,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

axios.interceptors.request.use(async function (config) {
  config.headers["Authorization"] = `Bearer ${
    JSON.parse(await localStorage.getItem("persist:auth")).token.split(`"`)[1]
  }`;
  return config;
});

//PHASES

export const getPropertyPhases = async () => {
  try {
    const result = await axios.get("getPropertyPhases");
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const rejectProperty = async (params) => {
  try {
    const result = await axios.post("rejectProperty?propertyId=" + params);
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const approveProperty = async (params) => {
  try {
    const result = await axios.post("approveProperty?propertyId=" + params);
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const addPropertyPhase = async (body) => {
  try {
    const result = await axios.post("addPropertyPhase", body);
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const removePropertyPhase = async (params) => {
  try {
    const result = await axios.post("removePropertyPhase?phaseId=" + params);
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

//PHASES

//STREET

export const getPropertyStreets = async () => {
  try {
    const result = await axios.get("getPropertyStreets");
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const addPropertyStreet = async (body) => {
  try {
    const result = await axios.post("addPropertyStreet", body);
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const removePropertyStreet = async (params) => {
  try {
    const result = await axios.post("removePropertyStreet?streetId=" + params);
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

//STREET

//NAMES

export const getPropertyNames = async () => {
  try {
    const result = await axios.get("getPropertyNames");
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const addPropertyName = async (body) => {
  try {
    const result = await axios.post("addPropertyName", body);
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const removePropertyName = async (params) => {
  try {
    const result = await axios.post(
      "removePropertyName?propertyNameId=" + params
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

//NAMES

//TYPES

export const getPropertyTypes = async () => {
  try {
    const result = await axios.get("getPropertyTypes");
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const addPropertyType = async (body) => {
  try {
    const result = await axios.post("addPropertyType", body);
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const removePropertyType = async (params) => {
  try {
    const result = await axios.post(
      "removePropertyType?propertyTypeId=" + params
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

//TYPES

//SIZE

export const getPropertySizes = async () => {
  try {
    const result = await axios.get("getPropertySizes");
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const addPropertySize = async (body) => {
  try {
    const result = await axios.post("addPropertySize", body);
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const removePropertySize = async (params) => {
  try {
    const result = await axios.post(
      "removePropertySize?propertySizeId=" + params
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

//SIZE

// export const getProperties = async () => {
//   try {
//     const result = await axios.get("getProperties");
//     console.log(result);
//     return result;
//   } catch (err) {
//     console.log(err);
//     return err;
//   }
// };

export const getProperties = async (body) => {
  try {
    const result = await token.get(
      "/api/associations/properties/" + "getProperties",
      {
        headers: {
          Authorization: "Bearer " + body,
        },
      }
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};
