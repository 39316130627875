import React from "react";
import { Tabs } from "antd";
import "../directories.css";
import HotlinesTab from "./HotlinesTab";
import HotlinesTypesTab from "./HotlinesTypesTab";

const items = [
  { label: "Hotlines", key: "item-1", children: <HotlinesTab /> }, // remember to pass the key prop
  {
    label: "Hotlines Types",
    key: "item-2",
    children: <HotlinesTypesTab />,
  },
];

const Hotlines = () => (
  <Tabs
    defaultActiveKey="item-1"
    type="card"
    className="tabs-directories"
    animated={true}
    items={items}
  ></Tabs>
);
export default Hotlines;
