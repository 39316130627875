import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

const initialState = {
  isLoading: false,
  forms: [],
};

const formsSlice = createSlice({
  name: "forms",
  initialState,
  reducers: {
    getForms(state) {
      state.forms = [];
      state.isLoading = true;
    },
    getFormsSuccess(state, action) {
      state.forms = action.payload;
      state.isLoading = false;
    },
    addForms(state) {
      state.isLoading = true;
    },
    addFormsSuccess(state, action) {
      state.forms = action.payload;
      state.isLoading = false;
    },
    updateForms(state) {
      state.isLoading = true;
    },
    updateFormsSuccess(state, action) {
      message.success(action.payload.message);
      state.isLoading = false;
    },
    removeForms(state) {
      state.isLoading = true;
    },
    removeFormsSuccess(state, action) {
      message.success(action.payload.message);
      state.isLoading = false;
    },
    requestError(state, action) {
      state.isLoading = false;
      message.error(action.payload.message);
    },
  },
});

export const {
  requestError,
  getForms,
  getFormsSuccess,
  addForms,
  addFormsSuccess,
  updateForms,
  updateFormsSuccess,
  removeForms,
  removeFormsSuccess,
} = formsSlice.actions;
export default formsSlice;
