import React from "react";
import { Link } from "react-router-dom";

const AccountDeletion = () => {
  return (
    <div className="flex flex-col gap-5">
      <h1 className="md:text-[28px] text-[16px] font-semibold">
        Account Deletion
      </h1>
      <p className="text-gray-600 text-justify text-[14px] md:text-[18px]">
        We understand that there may be circumstances where you may want to
        delete your account with us. To respect your privacy and provide you
        with control over your data, we have established a policy for account
        deletion.
      </p>
      <h3 className="font-semibold">Reasons for Account Deletion:</h3>
      <h3 className="font-semibold">
        You may choose to delete your account with us for various reasons,
        including but not limited to:
      </h3>
      <ul className="text-gray-600 pl-5">
        <li>You no longer use our services</li>
        <li>You have privacy concerns</li>
        <li>You have duplicate accounts</li>
        <li>You are experiencing technical difficulties</li>
        <li>You want to exercise your right to be forgotten</li>
      </ul>
      <h3 className="font-semibold">How to Delete Your Account:</h3>
      <p className="text-gray-600 text-justify text-[14px] md:text-[18px]">
        To delete your account with us, follow the steps outlined for account
        deletion process below. Once you confirm the deletion, all your data
        will be permanently deleted, and we will not be able to retrieve it.
        Please ensure that you have backed up any important information before
        proceeding with the deletion. over your data, we have established a
        policy for account deletion.
      </p>
      <ol className="text-gray-600 pl-5">
        <li>
          Navigate to Account Settings: To delete your account, start by
          clicking on the top-right icon to open the Account Settings menu.
        </li>
        <li>
          Navigate to Account Profile: From there, click on your Name to
          navigate to your Account Profile.
        </li>
        <li>
          Locate the Delete Account button: Once you are in your Account
          Profile, look for the Delete Account button below.
        </li>
        <li>
          Confirm account deletion: After clicking on the Delete Account button,
          you will be prompted to confirm that you want to delete your account.
          Follow the instructions to confirm and complete the account deletion
          process.
        </li>
      </ol>
      <h3 className="font-semibold">Data Retention:</h3>
      <p className="text-gray-600 text-justify text-[14px] md:text-[18px]">
        We will retain your data for as long as necessary to provide you with
        our services and comply with legal obligations. However, we will delete
        your data upon your request unless we are required to retain it by law.
      </p>
    </div>
  );
};

export default AccountDeletion;
