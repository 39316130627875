import { Button, Card, Checkbox, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import homebook from "../../assets/resources/images/homebook.png";
import pass from "../../assets/resources/images/password.png";
import userIcon from "../../assets/resources/images/userLogin.png";
import {
  cancelSetup,
  resetCheck,
} from "../../store/slices/registration-slice/registration-slice";
import { authActions, forgotPasswordActions } from "../../store/store";
import { useEffect } from "react";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.auth);
  const { login } = authActions;
  const { validateCodeReset, generateCodeReset } = forgotPasswordActions;

  const onFinish = (values) => {
    dispatch(
      login({
        email: values.email,
        password: values.password,
      })
    );
  };

  useEffect(() => {
    dispatch(resetCheck(false));
    localStorage.setItem("login", "login");
  }, []);

  const signUp = () => {
    dispatch(cancelSetup(true));
    navigate("/Registration");
  };

  const onFinishFailed = (errorInfo) => {};

  const clearToken = () => {
    dispatch(generateCodeReset());
    dispatch(validateCodeReset());
  };

  return (
    <div
      className="poppins  "
      style={{
        width: "100%",
        height: "100%",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#f5f5f5",
      }}
    >
      <Card
        className="max-w-full md:max-w-[450px] md:max-h-[600px]"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          borderRadius: "12px",
          boxShadow:
            "1.1px 1.2px 4.5px rgba(0, 0, 0, 0.02), 2.7px 2.8px 10.8px rgba(0, 0, 0, 0.028), 5px 5.3px 20.3px rgba(0, 0, 0, 0.035), 8.9px 9.4px 36.2px rgba(0, 0, 0, 0.042), 16.7px 17.5px 67.7px rgba(0, 0, 0, 0.05), 40px 42px 162px rgba(0, 0, 0, 0.07)",
        }}
        bodyStyle={{
          width: "100%",
          height: "100%",
        }}
      >
        <img
          className="w-[140px] md:w-[140px]"
          style={{
            margin: "auto",
            display: "flex",
          }}
          src={homebook}
          alt=""
        />

        <h4
          style={{
            fontWeight: "600",
            fontSize: "20px",
            alignItems: "center",
            textAlign: "center",
            padding: "20px 0px",
          }}
        >
          Login to your Account
        </h4>
        <Form
          disabled={isLoading}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email",
              },
            ]}
          >
            <Input
              style={{
                padding: "10px",
                fontSize: "20px",
                borderRadius: "3px",
              }}
              prefix={
                <img
                  style={{
                    width: "25px",
                  }}
                  src={userIcon}
                  alt=""
                />
              }
              placeholder="Email"
              size="large"
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password",
              },
            ]}
          >
            <Input.Password
              style={{
                padding: "10px",
                fontSize: "20px",
                borderRadius: "3px",
              }}
              prefix={
                <img
                  style={{
                    width: "25px",
                  }}
                  src={pass}
                  alt=""
                />
              }
              placeholder="Password"
              size="large"
            />
          </Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Form.Item name="remember" valuePropName="unchecked">
              <Checkbox
                style={{
                  color: "#9D9D9D",
                }}
              >
                Remember me
              </Checkbox>
            </Form.Item>
            <Link
              style={{
                color: "#9D9D9D",
                fontSize: "12px",
                display: "flex",
                justifyContent: "flex-end",
              }}
              to={"/Forgotpassword"}
              onClick={() => clearToken()}
            >
              Forgot password?
            </Link>
          </div>
          <Form.Item
            style={{
              margin: "auto",
              paddingBottom: "25px",
            }}
          >
            <Button
              loading={isLoading}
              type="primary"
              htmlType="submit"
              style={{
                width: "220px",
                height: "60px",
                fontSize: "16px",
                fontWeight: "400",
                borderRadius: "5px",
                background: "#4d8e03",
              }}
            >
              Sign In
              {/* <LoadingOutlined
                style={{
                  fontSize: 24,
                }}
                spin
              /> */}
            </Button>
          </Form.Item>
          <Form.Item
            style={{
              margin: "auto",
              padding: "0px 0px",
            }}
          >
            <Button
              onClick={() => signUp()}
              type="outlined"
              style={{
                width: "220px",
                height: "60px",
                fontSize: "16px",
                fontWeight: "400",
                borderRadius: "5px",
                color: "#4d8e03",
                border: "2px solid #4d8e03",
              }}
            >
              Sign up
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Login;
