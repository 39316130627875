import { ArrowLeftOutlined, PlusOutlined } from "@ant-design/icons";
import { Form, Modal, Upload, Input, Descriptions } from "antd";
import ImgCrop from "antd-img-crop";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dummyRequest } from "../../../../constants/staticConst";
import { convertToBase64 } from "../../../../helpers/tobase64";
import {
  addBillingStatementAttachments,
  setBillingChargesAttachements,
  setBillingDescriptions,
  setbillingChargesAttachmentsTable,
  updateBillingStatementAttachment,
} from "../../../../store/slices/billing/billing-slice";

const { Dragger } = Upload;

let IMAGE;
const AddChargeAttachment = ({
  openAddAttachment,
  closeAddAttachment,
  choiceId,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [setImage, setSetImage] = useState();
  const [test, setTest] = useState();

  const { billingChargesAttachments } = useSelector((state) => state.billing);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await convertToBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChangeAttachment = ({ fileList: newFileList }) => {
    setFileList(newFileList);

    form.setFieldsValue({
      imgUrl: fileList?.map((file) => file?.originFileObj) || null,
    });

    let file = fileList?.map((file) => file?.originFileObj);
  };

  const uploadButton = (
    <div className="w-full min-w-[100px]">
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload new
      </div>
    </div>
  );

  const kahitano = async (e) => {
    await convertToBase64(e).then(async (res) => {
      IMAGE = res;
    });
  };

  const onFinishFailed = (values) => {};

  const onFinish = async (values) => {
    await kahitano(values.imgUrl[0]);

    dispatch(setBillingChargesAttachements(values.imgUrl));

    dispatch(
      setbillingChargesAttachmentsTable({
        chargeId: choiceId,
        imageUrl: IMAGE,
        description: values.description ? values.description : "n/a",
      })
    );

    dispatch(
      setBillingDescriptions(
        values.description
          ? { description: values.description, file: choiceId }
          : "n/a"
      )
    );

    form.resetFields();
    setFileList([]);
    setSetImage("");
    closeEditAttachmentImg();
  };

  const closeEditAttachmentImg = () => {
    closeAddAttachment(true);
    setFileList([]);
    setSetImage("");
  };

  return (
    <Modal
      title={
        <div className="flex md:flex-row md:items-center items-start justify-start">
          <div
            onClick={closeEditAttachmentImg}
            className="w-[50px] h-[50px] items-center text-center flex justify-center text-[20px] rounded-full hover:bg-slate-200 transition-all ease-in duration-150 cursor-pointer hover:scale-110"
          >
            <ArrowLeftOutlined className="text-[#1b1b1b]" />
          </div>
          <h1 className="text-[24px] md:text-[24px] font-[600] text-[#1b1b1b] mb-0 p-3">
            Add attachment
          </h1>
        </div>
      }
      closable={false}
      onClose={closeEditAttachmentImg}
      open={openAddAttachment}
      onCancel={closeEditAttachmentImg}
      onOk={form.submit}
      className="demo w-full"
    >
      <div className="h-full w-full flex flex-col">
        <Form
          form={form}
          name="add"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <div className="flex flex-col gap-10 items-center md:items-start p-3">
            <div className="flex-1 h-[278px] ">
              <Form.Item
                valuePropName="fileList"
                name="imgUrl"
                className="max-w-[250px]"
                rules={[
                  {
                    required: true,
                    message: "Please input attachment",
                  },
                ]}
              >
                <>
                  <Upload
                    maxCount={1}
                    name="file"
                    customRequest={dummyRequest}
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChangeAttachment}
                  >
                    {uploadButton}
                  </Upload>
                </>
              </Form.Item>
              <Form.Item
                value=""
                name="description"
                label={
                  <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
                    Description
                  </h1>
                }
              >
                <Input
                  style={{
                    padding: "10px",
                    fontSize: "18px",
                    borderRadius: "3px",
                  }}
                  placeholder="Enter description"
                  size="large"
                />
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default AddChargeAttachment;
