import React, { useEffect } from "react";
import { DownOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Card,
  Table,
  Dropdown,
  Space,
  Button,
  Image,
  Popconfirm,
  Empty,
  Tag,
} from "antd";
import { getProperties } from "../../../../store/slices/property/property-phase-slice";
import { useDispatch, useSelector } from "react-redux";

const ApprovedProperties = () => {
  const dispatch = useDispatch();
  const { propertiesLoading, properties } = useSelector(
    (state) => state.propertyPhases
  );
  const { token } = useSelector((state) => state.auth);

  const columns = [
    // {
    //   title: "Action",
    //   dataIndex: "",
    //   key: "x",
    //   width: "150px",
    //   render: (record) => (
    //     <Dropdown
    //       trigger={["click"]}
    //       dropdownRender={() => (
    //         <div className="dropdown-action flex flex-col items-start">
    //           <Popconfirm
    //             placement="bottomLeft"
    //             // title={text}
    //             // description={description}
    //             // onConfirm={() => deleteAnnouncement(record.announcementId)}
    //             okText="Yes"
    //             cancelText="Cancel"
    //           >
    //             <Button
    //               style={{ color: "black" }}
    //               type="link"
    //               icon={<DeleteOutlined />}
    //             >
    //               Remove
    //             </Button>
    //           </Popconfirm>
    //         </div>
    //       )}
    //     >
    //       <Button
    //         type="primary"
    //         style={{
    //           background: "#4d8e03",
    //         }}
    //       >
    //         <Space>
    //           Action
    //           <DownOutlined />
    //         </Space>
    //       </Button>
    //     </Dropdown>
    //   ),
    // },
    {
      title: "Name",
      dataIndex: "customName",
      width: "200px",
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "200px",
      editable: true,
      render: (status) =>
        status === 1 ? (
          <Tag color="green">Approved</Tag>
        ) : (
          <Tag color="blue">Active</Tag>
        ),
    },
    {
      title: "Address",
      dataIndex: "address",
      width: "200px",
    },
    {
      title: "Property Name",
      dataIndex: "propertyName",
      width: "200px",
    },
    {
      title: "Type",
      dataIndex: "propertyType",
      width: "200px",
    },
    {
      title: "Size",
      dataIndex: "propertySize",
      width: "200px",
    },
    {
      title: "Phase",
      dataIndex: "propertyPhase",
      width: "200px",
    },
    {
      title: "Street",
      dataIndex: "propertyStreet",
      width: "200px",
    },
    {
      title: "Image",
      dataIndex: "propertyImage",
      align: "center",
      width: "200px",
      render: (record) => {
        return record ? (
          <Image
            width={100}
            src={process.env.REACT_APP_BASE_URL + "/" + record}
          />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(getProperties(token));
  }, []);
  return (
    <div>
      <Card>
        <Table
          loading={propertiesLoading}
          rowKey={"propertyId"}
          columns={columns}
          dataSource={
            properties ? properties?.filter((obj) => obj.status === 1) : []
          }
          scroll={{
            y: 500,
            x: "100%",
          }}
        />
      </Card>
    </div>
  );
};

export default ApprovedProperties;
