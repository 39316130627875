import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";

export const pendingActions = [
  {
    text: "Process",
    title: "Are you sure you want to process this inquiry?",
    description: "Process this inquiry",
    icon: <EyeOutlined />,
  },
  {
    text: "Remove",
    title: "Are you sure you want to remove this inquiry?",
    description: "Remove this inquiry",
    icon: <DeleteOutlined />,
  },
];

export const processedActions = [
  {
    text: "Resolve",
    title: "Are you sure you want to resolve this inquiry?",
    description: "Resolve this inquiry",
    icon: <EyeOutlined />,
  },
  {
    text: "Remove",
    title: "Are you sure you want to remove this inquiry?",
    description: "Remove this inquiry",
    icon: <DeleteOutlined />,
  },
];
