import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

const initialState = {
  inquiriesLoading: false,
  inquiries: [],
  utilityReportsLoading: false,
  utilityReports: [],
  utilityReportTypesLoading: false,
  utilityReportTypes: [],
  activeKey: "item-1",
  activeKeyReport: "item-1",
};

const inquiriesSlice = createSlice({
  name: "inquiry",
  initialState,
  reducers: {
    setActiveKey(state, action) {
      state.activeKey = action.payload;
    },
    setActiveKeyReport(state, action) {
      state.activeKeyReport = action.payload;
    },
    getInquiries(state) {
      state.inquiries = [];
      state.inquiriesLoading = true;
    },
    getInquiriesSuccess(state, action) {
      state.inquiriesLoading = false;
      state.inquiries = action.payload;
    },
    processInquiry(state, action) {
      state.inquiriesLoading = true;
    },
    processInquirySuccess(state, action) {
      state.inquiriesLoading = false;
      state.inquiries = action.payload;
      message.success(action.payload.message);
    },
    resolveInquiry(state, action) {
      state.inquiriesLoading = true;
    },
    resolveInquirySuccess(state, action) {
      state.inquiriesLoading = false;
      state.inquiries = action.payload;
      message.success(action.payload.message);
    },
    removeInquiry(state, action) {
      state.inquiriesLoading = true;
    },
    removeInquirySuccess(state, action) {
      state.inquiriesLoading = false;
      state.inquiries = action.payload;
      message.success(action.payload.message);
    },
    getUtilityReports(state) {
      state.utilityReports = [];
      state.utilityReportsLoading = true;
    },
    getUtilityReportsSuccess(state, action) {
      return {
        ...state,
        utilityReportsLoading: false,
        utilityReports: action.payload,
      };
    },
    processUtilityReport(state, action) {
      state.utilityReportsLoading = true;
    },
    processUtilityReportSuccess(state, action) {
      state.utilityReportsLoading = false;
      state.utilityReports = action.payload;
      message.success(action.payload.message);
    },
    resolveUtilityReport(state, action) {
      state.utilityReportsLoading = true;
    },
    resolveUtilityReportSuccess(state, action) {
      state.utilityReportsLoading = false;
      state.utilityReports = action.payload;
      message.success(action.payload.message);
    },
    removeUtilityReport(state, action) {
      state.utilityReportsLoading = true;
    },
    removeUtilityReportSuccess(state, action) {
      state.utilityReportsLoading = false;
      message.success(action.payload.message);
      state.utilityReports = action.payload;
    },
    getUtilityReportTypes(state) {
      state.utilityReportTypes = [];
      state.utilityReportTypesLoading = true;
    },
    getUtilityReportTypesSuccess(state, action) {
      state.utilityReportTypesLoading = false;
      state.utilityReportTypes = action.payload;
    },
    addUtilityReportType(state) {
      state.utilityReportTypesLoading = true;
    },
    addUtilityReportTypeSuccess(state, action) {
      state.utilityReportTypesLoading = false;
      message.success(action.payload.message);
      state.utilityReportTypes = action.payload;
    },
    removeUtilityType(state, action) {
      state.utilityReportTypesLoading = true;
    },
    removeUtilityTypeSuccess(state, action) {
      state.utilityReportTypesLoading = false;
      message.success(action.payload.message);
      state.utilityReportTypes = action.payload;
    },
    updateUtilityReportType(state, action) {
      state.utilityReportTypesLoading = true;
    },
    updateUtilityReportTypeSuccess(state, action) {
      state.utilityReportTypesLoading = false;
      message.success(action.payload.message);
      state.utilityReportTypes = action.payload;
    },
    requestError(state, action) {
      message.error(action.payload.data.message);
      return {
        ...state,
        utilityReportsLoading: false,
        inquiriesLoading: false,
        utilityReportTypesLoading: false,
      };
    },
  },
});

export const {
  getInquiries,
  getInquiriesSuccess,
  getUtilityReports,
  getUtilityReportsSuccess,
  getUtilityReportTypes,
  getUtilityReportTypesSuccess,
  removeUtilityReport,
  removeUtilityReportSuccess,
  removeInquiry,
  removeInquirySuccess,
  removeUtilityType,
  removeUtilityTypeSuccess,
  addUtilityReportType,
  addUtilityReportTypeSuccess,
  processInquiry,
  processInquirySuccess,
  processUtilityReport,
  processUtilityReportSuccess,
  resolveInquiry,
  resolveInquirySuccess,
  resolveUtilityReport,
  resolveUtilityReportSuccess,
  updateUtilityReportType,
  updateUtilityReportTypeSuccess,
  setActiveKey,
  setActiveKeyReport,
  requestError,
} = inquiriesSlice.actions;
export default inquiriesSlice;
