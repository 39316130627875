import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalButton from "../../../../UI/ModalButton";
import { Modal, Form, Input } from "antd";
import {
  addAssociationCharge,
  getAssociationCharges,
  updateAssociationCharge,
} from "../../../../store/slices/billing/billing-slice";
import { format_PHCurrency } from "../../../../helpers/formatCurrency";

const AssocChargeForm = ({
  openAddForm,
  closeAddForm,
  isAdding,
  isEditing,
  isLoading,
  chargeId,
}) => {
  const dispatch = useDispatch();
  const { assocCharges } = useSelector((state) => state.billing);
  const [form] = Form.useForm();

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    if (isEditing) {
      dispatch(
        updateAssociationCharge({
          params: chargeId,
          body: { name: values?.name },
        })
      );
    } else {
      dispatch(
        addAssociationCharge({
          name: values?.name,
          defaultAmount: 0,
        })
      );
    }

    closeHandler();
  };

  const closeHandler = () => {
    closeAddForm(true);
  };

  const found = assocCharges?.find((obj) => obj.chargeId === chargeId);

  useEffect(() => {
    if (!isEditing) form.resetFields();
  }, [isEditing]);

  useEffect(() => {
    if (isEditing) {
      form.setFieldsValue({
        ...found,
        name: found?.name,
      });
    } else {
      form.resetFields();
    }
  }, [assocCharges, isEditing, chargeId]);

  return (
    <Modal
      title={
        <h1 className="text-[24px] md:text-[30px] font-[600] text-[#1b1b1b] mb-0 ">
          {isEditing ? "Edit assoc charge" : "Add assoc charge"}
        </h1>
      }
      centered
      open={openAddForm}
      onOk={closeHandler}
      onCancel={closeHandler}
      footer={false}
    >
      <Form
        layout="vertical"
        form={form}
        disabled={isLoading}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label={
            <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
              Charge name
            </h1>
          }
          name="name"
          rules={[
            {
              required: true,
              message: "Please input assoc charge",
            },
          ]}
        >
          <Input
            style={{
              padding: "10px",
              fontSize: "18px",
              borderRadius: "3px",
            }}
            placeholder="Enter assoc charge"
            size="large"
          />
        </Form.Item>
        {/* <Form.Item
          name="cost"
          label={
            <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
              Amount
            </h1>
          }
          rules={[
            {
              required: true,
              message: "Please input amount!",
            },
            {
              pattern: /^[1-9][0-9]{0,3}$/g,
              message: "Maximum of 4 digits!",
            },
          ]}
        >
          <Input
            style={{
              padding: "10px",
              fontSize: "18px",
              borderRadius: "3px",
            }}
            placeholder="Enter amount"
            size="large"
          />
        </Form.Item> */}
        <ModalButton text={isEditing ? "Update" : "Add"} />
      </Form>
    </Modal>
  );
};

export default AssocChargeForm;
