import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, DatePicker, Drawer, Form, Input } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { format_addCommas } from "../../../helpers/formatCurrency";
import {
  createBillingStatement,
  updateBillingStatement,
} from "../../../store/slices/billing/billing-slice";

const BillingForm = ({
  isEditing,
  isAdding,
  close,
  open,
  propertyId,
  billId,
  billingStatement,
  homeownerId,
}) => {
  const found = billingStatement;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const onFinish = (values) => {
    if (isEditing) {
      dispatch(
        updateBillingStatement({
          params: billId,
          body: {
            dueDate: values?.dueDate.format("YYYY-MM-DD"),
            totalAmount:
              +values?.amount +
              +values?.vatAmount +
              +found.charges
                ?.map((obj) => +obj.amount)
                .reduce((num, curr) => num + curr, 0)
                .toFixed(2),
            amount: +values?.amount,
            vatAmount: +values?.vatAmount,
            remarks: values?.remarks,
          },
        })
      );
    } else {
      dispatch(
        createBillingStatement({
          propertyId: propertyId,
          dueDate: values?.dueDate.format("YYYY-MM-DD"),
          amount: values.amount ? +values?.amount : 0.0,
          vatAmount: values.vatAmount ? +values?.vatAmount : 0.0,
          totalAmount: +values?.amount
            ? +values?.amount
            : 0.0 + +values?.vatAmount
            ? +values?.vatAmount
            : 0.0,
          remarks: values?.remarks ? values?.remarks : "n/a",
        })
      );
      localStorage.setItem("homeOwnerId", homeownerId);
    }
    closeHandler();
  };

  const onFinishFailed = () => {};

  const closeHandler = () => {
    close(true);
  };

  useEffect(() => {
    if (isEditing) {
      form.setFieldsValue({
        ...found,
        totalAmount: format_addCommas((+found.totalAmount).toFixed(2)),
        amount: format_addCommas(found?.amount),
        vatAmount: format_addCommas(found?.vatAmount),
        remarks: found?.remarks,
        dueDate: dayjs(found?.dueDate, "YYYY-MM-DD"),
      });
    } else {
      form.resetFields();
    }
  }, [isEditing, propertyId, billId]);

  return (
    <Drawer
      title={
        <div className="flex flex-col md:flex-row md:items-center items-start justify-start">
          <div
            onClick={() => closeHandler(true)}
            className="w-[50px] h-[50px] items-center text-center flex justify-center text-[20px] rounded-full hover:bg-slate-200 transition-all ease-in duration-150 cursor-pointer hover:scale-110"
          >
            <ArrowLeftOutlined className="text-[#1b1b1b]" />
          </div>
          <h1 className="text-[24px] md:text-[30px] font-[600] text-[#1b1b1b] mb-0 p-3">
            {isEditing ? "Edit bill" : "Create bill"}
          </h1>
        </div>
      }
      placement="right"
      closable={false}
      onClose={closeHandler}
      open={open}
      className="demo"
      width={"30%"}
      footer={
        <div className="flex justify-end">
          <Button
            className="shadow-md"
            type="primary"
            onClick={form.submit}
            htmlType="submit"
            style={{
              minWidth: "200px",
              height: "52px",
              fontSize: "17px",
              fontWeight: "400",
              borderRadius: "5px",
              background: "#4d8e03",
              marginBlock: "13px",
            }}
          >
            {isEditing ? "Update" : "Confirm"}
          </Button>
        </div>
      }
    >
      <div className="">
        <Form
          layout="vertical"
          form={form}
          // disabled={isLoading}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div className="flex md:flex-col flex-col gap-10 items-center md:items-start p-6">
            <div className="flex flex-col gap-4 w-full   md:flex-1 ">
              <div className="text-label">
                <Form.Item
                  className={!isEditing ? "hidden" : "block"}
                  value=""
                  name="totalAmount"
                  label={<h1 className="form-title">Total amount</h1>}
                >
                  <Input
                    readOnly
                    style={{
                      padding: "10px",
                      fontSize: "18px",
                      borderRadius: "3px",
                    }}
                    size="large"
                    placeholder="Enter amount "
                  />
                </Form.Item>
                {/* <Form.Item
                  value={0.0}
                  name="amount"
                  label={<h1 className="form-title">Amount</h1>}
                  rules={[
                    {
                      required: true,
                      message: "Please input amount!",
                    },
                  ]}
                >
                  <Input
                    style={{
                      padding: "10px",
                      fontSize: "18px",
                      borderRadius: "3px",
                    }}
                    placeholder="Enter amount "
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  value={0.0}
                  name="vatAmount"
                  label={<h1 className="form-title">Vat amount</h1>}
                >
                  <Input
                    style={{
                      padding: "10px",
                      fontSize: "18px",
                      borderRadius: "3px",
                    }}
                    placeholder="Enter vat "
                    size="large"
                  />
                </Form.Item> */}
                <Form.Item
                  value=""
                  name="remarks"
                  label={<h1 className="form-title">Remarks</h1>}
                >
                  <Input
                    style={{
                      padding: "10px",
                      fontSize: "18px",
                      borderRadius: "3px",
                    }}
                    size="large"
                    placeholder="Enter remarks "
                  />
                </Form.Item>
                <Form.Item
                  value=""
                  name="dueDate"
                  label={<h1 className="form-title">Due date</h1>}
                  rules={[
                    {
                      required: true,
                      message: "Please input date!",
                    },
                  ]}
                >
                  <DatePicker
                    value=""
                    disabledDate={(d) =>
                      !d || d.isBefore(moment().calendar("LL"))
                    }
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

export default BillingForm;
