import { defaultAxios, axiosMultipart } from "../../axios";

const baseApi = "/api/associations/announcements/";

defaultAxios.interceptors.request.use(async function (config) {
  config.headers["Authorization"] = `Bearer ${
    JSON.parse(await localStorage.getItem("persist:auth")).token.split(`"`)[1]
  }`;
  return config;
});

axiosMultipart.interceptors.request.use(async function (config) {
  config.headers["Authorization"] = `Bearer ${
    JSON.parse(await localStorage.getItem("persist:auth")).token.split(`"`)[1]
  }`;
  return config;
});

export const getAnnouncements = async () => {
  try {
    const result = await defaultAxios.get(baseApi + "getAnnouncements");
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const removeAnnouncement = async (params) => {
  try {
    const result = await defaultAxios.post(
      baseApi + "removeAnnouncement/" + params
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const removeAnnouncementAttachment = async (params) => {
  try {
    const result = await defaultAxios.post(
      baseApi + "removeAnnouncementAttachment/" + params
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const updateAnnouncement = async (data) => {
  try {
    const result = await defaultAxios.post(
      baseApi + "updateAnnouncement/" + data.params,
      data.body
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const addAnnouncementAttachment = async (data) => {
  try {
    const result = await axiosMultipart.post(
      "/api/associations/announcements/addAnnouncementAttachment/" +
        data.params,
      data.body.formData
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const updateAnnouncementAttachment = async (data) => {
  try {
    const result = await axiosMultipart.post(
      "/api/associations/announcements/updateAnnouncementAttachment/" +
        data.params,
      data.body.formData
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const addAnnouncement = async (body) => {
  try {
    const result = await axiosMultipart.post(
      "/api/associations/announcements/addAnnouncement/",
      body.formData
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};
