import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "react-scroll";
import "../Header.css";
import "./MenuItems.css";
import { useSelector } from "react-redux";
const MenuItems = ({ menuActive, activeItem, shouldBlur, Nav, showMenu }) => {
  const { isAuthenticated, designation } = useSelector((state) => state.auth);

  return (
    <div
      className={
        menuActive
          ? ` backdrop-blur-sm
						 z-10 justify-center items-center gap-5 md:hidden flex flex-col fixed top-16 left-0 right-0 px-5 pt-10 pb-5 ${
               activeItem === "home" ? "bg-black/20" : "bg-white/70"
             }`
          : "hidden"
      }
    >
      <RouterLink
        to={
          isAuthenticated
            ? designation === "Admin"
              ? "/Dashboard"
              : "/Transactions"
            : "/Login"
        }
        className={
          menuActive
            ? `shadow-lg uppercase w-[90%] bg-transparent cursor-pointer px-8 py-2 rounded-lg font-semibold no-underline ${
                activeItem === "home" ? "text-white" : "text-[#57833C]"
              }`
            : "hidden"
        }
      >
        Login
      </RouterLink>
      {Nav.map((nav, i) => {
        return (
          <Link
            key={i}
            to={nav.id}
            spy={true}
            smooth={true}
            offset={nav.offset}
            duration={400}
            // activeClass="active-link"
            activeClass={
              activeItem === "home" ? "active-link2" : "not-home-active2"
            }
            // className="text-black py-3 px-1 cursor-pointer"
            // className={menuActive ? `${headerStyle} w-[90%]` : " hidden"}
            className={
              menuActive
                ? `uppercase w-[90%] cursor-pointer px-8 py-2 rounded-lg font-semibold ${
                    activeItem === "home" ? "text-white" : "text-[#57833C]"
                  }`
                : "hidden"
            }
            onClick={showMenu}

            // onClick={() => handleLinkClick(nav.name)}
          >
            {nav.name}
          </Link>
        );
      })}
    </div>
  );
};

export default MenuItems;
