import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

const initialState = {
  plansLoading: false,
  checkoutLoading: false,
  subscriptionLoading: false,
  cancelLoading: false,
  renewLoading: false,
  upgradeLoading: false,
  subscription: null,
  redirectUrl: "",
  plans: [],
};

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    getPlans(state) {
      state.plans = [];
      state.plansLoading = true;
    },
    getPlansSuccess(state, action) {
      state.plans = action.payload;
      state.plansLoading = false;
    },
    cancelSubscription(state) {
      state.cancelLoading = true;
    },
    cancelSubscriptionSuccess(state, action) {
      message.success(action.payload.message);
      state.cancelLoading = false;
    },
    renewSubscription(state) {
      state.renewLoading = true;
    },
    renewSubscriptionSuccess(state, action) {
      message.success(action.payload.message);
      state.renewLoading = false;
    },
    upgradeSubscription(state) {
      state.upgradeLoading = true;
    },
    upgradeSubscriptionSuccess(state, action) {
      message.success(action.payload.message);
      state.upgradeLoading = false;
    },
    getSubscription(state) {
      state.subscription = {};
      state.subscriptionLoading = true;
    },
    getSubscriptionSuccess(state, action) {
      state.subscription = action.payload;
      state.subscriptionLoading = false;
    },
    checkout(state) {
      state.checkoutLoading = true;
    },
    checkoutSuccess(state, action) {
      state.redirectUrl = action.payload.redirectUrl;
      state.checkoutLoading = false;
      window.location.href = action.payload.redirectUrl;
    },
    requestError(state, action) {
      state.plansLoading = false;
      message.error(action.payload.data.message);
    },
  },
});

export const {
  requestError,
  getPlans,
  getPlansSuccess,
  checkout,
  checkoutSuccess,
  getSubscription,
  getSubscriptionSuccess,
  upgradeSubscription,
  upgradeSubscriptionSuccess,
  renewSubscription,
  renewSubscriptionSuccess,
  cancelSubscription,
  cancelSubscriptionSuccess,
} = subscriptionSlice.actions;
export default subscriptionSlice;
