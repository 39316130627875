import { Image } from "antd";
import React from "react";
import { useEffect } from "react";
import check from "../../../assets/resources/images/check.png";
import cross from "../../../assets/resources/images/cross.png";
import { LoadingTime } from "../../../UI/LoadingScreen";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { verifyEmail } from "../../../store/slices/registration-slice/registration-slice";

const SuccessVerification = () => {
  const { verifyLoading, status } = useSelector((state) => state.onboarding);
  const { token } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(verifyEmail(token));
    localStorage.removeItem("login");
  }, []);

  const handleClick = () => {
    if (status === true) {
      window.open("https://properity.ph/", "_self");
    } else if (status === false) {
      dispatch(verifyEmail(token));
    }
  };

  return (
    <div className="flex w-full items-center justify-center h-screen relative bg-pro-bg">
      <LoadingTime loading={verifyLoading} />
      <div
        className={`w-full max-w-[685px] h-[685px] bg-white rounded-[10px] p-[10px] items-center justify-evenly text-center ${
          verifyLoading ? "hidden" : "flex  flex-col"
        }`}
      >
        <Image src={status === true ? check : cross} preview={false} />
        <div className="flex flex-col gap-[20px] px-10">
          <h1 className="text-[24px]">
            {status === true
              ? "Email verification successful!"
              : "We Couldn't Verify Your Email."}
          </h1>
          <p className="text-[18px]">
            {status === true
              ? " Congratulations! Your email has been successfully verified. You can now access all the features of our platform."
              : "We're sorry, but we were unable to verify your email address. Please double-check the email address you provided and try again."}
          </p>
        </div>
        <button
          className={`w-full border-none max-w-[390px] h-[76px] ${
            status === true ? "bg-properity" : "bg-[#EA7272]"
          } flex items-center justify-center text-white rounded-[5px] font-semibold text-[24px] no-underline`}
          onClick={() => handleClick()}
        >
          {status === true ? "Continue" : "Retry"}
        </button>
      </div>
    </div>
  );
};

export default SuccessVerification;
