import { put, call, takeLatest, select } from "redux-saga/effects";
import { propertyPhasesActions } from "../../store";
import {
  getPropertyPhases,
  addPropertyPhase,
  getPropertyStreets,
  addPropertyStreet,
  getPropertyNames,
  addPropertyName,
  getPropertyTypes,
  addPropertyType,
  getPropertySizes,
  addPropertySize,
  getProperties,
  rejectProperty,
  approveProperty,
  removePropertyPhase,
  removePropertyStreet,
  removePropertyName,
  removePropertyType,
  removePropertySize,
} from "../../api/properties/property-phase-api";
import {
  editPropertyPhase,
  editPropertyName,
  editPropertySize,
  editPropertyType,
  editPropertyStreet,
} from "../../api/properties/edit-property-api";
///
///

function* getPropertiesRequest({ payload }) {
  const { requestError, getPropertiesSuccess } = propertyPhasesActions;
  const result = yield call(getProperties, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getPropertiesSuccess(result.data));
  }
}

function* rejectPropertyRequest({ payload }) {
  const { requestError, rejectPropertySuccess, getProperties } =
    propertyPhasesActions;
  const result = yield call(rejectProperty, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(rejectPropertySuccess(result.data));
    const { token } = yield select((state) => state.auth);

    yield put(getProperties(token));
  }
}

function* approvePropertyRequest({ payload }) {
  const { requestError, approvePropertySuccess, getProperties } =
    propertyPhasesActions;
  const result = yield call(approveProperty, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    const { token } = yield select((state) => state.auth);
    yield put(approvePropertySuccess(result.data));
    yield put(getProperties(token));
  }
}

//PHASES

function* getPropertyPhasesRequest() {
  const { requestError, getPropertyPhasesSuccess } = propertyPhasesActions;
  const result = yield call(getPropertyPhases);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getPropertyPhasesSuccess(result.data));
  }
}

function* addPropertyPhasesRequest({ payload }) {
  const { requestError, addPropertyPhasesSuccess, getPropertyPhases } =
    propertyPhasesActions;
  const result = yield call(addPropertyPhase, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(addPropertyPhasesSuccess(result.data));
    yield put(getPropertyPhases());
  }
}

function* removePropertyPhaseRequest({ payload }) {
  const { requestError, removePropertyPhaseSuccess, getPropertyPhases } =
    propertyPhasesActions;
  const result = yield call(removePropertyPhase, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(removePropertyPhaseSuccess(result.data));
    yield put(getPropertyPhases());
  }
}

//PHASES

//STREET

function* getPropertyStreetsRequest() {
  const { requestError, getPropertyStreetsSuccess } = propertyPhasesActions;
  const result = yield call(getPropertyStreets);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getPropertyStreetsSuccess(result.data));
  }
}

function* addPropertyStreetRequest({ payload }) {
  const { requestError, addPropertyStreetsSuccess, getPropertyStreets } =
    propertyPhasesActions;
  const result = yield call(addPropertyStreet, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(addPropertyStreetsSuccess(result.data));
    yield put(getPropertyStreets());
  }
}

function* removePropertyStreetRequest({ payload }) {
  const { requestError, removePropertyStreetSuccess, getPropertyStreets } =
    propertyPhasesActions;
  const result = yield call(removePropertyStreet, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(removePropertyStreetSuccess(result.data));
    yield put(getPropertyStreets());
  }
}

//STREET

//NAMES

function* getPropertyNamesRequest() {
  const { requestError, getPropertyNamesSuccess } = propertyPhasesActions;
  const result = yield call(getPropertyNames);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getPropertyNamesSuccess(result.data));
  }
}

function* addPropertySNameRequest({ payload }) {
  const { requestError, addPropertyNameSuccess, getPropertyNames } =
    propertyPhasesActions;
  const result = yield call(addPropertyName, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(addPropertyNameSuccess(result.data));
    yield put(getPropertyNames());
  }
}

function* removePropertyNameRequest({ payload }) {
  const { requestError, removePropertyNameSuccess, getPropertyNames } =
    propertyPhasesActions;
  const result = yield call(removePropertyName, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(removePropertyNameSuccess(result.data));
    yield put(getPropertyNames());
  }
}

//NAMES

//TYPES

function* getPropertyTypesRequest() {
  const { requestError, getPropertyTypesSuccess } = propertyPhasesActions;
  const result = yield call(getPropertyTypes);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getPropertyTypesSuccess(result.data));
  }
}

function* addPropertyTypeRequest({ payload }) {
  const { requestError, addPropertyTypeSuccess, getPropertyTypes } =
    propertyPhasesActions;
  const result = yield call(addPropertyType, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(addPropertyTypeSuccess(result.data));
    yield put(getPropertyTypes());
  }
}

function* removePropertyTypeRequest({ payload }) {
  const { requestError, removePropertyTypeSuccess, getPropertyTypes } =
    propertyPhasesActions;
  const result = yield call(removePropertyType, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(removePropertyTypeSuccess(result.data));
    yield put(getPropertyTypes());
  }
}

//TYPES

//SIZE

function* getPropertySizesRequest() {
  const { requestError, getPropertySizesSuccess } = propertyPhasesActions;
  const result = yield call(getPropertySizes);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getPropertySizesSuccess(result.data));
  }
}

function* addPropertySizeRequest({ payload }) {
  const { requestError, addPropertySizeSuccess, getPropertySizes } =
    propertyPhasesActions;
  const result = yield call(addPropertySize, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(addPropertySizeSuccess(result.data));
    yield put(getPropertySizes());
  }
}

function* removePropertySizeRequest({ payload }) {
  const { requestError, removePropertySizeSuccess, getPropertySizes } =
    propertyPhasesActions;
  const result = yield call(removePropertySize, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(removePropertySizeSuccess(result.data));
    yield put(getPropertySizes());
  }
}

//SIZE

//EDIT PHASE

function* editPropertyPhaseRequest({ payload }) {
  const { requestError, getUpdatedPhaseSuccess, getPropertyPhases } =
    propertyPhasesActions;
  const result = yield call(editPropertyPhase, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getUpdatedPhaseSuccess(result.data));
    yield put(getPropertyPhases());
  }
}

//EDIT PHASE

//EDIT NAME

function* editPropertyNameRequest({ payload }) {
  const { requestError, getUpdatedPropertyNameSuccess, getPropertyNames } =
    propertyPhasesActions;
  const result = yield call(editPropertyName, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getUpdatedPropertyNameSuccess(result.data));
    yield put(getPropertyNames());
  }
}

//EDIT NAME

//EDIT SIZE

function* editPropertySizeRequest({ payload }) {
  const { requestError, getUpdatedPropertySizeSuccess, getPropertySizes } =
    propertyPhasesActions;
  const result = yield call(editPropertySize, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getUpdatedPropertySizeSuccess(result.data));
    yield put(getPropertySizes());
  }
}

//EDIT SIZE

//EDIT TYPE

function* editPropertyTypeRequest({ payload }) {
  const { requestError, getUpdatedPropertySizeSuccess, getPropertyTypes } =
    propertyPhasesActions;
  const result = yield call(editPropertyType, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getUpdatedPropertySizeSuccess(result.data));
    yield put(getPropertyTypes());
  }
}

//EDIT TYPE

//EDIT STREET

function* editPropertyStreetRequest({ payload }) {
  const { requestError, getUpdatedPropertyStreetSuccess, getPropertyStreets } =
    propertyPhasesActions;
  const result = yield call(editPropertyStreet, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getUpdatedPropertyStreetSuccess(result.data));
    yield put(getPropertyStreets());
  }
}

//EDIT STREET

// Export the saga (todo-saga)
export default function* propertyPhasesSaga() {
  yield takeLatest(
    `propertyPhases/getPropertyPhases`,
    getPropertyPhasesRequest
  );
  yield takeLatest(`propertyPhases/addPropertyPhase`, addPropertyPhasesRequest);
  yield takeLatest(
    `propertyPhases/getPropertyStreets`,
    getPropertyStreetsRequest
  );
  yield takeLatest(
    `propertyPhases/addPropertyStreets`,
    addPropertyStreetRequest
  );
  yield takeLatest(
    `propertyPhases/removePropertyPhase`,
    removePropertyPhaseRequest
  );
  yield takeLatest(
    `propertyPhases/removePropertyStreet`,
    removePropertyStreetRequest
  );
  yield takeLatest(
    `propertyPhases/removePropertyName`,
    removePropertyNameRequest
  );
  yield takeLatest(
    `propertyPhases/removePropertyType`,
    removePropertyTypeRequest
  );
  yield takeLatest(
    `propertyPhases/removePropertySize`,
    removePropertySizeRequest
  );
  yield takeLatest(`propertyPhases/getPropertyNames`, getPropertyNamesRequest);
  yield takeLatest(`propertyPhases/approveProperty`, approvePropertyRequest);
  yield takeLatest(`propertyPhases/rejectProperty`, rejectPropertyRequest);
  yield takeLatest(`propertyPhases/getProperties`, getPropertiesRequest);
  yield takeLatest(`propertyPhases/addPropertyName`, addPropertySNameRequest);
  yield takeLatest(`propertyPhases/addPropertyType`, addPropertyTypeRequest);
  yield takeLatest(`propertyPhases/addPropertySize`, addPropertySizeRequest);
  yield takeLatest(`propertyPhases/getPropertyTypes`, getPropertyTypesRequest);
  yield takeLatest(`propertyPhases/getPropertySizes`, getPropertySizesRequest);
  yield takeLatest(`propertyPhases/getUpdatedPhase`, editPropertyPhaseRequest);
  yield takeLatest(
    `propertyPhases/getUpdatedPropertyName`,
    editPropertyNameRequest
  );
  yield takeLatest(
    `propertyPhases/getUpdatedPropertySize`,
    editPropertySizeRequest
  );
  yield takeLatest(
    `propertyPhases/getUpdatedPropertyType`,
    editPropertyTypeRequest
  );
  yield takeLatest(
    `propertyPhases/getUpdatedPropertyStreet`,
    editPropertyStreetRequest
  );
}
