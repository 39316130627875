import { put, call, takeLatest } from "redux-saga/effects";
import {
  getProfile,
  updateProfile,
  updatePassword,
} from "../../api/profile/profile-api";
import { profileActions } from "../../store";
///
///

function* getProfileRequest({ payload }) {
  const { requestError, getProfileSuccess } = profileActions;
  const result = yield call(getProfile, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getProfileSuccess(result.data));
  }
}

function* updateProfileRequest({ payload }) {
  const { requestError, updateProfileSuccess, getProfile } = profileActions;
  const result = yield call(updateProfile, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(updateProfileSuccess(result.data));
    yield put(
      getProfile(
        JSON.parse(localStorage.getItem("persist:auth")).token.split(`"`)[1]
      )
    );
  }
}

function* updatePasswordRequest({ payload }) {
  const { requestError, updatePasswordSuccess, getProfile } = profileActions;
  const result = yield call(updatePassword, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(updatePasswordSuccess(result.data));
    yield put(
      getProfile(
        JSON.parse(localStorage.getItem("persist:auth")).token.split(`"`)[1]
      )
    );
  }
}

// Export the saga (todo-saga)
export default function* profileSaga() {
  yield takeLatest(`profile/getProfile`, getProfileRequest);
  yield takeLatest(`profile/updateProfile`, updateProfileRequest);
  yield takeLatest(`profile/updatePassword`, updatePasswordRequest);
}
