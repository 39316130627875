import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

const initialState = {
  isLoading: false,
  residentsLoading: false,
  properties: [],
  residents: [],
  propertyId: null,
};

const homeOwnerPropertiesSlice = createSlice({
  name: "homeownerProperties",
  initialState,
  reducers: {
    getProperties(state) {
      state.properties = [];
      state.isLoading = true;
    },
    getPropertiesSuccess(state, action) {
      state.isLoading = false;
      state.properties = action.payload;
    },
    getResidents(state, action) {
      state.residents = [];
      state.propertyId = action.payload;
      state.residentsLoading = true;
    },
    getResidentsSuccess(state, action) {
      state.residentsLoading = false;
      state.residents = action.payload;
    },
    removeResident(state) {
      state.isLoading = false;
    },
    removeResidentSuccess(state, action) {
      console.log(action.payload);
      state.isLoading = false;
    },
    getUpdatedProfileResident(state) {
      state.isLoading = true;
    },
    getUpdatedProfileResidentSuccess(state, action) {
      state.isLoading = false;
      message.success(action.payload.message);
    },
    requestError(state, action) {
      state.isLoading = false;
      message.error(action.payload.data.message);
    },
  },
});

export const {
  getProperties,
  getPropertiesSuccess,
  getResidents,
  getResidentsSuccess,
  removeResident,
  removeResidentSuccess,
  getUpdatedProfileResident,
  getUpdatedProfileResidentSuccess,
  requestError,
} = homeOwnerPropertiesSlice.actions;
export default homeOwnerPropertiesSlice;
