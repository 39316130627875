import { put, call, takeLatest } from "redux-saga/effects";
import { updateHomeownerProfile } from "../../api/homeowner/edit-profile-api";
import { getHomeowners } from "../../api/homeowner/homeowner-api";
import { homeownersActions } from "../../store";
import { homeownerProfileActions } from "../../store";
///
///

function* updateHomeownerProfileRequest({ payload }) {
  const { requestError, getUpdatedProfileSuccess } = homeownerProfileActions;
  const result = yield call(updateHomeownerProfile, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getUpdatedProfileSuccess(result.data));
  }
}

function* getHomeonwersRequest() {
  const { requestError, getHomeownersSuccess } = homeownersActions;
  const result = yield call(getHomeowners);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getHomeownersSuccess(result.data));
  }
}

// Export the saga (todo-saga)
export default function* homeownerProfileSaga() {
  yield takeLatest(
    `homeownerProfile/getUpdatedProfile`,
    updateHomeownerProfileRequest
  );
  yield takeLatest(
    `homeownerProfile/getUpdatedProfileSuccess`,
    getHomeonwersRequest
  );
}
