import defaultAxios from "axios";

const axios = defaultAxios.create({
  baseURL: process.env.REACT_APP_BASE_URL + `/api/associations/properties/`,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

axios.interceptors.request.use(async function (config) {
  config.headers["Authorization"] = `Bearer ${
    JSON.parse(await localStorage.getItem("persist:auth")).token.split(`"`)[1]
  }`;
  return config;
});

export const editPropertyPhase = async (data) => {
  try {
    const result = await axios.post(
      "editPropertyPhase?phaseId=" + data.params,
      data.body
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const editPropertyName = async (data) => {
  try {
    const result = await axios.post(
      "editPropertyName?propertyNameId=" + data.params,
      data.body
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const editPropertyType = async (data) => {
  try {
    const result = await axios.post(
      "editPropertyType?propertyTypeId=" + data.params,
      data.body
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const editPropertySize = async (data) => {
  try {
    const result = await axios.post(
      "editPropertySize?propertySizeId=" + data.params,
      data.body
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const editPropertyStreet = async (data) => {
  try {
    const result = await axios.post(
      "editPropertyStreet?streetId=" + data.params,
      data.body
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};
