import { ArrowLeftOutlined, EditOutlined } from "@ant-design/icons";
import { Drawer, Card, Button, Table } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getAssociationCharges,
  getBillingStatement,
  getPreviousBillingStatement,
} from "../../../store/slices/billing/billing-slice";
import BillingDetails from "./Paymentsummary/BillingDetails";
import PreviousBilling from "./Paymentsummary/PreviousBilling";
import {
  format_PHCurrency,
  format_addCommas,
} from "../../../helpers/formatCurrency";
import BillingForm from "./BillingForm";
import Chargesbreakdown from "./Paymentsummary/Chargesbreakdown";
import { LoadingTime } from "../../../UI/LoadingScreen";

const ViewBill = ({ open, close, billId }) => {
  const dispatch = useDispatch();
  const [isEditing, setisEditing] = useState(false);

  const {
    billingStatement,
    billingStatementLoading,
    previousBillingStatements,
    previousBillingStatementsLoading,
  } = useSelector((state) => state.billing);

  const columns = [
    {
      title: "Billing date",
      dataIndex: "billingDate",
      render: (billingDate) => moment(billingDate).format("LL"),
      width: "200px",
    },
    {
      title: "Due date",
      dataIndex: "dueDate",
      render: (billingDate) => moment(billingDate).format("LL"),
      width: "200px",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (amount) =>
        amount ? format_PHCurrency(amount) : format_PHCurrency(0),
      width: "200px",
    },
    {
      title: "Vat amount",
      dataIndex: "vatAmount",
      render: (amount) =>
        amount ? format_PHCurrency(amount) : format_PHCurrency(0),
      width: "200px",
    },
    {
      title: "Total amount",
      dataIndex: "totalAmount",
      render: (amount) =>
        amount ? format_PHCurrency(amount) : format_PHCurrency(0),
      // render: (record) =>
      //   format_addCommas(
      //     // (
      //     //   +record.charges
      //     //     ?.map((obj) => +obj.amount)
      //     //     .reduce((num, curr) => num + curr, 0)
      //     //     .toFixed(2) +
      //     //   +record.charges
      //     //     ?.map((obj) => +obj.additionalAmount)
      //     //     .reduce((num, curr) => num + curr, 0)
      //     //     .toFixed(2) +
      //     //   +record.totalAmount
      //     // ).toFixed(2)
      //     (+record).toFixed(2)
      //   ),
      width: "200px",
    },
  ];

  useEffect(() => {
    if (billId) {
      dispatch(getBillingStatement({ params: billId }));
    }
  }, [billId]);

  const getDrawerStyle = () => {
    const style = { position: "absolute" };
    if (open) {
      style.transform = undefined; // set to undefined here
    }
    return style;
  };

  const onEdit = () => {
    setisEditing(true);
  };

  const onClose = () => {
    setisEditing(false);
  };

  useEffect(() => {
    dispatch(getAssociationCharges());
  }, []);

  return (
    <div>
      <LoadingTime loading={billingStatementLoading} />
      <Drawer
        style={getDrawerStyle()}
        className="p-5"
        centered
        onClose={close}
        onCancel={close}
        open={open}
        height={"90%"}
        closable={false}
        placement={"bottom"}
      >
        <div className="flex flex-col w-full h-full">
          <div className="">
            <div className="flex flex-col md:flex-row md:items-center items-start justify-start">
              <div
                onClick={() => close(true)}
                className="w-[50px] h-[50px] items-center text-center flex justify-center text-[20px] rounded-full hover:bg-slate-200 transition-all ease-in duration-150 cursor-pointer hover:scale-110"
              >
                <ArrowLeftOutlined className="text-[#1b1b1b]" />
              </div>

              <h1 className="py-5 font-[600] text-[#474d63] text-[32px]">
                Statement summary
              </h1>
            </div>
            <div className="flex flex-col gap-7">
              {/* <BillingDetails
                billingStatement={billingStatement}
                billingStatementLoading={billingStatementLoading}
                billId={billId}
                previousBillingId={billingStatement?.previousBillingId}
              /> */}
              <Card
                title={
                  <div className="flex justify-between  items-center p-5">
                    <h1 className="font-[600] text-[25px] mb-0">
                      Billing details
                    </h1>
                    {/* <div className="flex items-center  text-center justify-center">
                      <Button
                        style={{
                          border: "1px solid transparent",
                          alignItems: "center",
                          display: "flex",
                        }}
                        type="outlined"
                        onClick={() => onEdit()}
                        icon={
                          <EditOutlined className="text-2xl h-full items-center flex justify-center" />
                        }
                      >
                        Edit
                      </Button>
                    </div> */}
                  </div>
                }
              >
                <Table
                  loading={billingStatementLoading}
                  className="table-charge"
                  rowKey={"key"}
                  columns={columns}
                  scroll={{
                    y: 500,
                    x: "100%",
                  }}
                  dataSource={[{ ...billingStatement, key: 1 }]}
                  expandable={{
                    expandedRowRender: (record) => (
                      <Chargesbreakdown
                        billingStatementLoading={billingStatementLoading}
                        billingStatement={billingStatement}
                        billId={billId}
                      />
                    ),
                    rowExpandable: (record) => record.name !== "Not Expandable",
                  }}
                />
              </Card>
              <PreviousBilling
                previousBillingStatementLoading={billingStatementLoading}
                billingStatement={billingStatement}
                previousBillingStatement={previousBillingStatements}
                billId={billId}
                previousBillingId={billingStatement?.previousBillingId}
              />
              {/* <PreviousBilling
                previousBillingStatementLoading={
                  previousBillingStatementsLoading
                }
                billingStatement={billingStatement}
                previousBillingStatement={previousBillingStatements}
              /> */}
            </div>
          </div>
        </div>
      </Drawer>
      <BillingForm
        isEditing={isEditing}
        close={onClose}
        open={isEditing}
        billId={billId}
        billingStatement={billingStatement}
      />
    </div>
  );
};

export default ViewBill;
