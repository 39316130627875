import { put, call, takeLatest } from "redux-saga/effects";
import { getHomeownerProperties } from "../../api/homeowner/homeowner-properties-api";
import { homeownerPropertiesActions } from "../../store";
import { getPropertyResidents } from "../../api/homeowner/property-residents-api";
import { removePropertyResident } from "../../api/homeowner/remove-resident-api";
import { updateResidentProfile } from "../../api/homeowner/edit-resident-api";
///
///

function* getHomeownerPropertiesRequest(action) {
  const { requestError, getPropertiesSuccess } = homeownerPropertiesActions;
  const result = yield call(getHomeownerProperties, action.payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getPropertiesSuccess(result.data));
  }
}

function* getPropertyResidentsRequest({ payload }) {
  const { requestError, getResidentsSuccess } = homeownerPropertiesActions;
  const result = yield call(getPropertyResidents, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getResidentsSuccess(result.data));
  }
}

function* removePropertyResidentRequest({ payload }) {
  console.log(payload);
  const { requestError, removeResidentSuccess, getResidents } =
    homeownerPropertiesActions;
  const result = yield call(removePropertyResident, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(removeResidentSuccess(payload.propertyId));
    yield put(getResidents(payload.params));
  }
}

function* updateResidentProfileRequest({ payload }) {
  console.log(payload);
  const { requestError, getUpdatedProfileResidentSuccess } =
    homeownerPropertiesActions;
  const result = yield call(updateResidentProfile, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getUpdatedProfileResidentSuccess(payload.propertyId));
  }
}

// Export the saga (todo-saga)
export default function* homeownerProfileSaga() {
  yield takeLatest(
    `homeownerProperties/getProperties`,
    getHomeownerPropertiesRequest
  );
  yield takeLatest(
    `homeownerProperties/getResidents`,
    getPropertyResidentsRequest
  );
  yield takeLatest(
    `homeownerProperties/removeResident`,
    removePropertyResidentRequest
  );
  yield takeLatest(
    `homeownerProperties/getUpdatedProfileResident`,
    updateResidentProfileRequest
  );
  yield takeLatest(
    `homeownerProperties/getUpdatedProfileResidentSuccess`,
    getPropertyResidentsRequest
  );
}
