import { motion } from "framer-motion";
import React from "react";
// import { circle } from "../../assets/design";
import { circle } from "../../../assets/landing/design";
// import { objBenefits } from "../../constants/static";
import { objBenefits } from "../../../constants/static";

const Objectives = ({ textAnimate, imageAnimate }) => {
	return (
		<>
			<div className="lg:pt-20 md:pt-16 xs:pt-10 pt-5 pb-20 px-4">
				<motion.div
					className=" grid grid-cols-8 md:px-20 sm:px-14 px-5 xs:pt-20 pt-10 sm:pb-28 xs:pb-16 pb-10 bg-[#20683B] rounded-lg"
					initial={"offscreen"}
					whileInView={"onscreen"}
					viewport={{ once: true, amount: 0.3 }}
					transition={{ staggerChildren: 0.2 }}
				>
					<motion.div
						className=" col-span-8 text-white md:pb-14 sm:pb-7 pb-4"
						variants={textAnimate}
					>
						<motion.h1 className=" md:text-5xl sm:text-4xl text-3xl font-bold">Benefits</motion.h1>
					</motion.div>
					<motion.div
						className=" 2xl:col-span-4 col-span-8 text-white flex flex-col sm:gap-y-8 gap-y-4"
						variants={textAnimate}
					>
						{objBenefits.map((obj, index) => {
							return (
								<motion.div className=" flex flex-col sm:gap-y-8 gap-y-2" key={index}>
									<motion.li
										className=" list-disc sm:text-xl text-[16px] font-semibold"
										// variants={textAnimate}
									>
										{obj.title}
									</motion.li>
									<motion.p
										className=" sm:text-[16px] text-sm sm:leading-8 leading-7 pl-7 md:pr-16 sm:pr-4 pr-0"
										// variants={textAnimate}
									>
										{obj.content}
									</motion.p>
								</motion.div>
							);
						})}
					</motion.div>
					<motion.div
						className=" 2xl:col-span-2 col-span-4 flex-col 2xl:justify-start justify-center 2xl:items-end items-center gap-y-16 2xl:mt-0 mt-14 sm:flex hidden"
						initial={"offscreen"}
						whileInView={"onscreen"}
						viewport={{ once: true, amount: 0.2 }}
						transition={{ staggerChildren: 0.2 }}
					>
						<motion.div className=" z-[1] relative" variants={imageAnimate}>
							<motion.img
								className="absolute top-0 left-0 translate-y-[-50px] translate-x-[-25px] z-[-1]"
								src={circle}
								alt="/"
							/>
							<motion.div
								className=" px-3 py-9 shadow-lg rounded-3xl text-center mx-7 max-w-[335px] min-h-[230px] bg-white flex flex-col justify-center items-center"
								// variants={imageAnimate}
							>
								<motion.h1 className=" text-xl font-bold pb-2">1</motion.h1>
								<motion.p className=" text-sm font-medium md:mx-5 mx-1">
									To build an online platform that will help the community to manage their
									properties easily and conveniently.
								</motion.p>
							</motion.div>
						</motion.div>
						<motion.div
							className=" px-3 py-9 shadow-lg rounded-3xl text-center mt-5 mx-7 max-w-[335px] min-h-[230px] bg-white flex flex-col justify-center items-center"
							variants={imageAnimate}
						>
							<motion.h1 className=" text-xl font-bold pb-2">3</motion.h1>
							<motion.p className=" text-sm font-medium md:mx-5 mx-1">
								To create awareness to property owners, residents, tenants, office workers,
								visitors, etc.
							</motion.p>
						</motion.div>
					</motion.div>
					<motion.div
						className=" 2xl:col-span-2 col-span-4 flex-col 2xl:justify-start justify-center 2xl:items-end items-center gap-y-16 mt-24 sm:flex hidden"
						initial={"offscreen"}
						whileInView={"onscreen"}
						viewport={{ once: true, amount: 0.2 }}
						transition={{ staggerChildren: 0.3 }}
					>
						<motion.div
							className=" px-3 py-9 shadow-lg rounded-3xl text-center mx-7 max-w-[335px] min-h-[230px] bg-white flex flex-col justify-center items-center "
							variants={imageAnimate}
						>
							<motion.h1 className=" text-xl font-bold pb-2">2</motion.h1>
							<motion.p className=" text-sm font-medium md:mx-5 mx-1">
								To process payments instantly and securely.
							</motion.p>
						</motion.div>
						<motion.div className=" z-[1] relative" variants={imageAnimate}>
							<motion.img
								className="absolute bottom-0 right-0 translate-y-[85px] translate-x-[25px] z-[-1]"
								src={circle}
								alt="/"
							/>

							<motion.div
								className=" px-3 py-9 shadow-lg rounded-3xl text-center mt-5 mx-7 max-w-[335px] min-h-[230px] bg-white flex flex-col justify-center items-center"
								// variants={imageAnimate}
							>
								<motion.h1 className=" text-xl font-bold pb-2">4</motion.h1>
								<motion.p className=" text-sm font-medium md:mx-5 mx-1">
									To create awareness to property owners, residents, tenants, office workers,
									visitors, etc.
								</motion.p>
							</motion.div>
						</motion.div>
					</motion.div>
				</motion.div>
			</div>
		</>
	);
};

export default Objectives;
