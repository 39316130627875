import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Divider,
  Dropdown,
  Form,
  Input,
  Modal,
  Popconfirm,
  Space,
  Table,
  Tag,
  theme,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addUtilityReportType,
  getUtilityReportTypes,
  removeUtilityType,
  updateUtilityReportType,
} from "../../../../store/slices/inquiries/inquiries-slice";
import { LoadingTime } from "../../../../UI/LoadingScreen";
import ModalButton from "../../../../UI/ModalButton";
import "../../properties/property.css";
import PopconfirmRemove from "../../../../UI/PopconfirmRemove";

const { useToken } = theme;

const UtilityReportTypes = () => {
  const { token } = useToken();
  const [form] = Form.useForm();
  const { utilityReportTypes, utilityReportTypesLoading } = useSelector(
    (state) => state.inquiry
  );
  const [addModal, setAddModal] = useState(false);
  const [typeId, setTypeId] = useState();
  const [editModal, setEditModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedPhase, setSelectedPhase] = useState();
  const dispatch = useDispatch();

  const text = "Are you sure to remove this report type?";
  const description = "Remove the report type";

  const items = [
    {
      label: (
        <div className="flex gap-2 items-center">
          <EditOutlined />
          <p>Edit</p>
        </div>
      ),
      key: "1",
    },
  ];

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle = {
    boxShadow: "none",
  };

  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "150px",
      render: (record) => (
        <Dropdown
          trigger={["click"]}
          menu={{
            items,
            onClick: () => editHandler(record.typeId),
          }}
          dropdownRender={(menu) => (
            <div className="flex flex-col items-start" style={contentStyle}>
              {React.cloneElement(menu, {
                style: menuStyle,
              })}
              <PopconfirmRemove
                text={text}
                desc={description}
                onConfirm={() => deleteReportType(record.typeId)}
              />
              {/* <Popconfirm
                placement="right"
                title={text}
                description={description}
                onConfirm={() => deleteReportType(record.typeId)}
                okText="Yes"
                cancelText="Cancel"
              >
                <Button
                  style={{ color: "black" }}
                  type="link"
                  icon={<DeleteOutlined />}
                >
                  Remove
                </Button>
              </Popconfirm> */}
            </div>
          )}
        >
          <Button
            type="primary"
            style={{
              background: "#4d8e03",
            }}
          >
            <Space>
              Action
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      width: "200px",
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    {
      title: "Date Created",
      dataIndex: "dateCreated",
      width: "200px",
      editable: true,
      render: (dateCreated) => moment(dateCreated).format("LL"),
    },
    {
      title: "Date Updated",
      dataIndex: "dateUpdated",
      width: "200px",
      editable: true,
      render: (dateUpdated) => moment(dateUpdated).format("LL"),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "200px",
      editable: true,
      render: (status) =>
        status === 1 ? (
          <Tag color="volcano">Inactive</Tag>
        ) : (
          <Tag color="blue">Active</Tag>
        ),
    },
  ];

  useEffect(() => {
    const found = utilityReportTypes.find((obj) => obj.typeId === typeId);

    if (isEdit) {
      form.setFieldsValue({
        ...found,
        reportType: found?.name,
      });
    } else {
      form.resetFields();
    }
  }, [utilityReportTypes, typeId]);

  useEffect(() => {
    dispatch(getUtilityReportTypes());
  }, []);

  const onFinish = (values) => {
    !isEdit
      ? dispatch(
          addUtilityReportType({
            name: values.reportType,
          })
        )
      : dispatch(
          updateUtilityReportType({
            params: typeId,
            body: { name: values?.reportType },
          })
        );

    setAddModal(false);
    form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {};

  const showDrawer = (record) => {
    setAddModal(true);
    setIsEdit(false);
    form.resetFields();
  };

  const onClose = () => {
    setAddModal(false);
    setIsEdit(false);
  };

  const editHandler = (value) => {
    setIsEdit(true);
    setAddModal(true);
    setTypeId(value);
  };

  const deleteReportType = (value) => {
    dispatch(removeUtilityType(value));
  };

  return (
    <div>
      <LoadingTime loading={utilityReportTypesLoading} />
      <Card
        title={
          <div className="flex justify-between w-full items-center p-5">
            <h1 className="font-[600] text-[25px] mb-0">Utility Report Type</h1>
            <button
              onClick={showDrawer}
              type="button"
              className="hidden md:block rounded-[10px] text-[18px] max-w-[200px] md:max-w-[173px] w-full h-[52px] border-transparent px-6 py-2.5 bg-properity text-white font-medium  leading-tight uppercase shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
            >
              ADD
            </button>
            <div className="flex items-center md:hidden text-center justify-center">
              <Button
                style={{
                  border: "1px solid transparent",
                  alignItems: "center",
                  display: "flex",
                }}
                type="outlined"
                onClick={showDrawer}
                icon={
                  <PlusSquareOutlined className="text-2xl h-full items-center flex justify-center" />
                }
              >
                Add
              </Button>
            </div>
          </div>
        }
      >
        <Modal
          title={
            <h1 className="text-[24px] md:text-[30px] font-[600] text-[#1b1b1b] mb-0 ">
              {!isEdit ? "Add report type" : "Edit report type"}
            </h1>
          }
          centered
          open={addModal}
          onOk={() => setAddModal(false)}
          onCancel={onClose}
          footer={false}
        >
          <Form
            layout="vertical"
            form={form}
            disabled={utilityReportTypesLoading}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Divider />
            <Form.Item
              label={<h1 className="form-title">Report type</h1>}
              name="reportType"
              rules={[
                {
                  required: true,
                  message: "Please input report type",
                },
              ]}
            >
              <Input
                style={{
                  padding: "10px",
                  fontSize: "18px",
                  borderRadius: "3px",
                }}
                placeholder="Enter report type"
                size="large"
              />
            </Form.Item>
            <ModalButton text={isEdit ? "Update" : "Add"} />
          </Form>
        </Modal>
        <Table
          loading={utilityReportTypesLoading}
          rowKey={"typeId"}
          columns={columns}
          dataSource={utilityReportTypes}
          scroll={{
            y: 500,
            x: "100%",
          }}
        />
      </Card>
    </div>
  );
};
export default UtilityReportTypes;
