import React from "react";

const Contact = () => {
  return (
    <div className="flex flex-col gap-5">
      <h1 className="md:text-[28px] text-[16px] font-semibold">Contact us</h1>
      <p className="text-gray-600 text-justify text-[14px] md:text-[18px]">
        If you have any questions and inquiries regarding this Privacy Policy
        and Terms & Conditions, please reach us via emailing
        privacy@properity.com.ph
      </p>
    </div>
  );
};

export default Contact;
