import { Button, Card, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  profileMode,
  updatePassword,
} from "../../../store/slices/profile/profile-slice";

const UpdatePassword = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const dispatch = useDispatch();
  const { profile, mode, isLoading } = useSelector((state) => state.profile);

  useEffect(() => {
    if (mode === "editProfile") {
      form.resetFields();
    }
  }, [mode]);

  useEffect(() => {
    dispatch(profileMode("editPass"));
  }, [location.pathname]);

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    dispatch(
      updatePassword({
        oldPassword: values?.oldPass,
        password: values?.newPass,
      })
    );
    form.resetFields();
  };

  return (
    <div className="w-full">
      <Card
        title={
          <div className="flex justify-between w-full items-center p-5">
            <h1 className="font-[600] text-[25px] mb-0">
              Password and security
            </h1>
          </div>
        }
      >
        <div className="w-full h-full">
          <Form
            form={form}
            name="password"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
          >
            <div className="flex md:flex-col flex-col gap-10 items-center md:items-start p-6">
              <div className="flex flex-col gap-4 w-full md:w-[40%]  md:flex-1 ">
                <div className="text-label">
                  <Form.Item
                    name="oldPass"
                    label={
                      <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
                        Old password
                      </h1>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please input your password",
                      },
                    ]}
                  >
                    <Input.Password
                      style={{
                        padding: "10px",
                        fontSize: "18px",
                        borderRadius: "3px",
                      }}
                      placeholder="Enter old password"
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item
                    name="newPass"
                    label={
                      <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
                        New password
                      </h1>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please input your password",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password
                      style={{
                        padding: "10px",
                        fontSize: "18px",
                        borderRadius: "3px",
                      }}
                      placeholder="Enter new password"
                    />
                  </Form.Item>

                  <Form.Item
                    name="confirmPass"
                    label={
                      <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
                        Confirm password
                      </h1>
                    }
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("newPass") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The two passwords that you entered do not match"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      style={{
                        padding: "10px",
                        fontSize: "18px",
                        borderRadius: "3px",
                      }}
                      placeholder="Confirm password"
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>
          <div className="flex gap-3 px-6">
            <Button
              loading={isLoading}
              onClick={form.submit}
              type="primary"
              style={{
                transition: "all ease 0.3s",
                background: "#4d8e03",
              }}
            >
              Change password
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default UpdatePassword;
