import { put, call, takeLatest } from "redux-saga/effects";
import {
  getResidentsCount,
  getHomeownersCount,
  getInquiriesCount,
  getDailyTransactionsCount,
  getOverdueTransactions,
  getReportsCount,
  getYearlyTransactionsCount,
  getRevenues,
  getActivities,
} from "../../api/dashboard/users-count-api";
import { usersActions } from "../../store";
///
///

function* getResidentsCountRequest({ payload }) {
  const { requestError, getResidentsCountSuccess } = usersActions;
  const result = yield call(getResidentsCount, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getResidentsCountSuccess(result.data));
  }
}

function* getHomeownersCountRequest({ payload }) {
  const { requestError, getHomeownersCountSuccess } = usersActions;
  const result = yield call(getHomeownersCount, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getHomeownersCountSuccess(result.data));
  }
}

function* getInquiriesCountRequest({ payload }) {
  const { requestError, getInquiriesCountSuccess } = usersActions;
  const result = yield call(getInquiriesCount, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getInquiriesCountSuccess(result.data));
  }
}

function* getDailyTransactionsCountRequest({ payload }) {
  const { requestError, getDailyTransactionsCountSuccess } = usersActions;
  const result = yield call(getDailyTransactionsCount, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getDailyTransactionsCountSuccess(result.data));
  }
}

function* getOverdueTransactionsRequest({ payload }) {
  const { requestError, getOverdueTransactionsSuccess } = usersActions;
  const result = yield call(getOverdueTransactions, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getOverdueTransactionsSuccess(result.data));
  }
}

function* getReportsCountRequest({ payload }) {
  const { requestError, getReportsCountSuccess } = usersActions;
  const result = yield call(getReportsCount, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getReportsCountSuccess(result.data));
  }
}

function* getYearlyTransactionsCountRequest({ payload }) {
  const { requestError, getYearlyTransactionsCountSuccess } = usersActions;
  const result = yield call(getYearlyTransactionsCount, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getYearlyTransactionsCountSuccess(result.data));
  }
}

function* getRevenuesRequest({ payload }) {
  const { requestError, getRevenuesSuccess } = usersActions;
  const result = yield call(getRevenues, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getRevenuesSuccess(result.data));
  }
}

function* getActivitiesRequest({ payload }) {
  const { requestError, getActivitiesSuccess } = usersActions;
  const result = yield call(getActivities, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getActivitiesSuccess(result.data));
  }
}

// Export the saga (todo-saga)
export default function* usersSaga() {
  yield takeLatest(`users/getResidentsCount`, getResidentsCountRequest);
  yield takeLatest(`users/getActivities`, getActivitiesRequest);
  yield takeLatest(`users/getRevenues`, getRevenuesRequest);
  yield takeLatest(
    `users/getYearlyTransactionsCount`,
    getYearlyTransactionsCountRequest
  );
  yield takeLatest(`users/getReportsCount`, getReportsCountRequest);
  yield takeLatest(`users/getHomeownersCount`, getHomeownersCountRequest);
  yield takeLatest(`users/getInquiriesCount`, getInquiriesCountRequest);
  yield takeLatest(
    `users/getOverdueTransactions`,
    getOverdueTransactionsRequest
  );
  yield takeLatest(
    `users/getDailyTransactionsCount`,
    getDailyTransactionsCountRequest
  );
}
