import { put, call, takeLatest } from "redux-saga/effects";
import {
  onboarding,
  verifyEmail,
} from "../../api/registration-api/registration-api";
import { onboardingActions } from "../../store";
///
///

function* onboardingRequest({ payload }) {
  const { requestError, onboardingSuccess } = onboardingActions;
  const result = yield call(onboarding, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(onboardingSuccess(result.data));
  }
}

function* verifyEmailRequest({ payload }) {
  const { requestError, verifyEmailSuccess } = onboardingActions;
  const result = yield call(verifyEmail, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(verifyEmailSuccess(result.data));
  }
}

// Export the saga (todo-saga)
export default function* onboardingSaga() {
  yield takeLatest(`onboarding/onboarding`, onboardingRequest);
  yield takeLatest(`onboarding/verifyEmail`, verifyEmailRequest);
}
