import { PlusSquareOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";

const CardTitle = ({ add, title, onAdd }) => {
  return (
    <div className="flex justify-between w-full items-center p-5">
      <h1 className="font-[600] text-[25px] mb-0">{title}</h1>
      <button
        onClick={onAdd}
        type="button"
        className="hidden md:block rounded-[10px] text-[18px] max-w-[200px] md:max-w-[173px] w-full h-[52px] border-transparent px-6 py-2.5 bg-properity text-white font-medium  leading-tight uppercase shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
      >
        {add}
      </button>
      <div className="flex items-center md:hidden text-center justify-center">
        <Button
          style={{
            border: "1px solid transparent",
            alignItems: "center",
            display: "flex",
          }}
          type="outlined"
          onClick={onAdd}
          icon={
            <PlusSquareOutlined className="text-2xl h-full items-center flex justify-center" />
          }
        >
          {add}
        </Button>
      </div>
    </div>
  );
};

export default CardTitle;
