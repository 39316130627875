import { call, put, takeLatest } from "redux-saga/effects";
import {
  getPaymentChannels,
  addPaymentChannel,
  editPaymentChannel,
  deactivatePaymentChannel,
  activatePaymentChannel,
} from "../../api/paymentchannel/payment-channel-api";
import { paymentChannelActions } from "../../store";
///
///

function* getPaymentChannelsRequest() {
  const { requestError, getPaymentChannelsSuccess } = paymentChannelActions;
  const result = yield call(getPaymentChannels);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getPaymentChannelsSuccess(result.data));
  }
}

function* addPaymentChannelRequest({ payload }) {
  const { requestError, addPaymentChannelSuccess, getPaymentChannels } =
    paymentChannelActions;
  const result = yield call(addPaymentChannel, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(addPaymentChannelSuccess(result.data));
    yield put(getPaymentChannels());
  }
}

function* editPaymentChannelRequest({ payload }) {
  const { requestError, editPaymentChannelSuccess, getPaymentChannels } =
    paymentChannelActions;
  const result = yield call(editPaymentChannel, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(editPaymentChannelSuccess(result.data));
    yield put(getPaymentChannels());
  }
}

function* deactivatePaymentChannelRequest({ payload }) {
  const { requestError, deactivatePaymentChannelSuccess, getPaymentChannels } =
    paymentChannelActions;
  const result = yield call(deactivatePaymentChannel, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(deactivatePaymentChannelSuccess(result.data));
    yield put(getPaymentChannels());
  }
}

function* activatePaymentChannelRequest({ payload }) {
  const { requestError, activatePaymentChannelSuccess, getPaymentChannels } =
    paymentChannelActions;
  const result = yield call(activatePaymentChannel, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(activatePaymentChannelSuccess(result.data));
    yield put(getPaymentChannels());
  }
}

// Export the saga (todo-saga)
export default function* paymentChannelSaga() {
  yield takeLatest(
    `paymentChannel/getPaymentChannels`,
    getPaymentChannelsRequest
  );
  yield takeLatest(
    `paymentChannel/addPaymentChannel`,
    addPaymentChannelRequest
  );
  yield takeLatest(
    `paymentChannel/editPaymentChannel`,
    editPaymentChannelRequest
  );
  yield takeLatest(
    `paymentChannel/activatePaymentChannel`,
    activatePaymentChannelRequest
  );
  yield takeLatest(
    `paymentChannel/deactivatePaymentChannel`,
    deactivatePaymentChannelRequest
  );
}
