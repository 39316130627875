import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-scroll";
import PriceCardLayout from "./Pricing/PriceCardLayout";
import PriceFeatures from "./Pricing/PriceFeatures";
import PriceMobileLayout from "./Pricing/PriceMobileLayout";

const Pricing = () => {
  const textAnimate = {
    offscreen: { y: 0, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.3, duration: 0.5 },
    },
  };
  const cardAnimate = {
    offscreen: { x: 0, y: 500, opacity: 0 },
    onscreen: {
      x: 0,
      y: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.3, duration: 0.8 },
    },
  };
  const cardTextAnimate = {
    offscreen: { x: 0, y: 500, opacity: 0 },
    onscreen: {
      x: 0,
      y: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.1, duration: 0.5 },
    },
  };
  //asdasd
  return (
    <>
      <div className=" bg-[#F5F5EE]" id="pricing">
        <div
          className=" w-full mx-auto flex flex-col items-center rounded-lg"
          // id="pricing"
        >
          <motion.div
            className=" text-center xs:my-10 my-5"
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={{ once: true, amount: 0.8 }}
            transition={{ staggerChildren: 0.2 }}
          >
            <motion.h1
              className=" text-[#0B9301] md:text-5xl sm:text-4xl xs:text-3xl text-2xl font-extrabold sm:leading-10 leading-8"
              variants={textAnimate}
            >
              Ready to start with
            </motion.h1>
            <motion.h1
              className=" text-[#0B9301] md:text-5xl sm:text-4xl xs:text-3xl text-2xl font-extrabold sm:leading-10 leading-8 sm:mt-5 mt-2"
              variants={textAnimate}
            >
              Properity?
            </motion.h1>
            <motion.p
              className=" text-[#4D4D4D] sm:text-xl xs:text-lg text-[16px] font-medium sm:py-5 py-2 sm:mt-10 mt-5 m-0"
              variants={textAnimate}
            >
              Start making things easy
            </motion.p>
            <motion.p
              className=" text-[#4D4D4D] sm:text-xl xs:text-lg text-[16px] m-0"
              variants={textAnimate}
            >
              Choose the package that best suits you
            </motion.p>
            <motion.p
              className=" text-[#4D4D4D] sm:text-xl xs:text-lg text-[16px] m-0"
              variants={textAnimate}
            >
              Contact us for pricing
            </motion.p>
          </motion.div>
          <div className=" bg-[#F5F5EE] w-full max-w-full">
            <div className=" sm:hidden block w-full max-w-full">
              <PriceMobileLayout
                cardAnimate={cardAnimate}
                cardTextAnimate={cardTextAnimate}
              />
            </div>
            <div className=" sm:block hidden">
              {/* <PriceCardLayout
                cardAnimate={cardAnimate}
                cardTextAnimate={cardTextAnimate}
              /> */}
              <motion.div
                className=" px-2 bg-[#F6F6F6]"
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: true, amount: 1 }}
                transition={{ staggerChildren: 0.2 }}
              >
                <div className=" w-full py-10 bg-[#F6F6F6]"></div>
                <motion.div
                  className=" grid grid-cols-8 gap-x-0 lg:px-20 px-5"
                  variants={cardAnimate}
                >
                  <motion.div
                    className=" h-[100px] flex items-center col-span-2 rounded-xl bg-white"
                    // variants={cardAnimate}
                  >
                    <motion.h1
                      className="lg:ml-16 md:ml-5 ml-2 text-2xl font-semibold text-[#139018]"
                      // variants={cardTextAnimate}
                    >
                      Features:
                    </motion.h1>
                  </motion.div>
                  <motion.div
                    className=" h-[100px] flex items-center justify-center col-span-2 rounded-xl bg-white"
                    // variants={cardAnimate}
                  >
                    <motion.h1
                      className=" text-2xl font-semibold text-[#139018]"
                      // variants={cardTextAnimate}
                    >
                      Starter
                    </motion.h1>
                  </motion.div>
                  <motion.div
                    className=" h-[100px] flex items-center justify-center col-span-2 rounded-xl bg-white"
                    // variants={cardAnimate}
                  >
                    <motion.h1
                      className=" text-2xl font-semibold text-[#139018]"
                      // variants={cardTextAnimate}
                    >
                      Plus
                    </motion.h1>
                  </motion.div>
                  <motion.div
                    className=" h-[100px] flex items-center justify-center col-span-2 rounded-xl bg-white"
                    // variants={cardAnimate}
                  >
                    <motion.h1
                      className=" text-2xl font-semibold text-[#139018]"
                      // variants={cardTextAnimate}
                    >
                      Premium
                    </motion.h1>
                  </motion.div>
                </motion.div>
              </motion.div>
              <PriceFeatures
                cardAnimate={cardAnimate}
                cardTextAnimate={cardTextAnimate}
              />
            </div>
          </div>
          <motion.div
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={{ once: true, amount: 0.8 }}
            transition={{ staggerChildren: 0.3 }}
            className="flex justify-center items-center sm:mx-0 mx-4"
          >
            <motion.div
              className=" sm:px-14 px-4 sm:py-4 py-2 bg-[#4D8E03] text-white rounded-lg sm:my-14 my-10"
              variants={textAnimate}
            >
              <motion.h1 className=" sm:text-lg text-sm text-center">
                Need a customized solution? Reach out through this{" "}
                <Link
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={500}
                  className=" cursor-pointer underline"
                >
                  form
                </Link>
              </motion.h1>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
