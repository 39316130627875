import { put, call, takeLatest } from "redux-saga/effects";
import { authActions, profileActions } from "../../store/store";
import { login } from "../api/auth-api";
///
///

function* loginRequest({ payload }) {
  const { authenticate, loginError } = authActions;

  const result = yield call(login, payload);
  console.log(result);
  if (result.name === "AxiosError") {
    yield put(loginError(result.response));
  } else {
    yield put(authenticate(result.data));
  }
}

// Export the saga (todo-saga)
export default function* authSaga() {
  yield takeLatest(`auth/login`, loginRequest);
}
