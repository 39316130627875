import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

const initialState = {
  paymentChannelLoading: false,
  paymentChannels: [],
};

const paymentChannelSlice = createSlice({
  name: "paymentChannel",
  initialState,
  reducers: {
    getPaymentChannels(state) {
      state.paymentChannels = [];
      state.paymentChannelLoading = true;
    },
    getPaymentChannelsSuccess(state, action) {
      state.paymentChannels = action.payload;
      state.paymentChannelLoading = false;
    },
    addPaymentChannel(state) {
      state.paymentChannelLoading = true;
    },
    addPaymentChannelSuccess(state, action) {
      message.success(action.payload.message);
      state.paymentChannelLoading = false;
    },
    editPaymentChannel(state) {
      state.paymentChannelLoading = true;
    },
    editPaymentChannelSuccess(state, action) {
      message.success(action.payload.message);
      state.paymentChannelLoading = false;
    },
    deactivatePaymentChannel(state) {
      state.paymentChannelLoading = true;
    },
    deactivatePaymentChannelSuccess(state, action) {
      message.success(action.payload.message);
      state.paymentChannelLoading = false;
    },
    activatePaymentChannel(state) {
      state.paymentChannelLoading = true;
    },
    activatePaymentChannelSuccess(state, action) {
      message.success(action.payload.message);
      state.paymentChannelLoading = false;
    },
    requestError(state, action) {
      state.paymentChannelLoading = false;
      message.error(action.payload.message);
    },
  },
});

export const {
  requestError,
  getPaymentChannels,
  getPaymentChannelsSuccess,
  addPaymentChannel,
  addPaymentChannelSuccess,
  editPaymentChannel,
  editPaymentChannelSuccess,
  deactivatePaymentChannel,
  deactivatePaymentChannelSuccess,
  activatePaymentChannel,
  activatePaymentChannelSuccess,
} = paymentChannelSlice.actions;
export default paymentChannelSlice;
