import { defaultAxios, token } from "../axios";

const baseApi = "/api/";

defaultAxios.interceptors.request.use(async function (config) {
  config.headers["Authorization"] = `Bearer ${
    JSON.parse(await localStorage.getItem("persist:auth")).token.split(`"`)[1]
  }`;
  return config;
});

export const getPlans = async () => {
  try {
    const result = await defaultAxios.get(baseApi + "getPlans");
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const checkout = async (body) => {
  try {
    const result = await defaultAxios.post(
      baseApi + "associations/services/payment/checkout",
      body
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const upgradeSubscription = async (body) => {
  try {
    const result = await defaultAxios.post(
      baseApi + "associations/subscriptions/upgradeSubscription",
      body
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const renewSubscription = async (body) => {
  try {
    const result = await defaultAxios.post(
      baseApi + "associations/subscriptions/renewSubscription",
      body
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const cancelSubscription = async (body) => {
  try {
    const result = await defaultAxios.post(
      baseApi + "associations/subscriptions/cancelSubscription",
      body
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getSubscription = async (body) => {
  try {
    const result = await token.get(
      `/api/associations/subscriptions/` + "getSubscription",
      {
        headers: {
          Authorization: "Bearer " + body,
        },
      }
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};
