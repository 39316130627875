import React from "react";
import { Tabs } from "antd";
import "../announcements/announcements.css";
import EventsTab from "./EventsTab";
import EventsType from "./EventsType";

const items = [
  { label: "Events", key: "item-1", children: <EventsTab /> }, // remember to pass the key prop
  { label: "Event Types", key: "item-2", children: <EventsType /> },
];

const Events = () => (
  <Tabs
    defaultActiveKey="item-1"
    type="card"
    className="tabs-events"
    animated={true}
    items={items}
  ></Tabs>
);
export default Events;
