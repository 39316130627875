import defaultAxios from "axios";
import { token } from "../axios";

const axios = defaultAxios.create({
  baseURL: process.env.REACT_APP_BASE_URL + `/api/associations/services/`,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

axios.interceptors.request.use(async function (config) {
  config.headers["Authorization"] = `Bearer ${
    JSON.parse(await localStorage.getItem("persist:auth")).token.split(`"`)[1]
  }`;
  return config;
});

// export const getInquiries = async () => {
//   try {
//     const result = await axios.get("getInquiries");
//     console.log(result);
//     return result;
//   } catch (err) {
//     console.log(err);
//     return err;
//   }
// };

export const getInquiries = async (body) => {
  try {
    const result = await token.get(
      `/api/associations/services/` + "getInquiries",
      {
        headers: {
          Authorization: "Bearer " + body,
        },
      }
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const processInquiry = async (data) => {
  try {
    const result = await axios.post("processInquiry/" + data.params, data.body);
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const resolveInquiry = async (data) => {
  try {
    const result = await axios.post("resolveInquiry/" + data.params, data.body);
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const removeInquiry = async (params) => {
  try {
    const result = await axios.post("removeInquiry/" + params);
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

// export const getUtilityReports = async () => {
//   try {
//     const result = await axios.get("getUtilityReports");
//     console.log(result);
//     return result;
//   } catch (err) {
//     console.log(err);
//     return err;
//   }
// };

export const getUtilityReports = async (body) => {
  try {
    const result = await token.get(
      `/api/associations/services/` + "getUtilityReports",
      {
        headers: {
          Authorization: "Bearer " + body,
        },
      }
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const processUtilityReport = async (params) => {
  try {
    const result = await axios.post("processUtilityReport/" + params);
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const resolveUtilityReport = async (params) => {
  try {
    const result = await axios.post("resolveUtilityReport/" + params);
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const removeUtilityReport = async (params) => {
  try {
    const result = await axios.post("removeUtilityReport/" + params);
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getUtilityReportTypes = async () => {
  try {
    const result = await axios.get("getUtilityReportTypes");
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const addUtilityReportType = async (body) => {
  try {
    const result = await axios.post("addUtilityReportType", body);
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const removeUtilityReportType = async (params) => {
  try {
    const result = await axios.post("removeUtilityReportType/" + params);
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const updateUtilityReportType = async (data) => {
  try {
    const result = await axios.post(
      "updateUtilityReportType/" + data.params,
      data.body
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};
