import React, { useEffect, useState } from "react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import EditEvent from "./EditEvent";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Modal, Popconfirm, Tooltip } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import PopconfirmRemove from "../../../UI/PopconfirmRemove";
import { removeEvent } from "../../../store/slices/announcements&events/events/events-slice";

const EventCalendar = ({ events }) => {
  const [editEvent, setEditEvent] = useState(false);
  const dispatch = useDispatch();
  const [eventID, setEventID] = useState();
  const { eventTypes } = useSelector((state) => state.events);
  const [eventFound, setEventFound] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const onCloseEdit = () => {
    setEditEvent(false);
  };

  useEffect(() => {
    const found = events.find((obj) => obj.eventId === eventID);
    setEventFound(found);
    console.log("xczx");
  }, [eventID, events]);

  console.log(eventFound);

  const text = "Are you sure you want to remove this event?";

  const deleteEvent = () => {
    dispatch(removeEvent(eventID));
    setDeleteModal(false);
  };

  const renderEventContent = (eventInfo) => {
    return (
      <div className="flex justify-between">
        <Tooltip
          className="z-[999] full-tooltip w-full"
          title={
            <div className="flex flex-col text-gray-700 p-3 ">
              <div>
                <span className="text-[18px] font-semibold">
                  {eventInfo.event.title}
                </span>
              </div>
              <Divider className="my-1 bg-white" />
              <div className="font-medium">
                <span>
                  {moment(eventInfo.event?.range?.start).format("LL")}
                </span>
                {" - "}
                <span>{moment(eventInfo.event?.range?.end).format("LL")}</span>
              </div>
              <div className="py-3">
                <span>
                  {eventInfo.event?.extendedProps
                    ? eventInfo.event?.extendedProps?.description
                    : "No description."}
                </span>
              </div>
              {/* <div>
              <span
                className="text-[13px] text-blue-700 cursor-pointer"
                onClick={() => showDesc(!desc)}
              >
                {desc ? "See less" : "See more"}
              </span>
            </div> */}
            </div>
          }
          color="#fff"
        >
          <div className="text-[14px] h-full p-[5px] z-[999] flex justify-between">
            {eventInfo.event.title ? eventInfo.event.title : "No title"}
          </div>
        </Tooltip>
        <div className="flex items-center">
          <DeleteOutlined
            className="text-[14px] pr-2 hover:scale-[1.3] transition-all ease-in duration-150"
            onClick={() => setDeleteModal(true)}
          />
          <EditOutlined
            className="text-[14px] pr-2 hover:scale-[1.3] transition-all ease-in duration-150"
            onClick={() => setEditEvent(true)}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          center: "today,prev,next",
          start: "title",
          end: "dayGridMonth,timeGridWeek,timeGridDay,list",
        }}
        eventClick={(e) => {
          setEventID(e.event.id);
        }}
        eventContent={renderEventContent}
        dayMaxEvents={3}
        events={
          events
            ? events
                .filter((obj) => obj.status === 0)
                .map((obj, key) => ({
                  id: obj.eventId,
                  title: obj.title,
                  description: obj.description,
                  start: moment(obj.dateFrom).format("YYYY-MM-DD"),
                  end: moment(obj.dateTo).format("YYYY-MM-DD"),
                  backgroundColor:
                    obj.type?.toLowerCase() === "Holidays".toLowerCase()
                      ? "#FFD800"
                      : obj.type?.toLowerCase() === "Important".toLowerCase()
                      ? "#308866"
                      : "#308866",
                  borderColor:
                    obj.type?.toLowerCase() === "Holidays".toLowerCase()
                      ? "yellow"
                      : obj.type?.toLowerCase() === "Important".toLowerCase()
                      ? "#308866"
                      : "#308866",
                }))
            : []
        }
      />
      <Modal
        title={
          <div className="flex items-center font-semibold text-xl gap-3">
            <QuestionCircleOutlined className="text-red-600" /> Delete this
            event?
          </div>
        }
        centered
        open={deleteModal}
        onOk={() => deleteEvent()}
        onCancel={() => setDeleteModal(false)}
      >
        <div className="flex flex-col">
          <div className="flex  gap-2">
            <span className="font-semibold">Title: </span>
            <span className="font-regular">{eventFound?.title}</span>
          </div>
          <div className="flex gap-2">
            <span className="font-semibold">Description: </span>
            <span className="font-regular break-all">
              {eventFound?.description}
            </span>
          </div>
          <div className="flex  gap-2">
            <span className="font-semibold">Start date: </span>
            <span className="font-regular">
              {moment(eventFound?.dateFrom).format("LL")}
            </span>
          </div>
          <div className="flex  gap-2">
            <span className="font-semibold">End date: </span>
            <span className="font-regular">
              {moment(eventFound?.dateFrom.to).format("LL")}
            </span>
          </div>
        </div>
      </Modal>
      <EditEvent
        eventId={eventID}
        editEvent={editEvent}
        closeEditEvent={onCloseEdit}
        eventTypes={eventTypes}
      />
    </div>
  );
};

export default EventCalendar;
