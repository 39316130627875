import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusSquareOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Divider,
  Dropdown,
  Form,
  Input,
  Modal,
  Popconfirm,
  Space,
  Table,
  Tag,
  theme,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addEventType,
  getEventTypes,
  removeEventType,
  updateEventType,
} from "../../../store/slices/announcements&events/events/events-slice";
import ModalButton from "../../../UI/ModalButton";
import PopconfirmRemove from "../../../UI/PopconfirmRemove";

const { useToken } = theme;
const EventTypes = () => {
  const { token } = useToken();
  const [form] = Form.useForm();
  const { eventTypes, eventTypesLoading } = useSelector(
    (state) => state.events
  );
  const [isAdding, setIsAdding] = useState(false);
  const [editToggle, setEditToggle] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [visible, setVisible] = useState(false);
  const [typeId, setTypeId] = useState("");
  const dispatch = useDispatch();

  const text = "Are you sure to remove this event type?";
  const description = "Remove the event type";

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle = {
    boxShadow: "none",
  };

  const items = [
    {
      label: (
        <div className="flex gap-2 items-center">
          <EditOutlined />
          <p>Edit</p>
        </div>
      ),
      key: "1",
    },
  ];

  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "150px",
      render: (record) => (
        <Dropdown
          trigger={["click"]}
          menu={{
            items,
            onClick: () => editHandler(record.typeId),
          }}
          dropdownRender={(menu) => (
            <div className="flex flex-col items-start" style={contentStyle}>
              {React.cloneElement(menu, {
                style: menuStyle,
              })}
              {/* <Button
                onClick={() => editHandler(record.typeId)}
                style={{ color: "black" }}
                type="link"
                icon={<EditOutlined />}
              >
                Edit
              </Button> */}
              <PopconfirmRemove
                text={text}
                desc={description}
                onConfirm={() => deleteEventType(record.typeId)}
              />
              {/* <Popconfirm
                icon={
                  <QuestionCircleOutlined
                    style={{
                      color: "red",
                    }}
                  />
                }
                placement="right"
                title={text}
                description={description}
                onConfirm={() => deleteEventType(record.typeId)}
                okText="Yes"
                cancelText="Cancel"
              >
                <Button
                  style={{ color: "black" }}
                  type="link"
                  icon={<DeleteOutlined />}
                >
                  Remove
                </Button>
              </Popconfirm> */}
            </div>
          )}
        >
          <Button
            type="primary"
            style={{
              background: "#4d8e03",
            }}
          >
            <Space>
              Action
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      width: "200px",
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "200px",
      editable: true,
      render: (status) =>
        status === 0 ? (
          <Tag color="blue">Active</Tag>
        ) : (
          <Tag color="blue">Inactive</Tag>
        ),
    },
    {
      title: "Date Created",
      dataIndex: "dateCreated",
      width: "200px",
      editable: true,
      defaultSortOrder: "descend",
      sorter: (a, b) => moment(a.dateCreated).diff(moment(b.dateCreated)),
      render: (dateCreated) => moment(dateCreated).format("LL"),
    },
    {
      title: "Date Updated",
      dataIndex: "dateUpdated",
      width: "200px",
      editable: true,
      render: (dateUpdated) => moment(dateUpdated).format("LL"),
    },
  ];

  useEffect(() => {
    const found = eventTypes.find((obj) => obj.typeId === typeId);

    if (isEditing) {
      form.setFieldsValue({
        ...found,
        type: found?.name,
      });
    } else {
      form.resetFields();
    }
  }, [eventTypes, typeId]);

  useEffect(() => {
    dispatch(getEventTypes());
  }, []);

  const onFinish = (values) => {
    if (isEditing) {
      dispatch(
        updateEventType({ params: typeId, body: { name: values?.type } })
      );
    } else {
      dispatch(
        addEventType({
          name: values?.type,
        })
      );
    }

    closeHandler();
    form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {};

  const showDrawer = (record) => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const deleteEventType = (value) => {
    dispatch(removeEventType(value));
  };

  const addHandler = () => {
    setIsAdding(true);
  };

  const editHandler = (value) => {
    setIsEditing(true);
    setTypeId(value);
  };

  const closeHandler = () => {
    setIsEditing(false);
    setIsAdding(false);
  };

  return (
    <div data-aos="fade" data-aos-easing="ease-in" data-aos-duration="300">
      <Card
        title={
          <div className="flex justify-between w-full items-center p-5">
            <h1 className="font-[600] text-[25px] mb-0">Event types</h1>
            <button
              onClick={addHandler}
              type="button"
              className="hidden md:block rounded-[10px] text-[18px] max-w-[200px] md:max-w-[173px] w-full h-[52px] border-transparent px-6 py-2.5 bg-[#4d8e03] text-white font-medium  leading-tight uppercase shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
            >
              ADD
            </button>
            <div className="flex items-center md:hidden text-center justify-center">
              <Button
                style={{
                  border: "1px solid transparent",
                  alignItems: "center",
                  display: "flex",
                }}
                type="outlined"
                onClick={addHandler}
                icon={
                  <PlusSquareOutlined className="text-2xl h-full items-center flex justify-center" />
                }
              >
                Add
              </Button>
            </div>
          </div>
        }
      >
        <Table
          loading={eventTypesLoading}
          rowKey={"typeId"}
          columns={columns}
          dataSource={eventTypes?.filter((obj) => obj.status === 0)}
          scroll={{
            y: 500,
            x: "100%",
          }}
        />
      </Card>
      <Modal
        title={
          <h1 className="text-[24px] md:text-[30px] font-[600] text-[#1b1b1b] mb-0 ">
            {isEditing ? "Edit event type" : "Add event type"}
          </h1>
        }
        centered
        open={isAdding || isEditing}
        onOk={closeHandler}
        onCancel={closeHandler}
        footer={false}
      >
        <Form
          layout="vertical"
          form={form}
          disabled={eventTypesLoading}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Divider />
          <Form.Item
            label={<h1 className="form-title">Event Type</h1>}
            name="type"
            rules={[
              {
                required: true,
                message: "Please input event type",
              },
            ]}
          >
            <Input
              style={{
                padding: "10px",
                fontSize: "18px",
                borderRadius: "3px",
              }}
              placeholder="Enter event type"
              size="large"
            />
          </Form.Item>
          <ModalButton text={isEditing ? "Update" : "Add"} />
        </Form>
      </Modal>
    </div>
  );
};
export default EventTypes;
