import { put, call, takeLatest } from "redux-saga/effects";
import {
  generateCode,
  validateCode,
  resetPassword,
} from "../../api/forgotpassword/forgotpassword-api";
import { forgotPasswordActions } from "../../store";
///
///

function* generateCodeRequest({ payload }) {
  const { generateCodeSuccess, generateError } = forgotPasswordActions;
  const result = yield call(generateCode, payload);
  if (result.name === "AxiosError") {
    yield put(generateError(result.response));
  } else {
    yield put(generateCodeSuccess(result.data));
  }
}

function* validateCodeRequest({ payload }) {
  const { validateCodeSuccess, validateError } = forgotPasswordActions;
  const result = yield call(validateCode, payload);
  if (result.name === "AxiosError") {
    yield put(validateError(result.response));
  } else {
    yield put(validateCodeSuccess(result.data));
  }
}

function* resetPasswordRequest({ payload }) {
  const { resetPasswordSuccess, resetPasswordError } = forgotPasswordActions;
  const result = yield call(resetPassword, payload);
  if (result.name === "AxiosError") {
    yield put(resetPasswordError(result.response));
  } else {
    yield put(resetPasswordSuccess(result.data));
  }
}

// Export the saga (todo-saga)
export default function* generateCodeSaga() {
  yield takeLatest(`generateOTP/generateCode`, generateCodeRequest);
  yield takeLatest(`generateOTP/validateCode`, validateCodeRequest);
  yield takeLatest(`generateOTP/resetPassword`, resetPasswordRequest);
}
