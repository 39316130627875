import {
  DownOutlined,
  PlusSquareOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Dropdown,
  Form,
  Input,
  Popconfirm,
  Space,
  Tooltip,
  Typography,
  Tag,
  theme,
} from "antd";
import { SearchTableInput, Table } from "ant-table-extensions";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptHomeowner,
  getHomeowner,
  getMasterlist,
} from "../../../store/slices/homeowner/homeowner-slice";
import "../homeowners/TableSample.css";

const originData = [];

const text = "Are you sure you want to accept this homeowner?";
const description = "Accept this homeowner";

for (let i = 0; i < 100; i++) {
  originData.push({
    key: i.toString(),
    name: `Edrward ${i}`,
    age: 32,
    address: `London Park no. ${i}`,
    status: false,
  });
}

const { useToken } = theme;

const MasterList = () => {
  const { token } = useToken();

  const { forms } = useSelector((state) => state.forms);
  const { masterList, searchedHomeowner, homeownerLoading, masterListLoading } =
    useSelector((state) => state.homeowners);
  const [searchDataSource, setSearchDataSource] = useState(masterList);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [accountId, setAccountId] = useState("");
  const [off, setOff] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [test, setTest] = useState(searchedHomeowner);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setAccountId(selectedRows[0].accountId);
      setOff(false);
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      width: "200px",
      editable: true,

      render: (status) =>
        status === 0 ? (
          <Tag color="volcano">Pending</Tag>
        ) : (
          <Tag color="blue">Accepted</Tag>
        ),
    },
    {
      title: "First name",
      dataIndex: "firstName",
      width: "200px",
      defaultFilteredValue: "Abcd",
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    {
      title: "Middle name",
      dataIndex: "middleName",
      width: "200px",
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    {
      title: "Last name",
      dataIndex: "lastName",
      width: "200px",
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      width: "200px",
    },
    {
      title: "Mobile number",
      dataIndex: "mobileNumber",
      width: "200px",
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "200px",
    },
    {
      title: "Date Created",
      dataIndex: "dateCreated",
      width: "200px",
      editable: true,
      defaultSortOrder: "descend",
      sorter: (a, b) => moment(a.dateCreated).diff(moment(b.dateCreated)),
      render: (dateCreated) => moment(dateCreated).format("LL"),
    },
    {
      title: "Date Updated",
      dataIndex: "dateUpdated",
      width: "200px",
      editable: true,
      render: (dateUpdated) => moment(dateUpdated).format("LL"),
    },
  ];

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const homeownerColumns = [
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "150px",
      render: (record) => (
        <Dropdown
          disabled={off}
          trigger={["click"]}
          dropdownRender={() => (
            <div className="flex flex-col items-start" style={contentStyle}>
              <Popconfirm
                placement="right"
                title={text}
                description={description}
                onConfirm={() => acceptHomeownerHandler(record.accountId)}
                okText="Yes"
                cancelText="Cancel"
              >
                <Button
                  style={{ color: "black" }}
                  type="link"
                  icon={<PlusSquareOutlined />}
                >
                  Accept
                </Button>
              </Popconfirm>
            </div>
          )}
        >
          <Button type="primary">
            <Space>
              <Tooltip
                title={
                  off ? "Please select an account from the table below." : null
                }
              >
                Action
                <DownOutlined />
              </Tooltip>
            </Space>
          </Button>
        </Dropdown>
      ),
    },
    {
      title: "QR Code",
      dataIndex: "qrCode",
      width: "200px",
    },
    {
      title: "First name",
      dataIndex: "firstName",
      width: "200px",
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    {
      title: "Middle name",
      dataIndex: "middleName",
      width: "200px",
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    {
      title: "Last name",
      dataIndex: "lastName",
      width: "200px",
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      width: "200px",
    },
    {
      title: "Mobile number",
      dataIndex: "mobileNumber",
      width: "200px",
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "200px",
    },
    {
      title: "Date Created",
      dataIndex: "dateCreated",
      width: "200px",
      editable: true,
      defaultSortOrder: "descend",
      sorter: (a, b) => moment(a.dateCreated).diff(moment(b.dateCreated)),
      render: (dateCreated) => moment(dateCreated).format("LL"),
    },
    {
      title: "Date Updated",
      dataIndex: "dateUpdated",
      width: "200px",
      editable: true,
      render: (dateUpdated) => moment(dateUpdated).format("LL"),
    },
  ];

  const qrHandler = (value) => {
    if (value.target.value.length == 16) {
      dispatch(getHomeowner(value.target.value));
    } else {
      setTest("");
    }
  };

  const searchHandler = (value) => {
    setTest([]);
    setSearchDataSource(
      masterList.filter(
        (obj) =>
          obj.firstName
            ?.toLowerCase()
            .includes(value.target.value.toLowerCase()) ||
          obj.lastName?.toLowerCase().includes(value.target.value.toLowerCase())
      )
    );
  };

  useEffect(() => {
    dispatch(getMasterlist());
  }, []);

  useEffect(() => {
    setSearchDataSource(masterList);
  }, [masterList]);

  useEffect(() => {
    setTest(searchedHomeowner);
    form2.setFieldValue(test?.firstName);
  }, [searchedHomeowner]);

  const acceptHomeownerHandler = (values) => {
    dispatch(acceptHomeowner({ accountId: accountId, homeownerId: values }));
    form.resetFields();
    setSelectedRowKeys([]);
  };

  const onFilterSearch = (values) => {
    dispatch(getHomeowner(values.referenceNumber));
  };

  const change = () => {
    setTest("");
    setSearchDataSource(masterList);
  };

  const onFinish = (value) => {};

  // Filter search

  return (
    <div className="flex flex-col gap-7">
      <Card
        className="shadow-md card-search"
        title={
          <div className="flex w-full pt-5">
            <Form
              form={form}
              layout="vertical"
              // disabled={isLoading}
              className="flex flex-col w-full"
              style={{
                gap: "20px",
              }}
              name="search"
              initialValues={{
                remember: true,
              }}
              onFinish={onFilterSearch}
              // onFinishFailed={onSearchFailed}
              autoComplete="off"
            >
              <div className="grid grid-cols-1 md:grid-cols-2 w-full">
                <div className="w-full">
                  <Form.Item
                    initialValue={""}
                    label={
                      <h1 className="text-[18px] font-[500]">
                        Homeowner QR Code
                      </h1>
                    }
                    name="referenceNumber"
                  >
                    <Input
                      allowClear
                      onChange={(e) => qrHandler(e)}
                      className="input-gray input-text-gray"
                      placeholder="Enter qr code"
                      style={{
                        width: "90%",
                        padding: "10px",
                        fontSize: "15px",
                      }}
                    />
                  </Form.Item>
                  {/* <Form.Item className="w-full md:w-[140px]">
                    <Button
                      style={{
                        borderRadius: "5px",
                        width: "100%",
                        maxWidth: "140px",
                        height: "40px",
                        border: "2px solid #4d8e03",
                        color: "#4d8e03",
                        fontSize: "15px",
                        fontWeight: "500",
                        marginBottom: 0,
                      }}
                      // loading={isLoading}
                      type="outlined"
                      onClick={() => {
                        clearFormFields();
                      }}
                    >
                      Clear
                    </Button>
                  </Form.Item> */}
                </div>
              </div>
            </Form>
          </div>
        }
      >
        <Table
          bordered
          loading={homeownerLoading}
          columns={homeownerColumns}
          rowKey="id"
          dataSource={searchedHomeowner}
          scroll={{
            y: 400,
            x: "100vw",
          }}
        />
      </Card>
      <Card className="shadow-md card-search" title={"Master List"}>
        <div className="w-[50%]">
          <Form form={form2} layout="vertical" onFinish={onFinish}>
            <Form.Item name="searching" onClick={() => change()}>
              <SearchTableInput
                onChange={() => change()}
                columns={columns}
                dataSource={masterList} // 🔴 Original dataSource
                setDataSource={setSearchDataSource} // 🔴 Newly created setSearchDataSource from useState hook
                inputProps={{
                  placeholder: "Enter homeowner name.",
                  prefix: <SearchOutlined />,
                }}
              />
            </Form.Item>
          </Form>
          {/* <Form
            form={form2}
            layout="vertical"
            // disabled={isLoading}
            className="flex flex-col w-full"
            style={{
              gap: "20px",
            }}
            name="search"
            initialValues={{
              remember: true,
            }}
            onFinish={onFilterSearch}
            // onFinishFailed={onSearchFailed}
            autoComplete="off"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 w-full">
              <div className="w-full">
                <Form.Item initialValue={""} name="searching">
                  <Input
                    allowClear
                    onChange={(e) => searchHandler(e)}
                    className="input-gray input-text-gray"
                    placeholder="Search name"
                    style={{
                      width: "90%",
                      padding: "10px",
                      fontSize: "15px",
                    }}
                  />
                </Form.Item>
              </div>
            </div>
          </Form> */}
        </div>
        <Table
          className="master-table"
          rowSelection={{
            type: "radio",
            ...rowSelection,
          }}
          bordered
          columns={columns}
          rowKey="id"
          dataSource={
            test
              ? searchDataSource?.filter(
                  (obj) =>
                    obj.firstName
                      ?.toLowerCase()
                      .includes(
                        test.map((obj) => obj.firstName?.toLowerCase())
                      ) ||
                    obj.lastName
                      ?.toLowerCase()
                      .includes(test.map((obj) => obj.lastName?.toLowerCase()))
                )
              : searchDataSource
          }
          loading={masterListLoading}
          scroll={{
            y: 400,
            x: "100vw",
          }}
        />
      </Card>
    </div>
  );
};

export default MasterList;
