import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusSquareOutlined,
  SearchOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Carousel,
  Dropdown,
  Empty,
  Image,
  Popconfirm,
  Space,
  Table,
  Tag,
  Form,
  Input,
  DatePicker,
  theme,
} from "antd";
import { useForm } from "antd/es/form/Form";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getEvents,
  getEventTypes,
  removeEvent,
} from "../../../store/slices/announcements&events/events/events-slice";
import { LoadingTime } from "../../../UI/LoadingScreen";
import "../announcements/announcements.css";
import "../properties/property.css";
import AddEvent from "./AddEvent";
import EditEvent from "./EditEvent";
import PopconfirmRemove from "../../../UI/PopconfirmRemove";
import EventCalendar from "./EventCalendar";

const { useToken } = theme;
const { RangePicker } = DatePicker;
const EventsTab = () => {
  const { token } = useToken();

  const [formFilter] = useForm();
  const { events, eventTypes, eventsLoading } = useSelector(
    (state) => state.events
  );
  const [visible, setVisible] = useState(false);
  const [editEvent, setEditEvent] = useState(false);
  const [eventsArr, setEventsArr] = useState(events);
  const [eventId, setEventId] = useState();
  const dispatch = useDispatch();

  const text = "Are you sure to remove this event?";
  const description = "Remove the event";

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle = {
    boxShadow: "none",
  };

  const items = [
    {
      label: (
        <div className="flex gap-2 items-center">
          <EditOutlined />
          <p>Edit</p>
        </div>
      ),
      key: "1",
    },
  ];

  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "150px",
      render: (record) => (
        <Dropdown
          trigger={["click"]}
          menu={{
            items,
            onClick: () => editHandler(record.eventId),
          }}
          dropdownRender={(menu) => (
            <div className=" flex flex-col items-start" style={contentStyle}>
              {React.cloneElement(menu, {
                style: menuStyle,
              })}
              <PopconfirmRemove
                text={text}
                desc={description}
                onConfirm={() => deleteEvent(record.eventId)}
              />
              {/* <Popconfirm
                icon={
                  <QuestionCircleOutlined
                    style={{
                      color: "red",
                    }}
                  />
                }
                placement="right"
                title={text}
                description={description}
                onConfirm={() => deleteEvent(record.eventId)}
                okText="Yes"
                cancelText="Cancel"
              >
                <Button
                  style={{ color: "black" }}
                  type="link"
                  icon={<DeleteOutlined />}
                >
                  Remove
                </Button>
              </Popconfirm> */}
            </div>
          )}
        >
          <Button
            type="primary"
            style={{
              background: "#4d8e03",
            }}
          >
            <Space>
              Action
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      width: "200px",
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "200px",
      editable: true,
      render: (status) =>
        status === 0 ? (
          <Tag color="blue">Active</Tag>
        ) : (
          <Tag color="blue">Inactive</Tag>
        ),
    },
    {
      title: "Type",
      dataIndex: "type",
      width: "200px",
    },
    {
      title: "Image",
      align: "center",
      dataIndex: "images",
      width: "150px",
      render: (record) => {
        return record ? (
          <Carousel autoplay dots>
            {record.map((obj, i) => (
              <div key={i}>
                <Image
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                  }}
                  src={process.env.REACT_APP_BASE_URL + "/" + obj?.imageUrl}
                />
              </div>
            ))}
          </Carousel>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        );
      },
    },
    {
      title: "Date Started",
      dataIndex: "dateFrom",
      width: "200px",
      editable: true,
      render: (dateFrom) => moment(dateFrom).format("LL"),
    },
    {
      title: "Date Ended",
      dataIndex: "dateTo",
      width: "200px",
      editable: true,
      render: (dateTo) => moment(dateTo).format("LL"),
    },
    {
      title: "Date Created",
      dataIndex: "dateCreated",
      width: "200px",
      editable: true,
      defaultSortOrder: "descend",
      sorter: (a, b) => moment(a.dateCreated).diff(moment(b.dateCreated)),
      render: (dateCreated) => moment(dateCreated).format("LL"),
    },
    {
      title: "Date Updated",
      dataIndex: "dateUpdated",
      width: "200px",
      editable: true,
      render: (dateUpdated) => moment(dateUpdated).format("LL"),
    },
  ];

  useEffect(() => {
    dispatch(getEvents());
    dispatch(getEventTypes());

    setEventsArr(events);
  }, [dispatch]);

  useEffect(() => {
    setEventsArr(events);
  }, [events]);

  const onClose = () => {
    setVisible(false);
  };

  const deleteEvent = (value) => {
    dispatch(removeEvent(value));
  };

  const editHandler = (value) => {
    setEditEvent(true);
    setEventId(value);
  };

  const onCloseEdit = () => {
    setEditEvent(false);
  };

  const filterHandler = (type, val, arr, key) => {
    switch (type) {
      case "title":
        return val
          ? arr.filter((obj) =>
              obj[key].toLowerCase().includes(val.toLowerCase())
            )
          : [];
      case "date":
        return val
          ? arr.filter(
              (obj) =>
                moment(obj[key]).format("YYYY-MM-DD") ===
                val.format("YYYY-MM-DD")
            )
          : [];
    }
  };

  const onFinished = (values) => {
    if (values.titleSearch || values.dateSearch) {
      const text = filterHandler("title", values.titleSearch, events, "title");
      const date = filterHandler(
        "date",
        values.dateSearch ? values.dateSearch[0] : "",
        events,
        "dateFrom"
      );

      const mergedArr = [...text, ...date];

      const uniqueAges = [...new Set(mergedArr)];

      setEventsArr(uniqueAges);
    } else {
      setEventsArr(events);
    }

    if (values.titleSearch !== "" && values.dateSearch !== null) {
      const filteredArr = events.filter(
        (obj) =>
          obj?.title.toLowerCase().includes(values.titleSearch.toLowerCase()) &&
          moment(obj.dateFrom).format("YYYY-MM-DD") ===
            values.dateSearch[0].format("YYYY-MM-DD")
      );

      setEventsArr(filteredArr);
    }
  };

  const clearFormFields = () => {
    formFilter.resetFields();
    setEventsArr(events);
  };

  const onSearchFailed = (errorInfo) => {};

  return (
    <div className="flex flex-col gap-7">
      <LoadingTime loading={eventsLoading} />
      <Card
        className="shadow-md card-search"
        title={<h1 className="font-[600] text-[25px] ">Search event</h1>}
      >
        <div className="flex gap-5 w-full">
          <Form
            form={formFilter}
            layout="vertical"
            // disabled={isLoading}
            className="flex flex-col w-full"
            style={{
              gap: "20px",
            }}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinished}
            onFinishFailed={onSearchFailed}
            autoComplete="off"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 w-full">
              <div className="w-full">
                <Form.Item
                  initialValue={""}
                  label={<h1 className="text-[18px] font-[500]">Title</h1>}
                  name="titleSearch"
                >
                  <Input
                    className="input-gray input-text-gray"
                    placeholder="Enter title"
                    allowClear
                    style={{
                      width: "90%",
                      padding: "10px",
                      fontSize: "15px",
                    }}
                  />
                </Form.Item>
              </div>
              <div className="w-full">
                <Form.Item
                  defaultValue={""}
                  name="dateSearch"
                  label={
                    <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
                      Date
                    </h1>
                  }
                >
                  <RangePicker
                    value={null}
                    allowEmpty
                    className="input-gray input-text-gray"
                  />
                </Form.Item>
              </div>
            </div>
            <div className="flex gap-5">
              <Form.Item className="w-full md:w-[140px]">
                <Button
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                    maxWidth: "140px",
                    height: "40px",
                    background: "#4d8e03",
                    border: "1px solid transparent",
                    fontSize: "15px",
                    fontWeight: "500",
                    marginBottom: 0,
                  }}
                  // loading={isLoading}
                  type="primary"
                  htmlType="submit"
                  icon={<SearchOutlined />}
                >
                  Search
                </Button>
              </Form.Item>
              <Form.Item className="w-full md:w-[140px]">
                <Button
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                    maxWidth: "140px",
                    height: "40px",
                    border: "2px solid #4d8e03",
                    color: "#1b1b1b",
                    fontSize: "15px",
                    fontWeight: "500",
                    marginBottom: 0,
                  }}
                  // loading={isLoading}
                  type="outlined"
                  onClick={() => {
                    clearFormFields();
                  }}
                >
                  Clear
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Card>
      <Card
        title={
          <div className="flex justify-between w-full items-center py-5">
            <h1 className="font-[600] text-[25px] mb-0">Events</h1>
            <button
              onClick={() => setVisible(true)}
              type="button"
              className="hidden md:block rounded-[10px] text-[18px] max-w-[200px] md:max-w-[173px] w-full h-[52px] border-transparent px-6 py-2.5 bg-[#4d8e03] text-white font-medium  leading-tight uppercase shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
            >
              ADD
            </button>
            <div className="flex items-center md:hidden text-center justify-center">
              <Button
                style={{
                  border: "1px solid transparent",
                  alignItems: "center",
                  display: "flex",
                }}
                type="outlined"
                onClick={() => setVisible(true)}
                icon={
                  <PlusSquareOutlined className="text-2xl h-full items-center flex justify-center" />
                }
              >
                Add
              </Button>
            </div>
          </div>
        }
      >
        {/* <Table
          loading={eventsLoading}
          rowKey={"eventId"}
          columns={columns}
          dataSource={eventsArr?.filter((obj) => obj.status === 0)}
          scroll={{
            y: 500,
            x: "100%",
          }}
        /> */}
        <EventCalendar events={eventsArr} />
      </Card>
      {/* <EditEvent
        eventId={eventId}
        editEvent={editEvent}
        closeEditEvent={onCloseEdit}
        eventTypes={eventTypes}
      /> */}
      <AddEvent
        openAddEvent={visible}
        closeAddEvent={onClose}
        eventTypes={eventTypes}
      />
    </div>
  );
};
export default EventsTab;
