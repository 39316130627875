import React from "react";
import { ExpandAltOutlined, ShrinkOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import {
  setActiveKey,
  setActiveKeyReport,
} from "../../../../store/slices/inquiries/inquiries-slice";
import { Divider } from "antd";
import { useNavigate } from "react-router-dom";

const Tasks = ({ reports, inquiries, expandTask, setExpandTask }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="w-full h-full p-5">
      <div className="flex-col flex">
        <div className="flex justify-between items-center">
          <span className="font-semibold text-lg">Tasks</span>
          <div
            onClick={() => setExpandTask()}
            className="text-xl hover:scale-[1.1] duration-150 cursor-pointer"
          >
            {!expandTask ? <ExpandAltOutlined /> : <ShrinkOutlined />}
          </div>

          {/* <Skeleton
        paragraph={{ rows: 1 }}
        active={inquiriesLoading}
        loading={inquiriesLoading}
      >
        <span className="font-semibold text-properity">
          {inquiries
            ? inquiries.filter((i) => i.status === 0).length
            : 0}
          &nbsp; pending
        </span>
      </Skeleton> */}
        </div>
        <Divider />
        <div className="grid grid-rows-2 gap-10">
          <div className="flex flex-col gap-3">
            <span className="cursor-pointer">Inquiries</span>
            <div
              className="w-full h-[85px] rounded-lg p-3 pending-card flex flex-row items-center justify-between duration-150  hover:-translate-x-2 cursor-pointer"
              onClick={() => {
                navigate("/Inquiries");
                dispatch(setActiveKey("item-1"));
              }}
            >
              <span className="pl-3 text-xl font-semibold z-[999] ">
                Pending
              </span>
              <div className="bg-[#FF0000] w-[60px] h-[60px] rounded-lg flex gap-3 items-center justify-center z-[999] pending-count">
                <p className="text-white text-2xl font-semibold">
                  {inquiries[0] ? inquiries[0]?.count : 0}
                </p>
              </div>
            </div>
            <div
              className={`w-full h-[85px] rounded-lg p-3 processed-card ${
                expandTask ? "flex" : "hidden"
              } flex-row items-center justify-between  duration-150  hover:-translate-x-2 cursor-pointer`}
              onClick={() => {
                navigate("/Inquiries");
                dispatch(setActiveKey("item-2"));
              }}
            >
              <span className="pl-3 text-xl font-semibold z-[999]">
                Processing
              </span>
              <div className="bg-[#ebd339] w-[60px] h-[60px] rounded-lg flex gap-3 z-[999] items-center justify-center processed-count">
                <p className="text-white text-2xl font-semibold z-[999]">
                  {inquiries[1] ? inquiries[1]?.count : 0}
                </p>
              </div>
            </div>
            <div
              className={`w-full h-[85px] rounded-lg p-3 resolved-card  ${
                expandTask ? "flex" : "hidden"
              } flex-row items-center justify-between  duration-150  hover:-translate-x-2 cursor-pointer`}
              onClick={() => {
                navigate("/Inquiries");
                dispatch(setActiveKey("item-3"));
              }}
            >
              <span className="pl-3 text-xl font-semibold z-[999]">
                Resolved
              </span>
              <div className="bg-[#03cd03] w-[60px] h-[60px] rounded-lg flex gap-3 z-[999] items-center justify-center resolved-count">
                <p className="text-white text-2xl font-semibold z-[999]">
                  {inquiries[2] ? inquiries[2]?.count : 0}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <span className="cursor-pointer">Reports</span>
            <div
              className="w-full h-[85px] rounded-lg p-3 pending-card flex flex-row items-center justify-between  duration-150  hover:-translate-x-2 cursor-pointer"
              onClick={() => {
                navigate("/UtilityReports");
                dispatch(setActiveKeyReport("item-1"));
              }}
            >
              <span className="pl-3 text-xl font-semibold z-[999]">
                Pending
              </span>
              <div className="bg-[#FF0000] w-[60px] h-[60px] rounded-lg  flex gap-3 items-center justify-center z-[999] pending-count">
                <p className="text-white text-2xl font-semibold z-[999]">
                  {reports[0] ? reports[0]?.count : 0}
                </p>
              </div>
            </div>
            <div
              className={`w-full h-[85px] rounded-lg p-3 processed-card  ${
                expandTask ? "flex" : "hidden"
              } flex-row items-center justify-between  duration-150  hover:-translate-x-2 cursor-pointer`}
              onClick={() => {
                navigate("/UtilityReports");
                dispatch(setActiveKeyReport("item-2"));
              }}
            >
              <span className="pl-3 text-xl font-semibold z-[999]">
                Processing
              </span>
              <div className="bg-[#ebd339] w-[60px] h-[60px] rounded-lg z-[999] flex gap-3 items-center justify-center processed-count">
                <p className="text-white text-2xl font-semibold z-[999]">
                  {reports[1] ? reports[1]?.count : 0}
                </p>
              </div>
            </div>
            <div
              className={`w-full h-[85px] rounded-lg p-3 resolved-card  ${
                expandTask ? "flex" : "hidden"
              } flex-row items-center justify-between  duration-150  hover:-translate-x-2 cursor-pointer`}
              onClick={() => {
                navigate("/UtilityReports");
                dispatch(setActiveKeyReport("item-3"));
              }}
            >
              <span className="pl-3 text-xl font-semibold z-[999]">
                Resolved
              </span>
              <div className="bg-[#03cd03] w-[60px] h-[60px] rounded-lg z-[999] flex gap-3 items-center justify-center resolved-count">
                <p className="text-white text-2xl font-semibold z-[999]">
                  {reports[2] ? reports[2]?.count : 0}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tasks;
