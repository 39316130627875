import {
  ArrowLeftOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Drawer,
  Empty,
  Form,
  Input,
  Modal,
  Select,
  Upload,
  Popconfirm,
} from "antd";
import ImgCrop from "antd-img-crop";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dummyRequest } from "../../../constants/staticConst";
import { convertToBase64 } from "../../../helpers/tobase64";
import {
  addEventAttachment,
  removeEvent,
  removeEventAttachment,
  updateEventAttachment,
  updateEvents,
} from "../../../store/slices/announcements&events/events/events-slice";
const { Dragger } = Upload;
const { RangePicker } = DatePicker;

const originData = [];
for (let i = 0; i < 100; i++) {
  originData.push({
    key: i.toString(),
    name: `Edrward ${i}`,
    age: 32,
    address: `London Park no. ${i}`,
  });
}
const EditEvent = ({ editEvent, closeEditEvent, eventId, eventTypes }) => {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const { events } = useSelector((state) => state.events);
  const dispatch = useDispatch();
  const [editAttachment, setEditAttachment] = useState(false);
  const [attachmentId, setAttachmentId] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [imagePrev, setImagePrev] = useState("");
  const [fileList, setFileList] = useState([]);

  const onFinishFailed = (errorInfo) => {};

  const found = events?.find((obj) => obj.eventId === eventId);

  const text = "Are you sure to remove this event?";
  const description = "Remove the event";

  useEffect(() => {
    form.setFieldsValue({
      ...found,
      eventType: found?.type,
      title: found?.title,
      description: found?.description,
      date: [
        dayjs(found?.dateFrom, "YYYY-MM-DD"),
        dayjs(found?.dateTo, "YYYY-MM-DD"),
      ],
    });
  }, [eventId]);

  const onFinishUpload = (values) => {
    let formData = new FormData();
    for (let index = 0; index < values?.imgUrl?.length; index++) {
      formData.append(
        "image",
        values?.imgUrl === "" ? null : values?.imgUrl[index]
      );
    }

    dispatch(
      addEventAttachment({
        params: eventId,
        body: { formData },
      })
    );

    setImagePrev("");
    setPreviewTitle("");
    setFileList([]);
  };

  const onFinishEdit = (values) => {
    let formData = new FormData();
    for (let index = 0; index < values?.imgUrl?.length; index++) {
      formData.append(
        "image",
        values?.imgUrl === "" ? null : values?.imgUrl[index]
      );
    }

    dispatch(
      updateEventAttachment({
        params: attachmentId,
        body: { formData },
      })
    );

    setImagePrev("");
    setPreviewTitle("");
    setEditAttachment(false);
    setFileList([]);
  };

  const onFinish = (values) => {
    dispatch(
      updateEvents({
        params: eventId,
        body: {
          typeId: values?.eventType,
          title: values?.title,
          description: values?.description,
          dateFrom: values.date[0].format("YYYY-MM-DD"),
          dateTo: values.date[1].format("YYYY-MM-DD"),
        },
      })
    );

    closeEditEvent(true);
  };

  const deleteEvent = () => {
    dispatch(removeEvent(eventId));
    closeEditDrawer();
  };

  const closeEditDrawer = () => {
    closeEditEvent(true);
    setImagePrev("");
    setPreviewTitle("");
    setFileList([]);
  };

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await convertToBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);

    form2.setFieldsValue({
      imgUrl: fileList?.map((file) => file?.originFileObj) || null,
    });
  };
  const handleChangeAttachment = ({ fileList: newFileList }) => {
    setFileList(newFileList);

    form3.setFieldsValue({
      imgUrl: fileList?.map((file) => file?.originFileObj) || null,
    });
  };
  const uploadButton = (
    <div className="w-full min-w-[100px]">
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload new
      </div>
    </div>
  );

  const removeAttachmentImg = (value) => {
    dispatch(removeEventAttachment(value));
  };

  const editAttachmentImg = (value) => {
    setEditAttachment(true);
    setAttachmentId(value);
  };
  const closeEditAttachmentImg = (value) => {
    setEditAttachment(false);

    setImagePrev("");
    setPreviewTitle("");
    setFileList([]);
  };

  return (
    <div>
      <Drawer
        title={
          <div className="flex md:flex-row md:items-center items-start justify-between">
            <div className="flex items-center">
              <div
                onClick={closeEditDrawer}
                className="w-[50px] h-[50px] items-center text-center flex justify-center text-[20px] rounded-full hover:bg-slate-200 transition-all ease-in duration-150 cursor-pointer hover:scale-110"
              >
                <ArrowLeftOutlined className="text-[#1b1b1b]" />
              </div>
              <h1 className="text-[24px] md:text-[30px] font-[600] text-[#1b1b1b] mb-0 p-3">
                Edit Event
              </h1>
            </div>
            <Popconfirm
              className="cursor-pointer text-red-700"
              placement="right"
              title={text}
              description={description}
              onConfirm={() => deleteEvent()}
            >
              <DeleteOutlined className="text-[14px] pr-2" />
              Remove
            </Popconfirm>
          </div>
        }
        closable={false}
        onClose={closeEditEvent}
        open={editEvent}
        className="demo"
        placement="right"
        width={window.innerWidth > 1200 ? "30%" : "100%"}
        footer={
          <div className="flex justify-end">
            <Button
              // loading={addAnnouncementsLoading}
              // disabled={addAnnouncementsLoading}
              className="shadow-md"
              type="primary"
              onClick={form.submit}
              style={{
                minWidth: "200px",
                height: "52px",
                fontSize: "17px",
                fontWeight: "400",
                borderRadius: "5px",
                background: "#4d8e03",
                marginBlock: "13px",
              }}
            >
              Update
            </Button>
          </div>
        }
      >
        <div className="h-full w-full flex flex-col">
          <Form
            form={form}
            name="add"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
          >
            <div className="flex flex-col gap-10 items-center md:items-start p-3">
              <div className="flex flex-col w-full gap-4 md:flex-1">
                <div className="text-label">
                  <Form.Item
                    name="eventType"
                    label={
                      <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
                        Event Type
                      </h1>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please enter event type",
                      },
                    ]}
                  >
                    <Select
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      optionFilterProp="children"
                      showSearch
                      // onChange={""}
                      // onSearch={""}
                      placeholder="Event type"
                      options={eventTypes?.map((event, i) => {
                        return {
                          value: event.typeId,
                          label: event.name,
                        };
                      })}
                    ></Select>
                  </Form.Item>
                  <Form.Item
                    name="title"
                    label={
                      <h1 className="font-[500] text-[14px] mb-0 md:text-[17px] text-[#1b1b1b]">
                        Title
                      </h1>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please input title",
                      },
                    ]}
                  >
                    <Input
                      style={{
                        padding: "10px",
                        fontSize: "18px",
                        borderRadius: "3px",
                      }}
                      placeholder="Enter title "
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item
                    name="description"
                    label={
                      <h1 className="font-[500] text-[14px] mb-0 md:text-[17px] text-[#1b1b1b]">
                        Description
                      </h1>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please input description",
                      },
                    ]}
                  >
                    <Input
                      style={{
                        padding: "10px",
                        fontSize: "18px",
                        borderRadius: "3px",
                      }}
                      placeholder="Enter description"
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item
                    name="date"
                    label={
                      <h1 className="font-[500] text-[14px] mb-0 md:text-[17px] text-[#1b1b1b]">
                        Date
                      </h1>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please input date",
                      },
                    ]}
                  >
                    <RangePicker />
                  </Form.Item>
                </div>
              </div>
              <div className="flex flex-wrap gap-3 items-center p-3">
                {found ? (
                  found?.images?.map((img, i) => (
                    <div key={i}>
                      <div className="w-[100px] relative">
                        <img
                          className="w-[100px] h-[100px] object-cover relative"
                          alt="example"
                          src={
                            process.env.REACT_APP_BASE_URL + "/" + img?.imageUrl
                          }
                        />
                        <div className="z-[999] opacity-0 absolute gap-3 left-0 top-0 h-full w-full flex items-center justify-center delete-overlay transition-all ease-in duration-300">
                          <EditOutlined
                            className="text-xl text-white opacity-100 icon-attachmen transition-all ease-in duration-200 hover:scale-[1.1]"
                            onClick={() => editAttachmentImg(img.attachmentId)}
                          />
                          <DeleteOutlined
                            className="text-xl text-white opacity-100 icon-attachmen transition-all ease-in duration-200 hover:scale-[1.2]"
                            onClick={() =>
                              removeAttachmentImg(img.attachmentId)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </div>
              <Modal
                open={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
                width={"100%"}
              >
                <img
                  alt="example"
                  style={{
                    width: "100%",
                  }}
                  src={previewImage}
                />
              </Modal>
            </div>
          </Form>
          <Form
            form={form2}
            name="add"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinishUpload}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
          >
            <div className="flex flex-col gap-10 items-center md:items-start p-3">
              <div className={editAttachment ? "hidden" : "flex-1 h-[278px]"}>
                <Form.Item
                  valuePropName="fileList"
                  name="imgUrl"
                  className="max-w-[250px]"
                >
                  <>
                    <Upload
                      name="file"
                      customRequest={dummyRequest}
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                    >
                      {uploadButton}
                    </Upload>
                  </>
                </Form.Item>
                <Button
                  className={fileList <= 0 ? "hidden" : "block"}
                  onClick={form2.submit}
                  style={{
                    minWidth: "120px",
                    height: "35px",
                    fontSize: "14px",
                    fontWeight: "400",
                    borderRadius: "5px",
                    marginBlock: "13px",
                  }}
                >
                  Upload
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Drawer>
      <Modal
        title={
          <div className="flex md:flex-row md:items-center items-start justify-start">
            <div
              onClick={closeEditAttachmentImg}
              className="w-[50px] h-[50px] items-center text-center flex justify-center text-[20px] rounded-full hover:bg-slate-200 transition-all ease-in duration-150 cursor-pointer hover:scale-110"
            >
              <ArrowLeftOutlined className="text-[#1b1b1b]" />
            </div>
            <h1 className="text-[24px] md:text-[30px] font-[600] text-[#1b1b1b] mb-0 p-3">
              Edit Attachment
            </h1>
          </div>
        }
        closable={false}
        onClose={closeEditAttachmentImg}
        open={editAttachment}
        onCancel={closeEditAttachmentImg}
        onOk={form3.submit}
        className="demo w-full"
      >
        <div className="h-full w-full flex flex-col">
          <div className="flex flex-wrap gap-3 items-center p-3">
            {found?.images?.map(
              (obj, i) =>
                obj.attachmentId === attachmentId && (
                  <div key={i}>
                    <div className="w-[100px]  relative">
                      <img
                        className="w-[100px] h-[100px] object-cover relative"
                        alt="example"
                        src={
                          process.env.REACT_APP_BASE_URL + "/" + obj?.imageUrl
                        }
                      />
                    </div>
                  </div>
                )
            )}
          </div>
          <Form
            form={form3}
            name="add"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinishEdit}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
          >
            <div className="flex flex-col gap-10 items-center md:items-start p-3">
              <div className="flex-1 h-[278px] ">
                <Form.Item
                  valuePropName="fileList"
                  name="imgUrl"
                  className="max-w-[250px]"
                >
                  <ImgCrop rotate quality={0.3} grid={true}>
                    <Dragger
                      name="file"
                      customRequest={dummyRequest}
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChangeAttachment}
                    >
                      {uploadButton}
                    </Dragger>
                  </ImgCrop>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default EditEvent;
