import React from "react";
import { Link } from "react-router-dom";

const ChangesToPrivacy = () => {
  return (
    <div className="flex flex-col gap-5">
      <h1 className="md:text-[28px] text-[16px] font-semibold">
        Changes to our privacy policy
      </h1>
      <p className="text-gray-600 text-justify text-[14px] md:text-[18px]">
        This Privacy Policy may change from time to time without any prior
        notice. If we make a change that we believe will affect your rights and
        use of the system, we will notify you via email you registered in our
        system or by posting it here on our website. By continuing to use our
        platform(s) and Services it provides you after the changes, updates and
        improvements made which we at Properity believe will be more effective,
        efficient and more user friendly, you agree to be bound by the said
        revised Privacy Policy and Terms & Conditions.
      </p>
    </div>
  );
};

export default ChangesToPrivacy;
