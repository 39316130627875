import React from "react";

const Consent = () => {
  return (
    <div className="flex flex-col gap-5">
      <h1 className="md:text-[28px] text-[16px] font-semibold">
        Consent to collection, transfer, store, use, disclosure of information
        provided
      </h1>
      <ul className="text-gray-600 pl-5">
        <li>Your Personal Data</li>
        <li>Data provided from registration, online payment transactions</li>
        <li>Other's Data</li>
        <li>Data you provided for your residents</li>
      </ul>
    </div>
  );
};

export default Consent;
