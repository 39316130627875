import {
  ArrowLeftOutlined,
  DownOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Drawer,
  Dropdown,
  Empty,
  Form,
  Image,
  Input,
  Select,
  Space,
  Table,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  format_addCommas,
  format_PHCurrency,
} from "../../../../helpers/formatCurrency";
import { getAssociationCharges } from "../../../../store/slices/billing/billing-slice";
import "../../billing/billing.css";

const PreviousCharge = ({
  previousBillingStatements,
  previousBillingStatementsLoading,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { assocChargesLoading, assocCharges } = useSelector(
    (state) => state.billing
  );
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [chargeId, setChargeId] = useState("");

  const found = previousBillingStatements?.charges?.find(
    (obj) => obj.chargeId === chargeId
  );

  useEffect(() => {
    form.setFieldsValue({
      ...found,
      chargeId: found?.chargeId,
      cost: found?.amount,
      additionalAmount: found?.additionalAmount,
      remarks: found?.remarks,
    });
  }, [chargeId]);

  useEffect(() => {
    dispatch(getAssociationCharges());
  }, []);

  const closeDrawer = () => {
    setIsEditing(false);
    setOpen(false);
  };

  const items = [
    {
      label: (
        <div className="flex gap-2 items-center">
          <EyeOutlined />
          <p>View bill</p>
        </div>
      ),
      key: "1",
    },
  ];

  const columns = [
    // {
    //   title: "Action",
    //   dataIndex: "",
    //   key: "x",
    //   width: "150px",
    //   render: (record) => (
    //     <Dropdown
    //       menu={{
    //         items,
    //         onClick: () => viewChargeAttachment(record),
    //       }}
    //       trigger={["click"]}
    //       // dropdownRender={() => (
    //       //   <div className="dropdown-action flex flex-col items-start">
    //       //     <Button
    //       //       onClick={() => viewChargeAttachment(record)}
    //       //       style={{ color: "black" }}
    //       //       type="link"
    //       //       icon={<EyeOutlined />}
    //       //     >
    //       //       View charge
    //       //     </Button>
    //       //   </div>
    //       // )}
    //     >
    //       <Button
    //         type="primary"
    //         style={{
    //           background: "#4d8e03",
    //         }}
    //       >
    //         <Space>
    //           Action
    //           <DownOutlined />
    //         </Space>
    //       </Button>
    //     </Dropdown>
    //   ),
    // },
    {
      title: "Name",
      dataIndex: "name",
      width: "200px",
    },
    {
      title: "Billing date",
      dataIndex: "billingDate",
      render: (billingDate) => moment(billingDate).format("LL"),
      width: "200px",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (amount) =>
        amount ? format_PHCurrency(amount) : format_PHCurrency(0),
      width: "200px",
    },
    {
      title: "Additional amount",
      dataIndex: "additionalAmount",
      render: (amount) =>
        amount ? format_PHCurrency(amount) : format_PHCurrency(0),
      width: "200px",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      width: "200px",
      render: (remarks) => (remarks ? remarks : "-"),
    },
  ];

  const attachmentCols = [
    {
      title: "Image",
      width: "200px",
      dataIndex: "imageUrl",
      render: (record) => {
        return record ? (
          <Image
            style={{
              width: "100px",
              height: "100px",
              objectFit: "cover",
            }}
            src={process.env.REACT_APP_BASE_URL + "/" + record}
          />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        );
      },
    },
    {
      title: "Description",
      width: "200px",
      dataIndex: "description",
      record: (record) => (record === "undefined" ? "n/a" : record),
    },
  ];

  const viewChargeAttachment = (value) => {
    setOpen(true);
    setChargeId(value?.chargeId);
  };

  return (
    <Card
      title={
        <div className="flex justify-between  items-center p-5">
          <h1 className="font-[600] text-[25px] mb-0">
            Previous billing charges
          </h1>
        </div>
      }
    >
      <Table
        loading={previousBillingStatementsLoading}
        className="table-charge"
        rowKey={"chargeId"}
        columns={columns}
        dataSource={previousBillingStatements?.charges}
      />
      <Drawer
        className="p-5"
        centered
        onClose={closeDrawer}
        onCancel={closeDrawer}
        open={open}
        width={"35%"}
        closable={false}
        placement={"right"}
      >
        <div className="p-3 flex flex-col md:flex-row md:items-center items-start justify-start">
          <div
            onClick={() => closeDrawer()}
            className="w-[50px] h-[50px] items-center text-center flex justify-center text-[20px] rounded-full hover:bg-slate-200 transition-all ease-in duration-150 cursor-pointer hover:scale-110"
          >
            <ArrowLeftOutlined className="text-[#1b1b1b]" />
          </div>
          <h1 className="py-5 font-[600] text-[#474d63] text-[32px]">
            Charge details
          </h1>
        </div>
        <div>
          <Form
            disabled={true}
            layout="vertical"
            form={form}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
            name="basic"
            initialValues={{
              remember: true,
            }}
            autoComplete="off"
          >
            <Form.Item
              name="chargeId"
              label={
                <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
                  Charge Type
                </h1>
              }
              rules={[
                {
                  required: true,
                  message: "Please enter charge",
                },
              ]}
            >
              <Select
                disabled={true}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                optionFilterProp="children"
                showSearch
                size="large"
                placeholder="Charge"
                options={assocCharges
                  ?.filter((obj) => obj.status === 0)
                  .map((charge, i) => {
                    return {
                      value: charge.chargeId,
                      label: charge.name,
                    };
                  })}
              ></Select>
            </Form.Item>
            <Form.Item
              name="cost"
              label={
                <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
                  Amount
                </h1>
              }
              rules={[
                {
                  required: true,

                  message: "Number only",
                },
              ]}
            >
              <Input
                disabled={true}
                style={{
                  padding: "10px",
                  fontSize: "18px",
                  borderRadius: "3px",
                }}
                precision={2}
                max={4}
                size="large"
              />
            </Form.Item>
            <Form.Item
              value="0.00"
              name="additionalAmount"
              label={
                <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
                  Additional amount
                </h1>
              }
              rules={[
                {
                  message: "Number only",
                },
              ]}
            >
              <Input
                disabled={true}
                precision={2}
                max={4}
                style={{
                  padding: "10px",
                  fontSize: "18px",
                  borderRadius: "3px",
                }}
                size="large"
              />
            </Form.Item>
            <Form.Item
              value=""
              name="remarks"
              label={
                <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
                  Remarks
                </h1>
              }
            >
              <Input
                disabled={true}
                style={{
                  padding: "10px",
                  fontSize: "18px",
                  borderRadius: "3px",
                }}
                size="large"
              />
            </Form.Item>
          </Form>
          <Card
            title={
              <div className="flex justify-between items-center">
                <h1 className="py-5 font-[600] text-[#474d63] text-[22px]">
                  Attachments:
                </h1>
              </div>
            }
          >
            <Table
              loading={previousBillingStatementsLoading}
              rowKey={"attachmentId"}
              columns={attachmentCols}
              dataSource={
                previousBillingStatements?.charges?.find(
                  (obj) => obj.chargeId === chargeId
                )?.attachments
              }
              scroll={{
                y: 500,
                x: "100%",
              }}
            />
          </Card>
        </div>
      </Drawer>
    </Card>
  );
};

export default PreviousCharge;
