import { motion } from "framer-motion";
import React from "react";
import { priceFeatures } from "../../../constants/static";
// import { priceFeatures } from "../../constants/static";

const PriceFeatures = ({ cardAnimate, cardTextAnimate }) => {
	return (
		<motion.div
			className=" px-2  bg-[#F6F6F6]"
			initial={"offscreen"}
			whileInView={"onscreen"}
			viewport={{ once: true, amount: 0.1 }}
			transition={{ staggerChildren: 0.1 }}
		>
			<motion.div
				className=" grid grid-cols-8 gap-x-0 lg:px-20 px-5"
				// initial={"offscreen"}
				// whileInView={"onscreen"}
				// viewport={{ once: true, amount: 0.2 }}
				// transition={{ staggerChildren: 0.1 }}
				variants={cardTextAnimate}
			>
				<motion.div
					className=" col-span-2 bg-white rounded-xl m-0"
					// variants={cardAnimate}
				>
					{/* <div className=" h-[100px] flex items-center">
						<h1 className=" ml-16 text-2xl font-semibold text-[#139018]">
							Features:
						</h1>
					</div> */}
					<motion.ul
						className="flex flex-col lg:ml-14 md:ml-5 ml-2 list-none"
						// initial={"offscreen"}
						// whileInView={"onscreen"}
						// viewport={{ once: true, amount: 0.2 }}
						// transition={{ delayChildren: 0.1, staggerChildren: 0.1 }}
						variants={cardTextAnimate}
					>
						{priceFeatures.map((obj, index) => {
							return (
								<motion.div
									key={index}
									className=" w-full h-[76px] flex items-center"
									// variants={cardTextAnimate}
								>
									<li className=" text-[#6D6D6D] lg:lg:text-xl text-[16px] font-semibold">
										{obj.featureName}
									</li>
								</motion.div>
							);
						})}
					</motion.ul>
				</motion.div>
				<motion.div
					className=" col-span-2 bg-white rounded-xl m-0"
					// variants={cardAnimate}
				>
					{/* <div className=" flex justify-around items-center h-[100px]"></div> */}
					<motion.ul
						className="flex flex-col items-center text-[#515151] "
						// initial={"offscreen"}
						// whileInView={"onscreen"}
						// viewport={{ once: true, amount: 0.2 }}
						// transition={{ staggerChildren: 0.1 }}
						variants={cardTextAnimate}
					>
						{priceFeatures.map((obj, index) => {
							const bgColor = index % 2 === 0 ? "#F6F6F6" : "#FFFFFF";
							return obj.starter !== "" ? (
								<motion.div
									key={index}
									className=" w-full flex flex-col items-center justify-center h-[76px]"
									style={{
										backgroundColor: `${bgColor}`,
									}}
									// variants={cardTextAnimate}
								>
									<p className=" lg:text-xl text-[16px] max-w-[205px] text-center font-semibold m-0">
										{obj.starter}
									</p>
								</motion.div>
							) : obj.starterCheck !== "" ? (
								<motion.div
									key={index}
									className="w-full h-[76px] flex flex-col items-center justify-center"
									style={{
										backgroundColor: `${bgColor}`,
									}}
									// variants={cardTextAnimate}
								>
									<img className="w-[28px]" src={obj.starterCheck} alt="/" />
								</motion.div>
							) : (
								<motion.div
									key={index}
									className=" w-full h-[76px] flex flex-col items-center justify-center"
									style={{
										backgroundColor: `${bgColor}`,
									}}
									// variants={cardTextAnimate}
								>
									<p className=" lg:text-xl text-[16px] max-w-[205px] text-center font-semibold">
										{" "}
									</p>
								</motion.div>
							);
						})}
					</motion.ul>
				</motion.div>
				<motion.div
					className=" col-span-2 bg-white rounded-xl"
					// variants={cardAnimate}
				>
					{/* <div className=" flex justify-around items-center h-[100px]"></div> */}
					<motion.ul
						className=" list-disc flex flex-col items-center text-[#515151]"
						// initial={"offscreen"}
						// whileInView={"onscreen"}
						// viewport={{ once: true, amount: 0.2 }}
						// transition={{ staggerChildren: 0.1 }}
						variants={cardTextAnimate}
					>
						{priceFeatures.map((obj, index) => {
							const bgColor = index % 2 === 0 ? "#F6F6F6" : "#FFFFFF";
							return obj.plus !== "" ? (
								<motion.div
									key={index}
									className=" w-full flex flex-col items-center justify-center h-[76px]"
									style={{
										backgroundColor: `${bgColor}`,
									}}
									// variants={cardTextAnimate}
								>
									<p className=" lg:text-xl text-[16px] max-w-[200px] text-center font-semibold m-0">
										{obj.plus}
									</p>
								</motion.div>
							) : obj.plusCheck !== "" ? (
								<motion.div
									key={index}
									className=" w-full flex flex-col items-center justify-center h-[76px]"
									style={{
										backgroundColor: `${bgColor}`,
									}}
									// variants={cardTextAnimate}
								>
									<img className="w-[28px]" src={obj.plusCheck} alt="/" />
								</motion.div>
							) : (
								<motion.div
									key={index}
									className=" w-full h-[76px] flex flex-col items-center justify-center"
									style={{
										backgroundColor: `${bgColor}`,
									}}
									// variants={cardTextAnimate}
								>
									<p className="lg:text-xl text-[16px] max-w-[205px] text-center font-semibold m-0">
										{" "}
									</p>
								</motion.div>
							);
						})}
					</motion.ul>
				</motion.div>
				<motion.div
					className=" col-span-2 bg-white rounded-xl"
					// variants={cardAnimate}
				>
					{/* <div className=" flex justify-around items-center h-[100px]"></div> */}
					<motion.ul
						className=" list-disc flex flex-col items-center text-[#515151]"
						// initial={"offscreen"}
						// whileInView={"onscreen"}
						// viewport={{ once: true, amount: 0.2 }}
						// transition={{ staggerChildren: 0.1 }}
						variants={cardTextAnimate}
					>
						{priceFeatures.map((obj, index) => {
							const bgColor = index % 2 === 0 ? "#F6F6F6" : "#FFFFFF";
							return obj.premiumPlus !== "" ? (
								<motion.div
									key={index}
									className=" w-full flex flex-col items-center justify-center h-[76px]"
									style={{
										backgroundColor: `${bgColor}`,
									}}
									// variants={cardTextAnimate}
								>
									<p className=" lg:text-xl text-[16px] max-w-[200px] text-center font-semibold m-0">
										{obj.premiumPlus}
									</p>
								</motion.div>
							) : obj.premiumPlusCheck !== "" ? (
								<motion.div
									key={index}
									className=" w-full flex flex-col items-center justify-center h-[76px]"
									style={{
										backgroundColor: `${bgColor}`,
									}}
									// variants={cardTextAnimate}
								>
									<img className="w-[28px]" src={obj.premiumPlusCheck} alt="/" />
								</motion.div>
							) : (
								<motion.div
									key={index}
									className=" w-full h-[76px] flex flex-col items-center justify-center"
									style={{
										backgroundColor: `${bgColor}`,
									}}
									// variants={cardTextAnimate}
								>
									<p className="lg:text-xl text-[16px] max-w-[205px] text-center font-semibold m-0">
										{" "}
									</p>
								</motion.div>
							);
						})}
					</motion.ul>
				</motion.div>
			</motion.div>
		</motion.div>
	);
};

export default PriceFeatures;
