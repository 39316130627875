import { defaultAxios, token } from "../axios";

const baseApi = "/api/associations/dashboard/";

defaultAxios.interceptors.request.use(async function (config) {
  config.headers["Authorization"] = `Bearer ${
    JSON.parse(await localStorage.getItem("persist:auth")).token.split(`"`)[1]
  }`;
  return config;
});

export const getResidentsCount = async (body) => {
  try {
    const result = await token.get(baseApi + "getResidentsCount", {
      headers: {
        Authorization: "Bearer " + body,
      },
    });
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getHomeownersCount = async (body) => {
  try {
    const result = await token.get(baseApi + "getHomeownersCount", {
      headers: {
        Authorization: "Bearer " + body,
      },
    });
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getInquiriesCount = async (body) => {
  try {
    const result = await token.get(baseApi + "getInquiriesCount", {
      headers: {
        Authorization: "Bearer " + body,
      },
    });
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getDailyTransactionsCount = async (body) => {
  try {
    const result = await token.get(baseApi + "getDailyTransactionsCount", {
      headers: {
        Authorization: "Bearer " + body,
      },
    });
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getOverdueTransactions = async (body) => {
  try {
    const result = await token.get(baseApi + "getOverdueTransactions", {
      headers: {
        Authorization: "Bearer " + body,
      },
    });
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getReportsCount = async (body) => {
  try {
    const result = await token.get(baseApi + "getReportsCount", {
      headers: {
        Authorization: "Bearer " + body,
      },
    });
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getYearlyTransactionsCount = async (body) => {
  try {
    const result = await token.get(baseApi + "getYearlyTransactionsCount", {
      headers: {
        Authorization: "Bearer " + body,
      },
    });
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getRevenues = async (body) => {
  try {
    const result = await token.get(baseApi + "getRevenues", {
      headers: {
        Authorization: "Bearer " + body,
      },
    });
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getActivities = async (body) => {
  try {
    const result = await token.get(baseApi + "getActivities", {
      headers: {
        Authorization: "Bearer " + body,
      },
    });
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};
