import { defaultAxios, axiosMultipart, token } from "../axios";

const baseApi = "/api/associations/services/";

defaultAxios.interceptors.request.use(async function (config) {
  config.headers["Authorization"] = `Bearer ${
    JSON.parse(await localStorage.getItem("persist:auth")).token.split(`"`)[1]
  }`;
  return config;
});

axiosMultipart.interceptors.request.use(async function (config) {
  config.headers["Authorization"] = `Bearer ${
    JSON.parse(await localStorage.getItem("persist:auth")).token.split(`"`)[1]
  }`;
  return config;
});

// export const getTransactionList = async () => {
//   try {
//     const result = await defaultAxios.get(baseApi + "getTransactionList");
//     console.log(result);
//     return result;
//   } catch (err) {
//     console.log(err);  
//     return err;
//   }
// };

export const getTransactionList = async (body) => {
  try {
    const result = await token.get(baseApi + "getTransactionList", {
      headers: {
        Authorization: "Bearer " + body,
      },
    });
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getTransaction = async ({ params }) => {
  try {
    const result = await defaultAxios.get(baseApi + "getTransaction/" + params);
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const updateTransaction = async (data) => {
  try {
    const result = await defaultAxios.post(
      baseApi + "updateTransaction/" + data.params,
      data.body
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};
