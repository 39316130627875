import { Breadcrumb } from "antd";
import UpdateProfile from "./UpdateProfile";
import { useState, useEffect } from "react";
import UpdatePassword from "./UpdatePassword";
import { profileMode } from "../../../store/slices/profile/profile-slice";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

const ManageProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [updatePassword, setUpdatePassword] = useState(false);
  const { mode } = useSelector((state) => state.profile);

  const test = () => {
    dispatch(profileMode("editProfile"));
    navigate("/EditProfile");
  };

  return (
    <div className="flex flex-col gap-5">
      <div>
        <Breadcrumb separator=">">
          <Breadcrumb.Item
            className="cursor-pointer hover:text-blue-600 transition-all ease-in duration-150 "
            onClick={() => test()}
          >
            Edit Profile
          </Breadcrumb.Item>
          {mode === "editPass" && (
            <Breadcrumb.Item className="cursor-pointer hover:text-blue-600 transition-all ease-in duration-150">
              Change Password
            </Breadcrumb.Item>
          )}
        </Breadcrumb>
      </div>
      <Outlet />
    </div>
  );
};
export default ManageProfile;
