import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  EyeOutlined,
  FileExcelOutlined,
  PlusSquareOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Table } from "ant-table-extensions";
import {
  Button,
  Card,
  Drawer,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  Space,
  Tag,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProperties } from "../../../store/slices/homeowner/homeowner-properties-slice";
import {
  getFilteredHomeowner,
  getHomeowners,
} from "../../../store/slices/homeowner/homeowner-slice";
import HomeownerProfile from "./HomeownerProfile";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const TableSample = () => {
  const [form] = Form.useForm();
  const { homeowner, isLoading, registered } = useSelector(
    (state) => state.homeowners
  );
  const [editToggle, setEditToggle] = useState(false);
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [viewHomeowner, setViewHomeowner] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [assocIdMem, setAssocIdMem] = useState();

  const [homeownerArr, setHomeownerArr] = useState(homeowner);
  const dispatch = useDispatch();

  const isEditing = (record) => record.key === editingKey;

  useEffect(() => {
    dispatch(getHomeowners());
  }, []);

  useEffect(() => {
    setHomeownerArr(homeowner);
  }, [homeowner]);

  const items = [
    {
      label: (
        <div className="flex gap-2 items-center">
          <EyeOutlined />
          <p>View profile</p>
        </div>
      ),
      key: "1",
    },
  ];

  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "120px",
      render: (record) => (
        <Dropdown
          trigger={["click"]}
          menu={{
            items,
            onClick: () => view(record),
          }}
        >
          <Button
            type="primary"
            style={{
              background: "#4d8e03",
            }}
          >
            <Space>
              Action
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
    {
      title: "QR Code",
      dataIndex: "qrCode",
      width: "200px",
    },
    {
      title: "Homeowner ID",
      dataIndex: "homeownerId",
      width: "200px",
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      width: "200px",
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    {
      title: "Middle Name",
      dataIndex: "middlename",
      width: "200px",
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
      render: (md) => (md ? md : "-"),
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      width: "200px",
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    {
      title: "Suffix",
      dataIndex: "suffix",
      width: "200px",
      render: (suffix) => (suffix === null ? "-" : suffix),
    },
    {
      title: "Sex",
      dataIndex: "sex",
      width: "200px",
      render: (sex) => (+sex === null ? "-" : +sex === 0 ? "Male" : "Female"),
      sorter: (a, b) => a - b,
    },
    {
      title: "Birthdate",
      dataIndex: "birthdate",
      width: "200px",
      render: (bday) => moment(bday).format("LL"),
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "200px",
    },
    {
      title: "Mobile number",
      dataIndex: "mobileNumber",
      width: "200px",
    },
    {
      title: "Type",
      dataIndex: "type",
      width: "200px",
      render: (type) => {
        if (type === "HOMEOWNER") {
          return <Tag color="blue">{type}</Tag>;
        } else if (type === "RESIDENT") {
          return <Tag color="volcano">{type}</Tag>;
        }
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  });

  const cancel = () => {
    setEditingKey("");
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const onCloseHomeowner = () => {
    setViewHomeowner(false);
    setEditToggle(false);
  };

  // Filter search
  const filterHandler = (type, val, arr, key) => {
    switch (type) {
      case "firstName":
        return val
          ? arr.filter((obj) =>
              obj[key]?.toLowerCase()?.includes(val.toLowerCase())
            )
          : [];
      case "lastName":
        return val
          ? arr.filter((obj) =>
              obj[key].toLowerCase()?.includes(val.toLowerCase())
            )
          : [];
      case "mobileNumber":
        return val ? arr.filter((obj) => obj[key]?.includes(val)) : [];
      case "qrCode":
        return val ? arr.filter((obj) => obj[key]?.includes(val)) : [];
    }
  };

  const onFinished = (values) => {
    if (
      values.firstName ||
      values.lastName ||
      values.qrCode ||
      values.mobileNumber
    ) {
      const fName = filterHandler(
        "firstName",
        values.firstName,
        homeowner,
        "firstName"
      );
      const lName = filterHandler(
        "lastName",
        values.lastName,
        homeowner,
        "lastName"
      );
      const qr = filterHandler("qrCode", values.qrCode, homeowner, "qrCode");
      const num = filterHandler(
        "mobileNumber",
        values.mobileNumber,
        homeowner,
        "mobileNumber"
      );

      const mergedArr = [...fName, ...lName];
      const mergedArr2 = [...qr, ...num];
      const mergedArr3 = [...mergedArr, ...mergedArr2];
      setHomeownerArr(mergedArr3);
    } else {
      setHomeownerArr(homeowner);
    }
  };

  const clearFormFields = () => {
    form.resetFields();
    setHomeownerArr(homeowner);
  };

  const onSearchFailed = (errorInfo) => {};

  const view = (record) => {
    setViewHomeowner(true);
    setAssocIdMem(record.homeownerId);
    setEditToggle(false);
    dispatch(getProperties(record.homeownerId));
  };

  const editHandler = (record) => {
    setViewHomeowner(true);
    setAssocIdMem(record.homeownerId);
    dispatch(getProperties(record.homeownerId));
    setEditToggle(true);
  };

  // Filter search

  return (
    <div className="flex flex-col gap-7">
      <Card
        className="shadow-md card-search"
        title={<h1 className="font-[600] text-[25px] ">Search homeowner</h1>}
      >
        <div className="flex gap-5 w-full">
          <Form
            form={form}
            layout="vertical"
            // disabled={isLoading}
            className="flex flex-col w-full"
            style={{
              gap: "20px",
            }}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinished}
            onFinishFailed={onSearchFailed}
            autoComplete="off"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 w-full">
              <div className="w-full">
                <Form.Item
                  initialValue={""}
                  label={<h1 className="text-[18px] font-[500]">First name</h1>}
                  name="firstName"
                >
                  <Input
                    className="input-gray input-text-gray"
                    placeholder="Enter first name"
                    allowClear
                    style={{
                      width: "90%",
                      padding: "10px",
                      fontSize: "15px",
                    }}
                  />
                </Form.Item>
                <Form.Item
                  initialValue={""}
                  name="lastName"
                  label={<h1 className="text-[18px] font-[500]">Last name</h1>}
                >
                  <Input
                    className="input-gray input-text-gray"
                    placeholder="Enter last name"
                    allowClear
                    style={{
                      width: "90%",
                      padding: "10px",
                      fontSize: "15px",
                    }}
                  />
                </Form.Item>
              </div>
              <div className="w-full">
                <Form.Item
                  value={""}
                  name="qrCode"
                  label={<h1 className="text-[18px] font-[500]">QR Code ID</h1>}
                >
                  <Input
                    className="input-gray input-text-gray"
                    name="grade"
                    placeholder="Enter qr code id"
                    allowClear
                    style={{
                      width: "90%",
                      padding: "10px",
                      fontSize: "15px",
                    }}
                  />
                </Form.Item>
                <Form.Item
                  value={""}
                  name="mobileNumber"
                  label={
                    <h1 className="text-[18px] font-[500]">Mobile number</h1>
                  }
                >
                  <Input
                    className="input-gray input-text-gray"
                    placeholder="Enter mobile number"
                    allowClear
                    style={{
                      width: "90%",
                      padding: "10px",
                      fontSize: "15px",
                    }}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="flex gap-5">
              <Form.Item className="w-full md:w-[140px]">
                <Button
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                    maxWidth: "140px",
                    height: "40px",
                    background: "#4d8e03",
                    border: "1px solid transparent",
                    fontSize: "15px",
                    fontWeight: "500",
                    marginBottom: 0,
                  }}
                  // loading={isLoading}
                  type="primary"
                  htmlType="submit"
                  icon={<SearchOutlined />}
                >
                  Search
                </Button>
              </Form.Item>
              <Form.Item className="w-full md:w-[140px]">
                <Button
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                    maxWidth: "140px",
                    height: "40px",
                    border: "2px solid #4d8e03",
                    color: "#1b1b1b",
                    fontSize: "15px",
                    fontWeight: "500",
                    marginBottom: 0,
                  }}
                  // loading={isLoading}
                  type="outlined"
                  onClick={() => {
                    clearFormFields();
                  }}
                >
                  Clear
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Card>
      <Card
        className="shadow-md"
        title={<h1 className="font-[600] text-[25px] mb-0">Homeowners</h1>}
        extra={
          <div className="flex items-center text-center justify-center">
            {/* <Button
              style={{
                border: "1px solid transparent",
                alignItems: "center",
                display: "flex",
              }}
              type="outlined"
              onClick={showDrawer}
              icon={
                <PlusSquareOutlined className="text-2xl h-full items-center flex justify-center" />
              }
            >
              Add
            </Button> */}
          </div>
        }
      >
        <Table
          // loading={isLoading}
          searchableProps={{
            // dataSource,
            // setDataSource: setSearchDataSource,
            inputProps: {
              placeholder: "Search this table...",
              suffix: <SearchOutlined />,
            },
          }}
          exportableProps={{
            btnProps: {
              className: "export-btn",
              icon: <FileExcelOutlined />,
              children: <span>Export to CSV</span>,
            },
          }}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={
            homeownerArr
              ? homeownerArr?.filter(
                  (approve) => approve.isApproved === "APPROVED"
                )
              : []
          }
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
          loading={isLoading}
          rowKey="assocMemberId"
          scroll={{
            y: 400,
            x: "100vw",
          }}
        />
        <Drawer
          title={"Sample"}
          placement="bottom"
          closable={false}
          onClose={onClose}
          open={visible}
          className="demo"
          height={"92%"}
        ></Drawer>
        <HomeownerProfile
          editToggle={editToggle}
          setEditToggle={setEditToggle}
          homeownerId={assocIdMem}
          viewHomeowner={viewHomeowner}
          closeHomeowner={onCloseHomeowner}
        />
      </Card>
    </div>
  );
};

export default TableSample;
