import { CloseOutlined } from "@ant-design/icons";
import { Form, Modal } from "antd";
import React, { useState } from "react";

const InquiriesModal = ({ viewBilling, closeBilling }) => {
  return (
    <div className="flex justify-center items-center">
      <Modal
        title={
          <div className="flex md:flex-row md:items-center items-start justify-between">
            <h1 className="text-[24px] md:text-[30px] font-[600] text-[#1b1b1b] mb-0 p-3">
              Inquiry
            </h1>
            <div
              onClick={() => closeBilling(true)}
              className="w-[50px] h-[50px] items-center text-center flex justify-center text-[20px] rounded-full hover:bg-slate-200 transition-all ease-in duration-150 cursor-pointer hover:scale-110"
            >
              <CloseOutlined className="text-[#1b1b1b]" />
            </div>
          </div>
        }
        centered
        onCancel={closeBilling}
        open={viewBilling}
        footer={false}
        width={"706px"}
        closable={false}
      ></Modal>
    </div>
  );
};

export default InquiriesModal;
