import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

const initialState = {
  isLoading: false,
  streetLoading: false,
  phaseLoading: false,
  propertyNameLoading: false,
  propertyTypeLoading: false,
  propertySizeLoading: false,
  propertiesLoading: false,
  phaseId: null,
  phases: [],
  propertyNames: [],
  propertyTypes: [],
  propertySize: [],
  streets: [],
  properties: [],
};

const propertyPhasesSlice = createSlice({
  name: "propertyPhases",
  initialState,
  reducers: {
    getProperties(state) {
      state.properties = [];
      state.propertiesLoading = true;
    },
    getPropertiesSuccess(state, action) {
      state.propertiesLoading = false;
      state.properties = action.payload;
    },
    rejectProperty(state) {
      state.propertiesLoading = true;
    },
    rejectPropertySuccess(state, action) {
      state.propertiesLoading = false;
      message.success(action.payload.message);
    },
    approveProperty(state) {
      state.propertiesLoading = true;
    },
    approvePropertySuccess(state, action) {
      state.propertiesLoading = false;
      message.success(action.payload.message);
    },
    getPropertyPhases(state, action) {
      state.phases = [];
      state.phaseLoading = true;
    },
    getPropertyPhasesSuccess(state, action) {
      state.phaseLoading = false;
      state.phases = action.payload.reverse();
      console.log(action.payload);
    },
    addPropertyPhase(state) {
      state.phaseLoading = true;
    },
    addPropertyPhasesSuccess(state, action) {
      state.phaseLoading = false;
      message.success(action.payload.message);
    },
    removePropertyPhase(state) {
      state.phaseLoading = true;
    },
    removePropertyPhaseSuccess(state, action) {
      state.phaseLoading = false;
      message.success(action.payload.message);
    },
    removePropertyStreet(state) {
      state.streetLoading = true;
    },
    removePropertyStreetSuccess(state, action) {
      state.streetLoading = false;
      message.success(action.payload.message);
    },
    removePropertyName(state) {
      state.propertyNameLoading = true;
    },
    removePropertyNameSuccess(state, action) {
      state.propertyNameLoading = false;
      message.success(action.payload.message);
    },
    removePropertyType(state) {
      state.propertyTypeLoading = true;
    },
    removePropertyTypeSuccess(state, action) {
      state.propertyTypeLoading = false;
      message.success(action.payload.message);
    },
    removePropertySize(state) {
      state.propertySizeLoading = true;
    },
    removePropertySizeSuccess(state, action) {
      state.propertySizeLoading = false;
      message.success(action.payload.message);
    },
    getPropertyStreets(state) {
      state.streets = [];
      state.streetLoading = true;
    },
    getPropertyStreetsSuccess(state, action) {
      state.streetLoading = false;
      state.streets = action.payload.reverse();
    },
    addPropertyStreets(state) {
      state.streetLoading = true;
    },
    addPropertyStreetsSuccess(state, action) {
      state.streetLoading = false;
      message.success(action.payload.message);
    },
    getPropertyNames(state) {
      state.propertyNames = [];
      state.propertyNameLoading = true;
    },
    getPropertyNamesSuccess(state, action) {
      state.propertyNameLoading = false;
      state.propertyNames = action.payload;
    },
    addPropertyName(state) {
      state.propertyNameLoading = true;
    },
    addPropertyNameSuccess(state, action) {
      state.propertyNameLoading = false;
      message.success(action.payload.message);
    },
    getPropertyTypes(state) {
      state.propertyTypes = [];
      state.propertyTypeLoading = true;
    },
    getPropertyTypesSuccess(state, action) {
      state.propertyTypeLoading = false;
      state.propertyTypes = action.payload.reverse();
    },
    addPropertyType(state) {
      state.propertyTypeLoading = true;
    },
    addPropertyTypeSuccess(state, action) {
      state.propertyTypeLoading = false;
      message.success(action.payload.message);
    },
    getPropertySizes(state) {
      state.propertySize = [];
      state.propertySizeLoading = true;
    },
    getPropertySizesSuccess(state, action) {
      state.propertySizeLoading = false;
      state.propertySize = action.payload.reverse();
    },
    addPropertySize(state) {
      state.propertySizeLoading = true;
    },
    addPropertySizeSuccess(state, action) {
      state.propertySizeLoading = false;
      message.success(action.payload.message);
    },
    getUpdatedPhase(state) {
      state.phaseLoading = true;
    },
    getUpdatedPhaseSuccess(state, action) {
      message.success(action.payload.message);
      state.phaseLoading = false;
    },
    getUpdatedPropertyName(state) {
      state.propertyNameLoading = true;
    },
    getUpdatedPropertyNameSuccess(state, action) {
      message.success(action.payload.message);
      state.propertyNameLoading = false;
    },
    getUpdatedPropertySize(state) {
      state.propertySizeLoading = true;
    },
    getUpdatedPropertySizeSuccess(state, action) {
      message.success(action.payload.message);
      state.propertySizeLoading = false;
    },
    getUpdatedPropertyType(state) {
      state.propertyTypeLoading = true;
    },
    getUpdatedPropertyTypeSuccess(state, action) {
      message.success(action.payload.message);
      state.propertyTypeLoading = false;
    },
    getUpdatedPropertyStreet(state) {
      state.streetLoading = true;
    },
    getUpdatedPropertyStreetSuccess(state, action) {
      message.success(action.payload.message);
      state.streetLoading = false;
    },
    requestError(state, action) {
      state.isLoading = false;
      state.streetLoading = false;
      state.phaseLoading = false;
      state.propertyNameLoading = false;
      state.propertyTypeLoading = false;
      state.propertySizeLoading = false;
      message.error(action.payload.data.message);
    },
  },
});

export const {
  getUpdatedPropertyStreet,
  getUpdatedPropertyStreetSuccess,
  getUpdatedPropertyType,
  getUpdatedPropertyTypeSuccess,
  getUpdatedPropertySize,
  getUpdatedPropertySizeSuccess,
  getUpdatedPropertyName,
  getUpdatedPropertyNameSuccess,
  getUpdatedPhase,
  getUpdatedPhaseSuccess,
  getPropertyPhases,
  getPropertyPhasesSuccess,

  addPropertyPhase,
  addPropertyPhasesSuccess,

  removePropertyStreet,
  removePropertyStreetSuccess,
  removePropertyName,
  removePropertyNameSuccess,
  removePropertyType,
  removePropertyTypeSuccess,
  removePropertySize,
  removePropertySizeSuccess,

  getPropertyStreets,
  getPropertyStreetsSuccess,
  addPropertyStreets,
  addPropertyStreetsSuccess,
  getPropertyNames,
  getPropertyNamesSuccess,
  addPropertyName,
  addPropertyNameSuccess,
  getPropertyTypes,
  getPropertyTypesSuccess,
  addPropertyType,
  addPropertyTypeSuccess,
  getPropertySizes,
  getPropertySizesSuccess,
  addPropertySize,
  addPropertySizeSuccess,
  getProperties,
  getPropertiesSuccess,
  rejectProperty,
  rejectPropertySuccess,
  removePropertyPhase,
  removePropertyPhaseSuccess,
  approveProperty,
  approvePropertySuccess,
  requestError,
} = propertyPhasesSlice.actions;
export default propertyPhasesSlice;
