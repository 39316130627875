import { CloseOutlined } from "@ant-design/icons";
import { Button, Drawer, Form, Input, InputNumber, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Marker } from "@googlemaps/react-wrapper";
import {
  addDirectories,
  updateDirectories,
} from "../../../../store/slices/directories-and-hotlines/directories/directories-slice";
import {
  addHotlines,
  updateHotlines,
} from "../../../../store/slices/directories-and-hotlines/hotlines/hotlines-slice";

const HotlineForm = ({
  openHotlineForm,
  closeHotlineForm,
  hotlineTypes,
  isEditing,
  isAdding,
  hotlines,
  id,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [marker, setMarker] = useState();
  const [map, setMap] = useState(null);
  const { addAnnouncementsLoading } = (state) => state.announcements;
  const mapRef = useRef();

  const onFinishFailed = (errorInfo) => {};

  const found = hotlines?.find((obj) => obj.hotlineId === id);

  const onFinish = (values) => {
    {
      isEditing
        ? dispatch(
            updateHotlines({
              params: found?.hotlineId,
              body: {
                name: values?.name,
                typeId: values?.hotlineType,
                contactNumber: "0" + values?.mobileNumber,
                contactPerson: values?.contactPerson,
              },
              cb: () => {
                form.resetFields();
              },
            })
          )
        : dispatch(
            addHotlines({
              name: values?.name,
              typeId: values?.hotlineType,
              contactNumber: "0" + values?.mobileNumber,
              contactPerson: values?.contactPerson,
              cb: () => {
                form.resetFields();
              },
            })
          );
    }

    closeHotlineForm(true);
  };

  useEffect(() => {
    const type = hotlineTypes?.find((obj) => obj?.typeId === found?.typeId);

    if (isEditing) {
      form.setFieldsValue({
        ...found,
        hotlineType: type?.name,
        name: found?.name,
        mobileNumber: found?.contactNumber.substring(1),
        contactPerson: found?.contactPerson,
      });
    } else {
      form.resetFields();
    }
  }, [isEditing, id]);

  const closeAdd = () => {
    closeHotlineForm(true);
  };

  return (
    <div className="flex justify-center items-center">
      <Drawer
        title={
          <div className="flex md:flex-row md:items-center items-start justify-between">
            <h1 className="text-[24px] md:text-[30px] font-[600] text-[#1b1b1b] mb-0 p-3">
              {isEditing ? "Edit Hotline" : "Add Hotline"}
            </h1>
            <div
              onClick={closeAdd}
              className="w-[50px] h-[50px] items-center text-center flex justify-center text-[20px] rounded-full hover:bg-slate-200 transition-all ease-in duration-150 cursor-pointer hover:scale-110"
            >
              <CloseOutlined className="text-[#1b1b1b]" />
            </div>
          </div>
        }
        centered
        onClose={closeAdd}
        open={openHotlineForm}
        footer={
          <div className="flex justify-end">
            <Button
              loading={addAnnouncementsLoading}
              disabled={addAnnouncementsLoading}
              className="shadow-md"
              type="primary"
              onClick={form.submit}
              htmlType="submit"
              style={{
                minWidth: "200px",
                height: "52px",
                fontSize: "17px",
                fontWeight: "400",
                borderRadius: "5px",
                background: "#4d8e03",
                marginBlock: "13px",
              }}
            >
              {isEditing ? "Update" : "Confirm"}
            </Button>
          </div>
        }
        height={"90%"}
        closable={false}
        placement={"bottom"}
      >
        <Form
          form={form}
          name="add"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <div className="flex md:flex-col flex-col gap-10 items-center md:items-start p-6">
            <div className="flex flex-col gap-4 w-full md:w-[20%]  md:flex-1 ">
              <div className="text-label">
                <Form.Item
                  name="hotlineType"
                  label={
                    <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
                      Hotline Type
                    </h1>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please enter hotline type",
                    },
                  ]}
                >
                  <Select
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    optionFilterProp="children"
                    showSearch
                    size="large"
                    // onChange={""}
                    // onSearch={""}
                    placeholder="Hotline type"
                    options={hotlineTypes
                      ?.filter((obj) => obj.status === 0)
                      .map((dir, i) => {
                        return {
                          value: dir.typeId,
                          label: dir.name,
                        };
                      })}
                  ></Select>
                </Form.Item>
                <Form.Item
                  name="name"
                  label={
                    <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
                      Name
                    </h1>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please input Name",
                    },
                  ]}
                >
                  <Input
                    style={{
                      padding: "10px",
                      fontSize: "18px",
                      borderRadius: "3px",
                    }}
                    placeholder="Enter name "
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="mobileNumber"
                  label={
                    <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
                      Contact number
                    </h1>
                  }
                >
                  <InputNumber
                    placeholder="Enter contact number"
                    style={{
                      padding: "10px",
                      fontSize: "18px",
                      borderRadius: "3px",
                      width: "100%",
                    }}
                  />
                </Form.Item>
                <Form.Item
                  initialValue={""}
                  name="contactPerson"
                  label={
                    <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
                      Contact person
                    </h1>
                  }
                >
                  <Input
                    style={{
                      padding: "10px",
                      fontSize: "18px",
                      borderRadius: "3px",
                    }}
                    placeholder="Enter contact person"
                    size="large"
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
      </Drawer>
    </div>
  );
};

export default HotlineForm;
