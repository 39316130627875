import { defaultAxios, axiosMultipart } from "../axios";

const baseApi = "/api/associations/auth/";
const verify = "/api/";

defaultAxios.interceptors.request.use(async function (config) {
  config.headers["Authorization"] = `Bearer ${
    JSON.parse(await localStorage.getItem("persist:auth")).token.split(`"`)[1]
  }`;
  return config;
});

axiosMultipart.interceptors.request.use(async function (config) {
  config.headers["Authorization"] = `Bearer ${
    JSON.parse(await localStorage.getItem("persist:auth")).token.split(`"`)[1]
  }`;
  return config;
});

export const onboarding = async (body) => {
  try {
    const result = await axiosMultipart.post(
      baseApi + "onboarding",
      body.formData
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const verifyEmail = async (params) => {
  try {
    const result = await defaultAxios.get(verify + "verifyEmail/" + params);
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};
