import {
  EyeOutlined,
  DownOutlined,
  FormOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { SearchTableInput, Table } from "ant-table-extensions";
import { Button, Drawer, Dropdown, Form, Space, Card } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProperties } from "../../../store/slices/homeowner/homeowner-properties-slice";
import { getHomeowners } from "../../../store/slices/homeowner/homeowner-slice";
import { LoadingTime } from "../../../UI/LoadingScreen";
import HomeownerProfile from "../homeowners/HomeownerProfile";
import HomeownerProperties from "./HomeownerProperties";

const SearchHomeowner = ({
  isEditing,
  close,
  open,
  billId,
  billingStatement,
  homeownerId,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [propertyId, setPropertyId] = useState("");
  const { homeowner, isLoading } = useSelector((state) => state.homeowners);
  const { properties } = useSelector((state) => state.homeownerProperties);
  const { billingStatementLoading } = useSelector((state) => state.billing);
  const [filterendHomeowner, setFilterendHomeowner] = useState([]);
  const [searchDataSource, setSearchDataSource] = useState(homeowner);
  const [viewHomeowner, setViewHomeowner] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [assocIdMem, setAssocIdMem] = useState();
  const [addBill, setAddBill] = useState(true);

  const items = [
    {
      label: (
        <div className="flex gap-2 items-center">
          <EyeOutlined />
          <p>View Properties</p>
        </div>
      ),
      key: "1",
    },
  ];

  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "120px",
      render: (record) => (
        <Dropdown
          trigger={["click"]}
          menu={{
            items,
            onClick: () => viewHomeownerProperties(record),
          }}
        >
          <Button
            type="primary"
            style={{
              background: "#4d8e03",
            }}
          >
            <Space>
              Action
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      width: "200px",
      editable: true,
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
      render: (obj) => (obj === null ? "-" : obj),
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      width: "200px",
      editable: true,
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
      render: (obj) => (obj === null ? "-" : obj),
    },
  ];

  const closeHandler = () => {
    close(true);
  };

  const viewHomeownerProperties = (value) => {
    setViewHomeowner(true);
    setAssocIdMem(value.homeownerId);
    dispatch(getProperties(value.homeownerId));
  };

  const onCloseHomeowner = () => {
    setViewHomeowner(false);
  };

  useEffect(() => {
    dispatch(getHomeowners());
  }, []);

  useEffect(() => {
    setSearchDataSource(homeowner);
  }, [homeowner]);

  const found = billingStatement;

  useEffect(() => {
    if (isEditing) {
      form.setFieldsValue({
        ...found,
        totalAmount: found?.totalAmount,
        amount: found?.amount,
        vatAmount: found?.vatAmount,
        remarks: found?.remarks,
        dueDate: dayjs(found?.dueDate, "YYYY-MM-DD"),
      });
    }
  }, [isEditing, propertyId, billId]);

  return (
    <div>
      <LoadingTime loading={billingStatementLoading} />
      <Card>
        <div className="">
          <div className="p-6 flex flex-col gap-6">
            <div className="flex flex-col w-[50%">
              <h1 className="text-[24px] md:text-[30px] font-[600] text-[#1b1b1b] mb-0 pb-2">
                Search homeowner
              </h1>
              <div className="w-[50%]">
                <SearchTableInput
                  columns={columns}
                  dataSource={homeowner} // 🔴 Original dataSource
                  setDataSource={setSearchDataSource} // 🔴 Newly created setSearchDataSource from useState hook
                  inputProps={{
                    placeholder: "Enter homeowner name.",
                    prefix: <SearchOutlined />,
                  }}
                />
              </div>
            </div>
            <Table
              loading={isLoading}
              rowKey={"homeownerId"}
              columns={columns}
              dataSource={searchDataSource}
              scroll={{
                y: 500,
                x: "100%",
              }}
            />
          </div>
        </div>
        <HomeownerProperties
          open={viewHomeowner}
          close={onCloseHomeowner}
          homeownerId={assocIdMem}
          properties={properties}
          isLoading={isLoading}
        />
        {/* <HomeownerProfile
          homeownerId={assocIdMem}
          viewHomeowner={viewHomeowner}
          closeHomeowner={onCloseHomeowner}
          billing={addBill}
        /> */}
      </Card>
    </div>
  );
};

export default SearchHomeowner;
