import React, { useEffect } from "react";
import { Tabs } from "antd";
import "../inquiries/inquiries.css";
import PendingReports from "./PendingReport";
import ProcessedReport from "./ProcessedReport";
import RemovedReport from "./RemovedReport";
import ResolvedReport from "./ResolvedReport";
import { useDispatch, useSelector } from "react-redux";
import { setActiveKeyReport } from "../../../../store/slices/inquiries/inquiries-slice";

const items = [
  { label: "Pending", key: "item-1", children: <PendingReports /> }, // remember to pass the key prop
  { label: "Processing", key: "item-2", children: <ProcessedReport /> },
  { label: "Resolved", key: "item-3", children: <ResolvedReport /> },
  { label: "Removed", key: "item-4", children: <RemovedReport /> },
];

const UtilityReports = () => {
  const dispatch = useDispatch();
  const { activeKeyReport } = useSelector((state) => state.inquiry);

  return (
    <Tabs
      defaultActiveKey="item-1"
      type="card"
      className="tabs-inquiries"
      activeKey={activeKeyReport}
      onChange={(e) => dispatch(setActiveKeyReport(e))}
      animated={true}
      items={items}
    ></Tabs>
  );
};
export default UtilityReports;
