import { defaultAxios, axiosMultipart } from "../../axios";

const baseApi = "/api/associations/services/";

defaultAxios.interceptors.request.use(async function (config) {
  config.headers["Authorization"] = `Bearer ${
    JSON.parse(await localStorage.getItem("persist:auth")).token.split(`"`)[1]
  }`;
  return config;
});

axiosMultipart.interceptors.request.use(async function (config) {
  config.headers["Authorization"] = `Bearer ${
    JSON.parse(await localStorage.getItem("persist:auth")).token.split(`"`)[1]
  }`;
  return config;
});

export const getEvents = async () => {
  try {
    const result = await defaultAxios.get(baseApi + "getEvents");
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const addEvent = async (body) => {
  try {
    const result = await axiosMultipart.post(
      baseApi + "addEvent",
      body.formData
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const addEventAttachment = async (data) => {
  try {
    const result = await axiosMultipart.post(
      baseApi + "addEventAttachment/" + data.params,
      data.body.formData
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const updateEvent = async (data) => {
  try {
    const result = await defaultAxios.post(
      baseApi + "updateEvent/" + data.params,
      data.body
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const updateEventAttachment = async (data) => {
  try {
    const result = await axiosMultipart.post(
      baseApi + "updateEventAttachment/" + data.params,
      data.body.formData
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const removeEventAttachment = async (params) => {
  try {
    const result = await defaultAxios.post(
      baseApi + "removeEventAttachment/" + params
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const removeEvent = async (params) => {
  try {
    const result = await defaultAxios.post(baseApi + "removeEvent/" + params);
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const addEventType = async (body) => {
  try {
    const result = await defaultAxios.post(baseApi + "addEventType", body);
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getEventTypes = async () => {
  try {
    const result = await defaultAxios.get(baseApi + "getEventTypes");
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const removeEventType = async (params) => {
  try {
    const result = await defaultAxios.post(
      baseApi + "removeEventType/" + params
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const updateEventType = async (data) => {
  try {
    const result = await defaultAxios.post(
      baseApi + "updateEventType/" + data.params,
      data.body
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};
