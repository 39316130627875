import { put, call, takeLatest } from "redux-saga/effects";
import {
  getHotlines,
  getHotlineTypes,
  updateHotlineType,
  addHotlineType,
  removeHotlineType,
  addHotline,
  updateHotline,
} from "../../../api/directories-and-hotlines/hotlines/hotlines-api";
import { hotlinesActions } from "../../../store";

function* getHotlinesRequest() {
  const { requestError, getHotlinesSuccess } = hotlinesActions;
  const result = yield call(getHotlines);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getHotlinesSuccess(result.data));
  }
}

function* getHotlineTypesRequest() {
  const { requestError, getHotlineTypesSuccess } = hotlinesActions;
  const result = yield call(getHotlineTypes);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getHotlineTypesSuccess(result.data));
  }
}

function* updateHotlineTypeRequest({ payload }) {
  const { requestError, updateHotlineTypesSuccess, getHotlineTypes } =
    hotlinesActions;
  const result = yield call(updateHotlineType, payload);

  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(updateHotlineTypesSuccess(result.data));
    yield put(getHotlineTypes());
  }
}

function* addDirectoryTypeRequest({ payload }) {
  const { requestError, addHotlineTypesSuccess, getHotlineTypes } =
    hotlinesActions;
  const result = yield call(addHotlineType, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(addHotlineTypesSuccess(result.data));
    yield put(getHotlineTypes());
  }
}

function* updateHotlineRequest({ payload }) {
  const { requestError, updateHotlinesSuccess, getHotlines } = hotlinesActions;
  const result = yield call(updateHotline, payload);

  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(updateHotlinesSuccess(result.data));
    yield put(getHotlines());
    if (payload.cb) {
      yield call(payload.cb);
    }
  }
}

function* addHotlineRequest({ payload }) {
  const { requestError, addHotlinesSuccess, getHotlines } = hotlinesActions;
  const result = yield call(addHotline, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(addHotlinesSuccess(result.data));
    yield put(getHotlines());
    if (payload.cb) {
      yield call(payload.cb);
    }
  }
}

function* removeHotlineTypeRequest({ payload }) {
  const { requestError, removeHotlineTypesSuccess, getHotlineTypes } =
    hotlinesActions;
  const result = yield call(removeHotlineType, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(removeHotlineTypesSuccess(result.data));
    yield put(getHotlineTypes());
  }
}
// Export the saga (todo-saga)
export default function* driectoriesSaga() {
  yield takeLatest(`hotlines/getHotlines`, getHotlinesRequest);
  yield takeLatest(`hotlines/addHotlines`, addHotlineRequest);
  yield takeLatest(`hotlines/updateHotlines`, updateHotlineRequest);
  yield takeLatest(`hotlines/getHotlineTypes`, getHotlineTypesRequest);
  yield takeLatest(`hotlines/updateHotlineTypes`, updateHotlineTypeRequest);
  yield takeLatest(`hotlines/addHotlineTypes`, addDirectoryTypeRequest);
  yield takeLatest(`hotlines/removeHotlineTypes`, removeHotlineTypeRequest);
}
