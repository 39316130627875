import { Button, Drawer, Form, Table } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCharges,
  createBillingStatementWithCharges,
  setAddingState,
} from "../../../store/slices/billing/billing-slice";
import AddChargesToBilling from "./AddChargesToBilling/AddChargesToBilling";
import { useEffect } from "react";
import { LoadingTime } from "../../../UI/LoadingScreen";

const HomeownerProperties = ({
  open,
  close,
  homeownerId,
  properties,
  isLoading,
}) => {
  const [accountId, setAccountId] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [off, setOff] = useState(true);
  const dispatch = useDispatch();
  const [adding, setAdding] = useState(false);
  const [form] = Form.useForm();
  const [file, setFile] = useState([]);
  const [extension, setExtension] = useState();

  const [descArr, setDescArr] = useState([]);
  const { isLoading: propertyLoading } = useSelector(
    (state) => state.homeownerProperties
  );

  const [date, setDate] = useState();
  const [remarks, setRemarks] = useState();

  const {
    assocCharges,
    billingCharges,
    billingChargesAttachments,
    descriptions,
    createBillLoading,
    success,
  } = useSelector((state) => state.billing);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setAccountId(selectedRows[0].propertyId);
      setOff(false);
      setAdding(true);
      dispatch(setAddingState(false));
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const onClose = () => {
    close(true);
    setSelectedRowKeys([]);
    setAdding(false);
    dispatch(clearCharges());
  };

  const columns = [
    {
      title: "Property name",
      dataIndex: "propertyName",
      width: "200px",
      editable: true,
      render: (obj) => (obj === null ? "-" : obj),
    },
    {
      title: "Custom name",
      dataIndex: "customName",
      width: "200px",
      editable: true,
      render: (obj) => (obj === null ? "-" : obj),
    },
    {
      title: "Address",
      dataIndex: "address",
      width: "200px",
      editable: true,
      sorter: (a, b) => a.age - b.age,
      render: (obj) => (obj === null ? "-" : obj),
    },
    {
      title: "Phase",
      dataIndex: "phase",
      width: "200px",
      editable: true,
      render: (obj) => (obj === null ? "-" : obj),
    },
    {
      title: "Street",
      dataIndex: "street",
      width: "200px",
      editable: true,
      render: (obj) => (obj === null ? "-" : obj),
    },
    {
      title: "Property size",
      dataIndex: "propertySize",
      width: "200px",
      editable: true,
      render: (obj) => (obj === null ? "-" : obj),
    },
    {
      title: "Property type",
      dataIndex: "propertyType",
      width: "200px",
      editable: true,
      render: (obj) => (obj === null ? "-" : obj),
    },
  ];

  useEffect(() => {
    for (let index = 0; index < billingChargesAttachments.length; index++) {
      if (billingChargesAttachments[index].length) {
        let attachment = billingChargesAttachments[index][0];
        const extension =
          attachment?.name.split(".")[attachment?.name.split(".").length - 1];

        setExtension(extension);
      }
    }
  }, [billingChargesAttachments]);

  useEffect(() => {
    setDescArr(descriptions);
  }, [descriptions]);

  const createBill = () => {
    let formData = new FormData();

    formData.append("dueDate", date);
    formData.append("propertyId", accountId);
    formData.append("remarks", remarks ? remarks : "n/a");
    formData.append(
      "charges",
      JSON.stringify(billingCharges.map(({ name, ...obj }) => obj))
    );
    formData.append(
      "descriptions",
      JSON.stringify(
        descriptions.map((obj, i) => ({
          ...obj,
          file: `image_${obj.file}_${i + 1}.${extension}`,
        }))
      )
    );

    for (let index = 0; index < billingChargesAttachments.length; index++) {
      if (billingChargesAttachments[index].length) {
        let attachment = billingChargesAttachments[index][0];
        let uid = attachment.uid;
        const extension =
          attachment?.name.split(".")[attachment?.name.split(".").length - 1];

        setExtension(extension);

        const updatedFile = new File(
          [attachment],
          `image_${descriptions[index]?.file}_${index}.${extension}`,
          {
            uid: uid,
            type: attachment.type,
            lastModified: attachment.lastModified,
          }
        );

        formData.append(
          "attachments",
          billingChargesAttachments === "" ? null : updatedFile
        );
      }
    }

    for (const value of formData.values()) {
    }

    dispatch(createBillingStatementWithCharges({ formData }));
  };

  useEffect(() => {
    if (success === true) {
      setSelectedRowKeys([]);
      setAdding(false);
      setRemarks("");
      setDate("");
    }
  }, [success]);

  return (
    <Drawer
      open={open}
      height={"92%"}
      onClose={onClose}
      placement="bottom"
      footer={
        <div className="flex justify-end">
          <Button
            className="shadow-md"
            type="primary"
            onClick={() => createBill()}
            htmlType="submit"
            style={{
              minWidth: "200px",
              height: "52px",
              fontSize: "17px",
              fontWeight: "400",
              borderRadius: "5px",
              background: "#4d8e03",
              marginBlock: "13px",
            }}
          >
            Create bill
          </Button>
        </div>
      }
    >
      <LoadingTime loading={createBillLoading} />
      <div className="p-5">
        <Table
          loading={propertyLoading}
          className="master-table"
          rowSelection={{
            type: "radio",
            ...rowSelection,
          }}
          bordered
          columns={columns}
          rowKey="propertyId"
          dataSource={properties}
          scroll={{
            y: 400,
            x: "100vw",
          }}
        />
        <AddChargesToBilling
          adding={adding}
          propertyId={accountId}
          setDate={setDate}
          setRemarks={setRemarks}
        />
      </div>
    </Drawer>
  );
};

export default HomeownerProperties;
