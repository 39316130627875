import React, { useEffect } from "react";
import {
  DownOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Card,
  Table,
  Dropdown,
  Space,
  Button,
  Image,
  Popconfirm,
  Empty,
  Tag,
  theme,
} from "antd";
import {
  getProperties,
  rejectProperty,
  approveProperty,
} from "../../../../store/slices/property/property-phase-slice";
import { useDispatch, useSelector } from "react-redux";

const { useToken } = theme;
const PendingProperties = () => {
  const { token } = useToken();
  const dispatch = useDispatch();
  const { propertiesLoading, properties } = useSelector(
    (state) => state.propertyPhases
  );

  const { token: token2 } = useSelector((state) => state.auth);

  const text = "Are you sure to reject this property?";
  const description = "Reject the property";

  const items = [
    {
      label: (
        <div className="flex gap-2 items-center">
          <PlusOutlined />
          <p>Approve</p>
        </div>
      ),
      key: "1",
    },
  ];

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle = {
    boxShadow: "none",
  };

  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "150px",
      render: (record) => (
        <Dropdown
          trigger={["click"]}
          menu={{
            items,
            onClick: () => approvePropertyHandler(record.propertyId),
          }}
          dropdownRender={(menu) => (
            <div className="flex flex-col items-start" style={contentStyle}>
              {React.cloneElement(menu, {
                style: menuStyle,
              })}
              {/* <Button
                onClick={() => approvePropertyHandler(record.propertyId)}
                style={{ color: "black" }}
                type="link"
                icon={<EditOutlined />}
              >
                Approve
              </Button> */}
              <Popconfirm
                placement="right"
                title={text}
                description={description}
                onConfirm={() => rejectPropertyHandler(record.propertyId)}
                okText="Yes"
                cancelText="Cancel"
              >
                <Button
                  style={{ color: "black" }}
                  type="link"
                  icon={<DeleteOutlined />}
                >
                  Reject
                </Button>
              </Popconfirm>
            </div>
          )}
        >
          <Button
            type="primary"
            style={{
              background: "#4d8e03",
            }}
          >
            <Space>
              Action
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
    {
      title: "Name",
      dataIndex: "customName",
      width: "200px",
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "200px",
      editable: true,
      render: (status) =>
        status === 0 ? (
          <Tag color="blue">Pending</Tag>
        ) : (
          <Tag color="blue">Active</Tag>
        ),
    },
    {
      title: "Address",
      dataIndex: "address",
      width: "200px",
    },
    {
      title: "Property Name",
      dataIndex: "propertyName",
      width: "200px",
    },
    {
      title: "Type",
      dataIndex: "propertyType",
      width: "200px",
    },
    {
      title: "Size",
      dataIndex: "propertySize",
      width: "200px",
    },
    {
      title: "Phase",
      dataIndex: "propertyPhase",
      width: "200px",
    },
    {
      title: "Street",
      dataIndex: "propertyStreet",
      width: "200px",
    },
    {
      title: "Image",
      dataIndex: "propertyImage",
      align: "center",
      width: "200px",
      render: (record) => {
        return record ? (
          <Image
            width={100}
            src={process.env.REACT_APP_BASE_URL + "/" + record}
          />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(getProperties(token2));
  }, []);

  const rejectPropertyHandler = (value) => {
    dispatch(rejectProperty(value));
  };

  const approvePropertyHandler = (value) => {
    dispatch(approveProperty(value));
  };

  return (
    <div>
      <Card>
        <Table
          loading={propertiesLoading}
          rowKey={"propertyId"}
          columns={columns}
          dataSource={
            properties ? properties?.filter((obj) => obj.status === 0) : []
          }
          scroll={{
            y: 500,
            x: "100%",
          }}
        />
      </Card>
    </div>
  );
};

export default PendingProperties;
