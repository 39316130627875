import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

const initialState = {
  residentsLoading: false,
  homeownersLoading: false,
  inquiriesLoading: false,
  reportsLoading: false,
  dailyTransactionsLoading: false,
  overdueTransactionLoading: false,
  yearlyTransactionsLoading: false,
  revenuesLoading: false,
  activitiesLoading: false,

  residents: [],
  homeowners: [],
  inquiries: [],
  reports: [],
  dailyTransactions: [],
  yearlyTransactions: [],
  overdueTransaction: [],
  revenues: [],
  activities: [],

  mode: null,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    getResidentsCount(state) {
      state.residents = [];
      state.residentsLoading = true;
    },
    getResidentsCountSuccess(state, action) {
      state.residents = action.payload;
      state.residentsLoading = false;
    },
    getHomeownersCount(state) {
      state.homeowners = [];
      state.homeownersLoading = true;
    },
    getHomeownersCountSuccess(state, action) {
      state.homeowners = action.payload;
      state.homeownersLoading = false;
    },
    getInquiriesCount(state) {
      state.inquiries = [];
      state.inquiriesLoading = true;
    },
    getInquiriesCountSuccess(state, action) {
      state.inquiries = action.payload;
      state.inquiriesLoading = false;
    },
    getReportsCount(state) {
      state.reports = [];
      state.reportsLoading = true;
    },
    getReportsCountSuccess(state, action) {
      state.reports = action.payload;
      state.reportsLoading = false;
    },
    getDailyTransactionsCount(state) {
      state.dailyTransactions = [];
      state.dailyTransactionsLoading = true;
    },
    getDailyTransactionsCountSuccess(state, action) {
      state.dailyTransactions = action.payload;
      state.dailyTransactionsLoading = false;
    },
    getYearlyTransactionsCount(state) {
      state.yearlyTransactions = [];
      state.yearlyTransactionsLoading = true;
    },
    getYearlyTransactionsCountSuccess(state, action) {
      state.yearlyTransactions = action.payload;
      state.yearlyTransactionsLoading = false;
    },
    getOverdueTransactions(state) {
      state.overdueTransaction = [];
      state.overdueTransactionLoading = true;
    },
    getOverdueTransactionsSuccess(state, action) {
      const overdue = action.payload;
      state.overdueTransaction = overdue;
      state.overdueTransactionLoading = false;
    },
    getRevenues(state) {
      state.revenues = [];
      state.revenuesLoading = true;
    },
    getRevenuesSuccess(state, action) {
      state.revenues = action.payload;
      state.revenuesLoading = false;
    },
    getActivities(state) {
      state.activities = [];
      state.activitiesLoading = true;
    },
    getActivitiesSuccess(state, action) {
      state.activities = action.payload;
      state.activitiesLoading = false;
    },
    requestError(state, action) {
      state.residentsLoading = false;
      state.homeownersLoading = false;
      state.inquiriesLoading = false;
      state.reportsLoading = false;
      state.dailyTransactionsLoading = false;
      state.overdueTransactionLoading = false;
      state.yearlyTransactionsLoading = false;
      state.revenuesLoading = false;
      state.activitiesLoading = false;
      message.error(action.payload.data.message);
    },
  },
});

export const {
  requestError,
  getResidentsCount,
  getResidentsCountSuccess,
  getHomeownersCount,
  getHomeownersCountSuccess,
  getInquiriesCount,
  getInquiriesCountSuccess,
  getDailyTransactionsCount,
  getDailyTransactionsCountSuccess,
  getOverdueTransactions,
  getOverdueTransactionsSuccess,
  getReportsCount,
  getReportsCountsuccess,
  getYearlyTransactionsCount,
  getYearlyTransactionsCountSuccess,
  getRevenues,
  getRevenuesSuccess,
  getActivities,
  getActivitiesSuccess,
} = usersSlice.actions;
export default usersSlice;
