import { Form, Input } from "antd";
import React from "react";
import { regExp_Email } from "../../helpers/regex";

const AssocName = ({ setFormValues, setCurrent, formData }) => {
  const onFinish = (values) => {
    setFormValues((prevState) => ({ ...prevState, ...values }));
    setCurrent((prev) => prev + 1);
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <div
      className="poppins flex flex-col items-center "
      data-aos="fade-left"
      data-aos-easing="ease-in"
      data-aos-duration="600"
    >
      <h1 className="text-[#568634] font-[700] text-[40px] pb-10">
        What is the name of your association?
      </h1>

      <Form
        form={formData}
        layout="vertical"
        style={{
          width: "100%",
        }}
        name="name"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
            gap: "50px",
          }}
        >
          <div>
            <Form.Item
              label={<h1 className="assoc-name-title">First name</h1>}
              rules={[
                {
                  required: true,
                  message: "Please enter your first name",
                },
              ]}
              name="firstName"
            >
              <Input
                style={{
                  padding: "10px",
                  fontSize: "18px",
                  width: "100%",
                  borderRadius: "3px",
                }}
                placeholder="Enter first name"
                size="large"
              />
            </Form.Item>
            <Form.Item
              label={
                <h1 className="assoc-name-title">Full Name of Association</h1>
              }
              rules={[
                {
                  required: true,
                  message: "Please enter your association name",
                },
              ]}
              name="assocName"
            >
              <Input
                style={{
                  padding: "10px",
                  fontSize: "18px",
                  width: "100%",
                  borderRadius: "3px",
                }}
                placeholder="Enter association name"
                size="large"
              />
            </Form.Item>
            <Form.Item
              label={<h1 className="assoc-name-title">Email address</h1>}
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
                {
                  pattern: regExp_Email,
                  message: "Invalid email format",
                },
              ]}
              name="email"
            >
              <Input
                type="email"
                style={{
                  padding: "10px",
                  fontSize: "18px",
                  width: "100%",
                  borderRadius: "3px",
                }}
                placeholder="Enter email address"
                size="large"
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label={<h1 className="assoc-name-title">Last name</h1>}
              rules={[
                {
                  required: true,
                  message: "Please enter your last name",
                },
              ]}
              name="lastName"
            >
              <Input
                style={{
                  padding: "10px",
                  fontSize: "18px",
                  width: "100%",
                  borderRadius: "3px",
                }}
                placeholder="Enter last name"
                size="large"
              />
            </Form.Item>
            <Form.Item
              label={
                <h1 className="assoc-name-title">Acronym of Association</h1>
              }
              rules={[
                {
                  required: true,
                  message: "Please enter your association acronym",
                },
              ]}
              name="assocAcronym"
            >
              <Input
                style={{
                  width: "100%",
                  padding: "10px",
                  fontSize: "18px",
                  borderRadius: "3px",
                }}
                placeholder="Enter association acronym"
                size="large"
              />
            </Form.Item>
            {/* <Form.Item
              label={
                <h1
                 className="assoc-name-title"
                >
                  Mobile number
                </h1>
              }
              name="mobileNumber"
              rules={[
                {
                  required: true,
                  message: "Please enter phone number",
                },
                {
                  pattern: new RegExp(/^[0-9]+$/),
                  message: "Please enter valid mobile number",
                },
                {
                  min: 10,
                  message: "Mobile number must be at least 10 characters",
                },
              ]}
            >
              <Input
                style={{
                  padding: "10px",
                  fontSize: "18px",
                  borderRadius: "3px",
                }}
                size="large"
                prefix="+63"
              />
            </Form.Item> */}
            <Form.Item
              name="mobileNumber"
              label={<h1 className="assoc-name-title">Mobile number</h1>}
              rules={[
                {
                  required: true,
                  pattern: /^[1-9]{10}$/,
                  message: "Please enter mobile number",
                },
              ]}
            >
              <Input
                placeholder="Mobile number"
                prefix="+63"
                size="large"
                maxLength={10}
                onChange={({ target }) => {
                  formData.setFieldValue(
                    "mobileNumber",
                    // eslint-disable-next-line no-useless-escape
                    target.value.replace(/[- #*;,.<>\{\}\[\]\\\/]|[^0-9]/gi, "")
                  );
                }}
                controls={false}
              />
            </Form.Item>
          </div>
        </div>
        {/* <Button onClick={formName.submit}>Sub</Button> */}
      </Form>
    </div>
  );
};

export default AssocName;
