import defaultAxios from "axios";

const axios = defaultAxios.create({
  baseURL: process.env.REACT_APP_BASE_URL + `/api/associations/homeowner/`,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

axios.interceptors.request.use(async function (config) {
  config.headers["Authorization"] = `Bearer ${
    JSON.parse(await localStorage.getItem("persist:auth")).token.split(`"`)[1]
  }`;
  return config;
});

export const getHomeowners = async () => {
  try {
    const result = await axios.get("getHomeowners");
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getHomeowner = async (params) => {
  try {
    const result = await axios.get("getHomeowner/" + params);
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getMasterlist = async () => {
  try {
    const result = await axios.get("getMasterlist");
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const addToMasterlist = async (body) => {
  try {
    const result = await axios.post("addToMasterlist", body);
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const acceptHomeowner = async (body) => {
  try {
    const result = await axios.post("acceptHomeowner", body);
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};
