import { Button, Form, Input, Card } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { authActions, forgotPasswordActions } from "../../store/store";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

const OTP = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, token, uId, email, duration } = useSelector(
    (state) => state.generateOTP
  );
  const { validateCode, generateCode } = forgotPasswordActions;
  const [countDown, setCountDown] = useState(duration);

  useEffect(() => {
    if (uId) {
      navigate("/Resetpassword");
    } else {
      return;
    }
  }, [uId]);

  const onFinish = (values) => {
    dispatch(
      validateCode({
        token: token,
        code: values.code,
      })
    );
  };

  const onFinishFailed = (errorInfo) => {};

  const resendCode = () => {
    dispatch(generateCode(email));
  };

  return (
    <div
      className="poppins"
      style={{
        width: "100%",
        height: "100%",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#f5f5f5",
      }}
    >
      <Card
        data-aos="fade-left"
        data-aos-easing="ease-in"
        data-aos-duration="600"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          maxWidth: "490px",
          maxHeight: "350px",
          height: "100%",
          borderRadius: "12px",
          boxShadow:
            "1.1px 1.2px 4.5px rgba(0, 0, 0, 0.02), 2.7px 2.8px 10.8px rgba(0, 0, 0, 0.028), 5px 5.3px 20.3px rgba(0, 0, 0, 0.035), 8.9px 9.4px 36.2px rgba(0, 0, 0, 0.042), 16.7px 17.5px 67.7px rgba(0, 0, 0, 0.05), 40px 42px 162px rgba(0, 0, 0, 0.07)",
        }}
        bodyStyle={{
          width: "100%",
        }}
      >
        <h1
          style={{
            fontWeight: "600",
            fontSize: "26px",
            alignItems: "center",
            textAlign: "center",
            fontWeight: "700",
          }}
        >
          Forgot Password
        </h1>
        <h4
          style={{
            fontWeight: "600",
            fontSize: "14px",
            alignItems: "center",
            textAlign: "center",
            paddingBottom: "40px",
            fontWeight: "400",
          }}
        >
          Enter the code sent to your email
        </h4>
        <Form
          disabled={isLoading}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Button onClick={resendCode} type="link">
            Resend
          </Button>
          <Form.Item
            name="code"
            rules={[
              {
                required: true,
                message: "Please input your OTP",
              },
            ]}
          >
            <Input
              style={{
                padding: "12px",
                fontSize: "20px",
                borderRadius: "3px",
              }}
              prefix={<MailOutlined className="pr-2" />}
              placeholder="Enter OTP"
              size="large"
            />
          </Form.Item>
          <Form.Item
            style={{
              margin: "auto",
              width: "100%",
            }}
          >
            <Button
              loading={isLoading}
              type="primary"
              htmlType="submit"
              style={{
                width: "100%",
                height: "62px",
                fontSize: "19px",
                fontWeight: "400",
                borderRadius: "5px",
                background: "#4d8e03",
              }}
            >
              Continue
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default OTP;
