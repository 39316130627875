import { put, call, takeLatest } from "redux-saga/effects";
import {
  getForms,
  addForm,
  removeForm,
  updateForm,
} from "../../api/forms/forms-api";
import { formsActions } from "../../store";
///
///

function* getFormsRequest() {
  const { requestError, getFormsSuccess } = formsActions;
  const result = yield call(getForms);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getFormsSuccess(result.data));
  }
}

function* addFormRequest({ payload }) {
  const { requestError, addFormsSuccess, getForms } = formsActions;
  const result = yield call(addForm, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(addFormsSuccess(result.data));
    yield put(getForms());
  }
}

function* removeFormRequest({ payload }) {
  const { requestError, removeFormsSuccess, getForms } = formsActions;
  const result = yield call(removeForm, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(removeFormsSuccess(result.data));
    yield put(getForms());
  }
}

function* updateFormRequest({ payload }) {
  const { requestError, updateFormsSuccess, getForms } = formsActions;
  const result = yield call(updateForm, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(updateFormsSuccess(result.data));
    yield put(getForms());
  }
}

// Export the saga (todo-saga)
export default function* homeownerProfileSaga() {
  yield takeLatest(`forms/getForms`, getFormsRequest);
  yield takeLatest(`forms/addForms`, addFormRequest);
  yield takeLatest(`forms/removeForms`, removeFormRequest);
  yield takeLatest(`forms/updateForms`, updateFormRequest);
}
