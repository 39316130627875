import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelSubscription,
  getSubscription,
  renewSubscription,
} from "../../../store/slices/subscription/subscription-slice";
import { useEffect } from "react";
import { RadioDotIcon } from "../../../assets/resources/icons/icons";
import { Image, Modal, Skeleton } from "antd";
import { useState } from "react";
import cancel from "../../../assets/resources/images/cancel.png";
import renew from "../../../assets/resources/images/renew.png";
import { useNavigate } from "react-router-dom";
import { LoadingTime } from "../../../UI/LoadingScreen";
import moment from "moment";
import { packages } from "./SubscriptionPackages";

const ManageSubscription = () => {
  const [modal2Open, setModal2Open] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { subscription, renewLoading, cancelLoading, subscriptionLoading } =
    useSelector((state) => state.subscription);
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getSubscription(token));
  }, []);

  const renewSub = () => {
    dispatch(
      renewSubscription({
        subscriptionId: subscription.subscriptionId,
        validUntil: moment(subscription.validUntil).format("YYYY-MM-DD"),
        cb: () => {
          setModalOpen(false);
        },
      })
    );
  };

  const cancelSubs = () => {
    dispatch(
      cancelSubscription({
        subscriptionId: subscription.subscriptionId,
        cb: () => {
          setModal2Open(false);
        },
      })
    );
  };

  return (
    <div className="flex flex-col justify-center h-full w-full items-center">
      <LoadingTime
        loading={renewLoading || cancelLoading || subscriptionLoading}
      />
      <div className="flex flex-col gap-5">
        <div className="flex flex-col items-center">
          <span className="text-[14px] font-regular uppercase text-gray-500">
            Current Plan
          </span>

          <h1 className="text-[32px] text-properity">
            {subscription?.name} Plan
          </h1>
        </div>
        <div
          className="flex flex-row w-[601px] h-full rounded-[5px] py-6 px-10 justify-between items-center"
          style={{ border: "2px solid #4D8E03" }}
        >
          <div className="flex items-center gap-5">
            <RadioDotIcon />
            <div className="flex flex-col">
              <span className="text-[20px] font-semibold text-properity">
                {subscription?.name} Plan
              </span>
              <div className="flex flex-col">
                <span className="text-[20px] font-semibold">
                  {" "}
                  {subscription?.name === "Free" ? "" : "Add on price"}
                </span>
                {subscription?.name === "Free"
                  ? ""
                  : packages
                      ?.filter((obj) => obj.type === subscription?.name)
                      .map((obj) => obj?.additional)}
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <span className="text-[20px] font-semibold">
              {subscription?.terms}
            </span>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-4 text-center text-white text-[16px]">
          <div
            onClick={() => setModalOpen(true)}
            className="items-center grid col-span-2 w-full h-[66px] bg-[#03228E] hover:bg-blue-700 rounded-[5px] duration-150 cursor-pointer"
          >
            Renew Subscription
          </div>
          <div
            onClick={() => setModal2Open(true)}
            className="items-center grid col-span-2 w-full h-[66px] bg-[#DD3A3A] hover:bg-red-700 rounded-[5px] duration-150 cursor-pointer"
          >
            Cancel Subscription
          </div>
          <div
            onClick={() => navigate("/UpgradeSubscription")}
            className="items-center grid col-span-4 w-full h-[66px] bg-properity hover:bg-green-600 rounded-[5px] duration-150 cursor-pointer"
          >
            Upgrade Subscription
          </div>
        </div>
        <Modal
          footer={false}
          centered
          closable={false}
          open={modal2Open}
          onOk={() => setModal2Open(false)}
          onCancel={() => setModal2Open(false)}
          width={"750px"}
        >
          <div className="flex flex-col gap-7 items-center text-center p-5">
            <Image preview={false} width={"182px"} src={cancel} />
            <span className="font-semibold text-[20px]">
              Are you sure you want to cancel your subscription?
            </span>
            <p>
              Please note that by canceling your subscription, you will lose
              access to all features and content. If you'd like to explore
              alternative plans or options, please contact our customer support
              team. We're here to help!
            </p>
            <div className="flex gap-4 w-full ">
              <div
                onClick={() => setModal2Open(false)}
                className="text-gray-700 items-center grid col-span-2 w-full h-[66px] bg-[#EBEBEB] hover:bg-gray-200 rounded-[5px] duration-150 cursor-pointer"
              >
                Keep Plan
              </div>
              <div
                onClick={() => cancelSubs()}
                className="text-white items-center grid col-span-2 w-full h-[66px] bg-[#DD3A3A] hover:bg-red-700 rounded-[5px] duration-150 cursor-pointer"
              >
                Cancel Subscription
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          footer={false}
          centered
          closable={false}
          open={modalOpen}
          onOk={() => setModalOpen(false)}
          onCancel={() => setModalOpen(false)}
          width={"750px"}
        >
          <div className="flex flex-col gap-7 items-center text-center p-5">
            <Image preview={false} width={"182px"} src={renew} />
            <span className="font-semibold text-[20px]">
              Are you sure you want to Renew your subscription?
            </span>
            <p>
              Renewing is easy! Simply enter your payment information and we'll
              take care of the rest.
            </p>
            <div className="flex gap-4 w-full ">
              <div
                onClick={() => setModalOpen(false)}
                className="text-gray-700 items-center grid col-span-2 w-full h-[66px] bg-[#EBEBEB] hover:bg-gray-200 rounded-[5px] duration-150 cursor-pointer"
              >
                Cancel
              </div>
              <div
                onClick={() => renewSub()}
                className="text-white items-center grid col-span-2 w-full h-[66px] bg-properity hover:bg-green-600 rounded-[5px] duration-150 cursor-pointer"
              >
                Renew
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ManageSubscription;
