import { ArrowLeftOutlined } from "@ant-design/icons";
import { Drawer, Table } from "antd";
import moment from "moment";
import React from "react";

const UpcomingEvents = ({ open, close, events, loading }) => {
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      width: "200px",
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "200px",
    },
    {
      title: "Date from",
      dataIndex: "dateFrom",
      width: "200px",
      render: (dueDate) => moment(dueDate).format("LL"),
    },
    {
      title: "Date to",
      dataIndex: "dateTo",
      width: "200px",
      render: (dueDate) => moment(dueDate).format("LL"),
    },
  ];

  const closeDrawer = () => {
    close(true);
  };
  return (
    <Drawer
      title={
        <div className="flex flex-col md:flex-row md:items-center items-start justify-start">
          <div
            onClick={() => closeDrawer()}
            className="w-[50px] h-[50px] items-center text-center flex justify-center text-[20px] rounded-full hover:bg-slate-200 transition-all ease-in duration-150 cursor-pointer hover:scale-110"
          >
            <ArrowLeftOutlined className="text-[#1b1b1b]" />
          </div>

          <h1 className="text-[24px] md:text-[25px] font-[600] text-[#1b1b1b] mb-0 p-3">
            Upcoming Events
          </h1>
        </div>
      }
      placement="right"
      closable={false}
      onClose={close}
      open={open}
      width={"40%"}
    >
      <div className="p-10">
        <Table
          dataSource={events}
          columns={columns}
          loading={loading}
          rowKey="eventId"
          scroll={{
            y: 500,
          }}
        ></Table>
      </div>
    </Drawer>
  );
};

export default UpcomingEvents;
