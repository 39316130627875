import { Button, Divider } from "antd";
import React, { useState } from "react";
import { Link } from "react-scroll";
import ScrollSpy from "react-ui-scrollspy";
import ChangesToPrivacy from "./ChangesToPrivacy";
import Consent from "./Consent";
import Contact from "./Contact";
import HowWeCollect from "./HowWeCollect";
import InformationWeCollect from "./InformationWeCollect";
import Privacy from "./Privacy";
import Purpose from "./Purpose";
import AccountDeletion from "./AccountDeletion";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const privacyList = [
    "/privacy-policy/introduction",
    "/privacy-policy/information",
    "/privacy-policy/collect",
    "/privacy-policy/store",
    "/privacy-policy/consent",
    "/privacy-policy/deletion",
    "/privacy-policy/changes",
    "/privacy-policy/contact",
  ];

  return (
    <div
      // className="h-full w-full max-w-[1650px] mx-auto"
      className="h-screen w-full "
    >
      <div className="md:grid md:grid-cols-3 flex flex-col h-full md:overflow-hidden">
        <div
          className="md:flex flex-col justify-center gap-10 p-10 hidden"
          style={{ borderRight: " 1px solid gray" }}
        >
          <h1
            className={
              location.pathname === "/privacy-policy/introduction"
                ? "text-[24px] text-properity font-semibold transition-all ease duration-150 cursor-pointer"
                : "text-[24px] text-black font-semibold cursor-pointer"
            }
            onClick={() => {
              navigate("/privacy-policy/introduction");
            }}
          >
            Introduction
          </h1>
          <h1
            className={
              location.pathname === "/privacy-policy/information"
                ? "text-[24px] text-properity font-semibold transition-all ease duration-150 cursor-pointer"
                : "text-[24px] text-black font-semibold cursor-pointer"
            }
            onClick={() => {
              navigate("/privacy-policy/information");
            }}
          >
            Information We Collect
          </h1>
          <h1
            className={
              location.pathname === "/privacy-policy/collect"
                ? "text-[24px] text-properity font-semibold transition-all ease duration-150 cursor-pointer"
                : "text-[24px] text-black font-semibold cursor-pointer"
            }
            onClick={() => {
              navigate("/privacy-policy/collect");
            }}
          >
            How We May Collect Data From You
          </h1>
          <h1
            className={
              location.pathname === "/privacy-policy/store"
                ? "text-[24px] text-properity font-semibold transition-all ease duration-150 cursor-pointer"
                : "text-[24px] text-black font-semibold cursor-pointer"
            }
            onClick={() => {
              navigate("/privacy-policy/store");
            }}
          >
            Where We Store your Data
          </h1>
          <h1
            className={
              location.pathname === "/privacy-policy/consent"
                ? "text-[24px] text-properity font-semibold transition-all ease duration-150 cursor-pointer"
                : "text-[24px] text-black font-semibold cursor-pointer"
            }
            onClick={() => {
              navigate("/privacy-policy/consent");
            }}
          >
            Consent to Collection, Transfer, etc.,
          </h1>
          <h1
            className={
              location.pathname === "/privacy-policy/deletion"
                ? "text-[24px] text-properity font-semibold transition-all ease duration-150 cursor-pointer"
                : "text-[24px] text-black font-semibold cursor-pointer"
            }
            onClick={() => {
              navigate("/privacy-policy/deletion");
            }}
          >
            Account Deletion
          </h1>
          <h1
            className={
              location.pathname === "/privacy-policy/changes"
                ? "text-[24px] text-properity font-semibold transition-all ease duration-150 cursor-pointer"
                : "text-[24px] text-black font-semibold cursor-pointer"
            }
            onClick={() => {
              navigate("/privacy-policy/changes");
            }}
          >
            Changes to our Privacy Policy
          </h1>
          <h1
            className={
              location.pathname === "/privacy-policy/contact"
                ? "text-[24px] text-properity font-semibold transition-all ease duration-150 cursor-pointer"
                : "text-[24px] text-black font-semibold cursor-pointer"
            }
            onClick={() => {
              navigate("/privacy-policy/contact");
            }}
          >
            Contact Us
          </h1>
        </div>
        <div className="col-span-2 grid p-5 md:p-20 md:overflow-scroll">
          <div className="row-span-2">
            <h1 className="py-5 md:text-[50px] text-[18px] text-properity">
              Privacy Policy
            </h1>
            {/* {privacyList[active]} */}
            <Outlet />
          </div>
          <div className="flex gap-5 py-5 items-center justify-center md:items-start md:justify-start">
            <button
              onClick={() =>
                navigate(
                  privacyList[privacyList.indexOf(location.pathname) - 1]
                )
              }
              className={
                privacyList.indexOf(location.pathname) !== 0
                  ? "md:w-[170px] w-[130px] md:h-[52px] h-[42px] border-2 border-green-500 text-properity rounded-[10px] hover:bg-gray-100 transition-all duration-150"
                  : "hidden"
              }
            >
              Back
            </button>
            <button
              onClick={() =>
                navigate(
                  privacyList[privacyList.indexOf(location.pathname) + 1]
                )
              }
              className="md:w-[170px] w-[130px] md:h-[52px] h-[42px]  bg-properity text-white border-none rounded-[10px] hover:bg-green-500 transition-all duration-150"
              disabled={
                privacyList.indexOf(location.pathname) + 1 ===
                privacyList.length
              }
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
