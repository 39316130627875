import { DeleteOutlined, DownOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Dropdown,
  Empty,
  Image,
  Popconfirm,
  Space,
  Table,
  Tag,
  Typography,
  theme,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardTitle from "../../../UI/CardTitle";
import { LoadingTime } from "../../../UI/LoadingScreen";
import { htmlToText } from "../../../helpers/htmlToText";
import parse from "html-react-parser";
import {
  getHelpCenter,
  getHelpCenterTypes,
  removeHelpCenter,
} from "../../../store/slices/help-center/help-center-slice";
import "../announcements/announcements.css";
import "../properties/property.css";
import {
  deactivatePaymentChannel,
  getPaymentChannels,
} from "../../../store/slices/paymentchannel/payment-channel-slice";
import PaymentChannelForm from "./PaymentChannelForm";

const { Paragraph, Text } = Typography;
const { useToken } = theme;

const ActiveChannel = () => {
  const { token } = useToken();

  const { paymentChannels, paymentChannelLoading } = useSelector(
    (state) => state.paymentChannel
  );
  const [ellipsis, setEllipsis] = useState(true);
  const [less, setLess] = useState(false);
  const [visible, setVisible] = useState(false);
  const [editAnnouncement, setEditAnnouncement] = useState(false);
  const [formId, setFormId] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const dispatch = useDispatch();

  const text = "Are you sure to deactivate this payment channel?";
  const description = "Deactivate the payment channel";

  const items = [
    {
      label: (
        <div className="flex gap-2 items-center">
          <EditOutlined />
          <p>Edit</p>
        </div>
      ),
      key: "1",
    },
  ];

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle = {
    boxShadow: "none",
  };

  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "150px",
      render: (record) => (
        <Dropdown
          trigger={["click"]}
          menu={{
            items,
            onClick: () => editHandler(record.paymentChannelId),
          }}
          dropdownRender={(menu) => (
            <div className="flex flex-col items-start" style={contentStyle}>
              {React.cloneElement(menu, {
                style: menuStyle,
              })}
              <Popconfirm
                placement="right"
                title={text}
                description={description}
                onConfirm={() => deactivateChannel(record.paymentChannelId)}
                okText="Yes"
                cancelText="Cancel"
              >
                <Button
                  style={{ color: "black" }}
                  type="link"
                  icon={<DeleteOutlined />}
                >
                  Deactivate
                </Button>
              </Popconfirm>
            </div>
          )}
        >
          <Button
            type="primary"
            style={{
              background: "#4d8e03",
            }}
          >
            <Space>
              Action
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      width: "200px",
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
      render: (record) => (
        <Typography className="poppins" level={1}>
          {parse(record)}
        </Typography>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "200px",
      editable: true,
      render: (status) =>
        status === 0 ? (
          <Tag color="blue">Active</Tag>
        ) : (
          <Tag color="blue">Inactive</Tag>
        ),
    },
    {
      title: "Instructions",
      dataIndex: "instructions",
      width: "200px",
      render: (record) => (
        <Paragraph
          ellipsis={
            ellipsis
              ? {
                  rows: 3,
                  expandable: true,
                  symbol: "more",
                }
              : false
          }
        >
          {parse(record)}
        </Paragraph>
      ),
    },
    {
      title: "Image",
      align: "center",
      dataIndex: "imageUrl",
      width: "150px",
      render: (record) => {
        return record ? (
          <div>
            <Image
              style={{
                width: "100px",
                height: "100px",
                objectFit: "cover",
              }}
              src={process.env.REACT_APP_BASE_URL + "/" + record}
            />
          </div>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        );
      },
    },
    {
      title: "Date Created",
      dataIndex: "dateCreated",
      width: "200px",
      editable: true,
      defaultSortOrder: "descend",
      sorter: (a, b) => moment(a.dateCreated).diff(moment(b.dateCreated)),
      render: (dateCreated) => moment(dateCreated).format("LL"),
    },
    {
      title: "Date Updated",
      dataIndex: "dateUpdated",
      width: "200px",
      editable: true,
      render: (dateUpdated) => moment(dateUpdated).format("LL"),
    },
  ];

  useEffect(() => {}, [isEditing, isAdding]);

  useEffect(() => {
    dispatch(getPaymentChannels());
  }, []);

  const onClose = () => {
    setVisible(false);
  };

  const onAdd = () => {
    setVisible(true);
    setIsEditing(false);
    setIsAdding(true);
  };

  const deactivateChannel = (value) => {
    dispatch(deactivatePaymentChannel(value));
  };

  const editHandler = (value) => {
    setEditAnnouncement(true);
    setIsEditing(true);
    setIsAdding(false);
    setVisible(true);
    setFormId(value);
  };

  return (
    <div className="flex flex-col gap-7">
      <LoadingTime loading={paymentChannelLoading} />
      <Card
        title={
          <CardTitle
            title={"Payment Channel"}
            add={"Add"}
            onAdd={onAdd}
          ></CardTitle>
        }
      >
        <Table
          loading={paymentChannelLoading}
          rowKey={"paymentChannelId"}
          columns={columns}
          dataSource={paymentChannels?.filter((obj) => obj.status === 0)}
          scroll={{
            y: 500,
            x: "100%",
          }}
        />
      </Card>
      <PaymentChannelForm
        openAddForm={visible}
        closeAddForm={onClose}
        isEditing={isEditing}
        isAdding={isAdding}
        formId={formId}
        isLoading={paymentChannelLoading}
      />
    </div>
  );
};
export default ActiveChannel;
