import React from "react";
import homebook from "../../assets/resources/images/homebook.png";
import success from "../../assets/resources/images/success-bg.png";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form } from "antd";

const SuccessSubscription = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full h-screen relative">
      <img
        className="absolute left-0 top-0 bottom-0 h-full "
        src={success}
        alt=""
        data-aos="fade-right"
        data-aos-easing="ease-in"
        data-aos-duration="600"
      />
      <div className="flex w-full items-center justify-end h-full p-10">
        <div
          className="flex flex-col items-left text-left gap-7 mr-[100px]"
          data-aos="fade"
          data-aos-easing="ease-in"
          data-aos-duration="600"
        >
          <img className="w-[277px] ml-[-25px]" src={homebook} alt="" />
          <h1 className="text-[#43597E] text-[35px] font-[600]">
            Subscription Complete!
          </h1>
          <h2 className="text-[#43597E] text-[35px] font-[600] leading-[43.58px]">
            You are now subscribed to <br /> the Homebook
            <span className="text-[#447AD6]"> Basic Plan</span>
          </h2>
          <p className="leading-[31.13px] text-[25px] font-[500] text-[#43597E]">
            Enjoy your lorem ipsum generator dot <br /> computer under the
            mistletoe dolor
          </p>
          <p className="leading-[31.13px] text-[25px] font-[500] text-[#43597E]">
            Now, we can set up your association to lorem <br /> ipsum dolor
            sapnu puas jolen har bank jilma
          </p>
          <Button
            style={{
              width: "605px",
              height: "73px",
              fontSize: "30px",
              borderRadius: "10px",
              background: "linear-gradient(90deg, #3794DB 0%, #81AFF3 100%) ",
            }}
            type="primary"
            onClick={() => navigate("/Registration")}
          >
            Proceed to Set Up
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SuccessSubscription;
