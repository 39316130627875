import { LoadingOutlined } from "@ant-design/icons";
import { Button, Modal, Result } from "antd";

export const LoadingTime = ({ loading }) => {
  return (
    <Modal
      centered
      open={loading}
      closable={false}
      footer={false}
      className="z-[999] items-center flex justify-center loading-modal"
      width={"250px"}
    >
      <div className="flex items-center justify-center flex-col gap-10 z-[999]">
        <LoadingOutlined
          style={{
            fontSize: 90,
            color: "#568634",
          }}
          spin
        />
        <p className="text-[#568634] text-center text-[16px] ">
          Loading
          <br />
          Please wait...
        </p>
      </div>
    </Modal>
  );
};

export const SuccessResult = ({ status, message, close }) => {
  return (
    <Modal
      centered
      open={status}
      closable={false}
      footer={false}
      className="z-[999] items-center flex justify-center loading-modal"
      width={"250px"}
    >
      <Result
        status="success"
        subTitle={message}
        extra={[
          <button
            className="bg-properity text-white w-[80px] h-[35px] border-none tracking-wide hover:bg-green-700 transitio-all ease-in duration-150 cursor-pointer rounded-md"
            onClick={() => close()}
            key="buy"
          >
            Close
          </button>,
        ]}
      />
    </Modal>
  );
};
