export const packages = [
  {
    type: "Free",
    price: 0,
    description: "This plan is up to 5 accounts",
    additional: "",
    features: [
      "Users (unique account)",
      "Association",
      "Generate Billing",
      "Online Payment",
      "Transaction History",
      "Announcements",
      "Event Calendar",
    ],
  },
  {
    type: "Starter",
    price: 5000,
    description: "This plan is up to 50 accounts",
    additional: (
      <p className="font-semibold text-[14px] italic">
        PHP 1000 <br />
        for every additional 25
      </p>
    ),
    features: [
      "Users (unique account)",
      "Association",
      "Generate Billing",
      "Online Payment",
      "Transaction History",
      "Announcements",
      "Event Calendar",
      "Inquiries and Utility Reporting",
      "Notification",
      "Setup and support",
      "Analytics and Reporting",
    ],
  },
  {
    type: "Premium",
    price: 10000,
    description: "This plan is up to 200 accounts",
    additional: (
      <p className="font-semibold text-[14px] italic">
        PHP 3000 <br />
        for every additional 50
      </p>
    ),
    features: [
      "Users (unique account)",
      "Association",
      "Generate Billing",
      "Online Payment",
      "Transaction History",
      "Announcements",
      "Event Calendar",
      "Inquiries and Utility Reporting",
      "Notification",
      "Setup and support",
      "Analytics and Reporting",
    ],
  },
  {
    type: "Plus",
    price: 25000,
    description: "This plan is up to 500 accounts",
    additional: (
      <p className="font-semibold text-[14px] italic">
        PHP 7500 <br />
        for every additional 100
      </p>
    ),
    features: [
      "Users (unique account)",
      "Association",
      "Generate Billing",
      "Online Payment",
      "Transaction History",
      "Announcements",
      "Event Calendar",
      "Inquiries and Utility Reporting",
      "Notification",
      "Setup and support",
      "Analytics and Reporting",
    ],
  },
];
