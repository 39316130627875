import { Card, Empty, Image, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getInquiries } from "../../../../store/slices/inquiries/inquiries-slice";
import "../../properties/property.css";
import InquiriesModal from "./InquiriesModal";

const RemovedInquiry = () => {
  const { inquiries, inquiriesLoading } = useSelector((state) => state.inquiry);
  const { token: userToken } = useSelector((state) => state.auth);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();

  const text = "Are you sure to remove this inquiry?";
  const description = "Remove the inquiry";

  const columns = [
    // {
    //   title: "Action",
    //   dataIndex: "",
    //   key: "x",
    //   width: "150px",
    //   render: (record) => (
    //     <Dropdown
    //       overlay={
    //         <Menu
    //           className="dropdown-action"
    //           items={[
    //             {
    //               label: "View bills",
    //               key: "1",
    //               icon: <EyeOutlined />,
    //               // onClick: () => {
    //               //   editHandler(record);
    //               // },
    //             },
    //             {
    //               label: "Create bill",
    //               key: "0",
    //               icon: <EyeOutlined />,
    //               onClick: () => {
    //                 showDrawer(record);
    //               },
    //             },
    //             {
    //               label: (
    //                 <Popconfirm
    //                   placement="bottomLeft"
    //                   title={text}
    //                   description={description}
    //                   onConfirm={() => deleteInquiry(record.inquiryId)}
    //                   okText="Yes"
    //                   cancelText="No"
    //                 >
    //                   Delete
    //                 </Popconfirm>
    //               ),
    //               key: "2",
    //               icon: <DeleteOutlined />,
    //               onClick: (e) => e.preventDefault(),
    //             },
    //           ]}
    //         />
    //       }
    //       trigger={["click"]}
    //     >
    //       <Button
    //         type="primary"
    //         style={{
    //           background: "#003664",
    //         }}
    //       >
    //         <Space>
    //           Action
    //           <DownOutlined />
    //         </Space>
    //       </Button>
    //     </Dropdown>
    //   ),
    // },
    {
      title: "Subject",
      dataIndex: "subject",
      width: "200px",
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    {
      title: "Message",
      dataIndex: "message",
      width: "200px",
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      render: (md) => (md ? md : "-"),
      width: "200px",
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    {
      title: "Middle Name",
      dataIndex: "middlename",
      width: "200px",
      render: (md) => (md ? md : "-"),
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      render: (md) => (md ? md : "-"),
      width: "200px",
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    {
      title: "Contact No.",
      dataIndex: "contactNumber",
      width: "200px",
    },
    {
      title: "Address",
      dataIndex: "address",
      width: "200px",
    },
    {
      title: "Image",
      align: "center",
      dataIndex: "imageUrl",
      width: "200px",
      render: (record) => {
        return record ? (
          <Image
            width={100}
            src={process.env.REACT_APP_BASE_URL + "/" + record}
          />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "200px",
      editable: true,
      render: (status) => status === -1 && <Tag color="red">Removed</Tag>,
    },
  ];
  useEffect(() => {
    dispatch(getInquiries(userToken));
  }, []);
  const onClose = () => {
    setVisible(false);
  };
  return (
    <div>
      <Card>
        <Table
          loading={inquiriesLoading}
          rowKey={"inquiryId"}
          columns={columns}
          dataSource={inquiries?.filter((obj) => obj.status === -1)}
          scroll={{
            y: 500,
            x: "100%",
          }}
        />
      </Card>
      <InquiriesModal viewBilling={visible} closeBilling={onClose} />
    </div>
  );
};
export default RemovedInquiry;
