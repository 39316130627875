import { motion } from "framer-motion";
import React from "react";

const FeatureCardTemplate = ({ feature, textAnimate, imageAnimate, cardImageAnimate }) => {
	const { imgSrc, title, details } = feature;

	return (
		<motion.div
			className=" col-span-2 flex flex-col items-center justiy-center "
			initial={"offscreen"}
			whileInView={"onscreen"}
			viewport={{ once: true, amount: 0.2 }}
			transition={{ staggerChildren: 0.2 }}
		>
			<div className=" xl:py-10 xl:px-10 py-0 px-0">
				<motion.img
					className=" lg:w-[94px] md:w-[84px] w-[74px]"
					src={imgSrc}
					alt="/"
					variants={cardImageAnimate}
				/>
				{/* <div className=" "> */}
				<motion.h1
					className="max-w-[286px] h-16 mt-4 lg:text-[20px] md:text-[18px] text-[16px] font-semibold "
					variants={textAnimate}
				>
					{title}
				</motion.h1>
				{/* </div> */}
				<motion.p
					className=" pt-7 text-[15px] xl:mr-6 mr-0 w-full leading-[1.5rem]"
					variants={textAnimate}
				>
					{details}
				</motion.p>
			</div>
		</motion.div>
	);
};

export default FeatureCardTemplate;
