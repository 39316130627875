import React from "react";
import { Tabs } from "antd";
import "../help-center/helpcenter.css";
import HelpCenterTab from "./HelpCenterTab";
import HelpCenterTypesTab from "./HelpCenterTypesTab";

const items = [
  { label: "Help Centers", key: "item-1", children: <HelpCenterTab /> }, // remember to pass the key prop
  {
    label: "Help Center Types",
    key: "item-2",
    children: <HelpCenterTypesTab />,
  },
];

const Events = () => (
  <Tabs
    defaultActiveKey="item-1"
    type="card"
    className="tabs-help"
    animated={true}
    items={items}
  ></Tabs>
);
export default Events;
