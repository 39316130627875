import { defaultAxios, axiosMultipart } from "../axios";

const baseApi = "/api/associations/services/";

defaultAxios.interceptors.request.use(async function (config) {
  config.headers["Authorization"] = `Bearer ${
    JSON.parse(await localStorage.getItem("persist:auth")).token.split(`"`)[1]
  }`;
  //   config.baseURL += baseApi;
  return config;
});

axiosMultipart.interceptors.request.use(async function (config) {
  config.headers["Authorization"] = `Bearer ${
    JSON.parse(await localStorage.getItem("persist:auth")).token.split(`"`)[1]
  }`;
  return config;
});

export const getForms = async () => {
  try {
    const result = await defaultAxios.get(baseApi + "getForms");
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const addForm = async (body) => {
  try {
    const result = await axiosMultipart.post(
      baseApi + "addForm",
      body.formData
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const removeForm = async (params) => {
  try {
    const result = await defaultAxios.post(baseApi + "removeForm/" + params);
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const updateForm = async (data) => {
  try {
    const result = await axiosMultipart.post(
      baseApi + "updateForm/" + data.params,
      data.body.formData
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};
