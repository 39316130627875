import defaultAxios from "axios";

const axios = defaultAxios.create({
  baseURL: process.env.REACT_APP_BASE_URL + `/api/associations/homeowner/`,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

axios.interceptors.request.use(async function (config) {
  config.headers["Authorization"] = `Bearer ${
    JSON.parse(await localStorage.getItem("persist:auth")).token?.split(`"`)[1]
  }`;
  return config;
});

export const getHomeownerProperties = async (params) => {
  try {
    const result = await axios.get(
      "getHomeownerProperties?homeownerId=" + params
    );
    return result;
  } catch (err) {
    return err;
  }
};
