import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

const initialState = {
  isLoading: false,
  directoryTypesLoading: false,
  directories: [],
  directoryTypes: [],
};

const directoriesSlice = createSlice({
  name: "directories",
  initialState,
  reducers: {
    getDirectories(state) {
      state.directories = [];
      state.isLoading = true;
    },
    getDirectoriesSuccess(state, action) {
      state.isLoading = false;
      state.directories = action.payload;
    },
    addDirectories(state) {
      state.isLoading = true;
    },
    addDirectoriesSuccess(state, action) {
      state.isLoading = false;
      message.success(action.payload.message);
      state.directories = action.payload;
    },
    updateDirectories(state) {
      state.isLoading = true;
    },
    updateDirectoriesSuccess(state, action) {
      state.isLoading = false;
      message.success(action.payload.message);
      state.directories = action.payload;
    },
    removeDirectory(state) {
      state.isLoading = true;
    },
    removeDirectorySuccess(state, action) {
      message.success(action.payload.message);
      state.isLoading = false;
    },
    getDirectoryTypes(state) {
      state.directoryTypes = [];
      state.directoryTypesLoading = true;
    },
    getDirectoryTypesSuccess(state, action) {
      state.directoryTypes = action.payload;
      state.directoryTypesLoading = false;
    },
    addDirectoryTypes(state) {
      state.directoryTypesLoading = true;
    },
    addDirectoryTypesSuccess(state, action) {
      state.directoryTypesLoading = false;
      message.success(action.payload.message);
      state.directoryTypes = action.payload;
    },
    updateDirectoryTypes(state) {
      state.directoryTypesLoading = true;
    },
    updateDirectoryTypesSuccess(state, action) {
      state.directoryTypesLoading = false;
      message.success(action.payload.message);
    },
    removeDirectoryTypes(state) {
      state.directoryTypesLoading = true;
    },
    removeDirectoryTypesSuccess(state, action) {
      message.success(action.payload.message);
      state.directoryTypesLoading = false;
    },
    requestError(state, action) {
      state.isLoading = false;
      message.error(action.payload.data.message);
    },
  },
});

export const {
  getDirectories,
  getDirectoriesSuccess,
  removeDirectory,
  removeDirectorySuccess,
  addDirectories,
  addDirectoriesSuccess,
  updateDirectories,
  updateDirectoriesSuccess,
  getDirectoryTypes,
  getDirectoryTypesSuccess,
  addDirectoryTypes,
  addDirectoryTypesSuccess,
  updateDirectoryTypes,
  updateDirectoryTypesSuccess,
  removeDirectoryTypes,
  removeDirectoryTypesSuccess,
  requestError,
} = directoriesSlice.actions;
export default directoriesSlice;
