import React from "react";

const HowWeCollect = () => {
  return (
    <div className="flex flex-col gap-5">
      <h1 className="md:text-[28px] text-[16px] font-semibold">
        How we collect data from you
      </h1>
      <p className="text-gray-600 text-justify text-[14px] md:text-[18px]">
        Users (“you” or “your”) can access the services provided by Dynamic
        Global Soft, Inc. through Properity different platforms such as our
        website, mobile applications and other related applications and tools
        (the “Services”) regardless of what type of device you use to access
        them.
      </p>
      <p className="text-gray-600 text-justify text-[14px] md:text-[18px]">
        Aside from the personal data you provide to us during your registration,
        here are the following ways on how we will get access to your data:
      </p>
      <ul className="text-gray-600 pl-5">
        <li>Information you provide</li>
        <li>Files</li>
      </ul>
      <p className="text-gray-600 text-justify text-[14px] md:text-[18px]">
        Properity collects files from you where you need to upload images for
        validation or proof of legitimacy in terms of identity, transactions,
        online payments made, association’s requirement and etc.
      </p>
      <ul className="text-gray-600 pl-5">
        <li>Cookies</li>
        <li>Log Data</li>
        <li>IP addresses</li>
        <li>Integrations</li>
      </ul>
      <p className="text-gray-600 text-justify text-[14px] md:text-[18px]">
        Properity make use of third-party integrations to make the
        functionalities and features available for you thus this we assure you
        will supplement or enhance the services we provide. With your consent by
        agreeing to this policy and our terms and conditions, it also means that
        you are allowing our partner integrators to make use of your data. These
        integrators will access (via API) and make use of the information solely
        for Properity purposes through the integrations.
      </p>
    </div>
  );
};

export default HowWeCollect;
