import { createSlice } from "@reduxjs/toolkit";
import { Button, Result, message } from "antd";
import { LoadingTime } from "../../../../UI/LoadingScreen";

const initialState = {
  announcementsLoading: false,
  addAnnouncementsLoading: false,
  announcements: [],
};

const announcementSlice = createSlice({
  name: "announcements",
  initialState,
  reducers: {
    getAnnouncements(state) {
      state.announcements = [];
      state.announcementsLoading = true;
    },
    getAnnouncementsSuccess(state, action) {
      state.announcementsLoading = false;
      state.announcements = action.payload;
    },
    addAnnouncement(state) {
      state.addAnnouncementsLoading = true;
    },
    addAnnouncementSuccess(state, action) {
      state.addAnnouncementsLoading = false;
      state.announcements = action.payload;
      message.success(action.payload.message);
    },
    updateAnnouncement(state) {
      state.addAnnouncementsLoading = true;
    },
    updateAnnouncementSuccess(state, action) {
      state.addAnnouncementsLoading = false;
      state.announcements = action.payload;
      message.success(action.payload.message);
    },
    updateAnnouncementAttachment(state) {
      state.addAnnouncementsLoading = true;
    },
    updateAnnouncementAttachmentSuccess(state, action) {
      state.addAnnouncementsLoading = false;
      state.announcements = action.payload;
      message.success(action.payload.message);
    },
    removeAnnouncements(state) {
      state.announcementsLoading = true;
    },
    removeAnnouncementsSuccess(state, action) {
      state.announcementsLoading = false;
      message.success(action.payload.message);
    },
    removeAnnouncementsAttachment(state) {
      state.announcementsLoading = true;
    },
    removeAnnouncementsAttachmentSuccess(state, action) {
      state.announcementsLoading = false;
      message.success(action.payload.message);
    },
    addAnnouncementsAttachment(state) {
      state.announcementsLoading = true;
    },
    addAnnouncementsAttachmentSuccess(state, action) {
      state.announcementsLoading = false;
      state.announcements = action.payload;
      message.success(action.payload.message);
    },
    requestError(state, action) {
      state.isLoading = false;
      state.addAnnouncementsLoading = false;
      message.error(action.payload.data.message);
    },
  },
});

export const {
  getAnnouncements,
  getAnnouncementsSuccess,
  addAnnouncement,
  addAnnouncementSuccess,
  removeAnnouncements,
  removeAnnouncementsSuccess,
  removeAnnouncementsAttachment,
  removeAnnouncementsAttachmentSuccess,
  addAnnouncementsAttachment,
  addAnnouncementsAttachmentSuccess,
  updateAnnouncement,
  updateAnnouncementSuccess,
  updateAnnouncementAttachment,
  updateAnnouncementAttachmentSuccess,
  requestError,
} = announcementSlice.actions;
export default announcementSlice;
