import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";

export const pendingActions = [
  {
    text: "Process",
    title: "Are you sure you want to process this report?",
    description: "Process this report",
    icon: <EyeOutlined />,
  },
  {
    text: "Remove",
    title: "Are you sure you want to remove this report?",
    description: "Remove this report",
    icon: <DeleteOutlined />,
  },
];

export const processedActions = [
  {
    text: "Resolve",
    title: "Are you sure you want to resolve this report?",
    description: "Resolve this report",
    icon: <EyeOutlined />,
  },
  {
    text: "Remove",
    title: "Are you sure you want to remove this report?",
    description: "Remove this report",
    icon: <DeleteOutlined />,
  },
];
