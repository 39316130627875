import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

const initialState = {
  isLoading: false,
  hotlineTypesLoading: false,
  hotlines: [],
  hotlineTypes: [],
};

const hotlinesSlice = createSlice({
  name: "hotlines",
  initialState,
  reducers: {
    getHotlines(state) {
      state.hotlines = [];
      state.isLoading = true;
    },
    getHotlinesSuccess(state, action) {
      state.isLoading = false;
      state.hotlines = action.payload;
      console.log("zxczxcxc");
    },
    addHotlineTypes(state) {
      state.hotlineTypesLoading = true;
    },
    addHotlineTypesSuccess(state, action) {
      state.hotlineTypes = action.payload;
      state.hotlineTypesLoading = false;
    },
    addHotlines(state) {
      state.isLoading = true;
    },
    addHotlinesSuccess(state, action) {
      message.success(action.payload.message);
      state.isLoading = false;
    },
    updateHotlines(state) {
      state.isLoading = true;
    },
    updateHotlinesSuccess(state, action) {
      message.success(action.payload.message);
      state.isLoading = false;
    },
    getHotlineTypes(state) {
      state.hotlineTypes = [];
      state.hotlineTypesLoading = true;
    },
    getHotlineTypesSuccess(state, action) {
      state.hotlineTypes = action.payload;
      state.hotlineTypesLoading = false;
    },
    updateHotlineTypes(state) {
      state.hotlineTypesLoading = true;
    },
    updateHotlineTypesSuccess(state, action) {
      message.success(action.payload.message);
      state.hotlineTypesLoading = false;
    },
    removeHotlineTypes(state) {
      state.hotlineTypesLoading = true;
    },
    removeHotlineTypesSuccess(state, action) {
      message.success(action.payload.message);
      state.hotlineTypesLoading = false;
    },
    requestError(state, action) {
      state.isLoading = false;
      message.error(action.payload.data.message);
    },
  },
});

export const {
  getHotlines,
  addHotlines,
  addHotlinesSuccess,
  getHotlinesSuccess,
  updateHotlines,
  updateHotlinesSuccess,
  addHotlineTypes,
  addHotlineTypesSuccess,
  getHotlineTypes,
  getHotlineTypesSuccess,
  updateHotlineTypes,
  updateHotlineTypesSuccess,
  removeHotlineTypes,
  removeHotlineTypesSuccess,
  requestError,
} = hotlinesSlice.actions;
export default hotlinesSlice;
