import { ArrowLeftOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { Button, Card, Descriptions, Drawer, Empty, Image, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format_PHCurrency } from "../../../../helpers/formatCurrency";
import AddChargeAttachment from "./AddChargeAttachment";

const ViewAddedCharge = ({ openAttachment, closeAttachment, charge, arr }) => {
  const {
    assocCharges,
    billingCharges,
    billingChargesAttachments,
    billingChargesAttachmentsTable,
  } = useSelector((state) => state.billing);
  const [openAttachmentModal, setOpenAttachmentModal] = useState(false);
  const dispatch = useDispatch();
  const [attachmentArr, setAttachmentArr] = useState();

  const closeDrawer = () => {
    closeAttachment(true);
  };

  useEffect(() => {
    localStorage.setItem(
      "attachments",
      JSON.stringify(billingChargesAttachmentsTable)
    );

    const arr = localStorage.getItem("attachments");

    setAttachmentArr(JSON.parse(arr));
  }, [billingChargesAttachmentsTable]);

  const found = billingCharges.find((e) => e.chargeId === charge);

  const attachmentCols = [
    {
      title: "Image",
      width: "150px",
      align: "center",
      dataIndex: "imageUrl",
      render: (record) => {
        return record ? (
          <Image
            style={{
              width: "100px",
              height: "100px",
              objectFit: "cover",
            }}
            src={record}
          />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        );
      },
    },
    {
      title: "Description",
      width: "150px",
      dataIndex: "description",
      render: (record) => {
        return record ? record : "-";
      },
    },
  ];

  const attachmentHandler = (e) => {
    setOpenAttachmentModal(true);
  };

  const closeAttachmentHandler = () => {
    setOpenAttachmentModal(false);
  };

  return (
    <Drawer
      className="p-5"
      centered
      onClose={closeDrawer}
      onCancel={closeDrawer}
      open={openAttachment}
      height={"90%"}
      closable={false}
      placement={"bottom"}
    >
      <div className="p-3 flex flex-col md:flex-row md:items-center items-start justify-start">
        <div
          onClick={() => closeDrawer()}
          className="w-[50px] h-[50px] items-center text-center flex justify-center text-[20px] rounded-full hover:bg-slate-200 transition-all ease-in duration-150 cursor-pointer hover:scale-110"
        >
          <ArrowLeftOutlined className="text-[#1b1b1b]" />
        </div>
        <h1 className="py-5 font-[600] text-[#474d63] text-[32px]">
          Charge details
        </h1>
      </div>
      <div className="flex flex-col gap-5">
        <Descriptions layout="vertical" bordered>
          <Descriptions.Item label="Name">{found?.name}</Descriptions.Item>
          <Descriptions.Item label="Amount">
            {format_PHCurrency(found?.amount)}
          </Descriptions.Item>
          <Descriptions.Item label="Additional Amount">
            {format_PHCurrency(found?.additionalAmount)}
          </Descriptions.Item>
        </Descriptions>
        <Card
          title={
            <div className="flex justify-between items-center">
              <h1 className="py-5 font-[600] text-[#474d63] text-[22px]">
                Attachments:
              </h1>
              <div className="flex items-center  text-center justify-center">
                <Button
                  style={{
                    border: "1px solid transparent",
                    alignItems: "center",
                    display: "flex",
                  }}
                  type="outlined"
                  onClick={() => attachmentHandler()}
                  icon={
                    <PlusSquareOutlined className="text-2xl h-full items-center flex justify-center" />
                  }
                >
                  Add
                </Button>
              </div>
            </div>
          }
        >
          <Table
            // loading={billingStatementLoading}
            rowKey={"index"}
            columns={attachmentCols}
            dataSource={
              attachmentArr
                ? attachmentArr
                    .map((c, i) => ({ ...c, index: i }))
                    .filter((obj) => obj.chargeId === charge)
                : []
            }
            scroll={{
              y: 500,
              x: "100%",
            }}
          />
          <AddChargeAttachment
            openAddAttachment={openAttachmentModal}
            closeAddAttachment={closeAttachmentHandler}
            choiceId={charge}
          />
        </Card>
      </div>
    </Drawer>
  );
};

export default ViewAddedCharge;
