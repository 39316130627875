import { CloseOutlined } from "@ant-design/icons";
import { Button, Drawer, Form, Input, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  addDirectories,
  updateDirectories,
} from "../../../../store/slices/directories-and-hotlines/directories/directories-slice";

const DirectoryForm = ({
  openDirectoryForm,
  closeDirectoryForm,
  directoryTypes,
  isEditing,
  isAdding,
  directories,
  id,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [marker, setMarker] = useState();
  const [map, setMap] = useState(null);
  const { isLoading } = (state) => state.directories;
  const mapRef = useRef();

  const onFinishFailed = (errorInfo) => {};

  const found = directories?.find((obj) => obj.id === id);

  const onFinish = (values) => {
    {
      isEditing
        ? dispatch(
            updateDirectories({
              params: found?.directoryId,
              body: {
                name: values?.name,
                typeId: values?.directoryType,
                address: values?.address,
                contactNumber: "0" + values?.mobileNumber,
                contactPerson: values?.contactPerson,
                latitude: values?.lat,
                longitude: values?.lng,
              },
            })
          )
        : dispatch(
            addDirectories({
              name: values?.name,
              typeId: values?.directoryType,
              address: values?.address,
              contactNumber: "0" + values?.mobileNumber,
              contactPerson: values?.contactPerson,
              latitude: values?.lat,
              longitude: values?.lng,
            })
          );
    }

    closeDirectoryForm(true);
  };

  useEffect(() => {
    const type = directoryTypes?.find((obj) => obj?.typeId === found?.typeId);

    if (isEditing) {
      form.setFieldsValue({
        ...found,
        directoryType: type?.name,
        name: found?.name,
        address: found?.address,
        mobileNumber: found?.contactNumber.substring(1),
        contactPerson: found?.contactPerson,
        lat: found?.latitude,
        lng: found?.longitude,
      });
    } else {
      form.resetFields();
    }
  }, [isEditing, isAdding, id]);

  const closeAdd = () => {
    closeDirectoryForm(true);
  };

  useEffect(() => {
    if (openDirectoryForm && !map) {
      const map = new window.google.maps.Map(mapRef.current, {
        center: { lat: 14.582461988255229, lng: 121.110686310587 },
        zoom: 14,
      });

      map.addListener("click", (e) => {
        form.setFieldsValue({
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        });
      });

      setMap(map);
    }
  }, [openDirectoryForm]);

  return (
    <div className="flex justify-center items-center">
      <Drawer
        title={
          <div className="flex md:flex-row md:items-center items-start justify-between">
            <h1 className="text-[24px] md:text-[30px] font-[600] text-[#1b1b1b] mb-0 p-3">
              {isEditing ? "Edit Directory" : "Add Directory"}
            </h1>
            <div
              onClick={closeAdd}
              className="w-[50px] h-[50px] items-center text-center flex justify-center text-[20px] rounded-full hover:bg-slate-200 transition-all ease-in duration-150 cursor-pointer hover:scale-110"
            >
              <CloseOutlined className="text-[#1b1b1b]" />
            </div>
          </div>
        }
        centered
        onClose={closeAdd}
        open={openDirectoryForm}
        footer={
          <div className="flex justify-end">
            <Button
              loading={isLoading}
              disabled={isLoading}
              className="shadow-md"
              type="primary"
              onClick={form.submit}
              htmlType="submit"
              style={{
                minWidth: "200px",
                height: "52px",
                fontSize: "17px",
                fontWeight: "400",
                borderRadius: "5px",
                background: "#4d8e03",
                marginBlock: "13px",
              }}
            >
              {isEditing ? "Update" : "Confirm"}
            </Button>
          </div>
        }
        height={"90%"}
        closable={false}
        placement={"bottom"}
      >
        <Form
          form={form}
          name="add"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <div className="flex md:flex-col flex-col gap-10 items-center md:items-start p-6">
            <div className="flex flex-col gap-4 w-full md:w-[20%]  md:flex-1 ">
              <div className="text-label">
                <Form.Item
                  name="directoryType"
                  label={
                    <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
                      Directory Type
                    </h1>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please enter directory type",
                    },
                  ]}
                >
                  <Select
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    optionFilterProp="children"
                    showSearch
                    size="large"
                    // onChange={""}
                    // onSearch={""}
                    placeholder="Directory type"
                    options={directoryTypes
                      ?.filter((obj) => obj.status === 0)
                      .map((dir, i) => {
                        return {
                          value: dir.typeId,
                          label: dir.name,
                        };
                      })}
                  ></Select>
                </Form.Item>
                <Form.Item
                  name="name"
                  label={
                    <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
                      Name
                    </h1>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please input Name",
                    },
                  ]}
                >
                  <Input
                    style={{
                      padding: "10px",
                      fontSize: "18px",
                      borderRadius: "3px",
                    }}
                    placeholder="Enter name "
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="address"
                  label={
                    <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
                      Address
                    </h1>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please input Address",
                    },
                  ]}
                >
                  <Input
                    style={{
                      padding: "10px",
                      fontSize: "18px",
                      borderRadius: "3px",
                    }}
                    placeholder="Enter Address"
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="mobileNumber"
                  label={
                    <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
                      Contact number
                    </h1>
                  }
                  rules={[
                    {
                      pattern: /^[0-9]{10}$/,
                      message: "Value must start with 9 and must be 10 digits",
                    },
                  ]}
                >
                  <Input
                    style={{
                      padding: "10px",
                      fontSize: "18px",
                      borderRadius: "3px",
                    }}
                    size="large"
                    prefix="+63"
                  />
                </Form.Item>
                <Form.Item
                  initialValue={""}
                  name="contactPerson"
                  label={
                    <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
                      Contact person
                    </h1>
                  }
                  rules={[
                    {
                      message: "Please input Address",
                    },
                  ]}
                >
                  <Input
                    style={{
                      padding: "10px",
                      fontSize: "18px",
                      borderRadius: "3px",
                    }}
                    placeholder="Enter contact person"
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  initialValue={""}
                  name="lat"
                  label={
                    <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
                      Latitude
                    </h1>
                  }
                >
                  <Input
                    style={{
                      padding: "10px",
                      fontSize: "18px",
                      borderRadius: "3px",
                    }}
                    placeholder="Enter latitude"
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  initialValue={""}
                  name="lng"
                  label={
                    <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
                      Longitude
                    </h1>
                  }
                >
                  <Input
                    style={{
                      padding: "10px",
                      fontSize: "18px",
                      borderRadius: "3px",
                    }}
                    placeholder="Enter Longitude"
                    size="large"
                  />
                </Form.Item>
              </div>
            </div>
            <div className="w-[30%] h-[500px]" ref={mapRef} id="map">
              {/* <Marker
                position={{ lat: 14.582461988255229, lng: 121.110686310587 }}
              /> */}
            </div>
          </div>
        </Form>
      </Drawer>
    </div>
  );
};

export default DirectoryForm;
