import { PlusOutlined } from "@ant-design/icons";
import { Form, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { dummyRequest } from "../../constants/staticConst";
import { convertToBase64 } from "../../helpers/tobase64";

const AssocLogo = ({ setFormValues, setCurrent, formData }) => {
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    setFormValues((prevState) => ({ ...prevState, ...values }));

    setCurrent((prev) => prev + 1);
  };

  useEffect(() => {}, []);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await convertToBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);

    formData.setFieldsValue({
      logo: fileList?.map((file) => file?.originFileObj) || null,
    });
  };
  const uploadButton = (
    <div className="w-full min-w-[100px]">
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  return (
    <div
      className="poppins flex flex-col items-center text-center"
      data-aos="fade"
      data-aos-easing="ease-in"
      data-aos-duration="600"
    >
      <h1 className="text-[#568634] font-[700] text-[40px] pb-10">
        Let’s set you up
      </h1>
      <p className="text-[20px] font-[500] text-[#568634] pb-10">
        First, we need your association logo. Upload the photo here by <br />
        browsing or dragging it here.
      </p>
      <Form
        form={formData}
        name="add"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      >
        <div className="">
          <Form.Item
            valuePropName="fileList"
            name="logo"
            rules={[
              {
                required: true,
                message: "Please upload your logo",
              },
            ]}
          >
            <>
              <Upload
                accept="image/png, image/jpg"
                maxCount={1}
                name="file"
                customRequest={dummyRequest}
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
              >
                {uploadButton}
              </Upload>
              {/* {fileList.map((file) => (
                <div key={file.uid}>
                  <img src={file.preview} alt={file.name} />
                </div>
              ))} */}
            </>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default AssocLogo;
