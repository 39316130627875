import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Bar } from "@ant-design/plots";
import { getRevenues } from "../../../store/slices/dashboard/users-count-slice";
import { format_PHCurrency } from "../../../helpers/formatCurrency";

const Revenue = () => {
  const dispatch = useDispatch();
  const { revenues } = useSelector((state) => state.users);
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getRevenues(token));
  }, []);

  const data = [
    {
      type: "Current month",
      sales: format_PHCurrency(+revenues.currentMonth),
    },
    {
      type: "Past month",
      sales: format_PHCurrency(+revenues.pastMonth),
    },
  ];
  const config = {
    data,
    xField: "sales",
    yField: "type",
    meta: {
      type: {
        alias: "type",
      },
      sales: {
        alias: "sales",
      },
    },
    minBarWidth: 20,
    maxBarWidth: 20,
  };
  return (
    <div className="h-[160px]">
      <Bar {...config} />
    </div>
  );
};

export default Revenue;
