import {
  ArrowLeftOutlined,
  DeleteOutlined,
  DownOutlined,
  EyeOutlined,
  QuestionCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Drawer,
  Dropdown,
  Empty,
  Form,
  Image,
  Input,
  Popconfirm,
  Select,
  Space,
  Table,
  theme,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format_addCommas } from "../../../../helpers/formatCurrency";
import {
  addBillingStatementCharges,
  getAssociationCharges,
  removeBillingStatementAttachment,
  removeBillingStatementCharge,
  updateBillingStatement,
  updateBillingStatementCharge,
} from "../../../../store/slices/billing/billing-slice";
import { LoadingTime } from "../../../../UI/LoadingScreen";
import "../../billing/billing.css";
import BillingChargeForm from "../Billingcharges/BillingChargeForm";
import AttachmentForm from "./AttachmentForm";
import PopconfirmRemove from "../../../../UI/PopconfirmRemove";

const { useToken } = theme;

const Chargesbreakdown = ({
  billId,
  billingStatement,
  billingStatementLoading,
}) => {
  const dispatch = useDispatch();
  const { token } = useToken();
  const [form] = Form.useForm();
  const { assocChargesLoading, assocCharges } = useSelector(
    (state) => state.billing
  );
  const [open, setOpen] = useState(false);
  const [openAttachment, setOpenAttachment] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [attachmentIsEditing, setAttachmentIsEditing] = useState(false);
  const [attachmentIsAdding, setAttachmentIsAdding] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [visible, setVisible] = useState(false);
  const [chargeId, setChargeId] = useState("");
  const [attachmentId, setAttachmentId] = useState("");
  const [billingStatementChargeId, setbillingStatementChargeId] = useState();

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    if (isEditing) {
      dispatch(
        updateBillingStatementCharge({
          billId: billId,
          billingStatementChargeId: billingStatementChargeId,
          chargeId: values?.chargeId,
          additionalAmount: +values?.additionalAmount
            ? +values?.additionalAmount
            : 0,
          remarks: values?.remarks ? values?.remarks : "n/a",
        })
      );
    } else {
      dispatch(
        addBillingStatementCharges({
          billId: billId,
          chargeId: values?.chargeId,
          amount: +values?.cost ? +values?.cost : 0,
          additionalAmount: +values?.additionalAmount
            ? +values?.additionalAmount
            : 0,
          remarks: values?.remarks ? values?.remarks : "n/a",
        })
      );
    }

    setIsEditing(false);
  };

  const found = billingStatement?.charges?.find(
    (obj) => obj.chargeId === chargeId
  );

  useEffect(() => {
    form.setFieldsValue({
      ...found,
      chargeId: found?.chargeId,
      cost: found?.amount,
      additionalAmount: found?.additionalAmount,
      remarks: found?.remarks,
    });
  }, [chargeId]);

  useEffect(() => {
    dispatch(getAssociationCharges());
  }, []);

  const closeDrawer = () => {
    setIsEditing(false);
    setOpen(false);
  };

  const text = "Are you sure to remove this charge?";
  const description = "Remove the charge";

  const text2 = "Are you sure to remove this attachment?";
  const description2 = "Remove the attachment";

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle = {
    boxShadow: "none",
  };

  const items = [
    {
      label: (
        <div className="flex gap-2 items-center">
          <EyeOutlined />
          <p> View charge</p>
        </div>
      ),
      key: "1",
    },
  ];

  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "150px",
      render: (record) => (
        <Dropdown
          trigger={["click"]}
          menu={{
            items,
            onClick: () => viewChargeAttachment(record),
          }}
          // dropdownRender={(menu) => (
          //   <div className="flex flex-col items-start" style={contentStyle}>
          //     {React.cloneElement(menu, {
          //       style: menuStyle,
          //     })}
          //     <Popconfirm
          //       placement="bottomLeft"
          //       title={text}
          //       description={description}
          //       onConfirm={() => deleteBillCharge(record)}
          //       okText="Yes"
          //       cancelText="Cancel"
          //     >
          //       <Button
          //         style={{ color: "black" }}
          //         type="link"
          //         icon={<DeleteOutlined />}
          //       >
          //         Remove
          //       </Button>
          //     </Popconfirm>
          //   </div>
          // )}
        >
          <Button
            type="primary"
            style={{
              background: "#4d8e03",
            }}
          >
            <Space>
              Action
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      width: "200px",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (amount) => (amount ? format_addCommas(amount).toString() : "-"),
      width: "200px",
    },
    {
      title: "Additional amount",
      dataIndex: "additionalAmount",
      render: (amount) => (amount ? format_addCommas(amount).toString() : "-"),
      width: "200px",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      width: "200px",
    },
    {
      title: "Billing date",
      dataIndex: "billingDate",
      render: (billingDate) => moment(billingDate).format("LL"),
      width: "200px",
    },
  ];

  const attachmentCols = [
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "150px",
      render: (record) => (
        <Dropdown
          trigger={["click"]}
          menu={{
            items,
            onClick: () => attachmentEdit(record.attachmentId),
          }}
          dropdownRender={() => (
            <div className="flex flex-col items-start" style={contentStyle}>
              <Button
                onClick={() => attachmentEdit(record.attachmentId)}
                style={{ color: "black" }}
                type="link"
                icon={<EditOutlined />}
              >
                Edit attachment
              </Button>
              <PopconfirmRemove
                text={text}
                desc={description}
                onConfirm={() => removeAttachmentHandler(record.attachmentId)}
              />
              {/* <Popconfirm
                icon={
                  <QuestionCircleOutlined
                    style={{
                      color: "red",
                    }}
                  />
                }
                placement="bottomLeft"
                title={text}
                description={description}
                onConfirm={() => removeAttachmentHandler(record.attachmentId)}
                okText="Yes"
                cancelText="Cancel"
              >
                <Button
                  style={{ color: "black" }}
                  type="link"
                  icon={<DeleteOutlined />}
                >
                  Remove
                </Button>
              </Popconfirm> */}
            </div>
          )}
        >
          <Button
            type="primary"
            style={{
              background: "#4d8e03",
            }}
          >
            <Space>
              Action
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
    {
      title: "Image",
      width: "200px",
      dataIndex: "imageUrl",
      render: (record) => {
        return record ? (
          <Image
            style={{
              width: "100px",
              height: "100px",
              objectFit: "cover",
            }}
            src={process.env.REACT_APP_BASE_URL + "/" + record}
          />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        );
      },
    },
    {
      title: "Description",
      width: "200px",
      dataIndex: "description",
      render: (record) => {
        return record ? record : "-";
      },
    },
  ];

  const viewChargeAttachment = (value) => {
    setOpen(true);
    setChargeId(value?.chargeId);
    setbillingStatementChargeId(value.billingStatementChargeId);
  };

  const deleteBillCharge = (value) => {
    dispatch(
      removeBillingStatementCharge({
        billId: billId,
        chargeId: value.chargeId,
        chargeId: value.chargeId,
        billingStatementChargeId: value.billingStatementChargeId,
      })
    );
  };

  const editHandler = () => {
    setIsEditing(!isEditing);

    if (isEditing) {
      form.setFieldsValue({
        ...found,
        chargeId: found?.chargeId,
        cost: found?.amount,
        additionalAmount: found?.additionalAmount,
        remarks: found?.remarks,
      });
    }
  };

  const removeAttachmentHandler = (value) => {
    dispatch(
      removeBillingStatementAttachment({
        params: value,
        body: { billId: billId },
      })
    );
  };

  const onClose = () => {
    setVisible(false);
  };

  const onAdd = () => {
    setVisible(true);
    setIsEditing(false);
    setIsAdding(true);
  };

  const attachmentHandler = () => {
    setOpenAttachment(true);
    setAttachmentIsAdding(true);
    setAttachmentIsEditing(false);
  };

  const attachmentEdit = (value) => {
    setOpenAttachment(true);
    setAttachmentIsAdding(false);
    setAttachmentIsEditing(true);
    setAttachmentId(value);
  };

  const closeAttachmentHandler = () => {
    setOpenAttachment(false);
  };

  return (
    <Card
      title={
        <div className="flex  items-center p-5">
          <h1 className="font-[600] text-[25px] mb-0">Billing charges</h1>
          {/* <div className="flex items-center  text-center justify-center">
            <Button
              style={{
                border: "1px solid transparent",
                alignItems: "center",
                display: "flex",
              }}
              type="outlined"
              onClick={() => onAdd()}
              icon={
                <PlusSquareOutlined className="text-2xl h-full items-center flex justify-center" />
              }
            >
              Add
            </Button>
          </div> */}
        </div>
      }
    >
      <LoadingTime loading={billingStatementLoading} />
      <Table
        loading={billingStatementLoading}
        className="table-charge"
        rowKey={"chargeId"}
        columns={columns}
        dataSource={billingStatement?.charges}
      />
      <Drawer
        className="p-5"
        centered
        onClose={closeDrawer}
        onCancel={closeDrawer}
        open={open}
        height={"90%"}
        closable={false}
        placement={"bottom"}
      >
        <LoadingTime loading={billingStatementLoading} />
        <div className="p-3 flex flex-col md:flex-row md:items-center items-start justify-start">
          <div
            onClick={() => closeDrawer()}
            className="w-[50px] h-[50px] items-center text-center flex justify-center text-[20px] rounded-full hover:bg-slate-200 transition-all ease-in duration-150 cursor-pointer hover:scale-110"
          >
            <ArrowLeftOutlined className="text-[#1b1b1b]" />
          </div>
          <h1 className="py-5 font-[600] text-[#474d63] text-[32px]">
            Charge details
          </h1>
          {/* <div className="flex gap-3 px-6">
            <Button
              onClick={editHandler}
              type="primary"
              style={{
                background: isEditing ? "red" : "#4d8e03",
                transition: "all ease 0.3s",
              }}
            >
              {!isEditing ? "Edit" : "Cancel"}
            </Button>
            <Button
              loading={assocChargesLoading}
              style={{ background: "#1b1b1b" }}
              className={!isEditing ? "hidden" : "block"}
              onClick={() => form.submit()}
              type="primary"
            >
              Update
            </Button>
          </div> */}
        </div>
        <div>
          <Form
            disabled={!isEditing}
            layout="vertical"
            form={form}
            // disabled={isLoading}
            style={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
            }}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="chargeId"
              label={
                <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
                  Charge Type
                </h1>
              }
              rules={[
                {
                  required: true,
                  message: "Please enter charge",
                },
              ]}
            >
              <Select
                disabled={true}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                optionFilterProp="children"
                showSearch
                size="large"
                // onChange={""}
                // onSearch={""}
                placeholder="Charge"
                options={assocCharges
                  ?.filter((obj) => obj.status === 0)
                  .map((charge, i) => {
                    return {
                      value: charge.chargeId,
                      label: charge.name,
                    };
                  })}
              ></Select>
            </Form.Item>
            <Form.Item
              name="cost"
              readOnly
              label={
                <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
                  Amount
                </h1>
              }
              rules={[
                {
                  required: true,

                  message: "Number only",
                },
              ]}
            >
              <Input
                readOnly
                style={{
                  padding: "10px",
                  fontSize: "18px",
                  borderRadius: "3px",
                }}
                precision={2}
                max={4}
                placeholder="Enter amount"
                size="large"
              />
            </Form.Item>
            <Form.Item
              value="0.00"
              name="additionalAmount"
              label={
                <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
                  Additional amount
                </h1>
              }
              rules={[
                {
                  message: "Number only",
                },
              ]}
            >
              <Input
                precision={2}
                max={4}
                style={{
                  padding: "10px",
                  fontSize: "18px",
                  borderRadius: "3px",
                }}
                placeholder="Enter additional amount"
                size="large"
              />
            </Form.Item>
            <Form.Item
              value=""
              name="remarks"
              label={
                <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
                  Remarks
                </h1>
              }
            >
              <Input
                style={{
                  padding: "10px",
                  fontSize: "18px",
                  borderRadius: "3px",
                }}
                placeholder="Enter remarks"
                size="large"
              />
            </Form.Item>
          </Form>
          <Card
            title={
              <div className="flex justify-between items-center">
                <h1 className="py-5 font-[600] text-[#474d63] text-[22px]">
                  Attachments:
                </h1>
                {/* <div className="flex items-center  text-center justify-center">
                  <Button
                    style={{
                      border: "1px solid transparent",
                      alignItems: "center",
                      display: "flex",
                    }}
                    type="outlined"
                    onClick={() => attachmentHandler()}
                    icon={
                      <PlusSquareOutlined className="text-2xl h-full items-center flex justify-center" />
                    }
                  >
                    Add
                  </Button>
                </div> */}
              </div>
            }
          >
            <Table
              loading={billingStatementLoading}
              rowKey={"attachmentId"}
              columns={attachmentCols}
              dataSource={
                billingStatement?.charges?.find(
                  (obj) => obj.chargeId === chargeId
                )?.attachments
              }
              scroll={{
                y: 500,
                x: "100%",
              }}
            />
          </Card>
        </div>
      </Drawer>
      <AttachmentForm
        billingStatementLoading={billingStatementLoading}
        found={found}
        isEditing={attachmentIsEditing}
        isAdding={attachmentIsAdding}
        open={openAttachment}
        close={closeAttachmentHandler}
        chargeId={chargeId}
        billId={billId}
        attachmentId={attachmentId}
      />
    </Card>
  );
};

export default Chargesbreakdown;
