import {
  DownOutlined,
  EyeOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Drawer,
  Dropdown,
  Space,
  Table,
  Tag,
  Typography,
  Descriptions,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  format_addCommas,
  format_PHCurrency,
} from "../../../helpers/formatCurrency";
import {
  getTransactionList,
  getTransaction,
} from "../../../store/slices/transactions/transactions-slice";

const { Paragraph, Text } = Typography;

const Paid = () => {
  const { isLoading, transactionList, transaction } = useSelector(
    (state) => state.transaction
  );
  const dispatch = useDispatch();
  const [ellipsis, setEllipsis] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(false);
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getTransactionList(token));
  }, []);

  const showDrawer = (value) => {
    dispatch(getTransaction({ params: value }));
    setOpenDrawer(true);
  };

  const closeHandler = () => {
    setOpenDrawer(false);
  };

  const items = [
    {
      label: (
        <div className="flex gap-2 items-center">
          <EyeOutlined />
          <p>View</p>
        </div>
      ),
      key: "1",
    },
  ];

  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "150px",
      render: (record) => (
        <Dropdown
          trigger={["click"]}
          menu={{
            items,
            onClick: () => showDrawer(record?.referenceNumber),
          }}
        >
          <Button
            type="primary"
            style={{
              background: "#4d8e03",
            }}
          >
            <Space>
              Action
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
    {
      title: "Payment channel",
      dataIndex: "paymentChannel",
      width: "200px",
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      width: "200px",
      render: (status) =>
        status === 0 ? (
          <Tag color="blue">Pending</Tag>
        ) : (
          <Tag color="green">Paid</Tag>
        ),
    },
    {
      title: "Total amount",
      dataIndex: "totalAmount",
      width: "200px",
      render: (amount) => format_PHCurrency(+amount),
    },
    {
      title: "Total paid",
      dataIndex: "totalPaid",
      width: "200px",
      render: (amount) => format_PHCurrency(+amount),
    },
    {
      title: "Total change",
      dataIndex: "totalChange",
      width: "200px",
      render: (amount) => format_PHCurrency(+amount),
    },
    {
      title: "Message",
      dataIndex: "message",
      width: "200px",
      render: (record) =>
        record ? (
          <Paragraph
            ellipsis={
              ellipsis
                ? {
                    rows: 3,
                    expandable: true,
                    symbol: "more",
                  }
                : false
            }
          >
            {record}
          </Paragraph>
        ) : (
          "-"
        ),
    },
    {
      title: "Due date",
      dataIndex: "dueDate",
      width: "200px",
      render: (dueDate) => moment(dueDate).format("LL"),
    },
    {
      title: "Date Created",
      dataIndex: "dateCreated",
      width: "200px",
      editable: true,
      render: (dateCreated) => moment(dateCreated).format("LL"),
    },
    {
      title: "Date Updated",
      dataIndex: "dateUpdated",
      width: "200px",
      editable: true,
      defaultSortOrder: "descend",
      sorter: (a, b) => moment(a.dateUpdated).diff(moment(b.dateUpdated)),
      render: (dateUpdated) => moment(dateUpdated).format("LL"),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      width: "200px",
    },
  ];

  const detailsCol = [
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "150px",
      render: (record) => (
        <Dropdown
          trigger={["click"]}
          dropdownRender={() => (
            <div className="dropdown-action flex flex-col items-start">
              <Button
                // onClick={() => showDrawer(record.billId)}
                style={{ color: "black" }}
                type="link"
                icon={<EyeOutlined />}
              >
                View transaction
              </Button>
              {/* <Popconfirm
                placement="bottomLeft"
                title={text}
                description={description}
                onConfirm={() => deleteBillingStatement(record.billId)}
                okText="Yes"
                cancelText="Cancel"
              >
                <Button
                  style={{ color: "black" }}
                  type="link"
                  icon={<DeleteOutlined />}
                >
                  Remove
                </Button>
              </Popconfirm> */}
            </div>
          )}
        >
          <Button
            type="primary"
            style={{
              background: "#4d8e03",
            }}
          >
            <Space>
              Action
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      width: "200px",
    },
    {
      title: "Date Created",
      dataIndex: "dateCreated",
      width: "200px",
      editable: true,
      defaultSortOrder: "descend",
      sorter: (a, b) => moment(a.dateCreated).diff(moment(b.dateCreated)),
      render: (dateCreated) => moment(dateCreated).format("LL"),
    },
  ];

  const transacColumns = [
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "150px",
      render: (record) => (
        <Dropdown
          trigger={["click"]}
          dropdownRender={() => (
            <div className="dropdown-action flex flex-col items-start">
              <Button
                onClick={() => showDrawer(record?.referenceNumber)}
                style={{ color: "black" }}
                type="link"
                icon={<EyeOutlined />}
              >
                View transaction
              </Button>
              {/* <Popconfirm
              placement="bottomLeft"
              title={text}
              description={description}
              onConfirm={() => deleteBillingStatement(record.billId)}
              okText="Yes"
              cancelText="Cancel"
            >
              <Button
                style={{ color: "black" }}
                type="link"
                icon={<DeleteOutlined />}
              >
                Remove
              </Button>
            </Popconfirm> */}
            </div>
          )}
        >
          <Button
            type="primary"
            style={{
              background: "#4d8e03",
            }}
          >
            <Space>
              Action
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
    {
      title: "Payment channel",
      dataIndex: "paymentChannel",
      width: "200px",
    },
    {
      title: "Total amount",
      dataIndex: "totalAmount",
      width: "200px",
    },
    {
      title: "Total paid",
      dataIndex: "totalPaid",
      width: "200px",
    },
    {
      title: "Total change",
      dataIndex: "totalChange",
      width: "200px",
    },
    {
      title: "Message",
      dataIndex: "message",
      width: "200px",
    },
    {
      title: "Due date",
      dataIndex: "dueDate",
      width: "200px",
      render: (dueDate) => moment(dueDate).format("LL"),
    },
    {
      title: "Date Created",
      dataIndex: "dateCreated",
      width: "200px",
      editable: true,
      defaultSortOrder: "descend",
      sorter: (a, b) => moment(a.dateCreated).diff(moment(b.dateCreated)),
      render: (dateCreated) => moment(dateCreated).format("LL"),
    },
    {
      title: "Total amount",
      dataIndex: "totalAmount",
      width: "200px",
      render: (amount) => format_addCommas(amount),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      width: "200px",
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      width: "200px",
      render: (status) =>
        status === 0 ? (
          <Tag color="green">Pending</Tag>
        ) : (
          <Tag color="green">Paid</Tag>
        ),
    },
  ];

  return (
    <Card>
      <Table
        loading={isLoading}
        rowKey={"referenceNumber"}
        columns={columns}
        dataSource={
          transactionList
            ? transactionList?.filter((obj) => obj?.status === 1)
            : []
        }
        scroll={{
          y: 500,
          x: "100%",
        }}
      />
      <Drawer
        placement="bottom"
        closable={false}
        onClose={closeHandler}
        open={openDrawer}
        className="demo"
        height={"70%"}
        title={
          <div className="flex flex-col md:flex-row md:items-center items-start justify-start">
            <div
              onClick={() => closeHandler(true)}
              className="w-[50px] h-[50px] items-center text-center flex justify-center text-[20px] rounded-full hover:bg-slate-200 transition-all ease-in duration-150 cursor-pointer hover:scale-110"
            >
              <ArrowLeftOutlined className="text-[#1b1b1b]" />
            </div>
            <h1 className="font-[600] text-[25px] mb-0">Transaction</h1>
          </div>
        }
      >
        <Card>
          {/* <Table
            loading={isLoading}
            rowKey={"referenceNumber"}
            columns={transacColumns}
            dataSource={transaction}
            scroll={{
              y: 500,
              x: "100%",
            }}
          /> */}
          <Descriptions layout="vertical" bordered>
            {
              <>
                <Descriptions.Item label="Payment channel">
                  {transaction.paymentChannel?.toUpperCase()}
                </Descriptions.Item>
                <Descriptions.Item label="Status">
                  {transaction?.status === 0 ? (
                    <Tag color="blue">Pending</Tag>
                  ) : (
                    <Tag color="green">Paid</Tag>
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Total amount">
                  {transaction.totalAmount
                    ? format_PHCurrency(+transaction.totalAmount)
                    : "0.00"}
                </Descriptions.Item>
                <Descriptions.Item label="Total paid">
                  {transaction.totalPaid
                    ? format_PHCurrency(+transaction.totalPaid)
                    : "0.00"}
                </Descriptions.Item>
                <Descriptions.Item label="Total change">
                  {transaction.totalChange
                    ? format_PHCurrency(+transaction.totalChange)
                    : "0.00"}
                </Descriptions.Item>
                <Descriptions.Item label="Message">
                  {transaction?.message ? (
                    <Paragraph
                      ellipsis={
                        ellipsis
                          ? {
                              rows: 3,
                              expandable: true,
                              symbol: "more",
                            }
                          : false
                      }
                    >
                      {transaction?.message}
                    </Paragraph>
                  ) : (
                    "-"
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Date created">
                  {moment(transaction?.dateCreated).format("LL")}
                </Descriptions.Item>
                <Descriptions.Item label="Bills">
                  {transaction?.details
                    ? transaction?.details?.map((d, key) => (
                        <p key={key}>{format_PHCurrency(+d.amount)}</p>
                      ))
                    : "-"}
                </Descriptions.Item>
              </>
            }
          </Descriptions>
        </Card>
      </Drawer>
    </Card>
  );
};

export default Paid;
