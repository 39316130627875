import { configureStore, combineReducers } from "@reduxjs/toolkit";
import createSagaMiddleware from "@redux-saga/core";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authSaga from "./sagas/auth-saga";
import authSlice from "./slices/auth-slice";
import forgotPasswordSaga from "./sagas/forgotpassword/forgotpassword-saga";
import forgotPasswordSlice from "./slices/forgotpassword/forgotpassword-slice";
import homeownersSaga from "./sagas/homeowner/homeowner-saga";
import homeownersSlice from "./slices/homeowner/homeowner-slice";
import homeownerPropertiesSaga from "./sagas/homeowner/homeowner-properties-saga";
import homeownerPropertiesSlice from "./slices/homeowner/homeowner-properties-slice";
import homeownerProfileSaga from "./sagas/homeowner/edit-profile-saga";
import homeownerProfileSlice from "./slices/homeowner/edit-profile-slice";
import propertyPhasesSaga from "./sagas/properties/property-phase-saga";
import propertyPhasesSlice from "./slices/property/property-phase-slice";
import inquiriesSaga from "./sagas/inquiries/inquiries-saga";
import inquiriesSlice from "./slices/inquiries/inquiries-slice";
import announcementSaga from "./sagas/announcements&events/announcements/announcements-saga";
import announcementSlice from "./slices/announcements&events/announcements/announcements-slice";
import eventsSlice from "./slices/announcements&events/events/events-slice";
import eventsSaga from "./sagas/announcements&events/events/events-saga";
import directoriesSlice from "./slices/directories-and-hotlines/directories/directories-slice";
import directoriesSaga from "./sagas/directories-and-hotlines/directories/directories-saga";
import hotlinesSlice from "./slices/directories-and-hotlines/hotlines/hotlines-slice";
import hotlinesSaga from "./sagas/directories-and-hotlines/hotlines/hotlines-saga";
import formsSlice from "./slices/forms/forms-slice";
import formsSaga from "./sagas/forms/forms-saga";
import helpCenterSaga from "./sagas/help-center/help-center-saga";
import helpCenterSlice from "./slices/help-center/help-center-slice";
import profileSlice from "./slices/profile/profile-slice";
import profileSaga from "./sagas/profile/profile-saga";
import onboardingSlice from "./slices/registration-slice/registration-slice";
import onboardingSaga from "./sagas/registration-saga/registration-saga";
import billingSlice from "./slices/billing/billing-slice";
import billingSaga from "./sagas/billing/billing-saga";
import transactionSlice from "./slices/transactions/transactions-slice";
import transactionSaga from "./sagas/transactions/transactions-saga";
import usersSlice from "./slices/dashboard/users-count-slice";
import usersSaga from "./sagas/dashboard/users-count-saga";
import notificationSlice from "./slices/notifications/notification-slice";
import notificationSaga from "./sagas/notifications/notification-saga";
import subscriptionSlice from "./slices/subscription/subscription-slice";
import subscriptionSaga from "./sagas/subscription/subscription-saga";
import paymentChannelSaga from "./sagas/paymentchannel/payment-channel-saga";
import paymentChannelSlice from "./slices/paymentchannel/payment-channel-slice";

const rootPersistConfig = {
  key: "root",
  version: 1,
  storage,
  whiteList: [],
};

const authPersistConfig = {
  key: "auth",
  version: 1,
  storage,
  blacklist: ["auth", "isLoading"],
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authSlice.reducer),
  profile: profileSlice.reducer,
  generateOTP: forgotPasswordSlice.reducer,
  homeowners: homeownersSlice.reducer,
  homeownerProfile: homeownerProfileSlice.reducer,
  homeownerProperties: homeownerPropertiesSlice.reducer,
  propertyPhases: propertyPhasesSlice.reducer,
  inquiry: inquiriesSlice.reducer,
  announcements: announcementSlice.reducer,
  events: eventsSlice.reducer,
  directories: directoriesSlice.reducer,
  hotlines: hotlinesSlice.reducer,
  forms: formsSlice.reducer,
  helpCenter: helpCenterSlice.reducer,
  onboarding: onboardingSlice.reducer,
  billing: billingSlice.reducer,
  transaction: transactionSlice.reducer,
  users: usersSlice.reducer,
  notification: notificationSlice.reducer,
  subscription: subscriptionSlice.reducer,
  paymentChannel: paymentChannelSlice.reducer,
});

// const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

const saga = createSagaMiddleware();
const store = configureStore({
  reducer: rootReducer,
  middleware: [saga],
});

saga.run(authSaga);
saga.run(forgotPasswordSaga);
saga.run(homeownersSaga);
saga.run(homeownerPropertiesSaga);
saga.run(homeownerProfileSaga);
saga.run(propertyPhasesSaga);
saga.run(inquiriesSaga);
saga.run(announcementSaga);
saga.run(eventsSaga);
saga.run(directoriesSaga);
saga.run(hotlinesSaga);
saga.run(formsSaga);
saga.run(helpCenterSaga);
saga.run(profileSaga);
saga.run(onboardingSaga);
saga.run(billingSaga);
saga.run(transactionSaga);
saga.run(usersSaga);
saga.run(notificationSaga);
saga.run(subscriptionSaga);
saga.run(paymentChannelSaga);

export const authActions = authSlice.actions;
export const forgotPasswordActions = forgotPasswordSlice.actions;
export const homeownersActions = homeownersSlice.actions;
export const homeownerPropertiesActions = homeownerPropertiesSlice.actions;
export const homeownerProfileActions = homeownerProfileSlice.actions;
export const propertyPhasesActions = propertyPhasesSlice.actions;
export const inquiriesActions = inquiriesSlice.actions;
export const announcementActions = announcementSlice.actions;
export const eventsActions = eventsSlice.actions;
export const directoriesActions = directoriesSlice.actions;
export const hotlinesActions = hotlinesSlice.actions;
export const formsActions = formsSlice.actions;
export const helpCenterActions = helpCenterSlice.actions;
export const profileActions = profileSlice.actions;
export const onboardingActions = onboardingSlice.actions;
export const billingActions = billingSlice.actions;
export const transactionActions = transactionSlice.actions;
export const usersActions = usersSlice.actions;
export const notificationActions = notificationSlice.actions;
export const subscriptionActions = subscriptionSlice.actions;
export const paymentChannelActions = paymentChannelSlice.actions;
export const persistor = persistStore(store);
export default store;
