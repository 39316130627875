import { motion } from "framer-motion";
import React from "react";
// import { feature } from "../../constants/static";
import { feature } from "../../../constants/static";
import FeatureCardTemplate from "./FeatureCardTemplate";

const FeatureCardLayout = ({ textAnimate, imageAnimate, cardImageAnimate }) => {
	return (
		<motion.div
		// initial={"offscreen"}
		// whileInView={"onscreen"}
		// viewport={{ once: true, amount: 0.2 }}
		// transition={{ staggerChildren: 0.3 }}
		>
			<motion.div
				className=" grid xl:grid-cols-10 lg:grid-cols-10 md:grid-cols-6 sm:grid-cols-4 grid-cols-4 lg:px-14 md:px-6 px-5 gap-x-4 gap-y-8"
				// variants={cardImageAnimate}
			>
				{feature.map((feature, index) => {
					return (
						<FeatureCardTemplate
							key={index}
							feature={feature}
							textAnimate={textAnimate}
							imageAnimate={imageAnimate}
							cardImageAnimate={cardImageAnimate}
						/>
					);
				})}
			</motion.div>
		</motion.div>
	);
};

export default FeatureCardLayout;
