import { Result } from "antd";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="h-screen w-full flex items-center justify-center">
      <Result
        status="404"
        title="404"
        subTitle={
          <span>
            "Sorry, the page you visited does not exist."
            <span
              onClick={() => navigate(-1)}
              className="text-blue-500 cursor-pointer"
            >
              {" "}
              Go back.
            </span>
          </span>
        }
      />
    </div>
  );
};

export default NotFound;
