import { FloatButton } from "antd";
// import { Footer } from "antd/es/layout/layout";
import React, { useState, useEffect } from "react";
import "../../../Properityweb.css";
import About from "../../landing/About";
import Contact from "../../landing/Contact";
import Discover from "../../landing/Discover";
import Features from "../../landing/Features";
import Footer from "../../landing/Footer";
import Header from "../../landing/Header";
import Home from "../../landing/Home";
import Pricing from "../../landing/Pricing";
// import "./properityweb.css";
// import About from "../../components/About";
// import Contact from "../../components/Contact";
// import Discover from "../../components/Discover";
// import Features from "../../components/Features";
// import Footer from "../../components/Footer";
// import Header2 from "../../components/Header2";
// import Home from "../../components/Home";
// import Pricing from "../../components/Pricing";

const ProperityLayout = () => {
  const [menuActive, setMenuActive] = useState(false);

  useEffect(() => {
    localStorage.setItem("login", "landing");
  }, []);

  return (
    <div id="/" className=" w-full max-w-full">
      <Header menuActive={menuActive} setMenuActive={setMenuActive} />

      <div
        onClick={() => {
          setMenuActive(false);
        }}
      >
        <FloatButton.BackTop visibilityHeight={800} />
        <Home />
        <Discover />
        <About />
        <Features />
        <Pricing />
        <Contact />
        <Footer />
      </div>
    </div>
  );
};

export default ProperityLayout;
