import { Divider, Form, Input, InputNumber, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalButton from "../../../../UI/ModalButton";
import {
  getAssociationCharges,
  setBillingCharges,
  updateBillingCharges,
} from "../../../../store/slices/billing/billing-slice";

const AddChargeModal = ({
  index,
  edit,
  record,
  openModal,
  closeModal,
  chargeList,
  updateChargeList,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [charge, setCharge] = useState("");
  const [selectedType, setselectedType] = useState();
  const { assocCharges } = useSelector((state) => state.billing);

  const closeHandler = () => {
    closeModal(true);
    form.resetFields();
  };

  useEffect(() => {
    if (edit) {
      form.setFieldsValue({
        chargeId: record?.chargeId,
        amount: record?.amount,
        additionalAmount: record?.additionalAmount || 0,
        remarks: record?.remarks || "n/a",
      });
    }
  }, [edit]);

  const onChangeCharge = (a, b) => {
    setCharge(b.label);
    setselectedType(b.value);
  };

  useEffect(() => {
    dispatch(getAssociationCharges());
  }, []);

  const onFinish = (values) => {
    if (edit) {
      dispatch(
        updateBillingCharges({
          index: index,
          name: charge,
          amount: +values.amount ? +values.amount : 0,
          additionalAmount: +values.additionalAmount
            ? +values.additionalAmount
            : 0,
          chargeId: values.chargeId,
          remarks: values.remarks ? values.remarks : "n/a",
        })
      );
    } else {
      let updated = chargeList.map((obj) => {
        if (obj.chargeId === selectedType) {
          return { ...obj, isSelected: 1 };
        }
        return obj;
      });
      updateChargeList(updated);
      dispatch(
        setBillingCharges({
          name: charge,
          amount: +values.amount ? +values.amount : 0,
          additionalAmount: +values.additionalAmount
            ? +values.additionalAmount
            : 0,
          chargeId: values.chargeId,
          remarks: values.remarks ? values.remarks : "n/a",
        })
      );
    }

    closeHandler();
    form.resetFields();
  };

  const chargeItems = () => {
    if (edit) {
      return assocCharges
        ?.filter((obj) => obj.status === 0)
        .map((charge, i) => {
          return {
            value: charge.chargeId,
            label: charge.name,
          };
        });
    } else {
      return chargeList
        ?.filter((obj) => obj.isSelected === 0)
        .map((charge, i) => {
          return {
            index: i,
            value: charge.chargeId,
            label: charge.name,
          };
        });
    }
  };

  return (
    <Modal
      destroyOnClose
      className="min-w-[650px]"
      title={
        <h1 className="text-[24px] md:text-[30px] font-[600] text-[#1b1b1b] mb-0 ">
          {edit ? "Update billing charge" : " Add billing charge"}
        </h1>
      }
      centered
      open={openModal}
      onOk={closeHandler}
      onCancel={closeHandler}
      footer={false}
    >
      <Divider />
      <Form
        layout="vertical"
        form={form}
        // disabled={isLoading}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="chargeId"
          label={
            <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
              Charge Type
            </h1>
          }
          rules={[
            {
              required: true,
              message: "Please enter charge",
            },
          ]}
        >
          <Select
            onChange={onChangeCharge}
            disabled={edit}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            optionFilterProp="children"
            showSearch
            size="large"
            // onChange={""}
            // onSearch={""}
            placeholder="Charge"
            options={chargeItems()}
          ></Select>
        </Form.Item>
        <Form.Item
          value={0.0}
          name="amount"
          label={
            <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
              Amount
            </h1>
          }
        >
          <InputNumber
            style={{
              padding: "10px",
              fontSize: "18px",
              width: "100%",
              borderRadius: "3px",
            }}
            min="0"
            precision={2}
            max="9999.99"
            placeholder="Enter amount"
            size="large"
          />
        </Form.Item>
        <Form.Item
          value={0.0}
          name="additionalAmount"
          label={
            <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
              Additional amount
            </h1>
          }
        >
          <InputNumber
            style={{
              padding: "10px",
              fontSize: "18px",
              borderRadius: "3px",
              width: "100%",
            }}
            min="0"
            precision={2}
            max="9999.99"
            placeholder="Enter additional amount"
            size="large"
          />
        </Form.Item>
        <Form.Item
          value=""
          name="remarks"
          label={
            <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
              Remarks
            </h1>
          }
        >
          <Input
            style={{
              padding: "10px",
              fontSize: "18px",
              borderRadius: "3px",
            }}
            placeholder="Enter remarks"
            size="large"
          />
        </Form.Item>
        <ModalButton text={edit ? "Update" : "Add"} />
      </Form>
    </Modal>
  );
};

export default AddChargeModal;
