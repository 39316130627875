import { put, call, takeLatest } from "redux-saga/effects";
import {
  getEvents,
  getEventTypes,
  addEvent,
  addEventType,
  addEventAttachment,
  removeEventType,
  updateEventType,
  updateEventAttachment,
  removeEventAttachment,
  removeEvent,
  updateEvent,
} from "../../../api/announcements&events/events/events-api";
import { eventsActions } from "../../../store";
///
///

function* getEventsRequest() {
  const { requestError, getEventsSuccess } = eventsActions;
  const result = yield call(getEvents);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getEventsSuccess(result.data));
  }
}

function* updateEventRequest({ payload }) {
  const { requestError, updateEventsSuccess, getEvents } = eventsActions;
  const result = yield call(updateEvent, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    if (payload.cb) {
      yield call(payload.cb);
    }
    yield put(updateEventsSuccess(result.data));
    yield put(getEvents());
  }
}

function* addEventRequest({ payload }) {
  const { requestError, addEventsSuccess, getEvents } = eventsActions;
  const result = yield call(addEvent, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    if (payload.cb) {
      yield call(payload.cb);
    }
    yield put(addEventsSuccess(result.data));
    yield put(getEvents());
    if (payload.cb) {
      yield call(payload.cb);
    }
  }
}

function* addEventAttachmentRequest({ payload }) {
  const { requestError, addEventAttachmentSuccess, getEvents } = eventsActions;
  const result = yield call(addEventAttachment, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    if (payload.cb) {
      yield call(payload.cb);
    }
    yield put(addEventAttachmentSuccess(result.data));
    yield put(getEvents());
  }
}

function* updateEventAttachmentRequest({ payload }) {
  const { requestError, updateEventAttachmentSuccess, getEvents } =
    eventsActions;
  const result = yield call(updateEventAttachment, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    if (payload.cb) {
      yield call(payload.cb);
    }
    yield put(updateEventAttachmentSuccess(result.data));
    yield put(getEvents());
  }
}

function* removeEventRequest({ payload }) {
  const { requestError, removeEventSuccess, getEvents } = eventsActions;
  const result = yield call(removeEvent, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    if (payload.cb) {
      yield call(payload.cb);
    }
    yield put(removeEventSuccess(result.data));
    yield put(getEvents());
  }
}

function* removeEventAttachmentRequest({ payload }) {
  const { requestError, removeEventAttachmentSuccess, getEvents } =
    eventsActions;
  const result = yield call(removeEventAttachment, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    if (payload.cb) {
      yield call(payload.cb);
    }
    yield put(removeEventAttachmentSuccess(result.data));
    yield put(getEvents());
  }
}

function* getEventTypesRequest() {
  const { requestError, getEventTypesSuccess } = eventsActions;
  const result = yield call(getEventTypes);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getEventTypesSuccess(result.data));
  }
}

function* addEventTypeRequest({ payload }) {
  const { requestError, addEventTypeSuccess, getEventTypes } = eventsActions;
  const result = yield call(addEventType, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    if (payload.cb) {
      yield call(payload.cb);
    }
    yield put(addEventTypeSuccess(result.data));
    yield put(getEventTypes());
  }
}

function* removeEventTypeRequest({ payload }) {
  const { requestError, removeEventTypeSuccess, getEventTypes } = eventsActions;
  const result = yield call(removeEventType, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    if (payload.cb) {
      yield call(payload.cb);
    }
    yield put(removeEventTypeSuccess(result.data));
    yield put(getEventTypes());
  }
}

function* updateEventTypeRequest({ payload }) {
  const { requestError, updateEventTypeSuccess, getEventTypes } = eventsActions;
  const result = yield call(updateEventType, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    if (payload.cb) {
      yield call(payload.cb);
    }
    yield put(updateEventTypeSuccess(result.data));
    yield put(getEventTypes());
  }
}
// Export the saga (todo-saga)
export default function* eventsSaga() {
  yield takeLatest(`events/getEvents`, getEventsRequest);
  yield takeLatest(`events/addEvents`, addEventRequest);
  yield takeLatest(`events/updateEvents`, updateEventRequest);
  yield takeLatest(
    `events/removeEventAttachment`,
    removeEventAttachmentRequest
  );
  yield takeLatest(`events/addEventAttachment`, addEventAttachmentRequest);
  yield takeLatest(
    `events/updateEventAttachment`,
    updateEventAttachmentRequest
  );
  yield takeLatest(`events/removeEvent`, removeEventRequest);
  yield takeLatest(`events/addEventType`, addEventTypeRequest);
  yield takeLatest(`events/getEventTypes`, getEventTypesRequest);
  yield takeLatest(`events/removeEventType`, removeEventTypeRequest);
  yield takeLatest(`events/updateEventType`, updateEventTypeRequest);
}
