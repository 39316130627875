// eslint-disable-next-line no-unused-vars
import { motion } from "framer-motion";
import React, { useState } from "react";
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
// import { slides } from "../../constants/static";
import { slides } from "../../../constants/static";

// import { SwiperSlide } from "swiper/react";

const CarouselSwiper = () => {
  const imageAnimate = {
    offscreen: { y: 200, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.2, duration: 1 },
    },
  };
  const detailsAnimate = {
    offscreen: { y: 100, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.2, duration: 1 },
    },
  };
  const scaleAnimate = {
    offscreen: { y: 0, opacity: 1, scale: 0.85 },
    onscreen: {
      y: 0,
      opacity: 1,
      scale: 1,
      transition: { type: "spring", bounce: 0.2, duration: 0.5 },
    },
  };
  const mScaleAnimate = {
    offscreen: { y: 0, opacity: 1, scale: 0.85 },
    onscreen: {
      y: 0,
      opacity: 1,
      scale: 1,
      transition: { type: "spring", bounce: 0.2, duration: 0.3 },
    },
  };
  const [activeSlide, setActiveSlide] = useState(0);

  const handleSlideChange = (swiper) => {
    setActiveSlide(swiper.activeIndex);
  };
  // console.log(activeSlide);
  return (
    <motion.div
      className=" flex justify-center items-center w-full "
      initial={"offscreen"}
      whileInView={"onscreen"}
      viewport={{ once: true, amount: 0.9 }}
      transition={{ staggerChildren: 0.1 }}
    >
      <motion.div
        variants={imageAnimate}
        className=" justify-center items-center w-full sm:flex hidden"
      >
        <Swiper
          slidesPerView={2}
          centeredSlides={true}
          spaceBetween={20}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Autoplay]}
          initialSlide={1}
          onSlideChange={handleSlideChange}
          style={{
            // "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "green",
          }}
          className="mySwiper 2xl:w-[85%] xl:w-[90%] w-[100%] 2xl:h-[550px] md:h-[550px] h-[400px] bg-[#F5F5EE] pt-5 pb-28"
        >
          {slides.map((slide, i) => {
            return (
              <SwiperSlide
                className=" flex justify-center items-center  relative cursor-grab"
                key={i}
              >
                <motion.div
                  className=" w-full h-[100%] rounded-lg py-0"
                  initial={"offscreen"}
                  whileInView={"onscreen"}
                  viewport={{ once: false, amount: 0.9 }}
                  transition={{ staggerChildren: 0.1 }}
                >
                  <motion.img
                    className=" w-full h-full rounded-lg"
                    src={slide.imgSrc}
                    alt="/"
                    variants={scaleAnimate}
                  />
                </motion.div>
              </SwiperSlide>
            );
          })}

          <motion.div
            className=" flex  flex-col justify-center items-center absolute md:bottom-[70px] bottom-16 mx-auto right-0 left-0 px-5 z-10 w-[50%] "
            initial={{ opacity: 0, scale: 1, y: 50 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            transition={{ delay: 0.1, duration: 0.3 }}
            variants={detailsAnimate}
            key={`slideDetails${activeSlide}`}
          >
            <div className=" bg-white p-5 rounded-2xl ">
              <h1 className=" lg:text-lg text-[16px] text-[#1B1C57] font-semibold">
                {slides[activeSlide]?.details.title}
              </h1>
              <p className="  lg:text-[16px] text-[14px] sm:leading-6 leading-4 text-[#626687] m-0">
                {slides[activeSlide]?.details.content}
              </p>
            </div>
          </motion.div>
        </Swiper>
      </motion.div>
      <motion.div
        variants={imageAnimate}
        className="flex justify-center items-center w-[90%] sm:hidden"
      >
        <Swiper
          slidesPerView={1}
          centeredSlides={true}
          // loop={true}
          spaceBetween={30}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Autoplay]}
          initialSlide={1}
          onSlideChange={handleSlideChange}
          className="mySwiper 2xl:w-[90%] xl:w-[90%] w-[100%] md:h-[550px] h-[400px] bg-[#F5F5EE]"
        >
          {slides.map((slide, i) => {
            return (
              <SwiperSlide
                className=" flex justify-center items-center  relative cursor-grab"
                key={i}
              >
                <motion.div
                  className=" w-full h-[75%]"
                  initial={"offscreen"}
                  whileInView={"onscreen"}
                  viewport={{ once: false, amount: 0.9 }}
                  transition={{ staggerChildren: 0.1 }}
                >
                  <motion.img
                    className=" w-full h-full rounded-lg"
                    src={slide.imgSrc}
                    alt="/"
                    variants={mScaleAnimate}
                  />
                </motion.div>
              </SwiperSlide>
            );
          })}

          <motion.div
            className=" flex  flex-col justify-center items-center absolute lg:bottom-20 bottom-5 mx-auto right-0 left-0 p-5 z-10 w-full "
            initial={{ opacity: 0, scale: 1, y: 50 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            transition={{ delay: 0.1, duration: 0.3 }}
            variants={detailsAnimate}
            key={`slideDetails${activeSlide}`}
          >
            <div className=" bg-white p-5 rounded-2xl ">
              <h1 className=" md:text-lg text-[16px] text-[#1B1C57] font-semibold">
                {slides[activeSlide]?.details.title}
              </h1>
              <p className="  md:text-[16px] text-[14px] sm:leading-6 leading-4 text-[#626687] m-0">
                {slides[activeSlide]?.details.content}
              </p>
            </div>
          </motion.div>
        </Swiper>
      </motion.div>
    </motion.div>
  );
};

export default CarouselSwiper;
