import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

const initialState = {
  isLoading: false,
  homeownerProfile: [],
};

const homeownerProfileSlice = createSlice({
  name: "homeownerProfile",
  initialState,
  reducers: {
    getUpdatedProfile(state) {
      state.isLoading = true;
    },
    getUpdatedProfileSuccess(state, action) {
      message.success(action.payload.message);
      state.isLoading = false;
    },
    requestError(state, action) {
      state.isLoading = false;
      message.error(action.payload.data.message);
    },
  },
});

export const { requestError, getUpdatedProfile, getUpdatedProfileSuccess } =
  homeownerProfileSlice.actions;
export default homeownerProfileSlice;
