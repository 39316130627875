import {
  ArrowLeftOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  EyeOutlined,
  FormOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import {
  Button,
  Card,
  DatePicker,
  Drawer,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  Select,
  Space,
  Table,
  Tooltip,
  theme,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Help from "../../../assets/resources/icons/Help.png";
import { getUpdatedProfile } from "../../../store/slices/homeowner/edit-profile-slice";
import {
  getResidents,
  removeResident,
} from "../../../store/slices/homeowner/homeowner-properties-slice";
import { getProperties } from "../../../store/slices/property/property-phase-slice";
import BillingForm from "../billing/BillingForm";
import ResidentProfile from "./ResidentProfile";
import "./TableSample.css";

const originData = [];

for (let i = 0; i < 100; i++) {
  originData.push({
    key: i.toString(),
    name: `Edrward ${i}`,
    age: 32,
    address: `London Park no. ${i}`,
  });
}
const { useToken } = theme;

const HomeownerProfile = ({
  viewHomeowner,
  closeHomeowner,
  homeownerId,
  editToggle,
  setEditToggle,
  billing,
}) => {
  const { token } = useToken();
  const [form] = Form.useForm();
  const { registered } = useSelector((state) => state.homeowners);
  const { properties, isLoading, residents, residentsLoading } = useSelector(
    (state) => state.homeownerProperties
  );
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [viewResident, setViewResident] = useState(false);
  const [propertyId, setPropertyId] = useState();
  const [residentId, setResidentId] = useState();
  const [editResident, setEditResident] = useState();
  const [propertyName, setPropertyName] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const [openBilling, setOpenBilling] = useState(false);

  const found = registered.find((obj) => obj.homeownerId === homeownerId);

  useEffect(() => {
    form.setFieldsValue({
      ...found,
      firstName: found?.firstName,
      lastName: found?.lastName,
      middleName: found?.middlename ? found?.middlename : "n/a",
      mobileNumber: "" + found?.mobileNumber?.slice(2),
      email: found?.email,
      sex: found?.sex,
      birthdate: dayjs(found?.birthdate, "YYYY-MM-DD"),
    });
  }, [registered, homeownerId]);

  useEffect(() => {
    dispatch(getResidents(propertyId));
  }, []);

  const items = [
    {
      label: (
        <div className="flex gap-2 items-center">
          <EditOutlined />
          <p>View</p>
        </div>
      ),
      key: 1,
      hidden: billing ? false : true,
    },
    {
      label: (
        <div className="flex gap-2 items-center">
          <EditOutlined />
          <p>Create</p>
        </div>
      ),
      key: 2,
      hidden: !billing ? false : true,
    },
  ];

  const residentItems = [
    {
      label: "View resident",
      key: "1",
      icon: <EyeOutlined />,
    },

    {
      label: "Edit resident",
      key: "2",
      icon: <EditOutlined />,
    },
    {
      label: "Delete resident",
      key: "3",
      icon: <DeleteOutlined />,
    },
  ];

  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "120px",
      render: (record) => (
        <Dropdown
          trigger={["click"]}
          menu={{
            items: items.filter((obj) => obj?.hidden === true),
            onClick: (e) => {
              if (e.key === "1") {
                showDrawer(record);
              } else if (e.key === "2") {
                addBill(record.propertyId);
              }
            },
          }}
        >
          <Button
            type="primary"
            style={{
              background: "#4d8e03",
            }}
          >
            <Space>
              Action
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
    {
      title: "Property name",
      dataIndex: "propertyName",
      width: "200px",
      editable: true,
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
      render: (obj) => (obj === null ? "-" : obj),
    },
    {
      title: "Custom name",
      dataIndex: "customName",
      width: "200px",
      editable: true,
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
      render: (obj) => (obj === null ? "-" : obj),
    },
    {
      title: "Address",
      dataIndex: "address",
      width: "200px",
      editable: true,
      render: (obj) => (obj === null ? "-" : obj),
    },
    {
      title: "Phase",
      dataIndex: "phase",
      width: "200px",
      editable: true,
      render: (obj) => (obj === null ? "-" : obj),
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    {
      title: "Street",
      dataIndex: "street",
      width: "200px",
      editable: true,
      render: (obj) => (obj === null ? "-" : obj),
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    {
      title: "Property size",
      dataIndex: "propertySize",
      width: "200px",
      editable: true,
      render: (obj) => (obj === null ? "-" : obj),
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
    {
      title: "Property type",
      dataIndex: "propertyType",
      width: "200px",
      editable: true,
      render: (obj) => (obj === null ? "-" : obj),
      sorter: (a, b) => {
        if (a.name == null) {
          return -1;
        } else if (b.name == null) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      },
    },
  ];

  const residentColumns = [
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "120px",
      render: (record) => (
        <Dropdown
          trigger={["click"]}
          menu={{
            items: residentItems,
            onClick: (e) => {
              if (e.key === "1") {
                viewResidentHandler(record);
              } else if (e.key === "2") {
                editHandler(record);
              } else if (e.key === "3") {
                deleteResident(record);
              }
            },
          }}
        >
          <Button
            type="primary"
            style={{
              background: "#4d8e03",
            }}
          >
            <Space>
              Action
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
    {
      title: "First name",
      dataIndex: "firstName",
      width: "200px",
      editable: true,
      sorter: (a, b) => a.name.localeCompare(b.firstName),
      render: (obj) => (obj === null ? "-" : obj),
    },
    {
      title: "Middle name",
      dataIndex: "middlename",
      width: "200px",
      editable: true,
      sorter: (a, b) => a.name.localeCompare(b.middlename),
      render: (obj) => (obj === null ? "-" : obj),
    },
    {
      title: "Last name",
      dataIndex: "lastName",
      width: "200px",
      editable: true,
      sorter: (a, b) => a.name.localeCompare(b.lastName),
      render: (obj) => (obj === null ? "-" : obj),
    },
    {
      title: "Suffix",
      dataIndex: "suffix",
      width: "200px",
      editable: true,
      render: (suffix) => (suffix === null ? "-" : suffix),
    },
    {
      title: "Birthdate",
      dataIndex: "birthdate",
      width: "200px",
      editable: true,
      render: (bday) => moment(bday).format("LL"),
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "200px",
      editable: true,
      render: (obj) => (obj === null ? "-" : obj),
    },
    {
      title: "Mobile number",
      dataIndex: "mobileNumber",
      width: "200px",
      editable: true,
      render: (obj) => (obj === null ? "-" : obj),
    },
    {
      title: "Relation",
      dataIndex: "relation",
      width: "200px",
      editable: true,
      render: (obj) => (obj === null ? "-" : obj),
    },
  ];

  const onFinish = (values) => {
    dispatch(
      getUpdatedProfile({
        params: homeownerId,
        body: {
          birthdate: values.birthdate.format("YYYY-MM-DD"),
          firstName: values.firstName,
          middleName: values.middleName,
          sex: values.sex,
          lastName: values.lastName,
          mobileNumber: "63" + values?.mobileNumber,
          email: values.email,
        },
      })
    );
  };

  const onFinishFailed = (errorInfo) => {};

  const showDrawer = (record) => {
    setOpen(true);
    setPropertyId(record.propertyId);
    dispatch(getResidents(record.propertyId));
    setPropertyName(record.propertyName);
  };

  const onClose = () => {
    setOpen(false);
  };

  const closeHandler = () => {
    setOpenBilling(false);
  };

  const addBill = (value) => {
    setOpenBilling(true);
    setPropertyId(value);
  };

  const deleteResident = (record) => {
    Modal.confirm({
      centered: true,
      title: "Are you sure to delete this resident?",
      onOk: () => {
        dispatch(
          removeResident({
            params: propertyId,
            body: { residentId: record.accountId },
          })
        );
      },
    });
  };

  const onCloseResident = () => {
    setViewResident(false);
  };

  const viewResidentHandler = (record) => {
    setViewResident(true);
    setResidentId(record?.accountId);
  };

  const editHandler = (record) => {
    setViewResident(true);
    setResidentId(record.accountId);
    setEditResident(true);
  };

  return (
    <div>
      <Drawer
        title={
          <div className="flex flex-col md:flex-row md:items-center items-start justify-start">
            <div
              onClick={() => closeHomeowner(true)}
              className="w-[50px] h-[50px] items-center text-center flex justify-center text-[20px] rounded-full hover:bg-slate-200 transition-all ease-in duration-150 cursor-pointer hover:scale-110"
            >
              <ArrowLeftOutlined className="text-[#1b1b1b]" />
            </div>

            <h1 className="text-[24px] md:text-[30px] font-[600] text-[#1b1b1b] mb-0 p-3">
              Homeowner Account
            </h1>
          </div>
        }
        placement="bottom"
        closable={false}
        onClose={closeHomeowner}
        open={viewHomeowner}
        className="demo"
        height={"90%"}
      >
        <div className="h-full w-full flex flex-col ">
          <Form
            className={billing ? "hidden" : "flex flex-col"}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
            style={{ padding: "20px" }}
            form={form}
            initialValues={{
              remember: true,
            }}
          >
            <div
              className="flex-col md:flex-row md:items-end md:justify-end "
              style={{
                display: "flex",
                gap: editToggle ? "10px" : "0px",
                transition: "all ease 0.3s",
              }}
            >
              <Form.Item>
                <Button
                  onClick={() => setEditToggle(true)}
                  type="primary"
                  size="default"
                  icon={<EditOutlined />}
                  style={{
                    transition: "all ease 0.3s",
                    background: "#4d8e03",
                    marginRight: editToggle ? "0px" : "6px",
                  }}
                >
                  Edit
                </Button>
              </Form.Item>
              <Form.Item
                style={{
                  display: !editToggle ? "none" : "block",
                }}
              >
                <Button
                  onClick={() => setEditToggle(false)}
                  style={{
                    display: !editToggle ? "none" : "block",
                    background: "#4d8e03",
                  }}
                  htmlType="submit"
                  type="primary"
                  size="default"
                  icon={<SaveOutlined />}
                >
                  Save
                </Button>
              </Form.Item>
              <Form.Item
                style={{
                  display: !editToggle ? "none" : "block",
                }}
              >
                <Button
                  onClick={() => {
                    setEditToggle(false);

                    form.setFieldsValue({
                      ...found,
                      firstName: found?.firstName,
                      lastName: found?.lastName,
                      mobileNumber: "" + found?.mobileNumber?.slice(2),
                      email: found?.email,
                      sex: found?.sex,
                      birthdate: dayjs(found?.birthdate, "YYYY-MM-DD"),
                    });
                  }}
                  style={{
                    display: !editToggle ? "none" : "block",
                  }}
                  type="primary"
                  danger
                  size="default"
                >
                  Cancel
                </Button>
              </Form.Item>
            </div>
            <div className="flex flex-wrap justify-start w-full mx-auto gap-2 md:gap-10">
              <Form.Item
                name="firstName"
                label={<h1 className="form-title">First name</h1>}
              >
                <Input
                  disabled={!editToggle}
                  style={{
                    padding: "10px",
                    fontSize: "18px",
                    borderRadius: "3px",
                  }}
                  size="large"
                />
              </Form.Item>
              <Form.Item
                name="middleName"
                label={<h1 className="form-title">Middle name</h1>}
              >
                <Input
                  disabled={!editToggle}
                  style={{
                    padding: "10px",
                    fontSize: "18px",
                    borderRadius: "3px",
                  }}
                  size="large"
                />
              </Form.Item>
              <Form.Item
                name="lastName"
                label={<h1 className="form-title">Last name</h1>}
              >
                <Input
                  disabled={!editToggle}
                  style={{
                    padding: "10px",
                    fontSize: "18px",
                    borderRadius: "3px",
                  }}
                  size="large"
                />
              </Form.Item>
              <Form.Item
                name="mobileNumber"
                label={<h1 className="form-title">Mobile number</h1>}
                rules={[
                  {
                    pattern: /^[0-9]{10}$/,
                    message: "Value must start with 9 and must be 10 digits",
                  },
                ]}
              >
                <Input
                  disabled={!editToggle}
                  style={{
                    padding: "10px",
                    fontSize: "18px",
                    borderRadius: "3px",
                  }}
                  size="large"
                  prefix="+63"
                />
              </Form.Item>
              <Form.Item
                name="email"
                label={<h1 className="form-title">Email address</h1>}
              >
                <Input
                  disabled={!editToggle}
                  className="p-0 md:p-[12px] text-[12px] md:text-[25px]"
                  style={{
                    padding: "10px",
                    fontSize: "18px",
                    borderRadius: "3px",
                  }}
                  size="large"
                />
              </Form.Item>
              <Form.Item name="sex" label={<h1 className="form-title">Sex</h1>}>
                <Select
                  className="home-owner"
                  style={{
                    borderRadius: "3px",
                    borderColor: "transparent",
                  }}
                  size="large"
                  disabled={!editToggle}
                  optionFilterProp="children"
                  showSearch
                  options={[
                    {
                      value: "0",
                      label: "Male",
                    },
                    {
                      value: "1",
                      label: "Female",
                    },
                  ]}
                ></Select>
              </Form.Item>
              <Form.Item
                name="birthdate"
                label={<h1 className="form-title">Birthdate</h1>}
              >
                <DatePicker disabled={!editToggle} size="large" />
              </Form.Item>
            </div>
          </Form>
          <div>
            <Card
              title={<h1 className="font-[600] text-[25px] ">Properties</h1>}
            >
              <Table
                components={{
                  body: {},
                }}
                loading={isLoading}
                bordered
                rowKey="propertyId"
                dataSource={properties}
                columns={columns}
                rowClassName="editable-row"
                scroll={{
                  y: 400,
                  x: "100vw",
                }}
              />
            </Card>
          </div>
        </div>
        <Drawer
          title={
            <div className="flex md:flex-row md:items-center items-start justify-start">
              <div
                onClick={() => setOpen(false)}
                className="w-[50px] h-[50px] items-center text-center flex justify-center text-[20px] rounded-full hover:bg-slate-200 transition-all ease-in duration-150 cursor-pointer hover:scale-110"
              >
                <ArrowLeftOutlined className="text-[#1b1b1b]" />
              </div>

              <h1 className="text-[24px] md:text-[30px] font-[600] text-[#1b1b1b] mb-0 p-3">
                Homeowner Residents
              </h1>
            </div>
          }
          placement="bottom"
          closable={false}
          onClose={onClose}
          open={open}
          className="demo"
          // width={window.innerWidth > 1200 ? "50%" : "100%"}
          height={"90%"}
        >
          <div className="h-full w-full flex flex-col p-0 ">
            <div>
              <Card
                title={
                  <h1 className="font-[600] text-[25px] ">
                    {propertyName ? propertyName : "-"}
                  </h1>
                }
              >
                <Table
                  components={{
                    body: {},
                  }}
                  loading={residentsLoading}
                  bordered
                  rowKey="accountId"
                  dataSource={residents}
                  columns={residentColumns}
                  rowClassName="editable-row"
                  scroll={{
                    y: "100%",
                    x: "100vw",
                  }}
                />
              </Card>
            </div>
          </div>
        </Drawer>
      </Drawer>
      <ResidentProfile
        viewResident={viewResident}
        closeResident={onCloseResident}
        residentId={residentId}
        homeownerId={homeownerId}
        editToggle={editResident}
        setEditToggle={setEditResident}
      />
      <BillingForm
        open={openBilling}
        isEditing={isEditing}
        isAdding={isAdding}
        close={closeHandler}
        propertyId={propertyId}
        homeownerId={homeownerId}
      />
    </div>
  );
};

export default HomeownerProfile;
