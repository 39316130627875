import { defaultAxios, axiosMultipart } from "../axios";

const baseApi = "/api/associations/services/payment/";

defaultAxios.interceptors.request.use(async function (config) {
  config.headers["Authorization"] = `Bearer ${
    JSON.parse(await localStorage.getItem("persist:auth")).token.split(`"`)[1]
  }`;
  return config;
});

axiosMultipart.interceptors.request.use(async function (config) {
  config.headers["Authorization"] = `Bearer ${
    JSON.parse(await localStorage.getItem("persist:auth")).token.split(`"`)[1]
  }`;
  return config;
});

export const getPaymentChannels = async () => {
  try {
    const result = await defaultAxios.get(baseApi + "getPaymentChannels");
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const addPaymentChannel = async (body) => {
  try {
    const result = await axiosMultipart.post(
      baseApi + "addPaymentChannel",
      body.formData
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const editPaymentChannel = async (data) => {
  try {
    const result = await axiosMultipart.post(
      baseApi + "editPaymentChannel/" + data.params,
      data.body.formData
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const deactivatePaymentChannel = async (params) => {
  try {
    const result = await defaultAxios.post(
      baseApi + "deactivatePaymentChannel/" + params
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const activatePaymentChannel = async (params) => {
  try {
    const result = await defaultAxios.post(
      baseApi + "activatePaymentChannel/" + params
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};
