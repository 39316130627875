import { put, call, takeLatest, select } from "redux-saga/effects";
import {
  getInquiries,
  getUtilityReports,
  getUtilityReportTypes,
  removeInquiry,
  removeUtilityReport,
  removeUtilityReportType,
  addUtilityReportType,
  processInquiry,
  resolveInquiry,
  resolveUtilityReport,
  processUtilityReport,
  updateUtilityReportType,
} from "../../api/inquiries/inquiries-api";
import { inquiriesActions } from "../../store";
///
///

function* getInquiriesRequest({ payload }) {
  const { requestError, getInquiriesSuccess } = inquiriesActions;
  const result = yield call(getInquiries, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getInquiriesSuccess(result.data));
  }
}

function* removeInquiryRequest({ payload }) {
  const { requestError, removeInquirySuccess, getInquiries } = inquiriesActions;
  const result = yield call(removeInquiry, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    const { token } = yield select((state) => state.auth);
    yield put(removeInquirySuccess(result.data));
    yield put(getInquiries(token));
  }
}

function* processInquiryRequest({ payload }) {
  const { requestError, processInquirySuccess, getInquiries } =
    inquiriesActions;
  const result = yield call(processInquiry, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    const { token } = yield select((state) => state.auth);
    yield put(processInquirySuccess(result.data));
    yield put(getInquiries(token));
  }
}

function* resolveInquiryRequest({ payload }) {
  const { requestError, resolveInquirySuccess, getInquiries } =
    inquiriesActions;
  const result = yield call(resolveInquiry, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    const { token } = yield select((state) => state.auth);
    yield put(resolveInquirySuccess(result.data));
    yield put(getInquiries(token));
  }
}

function* getUtilityReportsRequest({ payload }) {
  const { requestError, getUtilityReportsSuccess } = inquiriesActions;
  const result = yield call(getUtilityReports, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getUtilityReportsSuccess(result.data));
  }
}

function* processUtilityReportRequest({ payload }) {
  const { requestError, processUtilityReportSuccess, getUtilityReports } =
    inquiriesActions;
  const result = yield call(processUtilityReport, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    const { token } = yield select((state) => state.auth);
    yield put(processUtilityReportSuccess(result.data));
    yield put(getUtilityReports(token));
  }
}

function* resolveUtilityReportRequest({ payload }) {
  const { requestError, resolveUtilityReportSuccess, getUtilityReports } =
    inquiriesActions;
  const result = yield call(resolveUtilityReport, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    const { token } = yield select((state) => state.auth);

    yield put(resolveUtilityReportSuccess(result.data));
    yield put(getUtilityReports(token));
  }
}

function* removeUtilityReportRequest({ payload }) {
  const { requestError, removeUtilityReportSuccess, getUtilityReports } =
    inquiriesActions;
  const result = yield call(removeUtilityReport, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    const { token } = yield select((state) => state.auth);

    yield put(removeUtilityReportSuccess(result.data));
    yield put(getUtilityReports(token));
  }
}

function* getUtilityReportTypesRequest() {
  const { requestError, getUtilityReportTypesSuccess } = inquiriesActions;
  const result = yield call(getUtilityReportTypes);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getUtilityReportTypesSuccess(result.data));
  }
}

function* addUtilityTypeRequest({ payload }) {
  const { requestError, addUtilityReportTypeSuccess, getUtilityReportTypes } =
    inquiriesActions;
  const result = yield call(addUtilityReportType, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(addUtilityReportTypeSuccess(result.data));
    yield put(getUtilityReportTypes());
  }
}

function* removeUtilityTypeRequest({ payload }) {
  const { requestError, removeUtilityTypeSuccess, getUtilityReportTypes } =
    inquiriesActions;
  const result = yield call(removeUtilityReportType, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(removeUtilityTypeSuccess(result.data));
    yield put(getUtilityReportTypes());
  }
}

function* updateUtilityTypeRequest({ payload }) {
  const {
    requestError,
    updateUtilityReportTypeSuccess,
    getUtilityReportTypes,
  } = inquiriesActions;
  const result = yield call(updateUtilityReportType, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(updateUtilityReportTypeSuccess(result.data));
    yield put(getUtilityReportTypes());
  }
}

// Export the saga (todo-saga)
export default function* inquiriesSaga() {
  yield takeLatest(`inquiry/getInquiries`, getInquiriesRequest);
  yield takeLatest(`inquiry/removeInquiry`, removeInquiryRequest);
  yield takeLatest(`inquiry/getUtilityReports`, getUtilityReportsRequest);
  yield takeLatest(`inquiry/removeUtilityReport`, removeUtilityReportRequest);
  yield takeLatest(
    `inquiry/getUtilityReportTypes`,
    getUtilityReportTypesRequest
  );
  yield takeLatest(`inquiry/removeUtilityType`, removeUtilityTypeRequest);
  yield takeLatest(`inquiry/addUtilityReportType`, addUtilityTypeRequest);
  yield takeLatest(`inquiry/processInquiry`, processInquiryRequest);
  yield takeLatest(`inquiry/resolveInquiry`, resolveInquiryRequest);
  yield takeLatest(`inquiry/processUtilityReport`, processUtilityReportRequest);
  yield takeLatest(`inquiry/resolveUtilityReport`, resolveUtilityReportRequest);
  yield takeLatest(`inquiry/updateUtilityReportType`, updateUtilityTypeRequest);
}
