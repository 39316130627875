import {
  ArrowLeftOutlined,
  DownOutlined,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Drawer,
  Dropdown,
  Form,
  Input,
  Space,
  Table,
  Tag,
  Image,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  format_addCommas,
  format_PHCurrency,
} from "../../../helpers/formatCurrency";
import { getBillings } from "../../../store/slices/billing/billing-slice";
import {
  getTransaction,
  getTransactionList,
  updateTransaction,
  transacDrawerHandler,
  formDrawerHandler,
} from "../../../store/slices/transactions/transactions-slice";
import { LoadingTime } from "../../../UI/LoadingScreen";

const { TextArea } = Input;

const Transactions = () => {
  const [formFilter] = Form.useForm();
  const {
    isLoading,
    transactionList,
    transaction,
    transactionLoading,
    paymentError,
    transacDrawer,
    formDrawer,
  } = useSelector((state) => state.transaction);
  const { billings } = useSelector((state) => state.billing);
  const dispatch = useDispatch();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState("");
  const [totalPayment, setTotalPayment] = useState("");
  const [transactionListArr, setTransactionListArr] = useState([]);
  const [change, setChange] = useState("");
  const [form] = Form.useForm();
  const [accountId, setAccountId] = useState("");
  const { currentUser, token } = useSelector((state) => state.auth);
  const [openPaymentForm, setOpenPaymentForm] = useState(false);

  useEffect(() => {
    dispatch(getTransactionList(token));
  }, []);

  useEffect(() => {
    if (Object.keys(transactionList).length !== 0)
      setTransactionListArr(transactionList);
  }, [transactionList]);

  useEffect(() => {
    if (paymentError === true) {
      setOpenPaymentForm(true);
      setOpenDrawer(true);
    } else {
      setOpenPaymentForm(false);
      setOpenDrawer(false);
    }
  }, [paymentError]);

  const showDrawer = (value) => {
    dispatch(getTransaction({ params: value?.referenceNumber }));
    dispatch(transacDrawerHandler(true));
    setReferenceNumber(value?.referenceNumber);
    setTotalPayment(value?.totalAmount);
    setAccountId(value?.accountId);
  };

  const closeHandler = () => {
    form.resetFields();
    dispatch(transacDrawerHandler(false));
  };

  const openForm = () => {
    dispatch(formDrawerHandler(true));
  };

  const closeForm = () => {
    form.resetFields();
    dispatch(formDrawerHandler(false));
  };

  const billingColumn = [
    {
      title: "Property",
      dataIndex: "customName",
      width: "200px",
    },
    {
      title: "Address",
      dataIndex: "address",
      width: "200px",
    },
    {
      title: "Billing date",
      dataIndex: "billingDate",
      width: "200px",
      render: (billingDate) => moment(billingDate).format("LL"),
    },
    {
      title: "Due date",
      dataIndex: "dueDate",
      width: "200px",
      render: (dueDate) => moment(dueDate).format("LL"),
    },
    {
      title: "Date Created",
      dataIndex: "dateCreated",
      width: "200px",
      editable: true,
      defaultSortOrder: "descend",
      sorter: (a, b) => moment(a.dateCreated).diff(moment(b.dateCreated)),
      render: (dateCreated) => moment(dateCreated).format("LL"),
    },
    {
      title: "Total amount",
      dataIndex: "totalAmount",
      width: "200px",
      render: (amount) => format_PHCurrency(+amount),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      width: "200px",
    },
  ];

  const items = [
    {
      label: (
        <div className="flex gap-2 items-center">
          <EyeOutlined />
          <p>View</p>
        </div>
      ),
      key: "1",
    },
  ];

  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "150px",
      render: (record) => (
        <Dropdown
          trigger={["click"]}
          menu={{
            items,
            onClick: () => showDrawer(record),
          }}
        >
          <Button
            type="primary"
            style={{
              background: "#4d8e03",
            }}
          >
            <Space>
              Action
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
    {
      title: "Reference number",
      dataIndex: "referenceNumber",
      width: "200px",
    },
    {
      title: "Payment channel",
      dataIndex: "paymentChannel",
      width: "200px",
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      width: "200px",
      render: (status) =>
        status === 0 ? (
          <Tag color="blue">Pending</Tag>
        ) : (
          <Tag color="blue">Deleted</Tag>
        ),
    },
    {
      title: "Total amount",
      dataIndex: "totalAmount",
      render: (amount) => format_PHCurrency(+amount),
      width: "200px",
    },
    {
      title: "Total paid",
      dataIndex: "totalPaid",
      render: (amount) => format_PHCurrency(+amount),
      width: "200px",
    },
    {
      title: "Total change",
      dataIndex: "totalChange",
      render: (amount) => format_PHCurrency(+amount),
      width: "200px",
    },
    {
      title: "Message",
      dataIndex: "message",
      width: "200px",
    },
    {
      title: "Due date",
      dataIndex: "dueDate",
      width: "200px",
      render: (dueDate) => moment(dueDate).format("LL"),
    },
    {
      title: "Date Created",
      dataIndex: "dateCreated",
      width: "200px",
      editable: true,
      defaultSortOrder: "descend",
      sorter: (a, b) => moment(a.dateCreated).diff(moment(b.dateCreated)),
      render: (dateCreated) => moment(dateCreated).format("LL"),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      width: "200px",
    },
  ];

  const attachmentCols = [
    {
      title: "Image",
      dataIndex: "imageUrl",
      width: "200px",
      render: (record) => (
        <Image
          style={{
            width: "100px",
            height: "100px",
            objectFit: "cover",
          }}
          src={process.env.REACT_APP_BASE_URL + "/" + record}
        />
      ),
    },
    {
      title: "Date Created",
      dataIndex: "dateCreated",
      width: "200px",
      editable: true,
      defaultSortOrder: "descend",
      sorter: (a, b) => moment(a.dateCreated).diff(moment(b.dateCreated)),
      render: (dateCreated) => moment(dateCreated).format("LL"),
    },
  ];

  const onFinish = (values) => {
    dispatch(
      updateTransaction({
        params: referenceNumber,
        body: {
          totalPaid: +values?.totalPaid,
          totalChange: values?.totalPaid - totalPayment,
          message: values?.message,
        },
      })
    );

    form.resetFields();
  };

  const onFinishFailed = () => {};

  const onFilterSearch = (values) => {
    if (values?.referenceNumber) {
      const newArr = transactionList?.filter(
        (t) => t.referenceNumber === values.referenceNumber
      );
      setTransactionListArr(newArr);
    } else {
      setTransactionListArr(transactionList);
    }
  };

  const clearFormFields = () => {
    formFilter.resetFields();
    setTransactionListArr(transactionList);
  };

  useEffect(() => {
    if (accountId) {
      dispatch(getBillings(token, { params: accountId }));
    }
  }, [accountId]);

  return (
    <div className="flex flex-col gap-7">
      <LoadingTime loading={transactionLoading} />
      <Card
        title={
          <div className="flex w-full pt-5">
            <Form
              form={formFilter}
              layout="vertical"
              // disabled={isLoading}
              className="flex flex-col w-full"
              style={{
                gap: "20px",
              }}
              name="search"
              initialValues={{
                remember: true,
              }}
              onFinish={onFilterSearch}
              // onFinishFailed={onSearchFailed}
              autoComplete="off"
            >
              <div className="grid grid-cols-1 md:grid-cols-2 w-full">
                <div className="w-full">
                  <Form.Item
                    initialValue={""}
                    label={
                      <h1 className="text-[18px] font-[500]">
                        Reference number
                      </h1>
                    }
                    name="referenceNumber"
                  >
                    <Input
                      className="input-gray input-text-gray"
                      placeholder="Enter reference number"
                      allowClear
                      style={{
                        width: "90%",
                        padding: "10px",
                        fontSize: "15px",
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="flex gap-5">
                <Form.Item className="w-full md:w-[140px]">
                  <Button
                    onClick={() => formFilter.submit()}
                    style={{
                      borderRadius: "5px",
                      width: "100%",
                      maxWidth: "140px",
                      height: "40px",
                      background: "#4d8e03",
                      border: "1px solid transparent",
                      fontSize: "15px",
                      fontWeight: "500",
                      marginBottom: 0,
                    }}
                    // loading={isLoading}
                    type="primary"
                    icon={<SearchOutlined />}
                  >
                    Search
                  </Button>
                </Form.Item>
                <Form.Item className="w-full md:w-[140px]">
                  <Button
                    style={{
                      borderRadius: "5px",
                      width: "100%",
                      maxWidth: "140px",
                      height: "40px",
                      border: "2px solid #4d8e03",
                      color: "#4d8e03",
                      fontSize: "15px",
                      fontWeight: "500",
                      marginBottom: 0,
                    }}
                    // loading={isLoading}
                    type="outlined"
                    onClick={() => {
                      clearFormFields();
                    }}
                  >
                    Clear
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        }
      >
        <Table
          loading={isLoading}
          rowKey={"referenceNumber"}
          columns={columns}
          dataSource={
            transactionListArr
              ? transactionListArr?.filter((obj) => obj?.status === 0)
              : []
          }
          scroll={{
            y: 500,
            x: "100%",
          }}
        />
        <Drawer
          placement="right"
          closable={false}
          onClose={closeHandler}
          open={transacDrawer}
          className="demo"
          width={"60%"}
          title={
            <div className="flex flex-col md:flex-row md:items-center items-start justify-start">
              <div
                onClick={() => closeHandler(true)}
                className="w-[50px] h-[50px] items-center text-center flex justify-center text-[20px] rounded-full hover:bg-slate-200 transition-all ease-in duration-150 cursor-pointer hover:scale-110"
              >
                <ArrowLeftOutlined className="text-[#1b1b1b]" />
              </div>
              <h1 className="font-[600] text-[25px] mb-0">Transaction</h1>
            </div>
          }
          footer={
            <div className="fle flex-col justify-end items-end text-right p-4">
              <div className="flex flex-col">
                <h1 className="form-title">Total amount:</h1>
                <h1>
                  {+transaction?.totalAmount
                    ? format_PHCurrency(+transaction?.totalAmount)
                    : 0}
                </h1>
              </div>
              <Button
                className="shadow-md"
                type="primary"
                onClick={() => openForm()}
                style={{
                  minWidth: "200px",
                  height: "52px",
                  fontSize: "17px",
                  fontWeight: "400",
                  borderRadius: "5px",
                  background: "#4d8e03",
                  marginBlock: "13px",
                }}
              >
                Proceed to payment
              </Button>
            </div>
          }
        >
          <div className="flex flex-col gap-3">
            <Card
              title={
                <div className="flex justify-between items-center p-5">
                  <h1 className="font-[600] text-[25px] mb-0">
                    Billing details
                  </h1>
                </div>
              }
            >
              <Table
                loading={transactionLoading}
                className="table-charge"
                rowKey={"billId"}
                columns={billingColumn}
                scroll={{
                  y: 500,
                  x: "100%",
                }}
                dataSource={
                  transaction
                    ? transaction?.details?.map((obj) =>
                        billings?.filter((bill) => bill.billId === obj.billId)
                      )?.[0]
                    : []
                }
              />
            </Card>
            <Card
              title={
                <div className="flex justify-between items-center p-5">
                  <h1 className="font-[600] text-[25px] mb-0">Attachments</h1>
                </div>
              }
            >
              <Table
                loading={transactionLoading}
                className="table-charge"
                rowKey={"attachmentId"}
                columns={attachmentCols}
                scroll={{
                  y: 500,
                  x: "100%",
                }}
                dataSource={
                  transaction.attachments ? transaction.attachments : []
                }
              />
            </Card>
          </div>
          <Drawer
            placement="right"
            closable={false}
            onClose={closeForm}
            open={formDrawer}
            className="demo"
            width={"35%"}
            title={
              <div className="flex flex-col md:flex-row md:items-center items-start justify-start">
                <div
                  onClick={() => closeForm()}
                  className="w-[50px] h-[50px] items-center text-center flex justify-center text-[20px] rounded-full hover:bg-slate-200 transition-all ease-in duration-150 cursor-pointer hover:scale-110"
                >
                  <ArrowLeftOutlined className="text-[#1b1b1b]" />
                </div>
                <h1 className="font-[600] text-[25px] mb-0">Payment</h1>
              </div>
            }
            footer={
              <div className="fle flex-col justify-end items-end text-right p-4">
                <div className="flex flex-col">
                  <h1 className="form-title">Total amount:</h1>
                  <h1>
                    {+transaction?.totalAmount
                      ? format_PHCurrency(+transaction?.totalAmount)
                      : 0}
                  </h1>
                  <h1 className="form-title">Total change:</h1>
                  <h1>
                    {+change < +totalPayment
                      ? "0.00"
                      : format_PHCurrency(+change - +totalPayment)}
                  </h1>
                </div>
                <Button
                  onClick={() => form.submit()}
                  className="shadow-md"
                  type="primary"
                  style={{
                    minWidth: "200px",
                    height: "52px",
                    fontSize: "17px",
                    fontWeight: "400",
                    borderRadius: "5px",
                    background: "#4d8e03",
                    marginBlock: "13px",
                  }}
                >
                  Confirm
                </Button>
              </div>
            }
          >
            <Form
              layout="vertical"
              form={form}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
              name="basic"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className="flex md:flex-col flex-col gap-10 items-center md:items-start p-6">
                <div className="flex flex-col gap-4 w-full   md:flex-1 ">
                  <div className="text-label">
                    <Form.Item
                      value=""
                      name="totalPaid"
                      label={<h1 className="form-title">Enter payment</h1>}
                      rules={[
                        {
                          required: true,
                          message: "Please input your payment",
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) => setChange(e.target.value)}
                        style={{
                          padding: "10px",
                          fontSize: "18px",
                          borderRadius: "3px",
                        }}
                        placeholder="Payment"
                        size="large"
                      />
                    </Form.Item>
                    <Form.Item
                      value=""
                      name="message"
                      label={<h1 className="form-title">Enter message</h1>}
                    >
                      <TextArea placeholder="Message here..." rows={4} />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </Form>
          </Drawer>
        </Drawer>
      </Card>
    </div>
  );
};

export default Transactions;
