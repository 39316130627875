import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

const initialState = {
  isLoading: false,
  errorMessage: null,
  token: null,
  email: null,
  password: null,
  uId: null,
  duration: 0,
};

const generateCodeSlice = createSlice({
  name: "generateOTP",
  initialState,
  reducers: {
    generateCode(state, action) {
      state.isLoading = true;
      state.email = action.payload;
    },
    generateCodeReset(state) {
      state.isLoading = false;
      state.errorMessage = null;
      state.token = null;
    },
    generateCodeSuccess(state, action) {
      console.log(action.payload);
      state.token = action.payload.token;
      state.isLoading = false;
      message.success(action.payload.message);
    },
    validateCode(state) {
      state.isLoading = true;
    },
    validateCodeReset(state) {
      state.uId = null;
    },
    validateCodeSuccess(state, action) {
      console.log(action.payload);
      state.uId = action.payload.uId;
      state.isLoading = false;
      message.success(action.payload.message);
    },
    resetPassword(state) {
      state.isLoading = true;
    },
    resetPasswordSuccess(state, action) {
      console.log(action.payload);
      state.password = action.payload;
      state.isLoading = false;
      message.success(action.payload.message);
    },
    resetPasswordError(state, action) {
      console.log(action.payload);
      message.warning(action.payload.data.message);
      state.isLoading = false;
    },
    generateError(state, action) {
      console.log(action.payload);
      state.isLoading = false;
      state.duration = action?.payload?.data?.duration;
      message.warning(action.payload.data.message);
    },
    validateError(state, action) {
      console.log(action.payload);
      state.isLoading = false;
      message.warning(action.payload.data.message);
    },
  },
});

export default generateCodeSlice;
