import { Button, Form, Steps } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingTime } from "../../UI/LoadingScreen";
import propLogo from "../../assets/resources/images/properityReg.png";
import { onboarding } from "../../store/slices/registration-slice/registration-slice";
import "../registration/registration.css";
import AssocCheckDetails from "./AssocCheckDetails";
import AssocFiles from "./AssocFiles";
import AssocLocation from "./AssocLocation";
import AssocLogo from "./AssocLogo";
import AssocName from "./AssocName";

const Registration = () => {
  const [formNames] = Form.useForm();
  const [formLoc] = Form.useForm();
  const [formLogo] = Form.useForm();
  const [formFiles] = Form.useForm();
  const [done, setDone] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { checking, isLoading, settingUp } = useSelector(
    (state) => state.onboarding
  );

  const [formValues, setFormValues] = useState();

  const [current, setCurrent] = useState(1);

  const finishOnboarding = () => {
    let formData = new FormData();

    formData.append("firstName", formValues?.firstName);
    formData.append("lastName", formValues?.lastName);
    formData.append("assocName", formValues?.assocName);
    formData.append("assocAcronym", formValues?.assocAcronym);
    formData.append("email", formValues?.email);
    formData.append("mobileNumber", "63" + formValues?.mobileNumber);
    formData.append("brgyId", formValues?.brgyId);
    formData.append("cityId", formValues?.cityId);
    formData.append("regionId", formValues?.regionId);
    formData.append("provinceId", formValues?.provinceId);

    for (let index = 0; index < formValues?.logo?.length; index++) {
      formData.append(
        "logo",
        formValues?.logo === "" ? null : formValues?.logo[index]
      );
    }

    for (let index = 0; index < formValues?.fileUrl?.length; index++) {
      formData.append(
        "file",
        formValues?.fileUrl === "" ? null : formValues?.fileUrl[index]
      );
    }

    dispatch(onboarding({ formData }));
  };

  useEffect(() => {
    if (checking) {
      navigate("/Login");
    }
  }, [checking]);

  useEffect(() => {
    if (current === 4) {
      setDone(true);
    }
  }, [current]);

  const next = () => {
    if (current === 1) {
      formLogo.submit();
    } else if (current === 2) {
      formFiles.submit();
    } else if (current === 3) {
      formNames.submit();
    } else if (current === 4) {
      formLoc.submit();
    } else if (current === 5) {
      setCurrent(current + 0);
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const steps = [
    <AssocLogo
      setFormValues={setFormValues}
      setCurrent={setCurrent}
      formData={formLogo}
    />,
    <AssocFiles
      setFormValues={setFormValues}
      setCurrent={setCurrent}
      formData={formFiles}
    />,
    <AssocName
      setFormValues={setFormValues}
      setCurrent={setCurrent}
      formData={formNames}
    />,
    <AssocLocation
      setFormValues={setFormValues}
      setCurrent={setCurrent}
      formData={formLoc}
    />,
    <AssocCheckDetails information={formValues} />,
  ];

  return (
    <div
      className="relative "
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LoadingTime loading={isLoading} />

      <div className="flex flex-col h-[90%] items-center justify-evenly w-full gap-4 px-[100px]">
        <div className="w-[70%]">
          <Steps
            size="large"
            current={current - 1}
            items={[
              {
                title: <span className="cursor-pointer">Logo</span>,
                onClick: () => {
                  if (done === false) {
                    if (current >= 1) {
                      setCurrent(1);
                    }
                  } else {
                    setCurrent(1);
                  }
                },
              },
              {
                title: <span className="cursor-pointer">Files</span>,
                onClick: () => {
                  if (done === false) {
                    if (current >= 2) {
                      setCurrent(2);
                    }
                  } else {
                    setCurrent(2);
                  }
                },
              },
              {
                title: <span className="cursor-pointer">Details</span>,
                onClick: () => {
                  if (done === false) {
                    if (current >= 3) {
                      setCurrent(3);
                    }
                  } else {
                    setCurrent(3);
                  }
                },
              },
              {
                title: <span className="cursor-pointer">Location</span>,
                onClick: () => {
                  if (done === false) {
                    if (current >= 4) {
                      setCurrent(4);
                    }
                  } else {
                    setCurrent(4);
                  }
                },
              },
              {
                title: <span className="cursor-pointer">Finishing</span>,
                onClick: () => {
                  if (done === false) {
                    if (current >= 5) {
                      setCurrent(5);
                    }
                  } else {
                    setCurrent(5);
                  }
                },
              },
            ]}
          />
        </div>
        <img className="w-[210px]" src={propLogo} alt="" />
        <div>{steps[current - 1]}</div>
        <div className="flex justify-between items-center w-full text-center  ">
          <Button
            disabled={current === 1}
            style={{
              width: "253px",
              height: "70px",
              fontSize: "23px",
              borderColor: "transparent",
              background: "transparent",
              color: "#568634",
              fontWeight: 500,
              opacity: current === 1 ? 0 : 100,
              cursor: current === 1 ? "default" : "pointer",
            }}
            type="primary"
            onClick={() => prev()}
          >
            Back
          </Button>
          <p
            className={`text-center items-center font-[700] text-[#568634] text-[20px] ${
              current === 5 && "hidden"
            }`}
          >
            Step {current} of 4
          </p>
          <Button
            style={{
              width: "253px",
              height: "70px",
              fontSize: "23px",
              borderRadius: "10px",
              background: "linear-gradient(90deg, #4D8E03 0%, #B8DA96 100%) ",
            }}
            type="primary"
            onClick={current === 5 ? () => finishOnboarding() : () => next()}
          >
            {current === 5 ? "Finish" : "Next"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Registration;
