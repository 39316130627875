import { DownOutlined } from "@ant-design/icons";
import React from "react";
import { Link } from "react-scroll";

const Discover = () => {
	return (
		<>
			<div className=" flex justiy-center items-center w-full bg-[#F5F5EE]">
				<Link
					className=" mx-auto mt-2 border-none bg-[#F5F5EE] cursor-pointer flex flex-col justify-center items-center"
					to="about"
					spy={true}
					smooth={true}
					offset={-60}
					duration={500}
				>
					<h1 className=" sm:text-xl xs:text-lg text-[16px] text-[#535353] font-medium">
						Discover more
					</h1>
					<DownOutlined className="" />
				</Link>
			</div>
		</>
	);
};

export default Discover;
