import { Form, Select } from "antd";
import { React, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import location from "../../location/all.json";

const AssocLocation = ({ setFormValues, formData, setCurrent }) => {
  const { isLoading } = useSelector((state) => state.auth);

  const [region, setRegion] = useState([location.region]);
  const [province, setProvince] = useState([]);
  const [city, setCity] = useState([]);
  const [brgy, setBrgy] = useState([]);

  const [regionValue, setRegionValue] = useState();
  const [provinceValue, setProvinceValue] = useState();
  const [cityValue, setCityValue] = useState();
  const [brgyValue, setBrgyValue] = useState();

  const [regCode, setRegCode] = useState();
  const [provCode, setProvCode] = useState();
  const [cityCode, setCityCode] = useState();
  const [brgyCode, setBrgyCode] = useState();

  const onFinish = (values) => {
    setFormValues((prevState) => ({ ...prevState, ...values }));
    setCurrent((prev) => prev + 1);
  };

  const onFinishFailed = (errorInfo) => {};

  const onChangeRegion = (reg, label) => {
    setRegionValue(label.label);
    setRegion(reg);
    setRegCode(reg);

    setProvinceValue(null);
    setCityValue(null);
    setBrgyValue(null);

    formData.setFieldsValue({
      provinceId: null,
      cityId: null,
      brgyId: null,
    });

    setProvince(location.province.filter((obj) => obj.regCode === reg));
  };
  const onChangeProvince = (prov, label) => {
    setProvinceValue(label.label);
    setCity(location.city.filter((obj) => obj.provCode === prov));
    setProvCode(prov);
  };
  const onChangeCity = (city, label) => {
    setCityValue(label.label);
    setBrgy(location.brgy.filter((obj) => obj.cityCode === city));
    setCityCode(city);
  };
  const onChangeBrgy = (brgy, label) => {
    setBrgyValue(label.label);
    setBrgyCode(brgy);
  };
  const onSearch = (value) => {};

  useEffect(() => {
    setProvince(
      location.province.filter(
        (obj) => obj.regCode === formData.getFieldValue("regionId")
      )
    );
    setCity(
      location.city.filter(
        (obj) => obj.provCode === formData.getFieldValue("provinceId")
      )
    );
    setBrgy(
      location.brgy.filter(
        (obj) => obj.cityCode === formData.getFieldValue("cityId")
      )
    );
  }, []);

  return (
    <div
      className="poppins flex flex-col items-center"
      data-aos="fade-left"
      data-aos-easing="ease-in"
      data-aos-duration="600"
    >
      <h1 className="text-[#568634] font-[700] text-[40px] pb-10">
        Where are you located?
      </h1>

      <Form
        layout="vertical"
        form={formData}
        disabled={isLoading}
        style={{
          width: "100%",
        }}
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
            gap: "50px",
          }}
        >
          <div>
            <Form.Item
              name="regionId"
              label={
                <h1
                  style={{
                    color: "#568634",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  Region
                </h1>
              }
              rules={[
                {
                  required: true,
                  message: "Please state your region",
                },
              ]}
            >
              <Select
                className="loc"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                optionFilterProp="children"
                showSearch
                onChange={onChangeRegion}
                onSearch={onSearch}
                placeholder="Region"
                options={location.region.map((reg, i) => {
                  return {
                    value: reg.regCode,
                    label: reg.regDesc,
                  };
                })}
              ></Select>
            </Form.Item>
            <Form.Item
              name="cityId"
              label={
                <h1
                  style={{
                    color: "#568634",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  City
                </h1>
              }
              rules={[
                {
                  required: true,
                  message: "Please state your city",
                },
              ]}
            >
              <Select
                className="loc"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                optionFilterProp="children"
                showSearch
                onChange={onChangeCity}
                onSearch={onSearch}
                placeholder="City"
                options={city?.map((cty, i) => {
                  return {
                    value: cty.cityCode,
                    label: cty.cityDesc,
                  };
                })}
              ></Select>
            </Form.Item>
          </div>
          <div>
            <Form.Item
              name="provinceId"
              label={
                <h1
                  style={{
                    color: "#568634",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  Province
                </h1>
              }
              rules={[
                {
                  required: true,
                  message: "Please state your province",
                },
              ]}
            >
              <Select
                className="loc"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                optionFilterProp="children"
                showSearch
                onChange={onChangeProvince}
                onSearch={onSearch}
                placeholder="Province"
                options={province?.map((prov, i) => {
                  return {
                    value: prov.provCode,
                    label: prov.provDesc,
                  };
                })}
              ></Select>
            </Form.Item>

            <Form.Item
              name="brgyId"
              label={
                <h1
                  style={{
                    color: "#568634",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  Barangay
                </h1>
              }
              rules={[
                {
                  required: true,
                  message: "Please state your barangay",
                },
              ]}
            >
              <Select
                className="loc"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                optionFilterProp="children"
                showSearch
                onChange={onChangeBrgy}
                onSearch={onSearch}
                placeholder="Barangay"
                options={brgy?.map((brgy, i) => {
                  return {
                    value: brgy.brgyCode,
                    label: brgy.brgyDesc,
                  };
                })}
              ></Select>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AssocLocation;
