import { put, call, takeLatest, select } from "redux-saga/effects";
import {
  getTransactionList,
  getTransaction,
  updateTransaction,
} from "../../api/transactions/transactions-api";
import { transactionActions } from "../../store";

function* getTransactionListRequest({ payload }) {
  const { requestError, getTransactionListSuccess } = transactionActions;
  const result = yield call(getTransactionList, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getTransactionListSuccess(result.data));
    console.log(result.data);
  }
}

function* getTransactionRequest({ payload }) {
  const { requestError, getTransactionSuccess } = transactionActions;
  const result = yield call(getTransaction, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    yield put(getTransactionSuccess(result.data));
    console.log(result.data);
  }
}

function* updateTransactionRequest({ payload }) {
  const { requestError, updateTransactionSuccess, getTransactionList } =
    transactionActions;
  const result = yield call(updateTransaction, payload);
  if (result.name === "AxiosError") {
    yield put(requestError(result.response));
  } else {
    const { token } = yield select((state) => state.auth);

    yield put(updateTransactionSuccess(result.data));
    yield put(getTransactionList(token));
  }
}

export default function* transactionSaga() {
  yield takeLatest(`transaction/getTransactionList`, getTransactionListRequest);
  yield takeLatest(`transaction/getTransaction`, getTransactionRequest);
  yield takeLatest(`transaction/updateTransaction`, updateTransactionRequest);
}
