import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

const initialState = {
  isLoading: false,
  transactionList: [],
  transactionLoading: false,
  transaction: [],
  paymentError: false,

  transacDrawer: false,
  formDrawer: false,
};

const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    transacDrawerHandler(state, action) {
      state.transacDrawer = action.payload;
    },
    formDrawerHandler(state, action) {
      state.formDrawer = action.payload;
    },
    getTransactionList(state) {
      state.transactionList = [];
      state.isLoading = true;
    },
    getTransactionListSuccess(state, action) {
      state.transactionList = action.payload;
      state.isLoading = false;
    },
    getTransaction(state) {
      state.transaction = [];
      state.transactionLoading = true;
    },
    getTransactionSuccess(state, action) {
      state.transaction = action.payload;
      state.transactionLoading = false;
    },
    updateTransaction(state) {
      state.transactionLoading = true;
      state.paymentError = true;
    },
    updateTransactionSuccess(state, action) {
      state.transactionLoading = false;
      state.paymentError = false;
      state.transacDrawer = false;
      state.formDrawer = false;
      message.success(action.payload.message);
    },
    requestError(state, action) {
      state.paymentError = true;
      state.isLoading = false;
      state.transactionLoading = false;
      message.error(action.payload.data.message);
    },
  },
});

export const {
  requestError,
  closeTransacDrawer,
  closeFormDrawer,
  getTransactionList,
  getTransactionListSuccess,
  getTransaction,
  getTransactionSuccess,
  updateTransaction,
  updateTransactionSuccess,
  transacDrawerHandler,
  formDrawerHandler,
} = transactionSlice.actions;
export default transactionSlice;
